"use client";
import React from "react";
import PropTypes from "prop-types";

export default function TextArea({
  register,
  name,
  value,
  validation,
  ...props
}) {
  const registerField = register
    ? {
      ...register(name, { ...validation }),
      value,
      ...props,
    }
    : { value, ...props };

  return (
    <textarea
      className="min-h-[143px] w-[100%] text-[14px] leading-[20px] p-[10px] border-[1px] rounded-[5px] mb-[10px] border-cta-4 disabled:bg-cta-4 focus:outline-cta-1 hover:border-cta-4 focus:border-cta-1 active:border-cta-1 disabled:text-text-3 placeholder:text-text-3 focus:text-text-2 hover:text-text-2  active:text-text-2 bg-bg-3 text-text-3"
      {...registerField}
    ></textarea>
  );
}

TextArea.propTypes = {
  value: PropTypes.any,
  register: PropTypes.func,
  name: PropTypes.string,
  validation: PropTypes.object,
  props: PropTypes.object
};
