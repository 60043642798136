"use client"
import React from "react"
import { twMerge } from "tailwind-merge"
import PropTypes from "prop-types"
export default function InfoHeader({ icon, children, className }) {
  return (
    <div
      data-testid="info-header-wrapper"
      className={twMerge(
        "flex items-center gap-[10px] lg:gap-[11px] text-[14px] leading-[20px] font-medium font-font-family-6",
        className
      )}
    >
      <div
        data-testid="info-header-icon"
        className="!w-[18px] lg:!w-[28px] !h-[18px] lg:!h-[28px] bg-bg-5 rounded-[5px] lg:rounded-[10px] flex items-center justify-center overflow-hidden lg:pl-[0.5px]"
      >
        {icon}
      </div>
      <div
        data-testid="info-header-text"
        className="text-[14px] lg:text-[16px] break-words leading-[19px] lg:leading-[20px] font-medium font-font-family-6"
      >
        {children}
      </div>
    </div>
  )
}

InfoHeader.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
}
