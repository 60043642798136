'use client';
import { usePathname } from 'next/navigation';
import React, { createContext, useState } from 'react';

const PrevPath = createContext();

const usePrevious = (value) => {
    const [tuple, setTuple] = useState([null, value]); // [prev, current]

    if (tuple[1] !== value) {
        setTuple([tuple[1], value]);
    }

    return tuple;
};

const PrevPathProvider = ({ children }) => {
    const pathname = usePathname();

    const [prevPath, currentPath] = usePrevious(pathname);

    return (
        <PrevPath.Provider value={{ prevPath, currentPath }}>
            {children}
        </PrevPath.Provider>
    );
};

export { PrevPath, PrevPathProvider };
