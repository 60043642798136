"use client"
import React, { useEffect, useState } from "react"
import HeroLeaderboard from "./components/hero/hero"
import Reward from "./components/rewards/reward"
import Footer from "./components/footer/footer"
import Ranks from "./components/ranks/ranks"
import { usePathname } from "next/navigation"
import { useReqUrl } from "@/hooks/auth/use-req-url"
import { getQueryParams } from "@/helper/queryparam/get-query-params"
import { ClientApiList } from "@/lib/api/client-service"
import { pushDataLayer } from "@/helper/datalayer/data-layer"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { Image } from "@/components/shared/image/image"
import clsx from "clsx"
import { getGALinker, getGL } from "@/helper/ga"
import { useRouter } from "next/navigation";

const bgMobile = "/assets/amild/aspace-leaderboard/bg-hero-aspace.jpg"

const campaign = "a space"

const Leaderboard = () => {
  const landing = getQueryParams("to")
  const isMobile = useIsMobile()
  const pathname = usePathname()
  const {replace} = useRouter()

  const [dataRank, setDataRank] = useState(null)
  const [dataLeaderboard, setDataLeaderboard] = useState(null)
  const [loadingASpace, setLoadingASpace] = useState(false)

  const handleRequest = async () => {
    const handleUserRank = ClientApiList.getUserRank({
      page: 1,
      limit: 10,
    })
    const handleLeaderboard = ClientApiList.getLeaderBoard({
      page: 1,
      limit: 10,
    })
    const result = await Promise.all([handleLeaderboard, handleUserRank])
    setDataLeaderboard(result[0])
    setDataRank(result[1])
  }

  useEffect(() => {
    handleRequest()
  }, [])

  const { execute } = useReqUrl({
    redirectUrl: "/aspace/br/leaderboard",
    platform: "amild x a space",
    page: "login",
  })

  const { execute: autoLogin } = useReqUrl({
    redirectUrl: "/aspace/br/leaderboard?to=aspace",
    platform: "amild x a space",
    page: "login",
  })

  useEffect(() => {
    if (landing === "aspace") {
      handleRedirectASpace()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landing])

  const handleRedirectASpace = async () => {
    setLoadingASpace(true)

    const {
      data: { data: auth_data },
    } = await ClientApiList.getAuthData({
      ga: getGALinker(),
      gl: getGL(),
      platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
      redirect_url: `${process.env.NEXT_PUBLIC_META_URL}${pathname}`,
    })
    const { data } = await ClientApiList.getAspaceToken({
      auth_data: auth_data.data.auth_data,
    })
    const redirectASpace = data?.data?.redirect_url

    if (redirectASpace) {
      window
        .open(
          redirectASpace,
          "_self"
          // "_blank" // <- This is what makes it open in a new window.
        )
        .focus()
      setLoadingASpace(false)
      // router.replace(router.pathname)
    } else if (data.error?.code === 500) {
      console.log("failed login 500")
      autoLogin()
    } else {
      console.log("failed login a space")
      setLoadingASpace(false)
    }
  }

  const handleOnClick = async (btn, title) => {
    try {
      const { data: dataProfile } = await ClientApiList.getProfileData()
      pushDataLayer({
        event: "generalEvent",
        event_category: "clicked on button",
        event_action: btn?.toLowerCase(),
        campaignName: campaign,
      })
      const { data } = await ClientApiList.getInteractionUser({
        user_id: dataProfile?.data.data.gigya_id,
        event: "general_event",
        campaign: campaign,
        event_category: "button",
        event_action: `clicked on button ${btn}`,
        event_label: `${title?.toLowerCase()} - ${campaign}`,
        creative: typeof window !== "undefined" ? window.location.href : "",
      })

      if (data?.data.status === "OK") {
        //   if (branded) {
        handleRedirectASpace()
        // handleRedirectASpacePlanB()
      } else {
        execute()
      }
    } catch (err) {
      if (err.status === 401) window.location.reload()
    }
  }

  return (
    <div className="w-full relative bg-[#fafafa]">
      {isMobile && (
        <div
          className={clsx(
            "absolute w-full aspect-[375/750] top-0 left-0 right-0 overflow-hidden"
          )}
        >
          <Image alt="bg" src={bgMobile} />
        </div>
      )}
      <HeroLeaderboard
        handleOnClick={handleOnClick}
        loadingASpace={loadingASpace}
      />
      <Ranks dataRank={dataRank} dataLeaderboard={dataLeaderboard} />
      <div
        className={
          !isMobile &&
          "flex gap-[15px] justify-between p-[20px] border border-[#E5E5E5] rounded-[10px] m-[15px]"
        }
      >
        <Reward />
        <Footer handleOnClick={handleOnClick} loadingASpace={loadingASpace} />
      </div>
    </div>
  )
}

export default Leaderboard
