"use client"
import React, { useEffect, useState } from "react"
import { Image } from "@/components/shared/image/image"
import CampaignCard from "../campaign-intro/campaign-card/campaign-card"
import { useIsMobile } from "@/hooks/use-is-mobile"
import PropTypes from "prop-types"
import { getPublishDate, useDatalayer } from "@/hooks/use-datalayer"
import { useReqUrl } from "@/hooks/auth/use-req-url"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { ClientApiList } from "@/lib/api/client-service"
import { getGALinker, getGL } from "@/helper/ga"
import { urlToCampaign } from "@/helper/utils"

export function CampaignAspace({ ...props }) {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const [isAspaceLoading, setIsAspaceLoading] = useState(false)
  const { replace } = useRouter()
  const datalayer = useDatalayer()
  const isMobile = useIsMobile()

  const { execute: autoLogin } = useReqUrl({
    redirectUrl: `${pathname}?to=aspace`,
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
    page: "login",
  })

  const handleRedirectASpace = async () => {
    setIsAspaceLoading(true)
    const {
      data: { data: auth_data },
    } = await ClientApiList.getAuthData({
      ga: getGALinker(),
      gl: getGL(),
      platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
      redirect_url: `${process.env.NEXT_PUBLIC_META_URL}${pathname}`,
    })
    const { data, error } = await ClientApiList.getAspaceToken({
      auth_data: auth_data.data.auth_data,
    })
    const redirectASpace = data?.data.redirect_url

    if (redirectASpace) {
      window
        .open(
          redirectASpace,
          "_self"
          // "_blank" // <- This is what makes it open in a new window.
        )
        .focus()
      setIsAspaceLoading(false)
      replace(pathname)
    } else if (error?.code === 500) {
      autoLogin()
    } else {
      setIsAspaceLoading(false)
    }
  }

  useEffect(() => {
    if (searchParams.get("to") === "aspace") {
      handleRedirectASpace()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const handleDataLayer = (type, category, pos, name, date, campaign) => {
    datalayer.push({
      event: "general_event",
      event_name: "click_content",
      feature: "hero engagement page",
      content_section: "campaign page",
      content_brand: "branded", // e.g branded or unbranded
      content_type: type || "not available", // e.g article, carousel, video, etc
      content_category: category || "not available", // e.g music, travel, automotive, etc
      content_position: pos || 1, // e.g 1, 2, 3, etc (integer)
      content_name: name, // e.g the_ultimate_xperience, etc
      publish_date: getPublishDate(date) || "not available", // e.g 23-10-2023 (date of content publish)
      campaign_name: urlToCampaign(pathname), // e.g ride your flavour 2023
    })
  }

  const setImage = {
    mobile: props?.final_challenge_banner_mobile,
    desktop: props?.final_challenge_banner_desktop,
  }

  return (
    <>
      <CampaignCard className="w-full col-span-2 min-h-[340px] lg:min-h-[431px] lg:mb-[0] bg-bg-5 !p-0">
        {/* <div className='absolute inset-0 h-100'> */}
        <button
          type="button"
          disabled={isAspaceLoading}
          className={"min-h-[340px] lg:min-h-[431px] block"}
          onClick={() => {
            handleDataLayer(
              props?.format,
              props?.cat_string_id,
              1,
              props?.title,
              props?.start_date
            )
            handleRedirectASpace()
          }}
        >
          <Image
            className="absolute inset-[0] object-top"
            src={isMobile ? setImage.mobile : setImage.desktop}
            alt="cover"
          />
        </button>
        {/* </div> */}
      </CampaignCard>
    </>
  )
}

CampaignAspace.propTypes = {
  final_challenge_banner_mobile: PropTypes.string,
  final_challenge_banner_desktop: PropTypes.string,
  final_engagement_type: PropTypes.string,
}
