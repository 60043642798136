import { ClientApiList } from "@/lib/api/client-service"
import { usePathname, useRouter } from "next/navigation"
import { useState } from "react"
import { useForm } from "react-hook-form"

const useEditProfile = ({
  setIsError,
  setIsSuccess,
  cities,
  setToastMessage,
  captchaRef,
}) => {
  const { replace } = useRouter()
  const pathname = usePathname()

  const handleGetData = async () => {
    const { data } = await ClientApiList.getProfileData()
    const dob = new Date(data.data.data.dob)
    const date = dob.getDate()
    const month = dob.getMonth() + 1
    const year = dob.getFullYear()
    const fav_brand = data.data.data.favorite_brand.brand_id
    const stop_subscribe_email_promo = !data.data.data.subscribe_email_promo
    return {
      ...data.data.data,
      date,
      month,
      year,
      fav_brand,
      stop_subscribe_email_promo,
      "g-recaptcha-response": false,
    }
  }

  const resetFormValue = async () => {
    const result = await handleGetData()
    resetForm(result)
  }

  const {
    register,
    getValues,
    handleSubmit,
    reset: resetForm,
    setError,
    setFocus,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: async () => handleGetData(),
  })

  const [formError, setFormError] = useState(null)

  const reset = () => {
    setFormError(null)
  }

  const onSubmit = async (data) => {
    captchaRef.current.reset()
    const token = await captchaRef.current.executeAsync()

    const body = {
      email: data.email,
      phone_number: data.phone,
      fav_brand: data.fav_brand,
      address: data.address,
      city: data.city_id,
      gender: data.gender,
      stop_subscribe_email_promo: data.stop_subscribe_email_promo,
      province: cities.find((city) => city.id === data.city_id).mst_province
        .province_id,
      "g-recaptcha-response": token,
    }

    if (!token) {
      setIsError(true)
      // captchaRef.current?.execute();
      replace(pathname)
      return
    } else {
      setError(false)
      setIsError(false)

      try {
        setToastMessage("Berhasil Ubah Profile")
        const result = await ClientApiList.updateProfileData({ ...body })
        const resultCheckPoint = await ClientApiList.checkUserPoint()

        if (
          result.status === 200 &&
          resultCheckPoint.status === 200 &&
          resultCheckPoint.data.data.activity_point.complete_profile.status &&
          !resultCheckPoint.data.data.activity_point.complete_profile
            .popup_displayed
        ) {
          setToastMessage(
            `Berhasil Ubah Profile, ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "Kamu" : "Lo"} Berhasil Dapetin ${resultCheckPoint.data.data.activity_point.complete_profile.point_get} Points`
          )
          try {
            const resultUpdatePopup = await ClientApiList.updatePopup({
              activity: "complete_profile",
            })

            if (resultUpdatePopup.status === 200) {
              setTimeout(() => {
                setIsSuccess(true)
              }, 100)
              setTimeout(() => {
                replace(pathname + "?point=true")
                resetFormValue()
              }, [400])
            }
          } catch (err) {
            // captchaRef.current?.execute();
            return
          }
        } else if (
          result.status === 200 &&
          resultCheckPoint.status === 200 &&
          resultCheckPoint.data.data.activity_point.complete_profile.status &&
          resultCheckPoint.data.data.activity_point.complete_profile
            .popup_displayed
        ) {
          // if (result.status === 200) {
          setIsSuccess(true)
          setTimeout(() => {
            replace(pathname)
            resetFormValue()
          }, [300])
          // }
        } else {
          // captchaRef.current?.execute();
          return
        }
      } catch (err) {
        // captchaRef.current?.execute();
        return
      }
    }
  }

  return {
    register,
    getValues,
    errors,
    formError,
    reset,
    setError,
    clearErrors,
    setFocus,
    setValue,
    onSubmit: handleSubmit(onSubmit),
  }
}

export default useEditProfile
