"use client"
import React from 'react';
import { twMerge } from 'tailwind-merge';

export default function LeaderboardTopUserAvatar({ children, className }) {
    return (
        <div className={twMerge("relative z-[1] w-[65px] md:w-[95px] min-h-[65px] md:h-[95px] order-1 rounded-[50%] flex items-center justify-center mb-[10px] md:mb-[13.5px]", className)}>
            {children}
        </div>
    );
}
