"use client"
import React from "react"
import Style from "./index.module.css"
import { twMerge } from "tailwind-merge"
import PropTypes from "prop-types"

export default function CheckBox({
  label,
  className,
  value,
  name,
  register,
  isCenter = false,
  disabled,
  options,
  ...props
}) {
  const registerField = register
    ? {
        ...register(name, options ? options : {}),
        ...props,
      }
    : { value, ...props }

  return (
    <label
      data-testid="label"
      className={twMerge(
        `${Style.container} ${disabled ? Style.disabled : ""} py-[5px] text-text-2 text-[12px] leading-[16px] tracking-[0.2px] flex items-center gap-[5px] border-cta-4 disabled:bg-cta-4 focus:outline-cta-1 hover:border-cta-4 focus:border-cta-1 active:border-cta-1 disabled:text-text-3 placeholder:text-text-3 focus:text-text-2 hover:text-text-2  active:text-text-2 bg-bg-3`,
        className
      )}
    >
      <input disabled={disabled} type="checkbox" {...registerField} />
      <span
        className={`${Style.checkmark} ${isCenter ? Style.isCenter : ""} rounded-[4.8px] border-[1px] border-cta-4 disabled:bg-cta-4 focus:outline-cta-1 hover:border-cta-4 focus:border-cta-1 active:border-cta-1 disabled:text-text-3 placeholder:text-text-3 focus:text-text-2 hover:text-text-2  active:text-text-2 bg-bg-3`}
      ></span>
      {label}
    </label>
  )
}

CheckBox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  register: PropTypes.func,
  name: PropTypes.string,
}
