"use client"
import { useSearchParams, useRouter, usePathname } from "next/navigation"

const UseQueryParams = () => {
  const searchParams = useSearchParams()
  const { replace } = useRouter()
  const pathname = usePathname()

  const handleManipulateQuery = (
    addedName,
    addedValue,
    deleteName,
    isScroll = false
  ) => {
    let paramsEntries = [...searchParams.entries()]
    const foundParam = [...paramsEntries].findIndex(
      (param) => param[0] === addedName
    )
    if (foundParam !== -1) {
      paramsEntries = paramsEntries.map((item) =>
        item[0] === addedName ? [item[0], addedValue] : item
      )
    }

    const params = [
      ...paramsEntries,
      foundParam !== -1 ? [] : [addedName, addedValue],
    ]
      .filter((param) => param[0] !== deleteName)
      .map((param) => param.join("="))
      .join("&")

    replace(`${pathname}${params ? "?" : ""}${params}`, { scroll: isScroll })
  }

  const handleFilterQuery = (name, isScroll = true) => {
    console.log("here")

    const newSearchParams = new URLSearchParams(searchParams)

    if (newSearchParams.has(name)) {
      newSearchParams.delete(name)
      const finalSearchParamas = newSearchParams.toString()

      replace(
        `${pathname}${finalSearchParamas ? `?${finalSearchParamas}` : ""}`,
        { scroll: isScroll }
      )
    }
  }

  const handlePushQuery = (isScroll = false, ...valueAdded) => {
    const newSearchParams = new URLSearchParams(searchParams)

    valueAdded.forEach((value) => {
      newSearchParams.set(value[0], value[1])
    })

    const finalSearchParamas = newSearchParams.toString()

    replace(
      `${pathname}${finalSearchParamas ? `?${finalSearchParamas}` : ""}`,
      { scroll: isScroll }
    )
  }

  return { handlePushQuery, handleFilterQuery, handleManipulateQuery }
}

export default UseQueryParams
