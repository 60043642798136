"use client"
export const data = {
  title: "Verifikasi Akun",
  description: `Untuk memverifikasi akun ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"}, masukkan 4 digit kode yang dikirimkan lewat SMS ke nomor ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"}`,
  formList: {
    section_title: "Masukkan OTP",
    formItems: [
      {
        label: "",
        type: "otp",
        placeholder: "",
      },
    ],
  },
  resend_label: "Tidak menerima OTP?",
  resend_link_label: "Kirim Ulang",
  resend_wait_label: "Tunggu 60 detik untuk mengirim ulang OTP",
  cta_label: "Verifikasi",
}

export const dataEmail = {
  title: "Verifikasi Akun",
  description: `Untuk memverifikasi akun ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"}, masukkan 4 digit terakhir dari nomor telepon <i>missed call</i> yang ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"} terima`,
  formList: {
    section_title: "Masukkan OTP",
    formItems: [
      {
        label: "",
        type: "otp",
        placeholder: "",
      },
    ],
  },
  resend_label: "Tidak menerima OTP?",
  resend_link_label: "Kirim Ulang",
  resend_wait_label: "Tunggu 60 detik untuk mengirim ulang OTP",
  cta_label: "Verifikasi",
}
