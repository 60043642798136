"use client"
export const changeEmail = {
  title: "Update Email",
  description: `Masukkan email baru yang mau ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"} ganti dan password akun ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"} buat lanjut ganti email`,
  formList: {
    section_title: "Update Email",
    formItems: [
      {
        label: "Email Baru",
        type: "text",
        name: "email",
        placeholder: "Contoh: joshthompson@mail.com",
      },
      {
        label: "Password",
        type: "password",
        name: "password",
        placeholder: `Masukkan password ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"} di sini`,
      },
    ],
  },
  submit_lable: "Simpan Perubahan",
  cancel_lable: "Batal",
}
