"use client"
import React from "react"
import Styles from "./ranks-card.module.scss"
import { Image } from "@/components/shared/image/image"

const RanksCard = ({ user, top }) => {
  return (
    <div className={`${Styles.rank__card} ${!top ? Styles.top : ""}`}>
      <div data-testid="ranks-index">#{user.index}</div>
      <div data-testid="ranks-name">{user.name}</div>
      <div>
        <div data-testid="ranks-points">{user.points}</div>
        <div className={Styles.status}>
          {user.status !== "up" && user.status !== "down" ? null : (
            <Image
              data-testid="ranks-status"
              src={`/assets/leaderboard/${user.status}.png`}
              layout="fill"
              alt="status"
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default RanksCard
