"use client";
import React, { Fragment, useRef, useState } from "react";
import EditNav from "../edit-profile/edit-nav";
import EditHeader from "../edit-profile/edit-header";
import FormBox from "../edit-profile/form-box";
import ButtonAction from "../shared/button-action/button-action";

import { changePassword } from "../edit-profile/data/edit-profile";
import useRenderForm from "@/hooks/useRenderForm";
import useEditPassword from "@/hooks/use-edit-password";
import { ToastComponent } from "../toast";
import ReCAPTCHA from "react-google-recaptcha";
import { useIsMobile } from "@/hooks/use-is-mobile";
import { Image } from "../shared/image/image";
import { useRouter } from "next/navigation";
import { capitalizeFirstLetter } from "@/helper/utils";

export function EditProfilePassword() {
  const captchaRef = useRef();
  const [isError, setIsError] = useState(false);
  const { back } = useRouter();
  const isMobile = useIsMobile();
  const [isSuccess, setIsSuccess] = useState(false);
  const [handleRenderLabel, handleRenderInput] = useRenderForm();
  const { register, getValues, watch, onSubmit, errors } = useEditPassword({
    setIsSuccess,
    captchaRef, setIsError
  });

  const isPasswordEmpty = watch([
    "old_password",
    "new_password",
    "confirm_password",
  ]).includes("");

  return (
    <>
      <EditNav />
      <Image
        className="absolute h-[100%] w-[100%]"
        alt="delete"
        src={`/assets/${process.env.NEXT_PUBLIC_NAME}/profile-edit/${isMobile ? "bg-mobile.png" : "bg-desktop.png"}`}
      />
      <ToastComponent
        onClick={() => {
          setIsSuccess(false);
        }}
        title={`Berhasil Ubah Password`}
        desc={`Lanjut eksplor ${capitalizeFirstLetter(String(process.env.NEXT_PUBLIC_NAME))}.id sekarang!`}
        isOpen={isSuccess}
      />
      <div className="py-[20px] lg:py-[32px] px-[10px] relative lg:p-0 ">
        <div className="relative z-[3] max-w-[462px] mx-auto">
          <EditHeader
            title={changePassword.title}
            description={changePassword.description}
          />
          <form method="POST" onSubmit={onSubmit}>
            {isError ? (
              <div className="bg-cta-3 p-[10px] mb-[10px] rounded-[10px] my-[5px] flex gap-[5px] items-center text-[12px] text-text-1 font-medium font-font-family-6 tracking-[0.25px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <path d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11ZM5.25 8.25C5.25 7.83579 5.58579 7.5 6 7.5C6.41421 7.5 6.75 7.83579 6.75 8.25C6.75 8.66421 6.41421 9 6 9C5.58579 9 5.25 8.66421 5.25 8.25ZM5.50806 3.41012C5.55039 3.17688 5.75454 3 6 3C6.24546 3 6.44961 3.17688 6.49194 3.41012L6.5 3.5V6L6.49194 6.08988C6.44961 6.32312 6.24546 6.5 6 6.5C5.75454 6.5 5.55039 6.32312 5.50806 6.08988L5.5 6V3.5L5.50806 3.41012Z" fill="#FD2A29" />
                </svg>
                <p>{`Captcha harus diisi`}</p>
              </div>
            ) : null}
            <FormBox
              className="mb-[23px]"
              title={changePassword.changePassword.section_title}
            >
              {changePassword.changePassword.form_items.map((item) => {
                return (
                  <Fragment key={item.label}>
                    {handleRenderLabel(item.label, item.link_label)}
                    {handleRenderInput(item.type, {
                      placeholder: item.placeholder,
                      name: item.name,
                      register,
                      getValues,
                    })}
                  </Fragment>
                );
              })}
              {errors['old_password'] && errors['new_password'] && errors['confirm_password'] ? <div className="my-[5px] flex gap-[5px] items-center text-[12px] text-text-1 font-medium font-font-family-6 tracking-[0.25px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <path d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11ZM5.25 8.25C5.25 7.83579 5.58579 7.5 6 7.5C6.41421 7.5 6.75 7.83579 6.75 8.25C6.75 8.66421 6.41421 9 6 9C5.58579 9 5.25 8.66421 5.25 8.25ZM5.50806 3.41012C5.55039 3.17688 5.75454 3 6 3C6.24546 3 6.44961 3.17688 6.49194 3.41012L6.5 3.5V6L6.49194 6.08988C6.44961 6.32312 6.24546 6.5 6 6.5C5.75454 6.5 5.55039 6.32312 5.50806 6.08988L5.5 6V3.5L5.50806 3.41012Z" fill="#FD2A29" />
                </svg>
                <p>{errors['old_password'].message}</p>
              </div> : null}
            </FormBox>
            <ButtonAction
              className="mt-[20px] mb-[10px] max-w-[355px] mx-auto"
              intent={!isPasswordEmpty ? "primary" : "primary_disable"}
              disabled={isPasswordEmpty}
              type="submit"
              value="Submit"
            >
              Simpan Perubahan
            </ButtonAction>
            <ButtonAction
              type="reset"
              value="Reset"
              className="max-w-[355px] mx-auto"
              intent="secondary"
              onClick={() => back()}
            >
              Batal
            </ButtonAction>
            <ReCAPTCHA
              ref={captchaRef}
              size="invisible"
              sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA}
            />
          </form>
        </div>
      </div>
    </>
  );
}
