"use client"
import { cn } from '@/lib/utils';
import React from 'react';

export default function LeaderboardTopUserCard({ children, className }) {
  return (
    <div className={cn(`relative bg-cover bg-no-repeat bg-top w-full min-w-[96.2px] md:min-w-[131.5px] grow-[1] min-h-[131.5px] md:min-h-[191.6px] pt-[5px] md:p-[6.85px] text-center pb-[25px] md:pb-[33.76px] flex flex-col items-center`, className)}>
      {children}
    </div>
  );
}
