"use client";
import React from "react";
import TextInput from "../text-input";

const datas = [
  { type: "text", id: 1 },
  { type: "text", id: 2 },
  { type: "text", id: 3 },
  { type: "text", id: 4 },
];

export default function OTPInput({ register, getValues, ...props }) {
  const className =
    "!h-[74px] !w-[74px] !mb-[0px] !text-[36px] !rounded-[15px] font-medium font-font-family-6 text-center";

  const handleKeyup = (e, id) => {
    if (e.keyCode === 8 || e.keyCode === 37) {
      //find the previous element
      const prev = document.getElementById(id - 1);
      if (prev) {
        //select the previous element
        prev.select();
      }
    } else if (
      (e.keyCode >= 48 && e.keyCode <= 57) || //check if key is numeric keys 0 to 9
      e.keyCode === 39 //check if key is right arrow key
    ) {
      //find the next element
      const next = document.getElementById(id + 1);
      if (next) {
        //select the next element
        next.select();
      }
    }
  };

  return (
    <div className="flex items-center justify-center gap-[10px] mb-[20px]">
      {datas.map((data, index) => {
        return (
          <TextInput
            key={data.id}
            id={data.id}
            register={register}
            getValues={getValues}
            name={`otp${index + 1}`}
            {...props}
            onKeyUp={(e) => handleKeyup(e, data.id)}
            onKeyPress={(e) => (e.keyCode >= 48 && e.keyCode <= 57)}
            className={className}
          />
        );
      })}
    </div>
  );
}
