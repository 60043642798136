"use client"
import React, { useState } from "react"
import TextInput from "../text-input"
import { Image } from "@/components/shared/image/image"
import PropTypes from "prop-types"
export default function PasswordInput({ ...props }) {
  const [isShowing, setIsShowing] = useState(false)

  const handleShowPassword = () => {
    setIsShowing((prevValue) => !prevValue)
  }

  const handleKeyDown = (e) => {
    // Todo: Add keydown event
  }

  return (
    <div className="relative">
      <TextInput type={isShowing ? "text" : "password"} {...props} />
      <button
        type="button"
        onClick={handleShowPassword}
        onKeyDown={handleKeyDown}
        className="absolute top-[13px] right-[10px] w-[16px] h-[16px]"
      >
        <Image
          objectFit="contain"
          src="/assets/amild/icons/password.svg"
          alt="show password"
        />
      </button>
    </div>
  )
}

PasswordInput.propTypes = {
  props: PropTypes.object,
}
