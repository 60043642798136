"use client"
import React from "react"
import { getDays, getMonth, getYear } from "../helper"
import { SelectInput } from "../../select-input"
import { twMerge } from "tailwind-merge"
import PropTypes from "prop-types"

export default function DateInput({
  isShort,
  containerClass,
  register,
  name,
  monthOptions,
  yearOptions,
  value,
  ...props
}) {
  const today = new Date()
  const minYear = 1940
  const maxYear = today.getFullYear() - 5
  const years = getYear({ maxYear, minYear })
  const months = getMonth()
  const days = getDays()

  const dateField = register
    ? { register, name: "date", ...props }
    : { value, name, ...props }
  const monthField = register
    ? {
        register,
        name: "month",
        ...props,
      }
    : { value, name, ...props }
  const yearField = register
    ? {
        register,
        name: "year",
        ...props,
      }
    : { value, name, ...props }

  return (
    <div
      className={twMerge(
        "flex gap-[5px] items-center mb-[10px]",
        containerClass
      )}
    >
      {!isShort ? (
        <SelectInput
          {...dateField}
          className={`min-w-[113px]`}
          placeholder="Pilih Hari"
        >
          {days.map((day) => (
            <option
              key={day.value}
              value={day.value}
              // className={day.value != "" ? "!text-text-3" : "text-text-2"}
            >
              {day.label}
            </option>
          ))}
        </SelectInput>
      ) : null}
      <SelectInput
        validation={monthOptions}
        {...monthField}
        className={`min-w-[113px]`}
        placeholder="Pilih Bulan"
      >
        {months.map((month) => (
          <option
            key={month.value}
            value={month.value}
            // className={month.value != "" ? "!text-text-3" : "text-text-2"}
          >
            {month.label}
          </option>
        ))}
      </SelectInput>
      <SelectInput
        validation={yearOptions}
        {...yearField}
        className={``}
        placeholder="Pilih Tahun"
      >
        {years.map((year) => (
          <option
            key={year.value}
            value={year.value}
            // className={year.value != "" ? "!text-text-3" : "text-text-2"}
          >
            {year.label}
          </option>
        ))}
      </SelectInput>
    </div>
  )
}

DateInput.propTypes = {
  containerClass: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  register: PropTypes.func,
  name: PropTypes.string,
  isShort: PropTypes.bool,
  monthOptions: PropTypes.object,
  yearOptions: PropTypes.object,
}
