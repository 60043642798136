"use client"
import React from "react"
import ProgressCard from "../loyalty/progress-card/progress-card"
import TierDescription from "../loyalty/tier-description/tier-description"
import CardPil from "../loyalty/card-pil/card-pil"
import PilLink from "../loyalty/card-pil/pil-link.js/pil-link"
import { Image } from "@/components/shared/image/image"
import ButtonAction from "../shared/button-action/button-action"
import { useIsMobile } from "@/hooks/use-is-mobile"

export function LoyaltyTeaserLogin({
  mission_items = [],
  exp_count_to_next_tier = 0,
  next_tier = "Silver",
  exp_count = 0,
  next_exp_tier_count = 0,
  avatar_url = "",
  exp_img_url = "",
  tier_label = "",
  tier_img_url = "",
}) {
  const isMobile = useIsMobile()
  return (
    <>
      {isMobile && (
        <div
          className="p-[10px] bg-transparent overflow-hidden rounded-[10px] mb-[20px] mt-[10px] mx-[10px]
            lg:py-[17px] max-w-[401px] relative lg:col-start-2 lg:m-[0]"
        >
          <div className="absolute inset-0 z-[-1]">
            {/* <Image
                        alt='cover'
                        fill
                        style={{ objectFit: "cover" }}
                        src='https://images.unsplash.com/photo-1696263075473-49877750c1a2?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=300&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY5Nzc3MzAyMA&ixlib=rb-4.0.3&q=80&w=300'
                    /> */}
          </div>
          <div className="text-[16px] text-primary leading-[20px] font-bold font-font-family-7 mb-[5px]">
            Dapetin Semua Pengalaman Ekslusif
          </div>
          <div className="text-[14px] leading-[24px] mb-[10px]">
            Masuk/Daftar di amild.id buat nikmatin banyak konten dan pengalaman
            eksklusif.
          </div>
          <div className="flex items-center w-[166px] gap-[6px]">
            <ButtonAction
              className="py-[10px] text-[10px] leading-[12.6px]"
              intent="secondary"
            >
              Daftar
            </ButtonAction>
            <ButtonAction
              className="py-[10px] text-[10px] leading-[12.6px]"
              intent="primary"
            >
              Masuk
            </ButtonAction>
          </div>
        </div>
      )}
    </>
  )
}
