"use client";
import React, { useEffect, useState } from "react";
import { LandingQuiz } from "./landing-quiz";
import { GameQuiz } from "./game-quiz";
import { ClientApiList } from "@/lib/api/client-service";
import PropTypes from "prop-types";

const Quiz = (props) => {
  const [stage, setStage] = useState(1);
  const [game, setGame] = useState([]);
  const [images, setImages] = useState([]);
  const [finished, setFinished] = useState(0);
  const[detail, setDetail] = useState()

  const propsStage = {
    stage,
    setStage,
    game,
    finished,
    images,
    detail,
    ...props,
  };

  const getDetail = async () => {
    const { data } = await ClientApiList.getEngagementDetail({
      type: "quiz",
      engagement_uuid: props.quiz.engagement_uuid,
    });
    setDetail(data)
    setGame(data?.data.data.quiz.engagement_quiz_qnas);
    setImages(data?.data.data.quiz.engagement_quiz_answer_imgs.reduce((acc, item) => {
        if (acc[item.question_number] && acc[item.question_number][item.order_number]) {
          acc[item.question_number][item.order_number].images[item.image_type] = item.image_file;
        } else {
          acc[item.question_number] = {...acc[item.question_number], [item.order_number]: { images: { [item.image_type]: item.image_file } } };
        }
        return acc;
      }, {}))
    setFinished(data?.data.data.finished);
  };

  useEffect(() => {
    getDetail();
    // eslint-disable-next-line
  }, []);

  const contentView = () => {
    if (stage === 1) return <LandingQuiz {...propsStage} />;
    return <GameQuiz {...propsStage} />;
  };

  return <section>{contentView()}</section>;
};

export default Quiz;

Quiz.propTypes = {
  quiz: PropTypes.object,
};
