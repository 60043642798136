"use client"
import Link from "@/components/shared/link/link"
import Separator from "../slide-menu/separator/separator"
import { Description } from "../shared/description/description"
import { useIsMobile } from "@/hooks/use-is-mobile"
import clsx from "clsx"
import PropTypes from "prop-types"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { useEffect, useState } from "react"
import { usePathname } from "next/navigation"

export function AppFooter({
  links = [],
  links_br = [],
  copyright,
  is_outside_link,
}) {
  const isMobile = useIsMobile()
  const { isLogin, isLoading } = useIsLogin()
  const pathname = usePathname()
  const hideNav =
    pathname.includes("/cc-bridging") ||
    pathname.includes("/maintenance") ||
    pathname.includes('/amild-offline')

  const [items, setItems] = useState(links)

  useEffect(() => {
    if (isLogin && !isLoading) setItems(links_br)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLogin, isLoading])

  return (
    <>
      {" "}
      {!hideNav && (
        <div
          className={clsx(
            "relative z-10 bg-bg-3",
            isMobile ? "pb-[150px]" : "pb-[56px]"
          )}
        >
          <div className={clsx("px-[16px]", !isMobile && "py-[24px]")}>
            {isMobile && (
              <Separator className="my-[5px] bg-[#F6F7F9] h-[2px] mb-[20px] border-box rounded-[0]" />
            )}
            <div
              className={
                isMobile
                  ? "flex flex-col gap-3.5 mb-[40px]"
                  : "flex justify-center gap-x-8"
              }
            >
              {is_outside_link ? (
                <>
                  {items?.map((item, idx) => (
                    <a
                      href={item?.href}
                      className="font-bold font-font-family-7 text-sm text-[#6B6A69] w-fit"
                      key={idx}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item?.label}
                    </a>
                  ))}
                </>
              ) : (
                <>
                  {items?.map((item, idx) => (
                    <div key={idx}>
                      {item?.label === "Hubungi Kami" ? (
                        <button
                          className="!font-bold !font-font-family-7 !text-sm !text-[#6B6A69] !w-fit ot-sdk-show-settings"
                          // id="ot-sdk-btn"
                        >
                          Pemberitahuan Kuki
                        </button>
                      ) : (
                        <Link
                          href={item?.href}
                          className="!font-bold !font-font-family-7 !text-sm !text-[#6B6A69] !w-fit"
                          passHref
                        >
                          {item?.label}
                        </Link>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className="w-full py-[12px] px-[16px] bg-[#2C2A29] text-sm text-white font-bold font-font-family-7 text-center">
            <Description description={copyright} />
          </div>
        </div>
      )}
    </>
  )
}

AppFooter.propTypes = {
  links: PropTypes.array,
  links_br: PropTypes.array,
  is_outside_link: PropTypes.bool,
  copyright: PropTypes.string,
}
