"use client";
import React from "react";
import { Image } from "@/components/shared/image/image";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";
import { cn } from "@/lib/utils";
export default function CardPil({
  title,
  children,
  img,
  className,
  isLink = false,
}) {
  return (
    <div
      className={cn(
        `p-[5px] text-text-2 rounded-[10px] lg:rounded-[10px] gap-[5px] flex justify-between items-center ${isLink ? "hover:underline" : ""}`,
        className
      )}
      style={{ background: process.env.NEXT_PUBLIC_NAME === 'djisamsoe' ? 'var(--cta_2)' : 'var(--background_3)', }}
    >
      {img ? (
        <div className={cn("w-[20px] h-[20px] relative", process.env.NEXT_PUBLIC_NAME === 'djisamsoe' ? 'bg-cta-2' : 'bg-bg-3')}>
          <Image
            data-testid="card-pil-img"
            alt="cover"
            objectFit="cover"
            src={img}
          />
        </div>
      ) : null}
      {title ? (
        <div
          data-testid="card-pil-title"
          className=" md:max-w-none md:oveflow-auto text-[12px] lg:text-[14px] font-bold font-font-family-7 tracking-[0.12px] lg:tracking-[0.14px] leading-[16px] lg:leading-[19px]"
        >
          {title}
        </div>
      ) : null}
      {children}
    </div>
  );
}

CardPil.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  img: PropTypes.string,
  className: PropTypes.string,
};
