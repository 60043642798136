"use client"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import Link from "@/components/shared/link/link"

// Import Swiper styles
import "swiper/css"
import { ArticleItem } from "../shared/card/article-card"
import { ClientApiList } from "@/lib/api/client-service"
import { getPublishDate, useDatalayer } from "@/hooks/use-datalayer"
import PropTypes from "prop-types"
import { getArticlePathname } from "@/hooks/use-pathname"
import { useEffect, useState } from "react"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { usePathname, useRouter } from "next/navigation"
import { cn } from "@/lib/utils"
export function InspirationArticle({
  title,
  items = [],
  event = "regular_content",
}) {
  const datalayer = useDatalayer()
  const { isLogin, isLoading } = useIsLogin()
  const pathname = usePathname()
  // const swiperRef = useRef()
  const [list, setList] = useState(items)
  const [load, setLoad] = useState(true)
  const { replace } = useRouter()

  const getList = async () => {
    const data = await ClientApiList.getListArticle({
      length: 10,
      is_branded: isLogin ? 1 : 0,
      is_regular: 1,
      pinned: "",
      page: 0,
      campaign: pathname.split("/")[1],
    })
    setList(data?.data?.data?.items)
    setLoad(false)
  }

  useEffect(() => {
    if (pathname && !isLoading) getList()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pathname, isLoading])

  const onClickDatalayer = async (index, slide) => {
    datalayer.push({
      content_brand: slide?.is_branded == 0 ? "unbranded" : "branded",
      content_type: slide?.format,
      event: "general_event",
      event_name: `click_content`, // e.g. click_regular_content_section_1
      feature: "hero engagement page",
      content_section: "campaign page",
      content_category: slide?.category.toLowerCase(),
      content_position: index + 1, // e.g 1, 2, 3, etc (integer)
      content_name: slide?.title?.toLowerCase(), // e.g 20 tips persiapan
      publish_date: getPublishDate(slide?.start_date), // e.g 20-11-2023
      campaign_name: slide?.campaign?.toLowerCase(), // e.g ride your flavour 2023
      event_label: `${slide?.format} - ${slide?.title?.toLowerCase()}`,
      content_id: slide?.id,
      engagement_type: "content",
    })
  }

  const onViewArticle = async (slide) => {
    try {
      const { data: dataProfile } = await ClientApiList.getProfileData()
      await ClientApiList.getInteractionUser({
        user_id: dataProfile?.data.data.gigya_id,
        event: "general_event",
        campaign: slide?.campaign?.toLowerCase(),
        event_category: slide?.category.toLowerCase(),
        event_action: `on view ${slide?.format}`,
        event_label: `${slide?.format} - ${slide?.title?.toLowerCase()}`,
        creative: typeof window !== "undefined" ? window.location.href : "",
        content_id: slide?.id,
      })
    } catch (err) {
      if (err.status === 401) window.location.reload()
    }
  }

  return (
    <div className="border-[1px] border-cta-4 w-full h-full relative my-5 bg-bg-3 pt-[15px] pb-[20px] rounded-[10px] lg:col-span-2 lg:my-[0] lg:p-[20px]">
      <h2
        className={cn(
          "mb-[10px] font-bold font-font-family-7 pl-[15px] text-text-2 lg:text-[24px] lg:leading-[32px] lg:p-[0] lg:mb-[15px]",
          process.env.NEXT_PUBLIC_NAME === "amild"
            ? "font-font-family-3"
            : "font-font-family-7"
        )}
      >
        {process.env.NEXT_PUBLIC_NAME === "amild"
          ? String(title).toUpperCase()
          : title}
      </h2>
      <div className="relative  ">
        {!load && (
          <Swiper
            spaceBetween={5}
            slidesPerView="auto"
            // onSlideChange={(swiper) => {
            //   setActiveIndex(swiper.activeIndex)
            // }}
            // onSwiper={(swiper) => {
            //   swiperRef.current = swiper
            //   setActiveIndex(swiper.activeIndex)
            // }}
            className="pl-[15px] lg:p-[0]"
          >
            {list?.map((slide, index) => (
              <SwiperSlide
                className="w-fit"
                key={index}
                style={{
                  width: "fit-content",
                }}
              >
                <Link
                  href={getArticlePathname(slide)}
                  passHref
                  data-testid={"link-article-" + index}
                  onClick={() => {
                    onClickDatalayer(index, slide)
                  }}
                >
                  <ArticleItem
                    slide={slide}
                    card_size="small"
                    cover_size="small"
                    title_size="small"
                    onView={() => onViewArticle(slide)}
                  />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  )
}

InspirationArticle.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  event: PropTypes.string,
}
