"use client"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import Link from "@/components/shared/link/link"

// Import Swiper styles
import "swiper/css"
import { Suspense, useEffect, useRef, useState } from "react"
import { ArticleItem } from "../shared/card/article-card"
import PropTypes from "prop-types"
import clsx from "clsx"
import { usePathname, useRouter } from "next/navigation"
import { getPublishDate, useDatalayer } from "@/hooks/use-datalayer"
import { ClientApiList } from "@/lib/api/client-service"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { getArticlePathname } from "@/hooks/use-pathname"
import { useWindowSize } from "@/hooks/use-window-size"
import { cn } from "@/lib/utils"
import { useQuery } from "@tanstack/react-query"

function SuspendedFeaturedArticle({
  title,
  items = [],
  cta_more = false,
  event = "regular_content",
}) {
  const swiperRef = useRef()
  const pathname = usePathname()
  const { width } = useWindowSize()
  const datalayer = useDatalayer()
  const { isLogin, isLoading } = useIsLogin()
  const { replace } = useRouter()

  const { data: profile } = useQuery({
    queryKey: ["getProfile"],
    queryFn: async () => {
      const { data } = await ClientApiList.getProfileData()
      return data
    },
    enabled: Boolean(isLogin && !isLoading),
  })

  /* grid card layout pinned */
  const pinned =
    width >= 1024 && (pathname === "/br/archive" || pathname === "/ub")

  /* check article detail page  */
  const pageDetail = pathname.split("/")

  const [list, setList] = useState(items)

  const getList = async () => {
    const data = await ClientApiList.getListArticle({
      length: pathname === "/br/archive" || pathname === "/ub" ? 3 : 10,
      is_branded: isLogin ? 1 : 0,
      is_regular: 1,
      pinned: (pathname === "/br/archive" || pathname === "/ub") && "Yes",
      page: 0,
      campaign: pageDetail.length === 7 && pageDetail[2],
      string_id: pageDetail.length === 7 && pageDetail[6],
    })
    setList(data?.data?.data?.items)
  }

  /* update list article */
  useEffect(() => {
    if (!isLoading) {
      getList()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLoading])

  const onClickDatalayer = async (index, slide) => {
    const defaultDatalayer = {
      content_brand: slide?.is_branded == 0 ? "unbranded" : "branded",
      content_type: slide?.format,
    }
    if (pathname === "/br" || pathname === "/")
      datalayer.push({
        ...defaultDatalayer,
        event: "general_event",
        event_name: `click_${event}_section_${index + 1}`, // e.g. click_regular_content_section_1
        feature: "banner",
        content_category: slide?.category.toLowerCase(),
        content_position: index + 1, // e.g 1, 2, 3, etc (integer)
        content_name: slide?.title?.toLowerCase(), // e.g 20 tips persiapan
        publish_date: getPublishDate(slide?.start_date), // e.g 20-11-2023
        campaign_name: slide?.campaign?.toLowerCase(), // e.g ride your flavour 2023
        event_label: `${slide?.format} - ${slide?.title?.toLowerCase()}`,
        content_id: slide?.id,
        engagement_type: "content",
      })
  }

  const onViewArticle = async (slide) => {
    try {
      await ClientApiList.getInteractionUser({
        user_id: !isLoading && isLogin && profile?.data.data.gigya_id,
        event: "general_event",
        campaign: slide?.campaign?.toLowerCase(),
        event_category: slide?.category.toLowerCase(),
        event_action: `on view ${slide?.format}`,
        event_label: `${slide?.format} - ${slide?.title?.toLowerCase()}`,
        creative: typeof window !== "undefined" ? window.location.href : "",
        content_id: slide?.id,
      })
    } catch (err) {
      if (err.status === 401) window.location.reload()
    }
  }

  return (
    <>
      {list.length > 0 && (
        <div
          className={clsx(
            "w-full h-full relative mt-[20px]",
            pinned ? "mb-40px" : "mb-5"
          )}
        >
          <h2
            className={cn(
              "text-xl text-text-2 mb-[10px] px-[15px] font-medium",
              process.env.NEXT_PUBLIC_NAME === "amild"
                ? "font-font-family-2"
                : "font-font-family-6 "
            )}
          >
            {process.env.NEXT_PUBLIC_NAME === "amild"
              ? String(title).toUpperCase()
              : title}
          </h2>
          <div
            className={clsx(
              "relative inset-0 w-full h-full",
              pinned &&
                "px-[15px] grid grid-cols-[1fr_460px] grid-rows-[1fr_166px] gap-[15px] 2xl:grid-cols-[1fr_619px] 2xl:grid-rows-[1fr_240px]"
            )}
          >
            {pinned ? (
              <>
                {list.map((slide, index) => (
                  <Link
                    key={index}
                    data-testid={`pinned-featured-article-${index}`}
                    href={getArticlePathname(slide)}
                    passHref
                    className={
                      index == 0
                        ? "lg:row-span-3 max-h-[346px] 2xl:max-h-[490px]"
                        : "h-[166px] 2xl:h-[240px]"
                    }
                    onClick={() => {
                      onClickDatalayer(index, slide)
                    }}
                  >
                    <ArticleItem
                      slide={slide}
                      card_direction="horizontal"
                      card_size="full"
                      cover_size="medium_horizontal"
                      title_direction="col_reverse"
                      horizontal={true}
                      firstCard={index == 0}
                      onView={() => onViewArticle(slide)}
                    />
                  </Link>
                ))}
              </>
            ) : (
              <Swiper
                spaceBetween={10}
                slidesPerView="auto"
                onSwiper={(swiper) => {
                  swiperRef.current = swiper
                }}
                style={{
                  padding: "0 15px",
                }}
              >
                {list.map((slide, index) => (
                  <SwiperSlide
                    key={index}
                    style={{
                      width: "fit-content",
                    }}
                  >
                    <Link
                      href={getArticlePathname(slide)}
                      passHref
                      onClick={() => {
                        onClickDatalayer(index, slide)
                      }}
                    >
                      <ArticleItem
                        slide={slide}
                        onView={() => onViewArticle(slide)}
                      />
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
          {cta_more && (
            <Link
              href={isLogin ? "/br/archive" : "/ub"}
              passHref
              data-testid="cta-see-more-articles"
              className="absolute top-0 right-[10px] text-text-1 font-bold font-font-family-7 underline decoration-1 underline-offset-4"
              onClick={() => {
                datalayer.push({
                  event: "general_event",
                  event_name: "click_see_more_homepage",
                  feature: "articles",
                  button_name: "see more", // e.g., home
                  campaign_name: "all", // e.g all
                })
              }}
            >
              See More
            </Link>
          )}
        </div>
      )}
    </>
  )
}

SuspendedFeaturedArticle.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  cta_more: PropTypes.bool,
  event: PropTypes.string,
}

function FeaturedArticleFallback() {
  return <div>Fallback</div>
}

export function FeaturedArticle(props) {
  return (
    <Suspense fallback={<FeaturedArticleFallback />}>
      <SuspendedFeaturedArticle {...props} />
    </Suspense>
  )
}
