"use client"
import React, { Suspense } from "react"
import { usePathname, useSearchParams } from "next/navigation"
import { cva } from "class-variance-authority"
import Link from "@/components/shared/link/link"
import PropTypes from "prop-types"

const CategoryCardFallback = () => {
  return <Link href="#">Link</Link>
}

const button = cva("a", {
  variants: {
    intent: {
      active: [
        "bg-cta-1",
        "text-cta-2",
        "border-cta-1",
        "font-bold font-font-family-7",
        "hover:bg-blend-lighten",
      ],
      default: [
        "bg-cta-2",
        "text-text-2",
        "border-cta-2",
        "font-medium font-font-family-6",
      ],
      tertiary: [
        "bg-transparent",
        "text-text-2",
        "border-transparent",
        "font-medium font-font-family-6",
      ],
      categories: [
        "bg-cta-2",
        "text-text-5",
        "border-cta-4",
        "font-medium font-font-family-6",
        "hover:bg-blend-lighten",
      ],
      categoriesTransparent: [
        "bg-cta-4",
        "text-text-5",
        "border-cta-4",
        "font-medium font-font-family-6",
        "hover:bg-blend-lighten",
      ],
    },
    size: {
      medium: ["text-[14px] leading-[20px]"],
      small: [
        "text-[14px] lg:text-[16px] leading-[20px] lg:leading-[22px] lg:px-[9px] lg:py-[8.5px]",
      ],
    },
  },
  compoundVariants: [
    {
      intent: [
        "active",
        "default",
        "tertiary",
        "categories",
        "categoriesTransparent",
      ],
      size: ["medium", "small"],
      class:
        "block overflow-hidden capitalize p-[10px] rounded-[10px] box-border lg:tracking-[0.16px] relative transition-300",
    },
  ],
  defaultVariants: {
    intent: "default",
    size: "medium",
  },
})

function SuspendedCategoryCard({
  href,
  className,
  intent,
  size,
  categoryName,
  label,
  setIsLoading,
  setSelectedEvent,
  children,
  value,
  isCompound = false,
  ...props
}) {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const handleClick = () => {
    if (typeof setIsLoading === "function") setIsLoading(true)
    if (typeof setSelectedEvent === "function") setSelectedEvent(value)
  }

  const hrefToLink = () => {
    if (href) return href

    const current = new URLSearchParams(Array.from(searchParams.entries())) // -> has to use this form

    current.set(categoryName, label || value)
    current.delete(categoryName + "-page")

    return {
      pathname,
      query: Object.fromEntries(current.entries()),
    }
  }

  const switchBorder = () => {
    switch (intent) {
      case "active":
        return "var(--cta_1)"
      case "categoriesTransparent":
        return "var(--cta_4)"
      case "tertiary":
        return "transparent"
      case "default":
        return "var(--cta_4)"
      default:
        return "var(--cta_4)"
    }
  }
  const switchBG = () => {
    switch (intent) {
      case "active":
        return "var(--cta_1)"
      case "categoriesTransparent":
        return "var(--background_3)"
      case "default":
        return "var(--cta_2)"
      case "tertiary":
        return "transparent"
      default:
        return "var(--cta_2)"
    }
  }
  const switchText = () => {
    switch (intent) {
      case "active":
        return "var(--cta_2)"
      case "default":
        return "var(--text_2)"
      case "tertiary":
        return "var(--text_2)"
      default:
        return  "var(--text_5)"
    }
  }
  const style = { background: switchBorder() }

  return (
    <Link
      data-testid="category-card-link"
      replace
      href={hrefToLink()}
      onClick={handleClick}
      scroll={false}
      style={style}
      className={button({ intent, size, className })}
      {...props}
    >
      <div
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[calc(100%_-_1.95px)] h-[calc(100%_-_1.9px)] z-[0] rounded-[9px]"
        style={{ background: switchBG() }}
      ></div>
      {isCompound ? (
        children
      ) : (
        <span
          className="!bg-clip-text relative z-[1]"
          style={{
            background: switchText(),
            WebkitTextFillColor: "transparent",
          }}
        >
          {children}
        </span>
      )}
    </Link>
  )
}

export default function CategoryCard(props) {
  return (
    <Suspense fallback={<CategoryCardFallback />}>
      <SuspendedCategoryCard {...props} />
    </Suspense>
  )
}

SuspendedCategoryCard.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  intent: PropTypes.string,
  size: PropTypes.string,
  categoryName: PropTypes.string,
  label: PropTypes.string,
  setIsLoading: PropTypes.func,
  setSelectedEvent: PropTypes.func,
  children: PropTypes.string,
}
