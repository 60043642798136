'use client';
import React, { useEffect, useRef,  } from 'react';
import * as crypto from 'crypto';

export const IframeLeveling = () => {
    const iframeRef = useRef();

    useEffect(() => {
        function eventHandler(event) {
            const data = JSON.parse(atob(event.data));
            if (data.action == "submit_game") {
                if (data.nextIframeURL) {
                    const originalIFrameURL = iframeRef.current.src.split("?")[0];
                    iframeRef.current.src = originalIFrameURL + "?" + data.nextIframeURL;
                }
            } else console.log(data);
        }

        window.addEventListener("message", (e) => {
            if (e.origin !== 'https://g.minigim.fun')
                return;
            eventHandler(e);
        }, false);

        return () => {
            window.removeEventListener("message", eventHandler, false);
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        // addGamePoint,
        // trackerUser,
    ]);


    return (
        // <template>
        <div className='w-[100%] h-[100%] min-h-[calc(100vh-170px)] min-w-[100vw]'>
            <iframe
                ref={iframeRef}
                className='block w-[100dvw] h-[100dvh]'
                seamless="seamless"
                title="game-view"
                // src="https://g.minigim.fun/magnum-studio-halloffame-2023/index.html"
                frameBorder="0"
            ></iframe>
        </div>
        // </template>
    );
};
