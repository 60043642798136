"use client";
import { Description } from "../shared/description/description";
import clsx from "clsx";
import { useIsMobile } from "@/hooks/use-is-mobile";
import PropTypes from "prop-types";
import { ClientApiList } from "@/lib/api/client-service";
import { Suspense, useEffect, useState } from "react";
import ButtonAction from "../shared/button-action/button-action";
import { useForm } from "react-hook-form";
import { useRouter, useSearchParams } from "next/navigation";
import { LoadingOverlay } from "../shared/loading-overlay";

export function SuspendedSubmissionForm({
  title,
  description,
  form = {},
  btn_label,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      address: "",
    },
  });
  const isMobile = useIsMobile();
  const isLogin = true; //dummy
  const router = useRouter();
  const searchParams = useSearchParams();

  const [dataUser, setDataUser] = useState(false);
  const [formLoading, setFormLoading] = useState(true);

  const getProfile = async () => {
    const { data } = await ClientApiList.getProfileData();
    if (isLogin && data) {
      setDataUser(data?.data.data);
    }
  };

  const getStatusClaim = async () => {
    const { error } = await ClientApiList.getStatusClaim({
      merchandise_id: searchParams?.get("id"),
    });

    if (error) router.push("/");
    else setFormLoading(false);
  };

  useEffect(() => {
    getStatusClaim();

    if (!formLoading) {
      getProfile();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [formLoading]);

  useEffect(() => {
    if (searchParams?.get("id") == null) router.push("/");
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [searchParams]);

  const onSubmit = async (data) => {
    setFormLoading(true);

    const { data: submitData } = await ClientApiList.getSubmitForm({
      ...data,
      merchandise_id: 1, // dummy merch id
    });

    if (submitData?.data.status === "OK") {
      router.push("/");
    }
  };

  return (
    <>
      <LoadingOverlay visible={formLoading || !dataUser} />
      <div
        className={clsx(
          "w-full max-w-[460px] mx-auto relative text-text-2",
          isMobile ? "pt-[23px] px-3.5" : " pt-[30px] px-0"
        )}
      >
        <p className="font-bold font-font-family-3 text-2xl capitalize">
          {title}
        </p>
        <Description
          className={clsx(
            "mb-[23px] mt-[10px]",
            isMobile ? "text-sm" : "text-base"
          )}
          description={description}
        />
        <form method="POST"
          onSubmit={handleSubmit(onSubmit)}
          className="w-full border-t-4 border-cta-1 rounded-t-lg rounded-b-lg px-[20px] py-[15px] bg-bg-3 flex flex-col gap-[10px]"
        >
          <p className="text-base font-bold font-font-family-7 capitalize">
            {form?.title}
          </p>
          {form?.name && (
            <label className="text-sm font-medium font-font-family-6">
              Nama Penerima<span className="text-text-1">*</span>{" "}
              <input
                className="w-full p-[10px] mt-[7px] bg-cta-4 border border-cta-4 rounded-[5px] text-text-3"
                type="text"
                value={dataUser?.fullname}
                disabled
              />
            </label>
          )}
          {form?.mobile_phone && (
            <label className="text-sm font-medium font-font-family-6 ">
              Nomor Handphone<span className="text-text-1">*</span>
              <div className="w-full flex gap-[5px] mt-[7px]">
                <div className="p-[10px] bg-cta-4 border border-cta-4 rounded-[5px] text-text-3">
                  +62
                </div>
                <input
                  className="w-full p-[10px] bg-cta-4 border border-cta-4 rounded-[5px] text-text-3"
                  type="number"
                  value={dataUser?.phone}
                  disabled
                />
              </div>
            </label>
          )}
          {form?.email && (
            <label className="text-sm font-medium font-font-family-6">
              Email<span className="text-text-1">*</span>
              <input
                className="w-full p-[10px] mt-[7px] bg-cta-4 border border-cta-4 rounded-[5px] text-text-3"
                type="email"
                value={dataUser?.email}
                disabled
              />
            </label>
          )}
          {form?.address && (
            <label className="text-sm font-medium font-font-family-6">
              Alamat Lengkap Penerima<span className="text-text-1">*</span>
              <textarea
                className="w-full p-[10px] mt-[7px] bg-bg-3 border border-cta-4 rounded-[5px] placeholder-shown:italic"
                placeholder="Contoh: Budi Perumahan Indah Asri no.15. Jalan Panjang Raya, Kecamatan Kembangan, Kebun Jeruk, Jakarta Barat, 11530"
                {...register("address", {
                  validate: (v) =>
                    v.trim().length >= 1 || "Alamat tidak boleh kosong",
                  required: "Alamat tidak boleh kosong",
                  minLength: {
                    value: 5,
                    message: "Minimal 5 karakter",
                  },
                  maxLength: {
                    value: 400,
                    message: "Maksimal 400 karakter",
                  },
                })}
                data-state-error={Boolean(errors.address?.message)}
                rows={6}
              />
              {errors.address?.message && (
                <p className="text-text-1 mt-[5px] text-sm italic">
                  {errors.address?.message}
                </p>
              )}
            </label>
          )}
          <ButtonAction
            className="w-full max-w-[355px] mx-auto mt-[35px]"
            intent="primary"
          >
            {btn_label}
          </ButtonAction>
        </form>
      </div>
    </>
  );
}

function SubmissionFormFallback() {
  return <>onBoarding</>;
}

export function SubmissionForm(props) {
  return (
    <Suspense fallback={<SubmissionFormFallback />}>
      <SuspendedSubmissionForm {...props} />
    </Suspense>
  );
}

SuspendedSubmissionForm.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  form: PropTypes.object,
  btn_label: PropTypes.string,
};
