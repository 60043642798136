"use client"
import React, { useEffect, useState } from "react"
import { ClientApiList } from "@/lib/api/client-service"
import crypto from "crypto"
import { ToastComponent } from "../toast"
import { usePathname, useRouter } from "next/navigation"
import { useDatalayer } from "@/hooks/use-datalayer"
import UseQueryParams from "@/hooks/use-query-params"

export const encryptGame = (data) => {
  // console.log(data, 'data encrypt');
  let dechiper = crypto.createCipheriv(
    "aes-256-ctr",
    process.env.NEXT_PUBLIC_GAME_KEY,
    process.env.NEXT_PUBLIC_GAME_IV
  )
  let dec = dechiper.update(data, "utf8", "hex")
  dec += dechiper.final("hex")
  // console.log(dec);
  return dec
}

export const decryptData = (data) => {
  let dechiper = crypto.createDecipheriv(
    "aes-256-ctr",
    process.env.NEXT_PUBLIC_GAME_KEY,
    process.env.NEXT_PUBLIC_GAME_IV
  )
  let dec = dechiper.update(data, "hex", "utf8")
  dec += dechiper.final("utf8")
  return JSON.parse(dec)
}

export const IframeVideo = () => {
  const QUERYPARAM = "point"
  const { handlePushQuery } = UseQueryParams()
  const datalayer = useDatalayer()
  const [point, setPoint] = useState()
  const [isSuccess, setIsSuccess] = useState(false)
  const pathname = usePathname()
  const { replace } = useRouter()
  const campaign = pathname.split("/")[2]

  function handlePoint(point) {
    if (point !== 0) {
      setPoint(point)
      handlePushQuery(false, [QUERYPARAM, true])
      setIsSuccess(true)
    }
  }

  async function handleSubmit(data) {
    const { data: dataReturn } = await ClientApiList.postIframeData({
      data: encryptGame(JSON.stringify(data)),
    })
    handlePoint(dataReturn.data.data.point_get)
  }

  function handleDataLayer(data) {
    datalayer.push(data)
  }

  async function eventHandler(event) {
    // console.log(event)
    const data = JSON.parse(atob(event.data))
    if (data.action == "datalayer") handleDataLayer(data.value[0])
    else if (data.action === "submit") handleSubmit(data)
    else if (data.action === "cek_sekarang")
      replace(`${process.env.NEXT_PUBLIC_META_URL}/${campaign}/br`)
    else console.log(data)
  }

  const events = (e) => {
    if (e.origin !== "https://g.minigim.fun") return
    eventHandler(e)
  }

  useEffect(
    () => {
      window.addEventListener("message", events, false)

      return () => {
        window.removeEventListener("message", events, false)
      }
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    },
    []
  )

  const titleBrand =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? `Kamu berhasil raih ${point} poin`
      : `Lo berhasil dapetin ${point} poin!`

  return (
    // <template>
    <div className="w-[100%] h-[100%] h-[calc(100dvh-56px)] min-w-[100vw]">
      <ToastComponent
        onClick={() => {
          setIsSuccess(false)
        }}
        title={titleBrand}
        desc="Lanjut eksplor dan kumpulkan lebih banyak poin!"
        isOpen={isSuccess}
      />
      <iframe
        className="block w-[100dvw] h-[calc(100dvh-56px)]"
        seamless="seamless"
        title="game-view"
        // src="https://g.minigim.fun/magnum-loud-music-day/index.html#/"
        src="https://g.minigim.fun/magnum-loud-music-day-dev/index.html#/"
        allow="camera; microphone"
        frameBorder="0"
      ></iframe>
    </div>
    // </template>
  )
}
