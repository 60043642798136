"use client"
import React, { Suspense } from "react"

function PuzzlePillFallback() {
  return <div>PuzzlePillFallback</div>
}

function SuspendedPuzzlePill({
  time_label,
  time_need,
  handleOpenPopup,
  setIsPaused,
  handleIsExited,
  level,
}) {
  // const pathname = usePathname();
  // const searchParams = useSearchParams();

  // const hrefParse = () => {
  //   const current = new URLSearchParams(Array.from(searchParams.entries())); // -> has to use this form

  //   return {
  //     pathname,
  //     query: {
  //       ...Object.fromEntries(current.entries()),
  //       "hide-puzzle-rule": false,
  //     },
  //   };
  // };

  return (
    <div className="bg-bg-5 max-w-[375px] md:max-w-[355px] mx-auto mb-[75px] md:mb-[60px] py-[12px] px-[15px] rounded-[15px] flex items-center justify-between gap-[10px] relative z-[1]">
      <button
        onClick={() => {
          handleOpenPopup(true)
          setIsPaused(true)
        }}
      >
        <div className="w-[40px] h-[40px] bg-bg-5 relative">
          {process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? (
            <InfoConDSS />
          ) : (
            <InfoIcon />
          )}
        </div>
      </button>
      <div className="grow-[1]">
        <div
          data-testid="time-label"
          style={{ background: "var(--background_3" }}
          className="text-[14px] flex items-center gap-[5px] p-[5px] rounded-[100px] w-fit mx-auto bg-bg-3 leading-[22px] mb-[10px] text-center font-family-3 text-[14px] leading-[22px] text-cta-1 font-bold uppercase"
        >
          <div className="w-[12px] h-[12px]">
            <Star />
          </div>
          <div className="text-[14px] leading-[22px] font-bold text-text-1">
            Level {level}
          </div>
          <div className="w-[12px] h-[12px]">
            <Star />
          </div>
        </div>
        <div className="flex gap-[5px] items-center justify-center">
          <div className="w-[16px] md:w-[16px] h-[16px] md:h-[16px] bg-bg-5">
            <ClockIcon />
          </div>
          <div className="text-[12px] leading-[18px]" data-testid="time-need">
            {time_need} detik
          </div>
        </div>
      </div>
      <div className="w-[40px] h-[40px] bg-bg-5 relative">
        <div
          role="button"
          tabIndex={0}
          onClick={() => {
            handleIsExited(true)
            setIsPaused(true)
          }}
        >
          {process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? (
            <CloseIconDSS />
          ) : (
            <CloseIcon />
          )}
        </div>
      </div>
    </div>
  )
}

export default function PuzzlePill(props) {
  return (
    <Suspense fallback={<PuzzlePillFallback />}>
      <SuspendedPuzzlePill {...props} />
    </Suspense>
  )
}
const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="12"
      // height="12"
      className="w-[100%] h-[100%]"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M6 2.5C3.7945 2.5 2 4.2945 2 6.5C2 8.7055 3.7945 10.5 6 10.5C8.2055 10.5 10 8.7055 10 6.5C10 4.2945 8.2055 2.5 6 2.5ZM6.5 6.5H5.5V4H6.5V6.5ZM4.5 1H7.5V2H4.5V1ZM9.3535 2.1465L10.3535 3.1465L9.6465 3.8535L8.6465 2.8535L9.3535 2.1465Z"
        // fill="white"
        className="fill-icon-4"
      />
    </svg>
  )
}

const Star = () => {
  return (
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 0L6.33992 2.06367C6.64394 3.90941 8.09059 5.35606 9.93633 5.66008L12 6L9.93633 6.33992C8.09059 6.64394 6.64394 8.09059 6.33992 9.93633L6 12L5.66008 9.93633C5.35606 8.09059 3.90941 6.64394 2.06367 6.33992L0 6L2.06367 5.66008C3.90941 5.35606 5.35606 3.90941 5.66008 2.06367L6 0Z"
        // fill="#ED1C24"
        className="fill-icon-1"
      />
    </svg>
  )
}

const CloseIconDSS = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 20C4 11.1634 11.1634 4 20 4C28.8366 4 36 11.1634 36 20C36 28.8366 28.8366 36 20 36C11.1634 36 4 28.8366 4 20Z"
        fill="url(#paint0_linear_8028_17306)"
      />
      <path
        d="M13.6563 13.6871C13.8642 13.4799 14.146 13.3635 14.4399 13.3635C14.7337 13.3635 15.0155 13.4799 15.2234 13.6871L19.9839 18.4375L24.7444 13.6871C24.8799 13.5414 25.0523 13.435 25.2434 13.3789C25.4345 13.3228 25.6372 13.3192 25.8301 13.3684C26.0231 13.4177 26.1992 13.5179 26.3399 13.6586C26.4805 13.7994 26.5805 13.9753 26.6294 14.168C26.6788 14.3604 26.6753 14.5625 26.6194 14.753C26.5635 14.9436 26.4571 15.1156 26.3115 15.2509L21.551 20.0014L26.3115 24.7518C26.4575 24.8871 26.5642 25.0591 26.6204 25.2498C26.6766 25.4404 26.6802 25.6427 26.6309 25.8353C26.5815 26.0278 26.481 26.2036 26.34 26.3439C26.199 26.4843 26.0226 26.5841 25.8296 26.6328C25.6368 26.6821 25.4343 26.6787 25.2433 26.6229C25.0524 26.5671 24.88 26.4609 24.7444 26.3156L19.9839 21.5652L15.2234 26.3156C15.0131 26.511 14.7351 26.6174 14.4478 26.6124C14.1605 26.6074 13.8864 26.4916 13.6829 26.2891C13.48 26.0861 13.3639 25.8125 13.3589 25.5258C13.354 25.2391 13.4606 24.9617 13.6563 24.7518L18.4168 20.0014L13.6563 15.2509C13.4487 15.0434 13.332 14.7622 13.332 14.469C13.332 14.1758 13.4487 13.8946 13.6563 13.6871Z"
        fill="url(#paint1_linear_8028_17306)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8028_17306"
          x1="20"
          y1="4"
          x2="20"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-color="#221F20" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8028_17306"
          x1="26.6654"
          y1="26.6674"
          x2="11.2359"
          y2="23.4795"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#654D2C" />
          <stop offset="0.500935" stop-color="#DFD59A" />
          <stop offset="1" stop-color="#654D2C" />
          <stop offset="1" stop-color="#654D2C" />
        </linearGradient>
      </defs>
    </svg>
  )
}

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M4 20C4 11.1634 11.1634 4 20 4C28.8366 4 36 11.1634 36 20C36 28.8366 28.8366 36 20 36C11.1634 36 4 28.8366 4 20Z"
        // fill="white"
        className="fill-cta-2"
      />
      <path
        d="M13.6563 13.6866C13.8642 13.4794 14.146 13.363 14.4399 13.363C14.7337 13.363 15.0155 13.4794 15.2234 13.6866L19.9839 18.4371L24.7444 13.6866C24.8799 13.541 25.0523 13.4345 25.2434 13.3784C25.4345 13.3223 25.6372 13.3187 25.8301 13.3679C26.0231 13.4172 26.1992 13.5174 26.3399 13.6582C26.4805 13.7989 26.5805 13.9749 26.6294 14.1675C26.6788 14.3599 26.6753 14.562 26.6194 14.7525C26.5635 14.9431 26.4571 15.1151 26.3115 15.2504L21.551 20.0009L26.3115 24.7513C26.4575 24.8866 26.5642 25.0586 26.6204 25.2493C26.6766 25.44 26.6802 25.6422 26.6309 25.8348C26.5815 26.0274 26.481 26.2031 26.34 26.3434C26.199 26.4838 26.0226 26.5836 25.8296 26.6323C25.6368 26.6816 25.4343 26.6782 25.2433 26.6224C25.0524 26.5666 24.88 26.4604 24.7444 26.3151L19.9839 21.5647L15.2234 26.3151C15.0131 26.5105 14.7351 26.6169 14.4478 26.6119C14.1605 26.607 13.8864 26.4911 13.6829 26.2886C13.48 26.0856 13.3639 25.812 13.3589 25.5253C13.354 25.2386 13.4606 24.9612 13.6563 24.7513L18.4168 20.0009L13.6563 15.2504C13.4487 15.043 13.332 14.7617 13.332 14.4685C13.332 14.1753 13.4487 13.8941 13.6563 13.6866Z"
        className="fill-icon-1"
        // fill="#ED1C24"
      />
    </svg>
  )
}

const InfoConDSS = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="13.0391"
        y="11.04"
        width="14.08"
        height="19.2003"
        fill="url(#paint0_linear_8028_17302)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.0001 35.8337C24.1993 35.8337 28.2266 34.1655 31.1959 31.1962C34.1653 28.2269 35.8334 24.1996 35.8334 20.0003C35.8334 15.8011 34.1653 11.7738 31.1959 8.80447C28.2266 5.83514 24.1993 4.16699 20.0001 4.16699C15.8008 4.16699 11.7736 5.83514 8.80422 8.80447C5.8349 11.7738 4.16675 15.8011 4.16675 20.0003C4.16675 24.1996 5.8349 28.2269 8.80422 31.1962C11.7736 34.1655 15.8008 35.8337 20.0001 35.8337ZM21.9792 14.0628C21.9792 14.5877 21.7707 15.0911 21.3996 15.4623C21.0284 15.8335 20.525 16.042 20.0001 16.042C19.4752 16.042 18.9718 15.8335 18.6006 15.4623C18.2294 15.0911 18.0209 14.5877 18.0209 14.0628C18.0209 13.5379 18.2294 13.0345 18.6006 12.6633C18.9718 12.2922 19.4752 12.0837 20.0001 12.0837C20.525 12.0837 21.0284 12.2922 21.3996 12.6633C21.7707 13.0345 21.9792 13.5379 21.9792 14.0628ZM18.0209 18.0212C17.6272 18.0212 17.2497 18.1775 16.9713 18.4559C16.6929 18.7343 16.5365 19.1119 16.5365 19.5055C16.5365 19.8992 16.6929 20.2768 16.9713 20.5551C17.2497 20.8335 17.6272 20.9899 18.0209 20.9899H18.5157V24.9482H16.5365C16.1429 24.9482 15.7653 25.1046 15.4869 25.383C15.2086 25.6614 15.0522 26.0389 15.0522 26.4326C15.0522 26.8263 15.2086 27.2039 15.4869 27.4822C15.7653 27.7606 16.1429 27.917 16.5365 27.917H23.4636C23.8573 27.917 24.2349 27.7606 24.5132 27.4822C24.7916 27.2039 24.948 26.8263 24.948 26.4326C24.948 26.0389 24.7916 25.6614 24.5132 25.383C24.2349 25.1046 23.8573 24.9482 23.4636 24.9482H21.4845V18.0212H18.0209Z"
        fill="url(#paint1_linear_8028_17302)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8028_17302"
          x1="27.1191"
          y1="30.2404"
          x2="10.5112"
          y2="27.7241"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#654D2C" />
          <stop offset="0.500935" stop-color="#DFD59A" />
          <stop offset="1" stop-color="#654D2C" />
          <stop offset="1" stop-color="#654D2C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8028_17302"
          x1="20.0001"
          y1="4.16699"
          x2="20.0001"
          y2="35.8337"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-color="#221F20" />
        </linearGradient>
      </defs>
    </svg>
  )
}

const InfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M4 20C4 11.1634 11.1634 4 20 4C28.8366 4 36 11.1634 36 20C36 28.8366 28.8366 36 20 36C11.1634 36 4 28.8366 4 20Z"
        // fill="#ED1C24"
        className="fill-icon-1"
      />
      <rect
        x="13.0391"
        y="11.0403"
        width="14.08"
        height="19.2003"
        // fill="#ED1C24"
        className="fill-icon-1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 36C24.2435 36 28.3131 34.3143 31.3137 31.3137C34.3143 28.3131 36 24.2435 36 20C36 15.7565 34.3143 11.6869 31.3137 8.68629C28.3131 5.68571 24.2435 4 20 4C15.7565 4 11.6869 5.68571 8.68629 8.68629C5.68571 11.6869 4 15.7565 4 20C4 24.2435 5.68571 28.3131 8.68629 31.3137C11.6869 34.3143 15.7565 36 20 36ZM22 14C22 14.5304 21.7893 15.0391 21.4142 15.4142C21.0391 15.7893 20.5304 16 20 16C19.4696 16 18.9609 15.7893 18.5858 15.4142C18.2107 15.0391 18 14.5304 18 14C18 13.4696 18.2107 12.9609 18.5858 12.5858C18.9609 12.2107 19.4696 12 20 12C20.5304 12 21.0391 12.2107 21.4142 12.5858C21.7893 12.9609 22 13.4696 22 14ZM18 18C17.6022 18 17.2206 18.158 16.9393 18.4393C16.658 18.7206 16.5 19.1022 16.5 19.5C16.5 19.8978 16.658 20.2794 16.9393 20.5607C17.2206 20.842 17.6022 21 18 21H18.5V25H16.5C16.1022 25 15.7206 25.158 15.4393 25.4393C15.158 25.7206 15 26.1022 15 26.5C15 26.8978 15.158 27.2794 15.4393 27.5607C15.7206 27.842 16.1022 28 16.5 28H23.5C23.8978 28 24.2794 27.842 24.5607 27.5607C24.842 27.2794 25 26.8978 25 26.5C25 26.1022 24.842 25.7206 24.5607 25.4393C24.2794 25.158 23.8978 25 23.5 25H21.5V18H18Z"
        // fill="white"
        className="fill-icon-4"
      />
    </svg>
  )
}
