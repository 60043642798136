"use client"
import { BannerItem } from "../shared/banner/banner-item"

export function SupportingBanner({
  show_navigation,
  autoplay,
  speed = 1000,
  items = [],
}) {
  return (
    <div className="my-[10px] px-[10px] lg:m-[0] lg:p-[0]">
      <BannerItem
        type="secondary"
        items={items}
        speed={speed}
        autoplay={autoplay}
        show_navigation={show_navigation}
        event="sub_banner"
      />
    </div>
  )
}
