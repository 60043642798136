"use client"
import React, { useState } from "react"
import PropTypes from "prop-types"
export default function ProgressCard({
  missionIcon,
  missionDescription = "mission",
  missonCountCurrent = 0,
  missionCountTotal = 0,
  isLimited,
  isMgm,
  info,
  toolTip,
  href,
}) {
  const [isShowInfo, setIsShowInfo] = useState(false)

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex justify-between items-center gap-[5px]">
          <div
            data-testid="mission-icon"
            className="w-[16px] h-[16px] bg-bg-1 rounded-[5px] relative overflow-hidden"
          >
            {missionIcon}
          </div>
          <div
            data-testid="mission-description"
            className="text-[12px] lg:text-[14px] lg:text-[10px] lg:tracking-[0.2px] leading-[16px] lg:leading-[19px] text-text-2 font-medium font-font-family-6"
          >
            {href ? (
              <a
                data-testid="mission-link"
                className="inline-block hover:underline"
                href={href}
              >
                {missionDescription}
              </a>
            ) : (
              <>{missionDescription}</>
            )}
          </div>
        </div>
        <div
          data-testid="mission-count"
          className="text-[12px] lg:text-[14px] font-bold font-font-family-7 leading-[16px] text-text-1 flex items-center gap-[5px]"
        >
          {isLimited ? (
            <>
              <span className="text-text-1">{missonCountCurrent}</span> /{" "}
              {missionCountTotal}
            </>
          ) : isMgm ? (
            <>{`+${missionCountTotal} Teman`}</>
          ) : (
            <>{missionCountTotal} Poin</>
          )}
          {toolTip ? (
            <svg
              data-testid="tooltip"
              onMouseEnter={() => setIsShowInfo(true)}
              onMouseLeave={() => setIsShowInfo(false)}
              onClick={() => setIsShowInfo((prev) => !prev)}
              role="button"
              tabIndex={0}
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M9.16699 6.33366V8.00033H10.8337V6.33366H9.16699ZM11.667 14.667V13.0003H10.8337V9.66699H8.33366V11.3337H9.16699V13.0003H8.33366V14.667H11.667ZM18.3337 10.5003C18.3337 15.0837 14.5837 18.8337 10.0003 18.8337C5.41699 18.8337 1.66699 15.0837 1.66699 10.5003C1.66699 5.91699 5.41699 2.16699 10.0003 2.16699C14.5837 2.16699 18.3337 5.91699 18.3337 10.5003ZM16.667 10.5003C16.667 6.81699 13.6837 3.83366 10.0003 3.83366C6.31699 3.83366 3.33366 6.81699 3.33366 10.5003C3.33366 14.1837 6.31699 17.167 10.0003 17.167C13.6837 17.167 16.667 14.1837 16.667 10.5003Z"
                className="fill-icon-1"
              />
            </svg>
          ) : null}
        </div>
      </div>
      {info && isShowInfo ? (
        <div className="rounded-[5px] my-[5px] pl-[21px] py-[5px] pr-[5px] text-[12px] leading-[16px] tracking-[0.25px] text-icon-1 italic bg-cta-3">
          {info}
        </div>
      ) : null}
    </>
  )
}

ProgressCard.propTypes = {
  missionIcon: PropTypes.object,
  missionDescription: PropTypes.string,
  missonCountCurrent: PropTypes.number,
  missionCountTotal: PropTypes.number,
  isLimited: PropTypes.bool,
}
