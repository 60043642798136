import React from "react"
import { useEffect, useState } from "react"
import Style from "./Letter.module.scss"
import clsx from "clsx"

export default function Letter({
  board,
  level,
  letterPos,
  attemptVal,
  size,
  currAttempt,
  correctLetter,
  almostLetter,
  shake,
  setShake,
  flipAnimation,
  setFlipAnimation,
  stage,
  failTime,
  wordleRow,
  wrongLetter,
}) {
  const [colorClass, setColorClass] = useState(
    "!bg-bg-3 !text-text-1 !border-[1px] !border-cta-4 !shadow-[0_4px_5px_rgba(0,0,0,0.2),_inset_0_-5px_0_rgba(0,0,0,0.1)]"
  )
  const [flipClass, setFlipClass] = useState("")
  const { attempt } = currAttempt
  const letter = board[attemptVal][letterPos]
  const correct = correctLetter[letterPos] === letter
  const almost = almostLetter.includes(letter)
  const wrong = wrongLetter.includes(letter)
  const shakeClass = shake && attempt === attemptVal ? Style.shake : ""

  let sizeClass = ""
  if (size === "small") sizeClass = Style.small
  else if (size === "big") sizeClass = Style.big

  const correctClassSk =
    process.env.NEXT_PUBLIC_NAME === "sampoernakretek"
      ? Style.correctSk
      : Style.correct
  const correctClass =
    process.env.NEXT_PUBLIC_NAME === "magnum"
      ? Style.correctMagnum
      : correctClassSk
  const almostClass =
    process.env.NEXT_PUBLIC_NAME === "magnum"
      ? Style.almostMagnum
      : Style.almost
  const wrongClass =
    process.env.NEXT_PUBLIC_NAME === "magnum" ? Style.wrongMagnum : Style.wrong

  const setColor = () => {
    if (attempt < attemptVal + 1) return
    if (correct) {
      setColorClass(correctClass)
    } else if (almost) {
      setColorClass(almostClass)
    } else if (wrong) {
      setColorClass(wrongClass)
    } else {
      setColorClass(
        "!bg-bg-3 !text-text-1 !border-cta-4 !shadow-[0_4px_5px_rgba(0,0,0,0.2),_inset_0_-5px_0_rgba(0,0,0,0.1)]"
      )
    }
  }

  const setFlip = () => {
    if (flipAnimation && attempt === attemptVal) setFlipClass(Style.flip)
  }

  useEffect(() => {
    setTimeout(() => setFlip(), (letterPos * 400) / 2)
    // eslint-disable-next-line
  }, [flipAnimation])

  useEffect(() => {
    if (
      correctLetter.length === 0 &&
      wrongLetter.length === 0 &&
      almostLetter.length === 0
    )
      setColorClass("")
  }, [correctLetter, wrongLetter, almostLetter])

  useEffect(() => {
    setColor()
    // eslint-disable-next-line
  }, [stage])

  return (
    <div
      onAnimationEnd={() => setShake(false)}
      onTransitionEnd={() => {
        setFlipClass("")
        setColor()
        setFlipAnimation(false)
      }}
      className={clsx(
        `!border aspect-square flex justify-center items-center uppercase font-medium font-font-family-6`,
        Style.letterContainer,
        flipClass,
        shakeClass,
        sizeClass,
        colorClass === ""
          ? "!bg-bg-3 !text-text-1 !border-cta-4 !shadow-[0_4px_5px_rgba(0,0,0,0.2),_inset_0_-5px_0_rgba(0,0,0,0.1)]"
          : colorClass,
        wordleRow <= 6
          ? "w-[50px] text-[22px] rounded-[9px]"
          : "w-[32px] text-[16px] rounded-[5px]"
      )}
    >
      {letter}
    </div>
  )
}
