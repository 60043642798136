import clsx from "clsx"
import styles from "./spinner.module.scss"

export const Spinner = ({ centered = true }) => {
  return (
    <svg
      style={{
        margin: centered ? "0 auto" : undefined,
        display: "block",
      }}
      className={styles.spinner}
      viewBox="0 0 50 50"
    >
      <circle
        className={clsx("stroke-text-1", styles.path)}
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
      ></circle>
    </svg>
  )
}
