"use client"
import React from "react"
import { twMerge } from "tailwind-merge"
import PropTypes from "prop-types"
export default function CampaignTitle({ children, className }) {
  return (
    <div
      data-testid="campaign-title"
      className={twMerge(
        "mb-[20px] text-text-2 text-[16px] lg:text-[24px] font-bold font-font-family-7 leading-[22px] lg:leading-[32px]",
        // "mb-[20px] text-text-2 text-[16px] lg:text-[40px] font-bold font-font-family-7 lg:font-font-family-3 leading-[22px] lg:leading-[32px]",
        className
      )}
    >
      {children}
    </div>
  )
}

CampaignTitle.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}
