"use client"

import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import { Image } from "@/components/shared/image/image"
import Link from "@/components/shared/link/link"
import { useEffect, useRef, useState } from "react"
import { ArticleItem } from "../shared/card/article-card"
import { cva } from "class-variance-authority"
import { useIsMobile } from "@/hooks/use-is-mobile"
import clsx from "clsx"
import PropTypes from "prop-types"
import { ClientApiList } from "@/lib/api/client-service"
import { Spinner } from "../shared/spinner"
import { getPublishDate, useDatalayer } from "@/hooks/use-datalayer"
import ButtonAction from "../shared/button-action/button-action"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { getArticlePathname } from "@/hooks/use-pathname"
import { useWindowSize } from "@/hooks/use-window-size"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { cn } from "@/lib/utils"

const ActiveCategory = ({
  idx,
  item,
  mode,
  setActiveFilter,
  pathname,
  pagingParams,
  setAllSubCat,
}) => {
  const datalayer = useDatalayer()
  const isMobile = useIsMobile()
  const { replace } = useRouter()
  const button = cva(
    `${
      isMobile ? "p-[15px]" : "py-[15px] px-[25px]"
    } rounded-[10px] flex items-center gap-[5px]`,
    {
      variants: {
        mode: {
          active: ["bg-cta-1", "text-cta-2", "font-bold font-font-family-7 "],
          unactive: [
            "bg-cta-4",
            "text-text-3",
            "font-medium font-font-family-6",
          ],
        },
      },
      compoundVariants: [{ mode: "unactive" }],
      defaultVariants: {
        mode: "unactive",
      },
    }
  )

  const switchBGActive = () => {
    switch (mode) {
      case "active":
        return "var(--cta_1)"
      case "unactive":
        return "var(--cta_4)"
      default:
        return "transparent"
    }
  }

  const pagingSubCat = () => {
    const params = [
      ["page", pagingParams],
      ["subcategory", idx],
    ]
    const joinedParams = params.map((param) => param.join("=")).join("&")
    replace(`${pathname}?${joinedParams}`, { scroll: false })
  }

  return (
    <button
      className={button({ mode })}
      style={{ background: switchBGActive() }}
      data-testid={`subcategory-filter-${item.id}`}
      onClick={() => {
        pagingSubCat()
        datalayer.push({
          event: "general_event",
          event_name: `click_${item.sub_category_name}_article`,
          feature: "content",
          button_name: item.sub_category_name, // e.g., home
          campaign_name: "all", // e.g all
        })
        setActiveFilter(idx)
        setAllSubCat(idx === 0)
      }}
    >
      {item.icon && (
        <div className="relative w-[16px] aspect-square">
          <Image alt="icon" src={item.icon} />
        </div>
      )}
      <span className="text-[14px] leading-[20px] ">
        {item.sub_category_name}
      </span>
    </button>
  )
}

export function ArticleList({
  title,
  // items,
  paging,
  subCategory,
  event = "regular_content",
  cta,
}) {
  const swiperRef = useRef()
  const { width } = useWindowSize()
  const isDesktop = width >= 1024
  const isMobile = useIsMobile()
  const datalayer = useDatalayer()
  const { isLogin, isLoading } = useIsLogin()
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const { replace } = useRouter()

  const [activeFilter, setActiveFilter] = useState(0)
  const [nextPage, setNextPage] = useState(paging?.nextPage || 0)
  const [articles, setArticles] = useState([])
  const [loading, setLoading] = useState(false)
  const [filterArticles, setFilterArticles] = useState([])
  const [filterNextPage, setFilterNextPage] = useState(paging?.nextPage)
  const [firstLoad, setFirstLoad] = useState(false)
  const [loadList, setLoadList] = useState(true)
  const [autoScroll, setAutoScroll] = useState(true)
  const [allSubCat, setAllSubCat] = useState(false)

  const ctaAmild = isLogin ? "Telusuri Lebih Lanjut" : "Muat Lebih Banyak"

  let allSubCategory = [{ sub_category_name: "All" }, ...subCategory]

  const getList = async (query = false) => {
    setLoadList(true)
    const { data } = await ClientApiList.getListArticle({
      length:
        query && Number(searchParams.get("page")) > 0
          ? Number(searchParams.get("page")) * 6
          : 6,
      is_branded: isLogin ? 1 : 0,
      is_regular: 1,
      pinned: "No",
      page: 0,
      sub_category: query
        ? allSubCategory[Number(searchParams.get("subcategory"))].string_id
        : "",
    })

    if (query && Number(searchParams.get("subcategory")) > 0) {
      setFilterArticles(data?.data?.items)
      setFilterNextPage(data?.data?.paging.nextPage)
    } else {
      setArticles(data?.data?.items)
      setNextPage(data?.data?.paging.nextPage)
    }
    setLoadList(false)
  }

  /* first load list */
  useEffect(() => {
    if (
      !isLoading &&
      !firstLoad &&
      (searchParams.get("page") || searchParams.get("subcategory"))
    ) {
      getList(true)
      setFirstLoad(true)
    } else if (!isLoading && !firstLoad) {
      getList()
      setFirstLoad(true)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLoading, firstLoad, searchParams])

  const loadMore = async () => {
    setAutoScroll(false)
    const params = [
      ["page", activeFilter ? filterNextPage + 1 : nextPage + 1],
      ["subcategory", activeFilter],
    ]
    const joinedParams = params.map((param) => param.join("=")).join("&")
    replace(`${pathname}?${joinedParams}`, { scroll: false })
    const data = await ClientApiList.getListArticle({
      length: 6,
      is_branded: isLogin ? 1 : 0,
      is_regular: 1,
      pinned: "No",
      page: activeFilter ? filterNextPage : nextPage,
      sub_category: activeFilter ? allSubCategory[activeFilter].string_id : "",
    })
    if (activeFilter) {
      setFilterArticles(filterArticles.concat(data?.data?.data?.items))
      setFilterNextPage(data?.data?.data?.paging.nextPage)
    } else {
      setArticles(articles.concat(data?.data?.data?.items))
      setNextPage(data?.data?.data?.paging.nextPage)
    }
    setLoading(false)

    datalayer.push({
      event: "general_event",
      event_name: `click_no_more_articles_article`,
      feature: "articles",
      button_name: cta?.toLowerCase(), // e.g., home
      campaign_name: "all", // e.g all
    })
  }

  const getFilterArticle = async () => {
    setLoadList(true)
    const { data } = await ClientApiList.getListArticle({
      length:
        Number(searchParams.get("page")) > 0
          ? Number(searchParams.get("page")) * 6
          : 6,
      is_branded: isLogin ? 1 : 0,
      is_regular: 1,
      pinned: "No",
      page: 0,
      sub_category: activeFilter ? allSubCategory[activeFilter].string_id : "",
    })
    setFilterArticles(data?.data?.items)
    setFilterNextPage(data?.data?.paging.nextPage)
    if (data?.data?.items) setLoadList(false)
  }

  /* get list active filter */
  useEffect(() => {
    if (activeFilter && firstLoad) {
      getFilterArticle()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [activeFilter, firstLoad])

  /* get list all after active filter */
  useEffect(() => {
    if (allSubCat && firstLoad && articles.length < 1) {
      getList()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [allSubCat, firstLoad, articles])

  const section = Number(searchParams.get("page"))

  /* scroll to button load more */
  useEffect(() => {
    let t

    if (!isLoading && !loadList && section > 1 && autoScroll) {
      const el = document?.getElementById("cat-btn")

      if (el)
        t = setTimeout(() => {
          const offsetHeader = isMobile ? 58 : 72
          const offset = el.offsetTop - offsetHeader

          window.scrollTo({
            top: offset,
            behavior: "smooth",
          })
        }, 200)
    }

    return () => {
      clearTimeout(t)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLoading, loadList, searchParams, autoScroll, section])

  const onClickDatalayer = (index, item) => {
    if (pathname === "/br" || pathname === "/")
      datalayer.push({
        event: "general_event",
        event_name: `click_${event}_section_${index + 1}`, // e.g. click_regular_content_section_1
        feature: "banner",
        content_brand: item?.is_branded == 0 ? "unbranded" : "branded",
        content_type: item?.format,
        content_category: item?.category.toLowerCase(),
        content_position: index + 1, // e.g 1, 2, 3, etc (integer)
        content_name: item?.title?.toLowerCase(), // e.g 20 tips persiapan
        publish_date: getPublishDate(item?.start_date), // e.g 20-11-2023
        campaign_name: item?.campaign?.toLowerCase(), // e.g ride your flavour 2023
        event_label: `${item?.format} - ${item?.title?.toLowerCase()}`,
        content_id: item?.id,
        engagement_type: "content",
      })
  }

  const onViewArticle = async (slide) => {
    try {
      const { data: dataProfile } = await ClientApiList.getProfileData()
      await ClientApiList.getInteractionUser({
        user_id: dataProfile?.data.data.gigya_id,
        event: "general_event",
        campaign: slide?.campaign?.toLowerCase(),
        event_category: slide?.category.toLowerCase(),
        event_action: `on view ${slide?.format}`,
        event_label: `${slide?.format} - ${slide?.title?.toLowerCase()}`,
        creative: typeof window !== "undefined" ? window.location.href : "",
        content_id: slide?.id,
      })
    } catch (err) {
      if (err.status === 401) window.location.reload()
    }
  }

  const checkNextPage =
    Number(searchParams.get("subcategory")) > 0 ? filterNextPage : nextPage

  return (
    <div id="cat-btn" className="w-full h-full relative my-5 ">
      <h2
        className={cn(
          "mx-[16px] text-xl text-text-2 font-medium font-font-family-6",
          isMobile ? "mb-[10px]" : "mb-[15px]",
          process.env.NEXT_PUBLIC_NAME === "amild"
            ? "font-font-family-3"
            : "font-font-family-7"
        )}
      >
        {process.env.NEXT_PUBLIC_NAME === "amild"
          ? String(title).toUpperCase()
          : title}
      </h2>
      <div className="relative mb-[15px]">
        <Swiper
          spaceBetween={5}
          slidesPerView="auto"
          onSlideChange={(swiper) => {
            setActiveFilter(swiper.activeIndex)
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper
            setActiveFilter(swiper.activeIndex)
          }}
          style={{
            padding: "0 15px",
          }}
        >
          {allSubCategory?.map((slide, index) => (
            <SwiperSlide
              style={{
                width: "fit-content",
              }}
              key={index}
            >
              <ActiveCategory
                idx={index}
                item={slide}
                mode={
                  index === Number(searchParams.get("subcategory"))
                    ? "active"
                    : "unactive"
                }
                setActiveFilter={setActiveFilter}
                setAllSubCat={setAllSubCat}
                pathname={pathname}
                pagingParams={Number(searchParams.get("page"))}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div
        className={clsx(
          "relative inset-0 w-full h-full flex flex-wrap gap-[5px] px-[15px]",
          isDesktop ? "justify-left" : "justify-center"
        )}
      >
        {(activeFilter || Number(searchParams.get("subcategory"))
          ? filterArticles
          : articles
        )?.map((slide, index) => (
          <div
            key={index}
            className={clsx(
              "mb-[5px]",
              isDesktop ? "basis-[calc((100%/3)-5px)]" : "basis-[calc(50%-5px)]"
            )}
          >
            <Link
              href={getArticlePathname(slide)}
              passHref
              data-testid={`article-item-${index}`}
              onClick={() => onClickDatalayer(index, slide)}
            >
              <ArticleItem
                slide={slide}
                card_size="full"
                cover_size={isDesktop ? "medium_horizontal" : "small"}
                title_size={!isDesktop && "small"}
                card_direction={isDesktop ? "horizontal" : "vertical"}
                title_direction={isDesktop ? "col_reverse" : "col"}
                horizontal={isDesktop}
                onView={() => onViewArticle(slide)}
              />
            </Link>
          </div>
        ))}
      </div>
      {loading || loadList ? (
        <div className="w-full flex justify-center mt-[32px]">
          <Spinner />
        </div>
      ) : (
        <div className="flex w-full justify-center">
          {checkNextPage !== null ? (
            <ButtonAction
              data-testid="load-more-article"
              className={clsx(
                "mt-[32px] max-w-[355px]",
                isMobile ? "mx-[10px]" : "flex justify-center items-center"
              )}
              intent="secondary"
              onClick={() => {
                setLoading(true)
                loadMore()
              }}
            >
              {process.env.NEXT_PUBLIC_NAME === "amild" ? ctaAmild : cta}
            </ButtonAction>
          ) : (
            <p className="w-full text-base mt-[32px] text-center text-text-2">
              No More Articles
            </p>
          )}
        </div>
      )}
    </div>
  )
}

ArticleList.propTypes = {
  title: PropTypes.string,
  cta: PropTypes.string,
  paging: PropTypes.object,
  subCategory: PropTypes.array,
  event: PropTypes.string,
}

ActiveCategory.propTypes = {
  idx: PropTypes.number,
  item: PropTypes.object,
  mode: PropTypes.string,
  setActiveFilter: PropTypes.func,
  pathname: PropTypes.string,
  pagingParams: PropTypes.number,
  setAllSubCat: PropTypes.bool,
}
