export const board4 = [
  ["", "", "", ""],
  ["", "", "", ""],
  ["", "", "", ""],
  ["", "", "", ""],
  ["", "", "", ""],
  ["", "", "", ""],
]

// FUNCTION mengambil verfikasi kata untuk game
// export const generate5Word = async () => {
//   let wordSet;

//   await fetch(wordBank5)
//     .then((response) => response.text())
//     .then((result) => {
//       const wordArr = result.split(/[\n\s]+/);
//       wordSet = new Set(wordArr);
//     });

//   return { wordSet };
// };
