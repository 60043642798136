"use client"
import clsx from "clsx"
import { Description } from "../shared/description/description"
import { useIsMobile } from "@/hooks/use-is-mobile"
import PropTypes from "prop-types"

export function SyaratKetentuan(props) {
  const { title, subtitle, description } = props
  const isMobile = useIsMobile()
  return (
    <div
      className={clsx(
        "w-full max-w-[460px] mx-auto relative pt-[30px] ",
        isMobile && "px-3.5"
      )}
    >
      <p className="font-bold text-2xl text-text-2 capitalize font-font-family-3">
        {title}
      </p>
      <p className={clsx("font-medium font-font-family-6 mt-[10px] text-text-2 text-base")}>
        {subtitle}
      </p>
      <Description
        className="text-sm my-[20px] text-text-2"
        description={description}
      />
    </div>
  )
}

SyaratKetentuan.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  subtitle: PropTypes.string,
}
