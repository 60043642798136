"use client"
import { useIsDesktop } from "@/hooks/use-is-mobile"
import { useViewportSize } from "@mantine/hooks"
import {
  motion,
  useAnimation,
  useMotionValueEvent,
  useScroll,
} from "framer-motion"
import Link from "@/components/shared/link/link"
import { Suspense, useEffect, useRef, useState } from "react"
import NavItem from "../nav/nav-item/navItem"
import { useDropdown } from "@/hooks/use-dropdown"
import {
  DropdownIcon,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../shared/dropdown"
import ButtonAction from "../shared/button-action/button-action"
import MenuContent from "../slide-menu/menu-content/menu-content"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { Loyalty } from "../loyalty"
import PropTypes from "prop-types"
import clsx from "clsx"
import { Image } from "@/components/shared/image/image"
import { useReqUrl } from "@/hooks/auth/use-req-url"
import { useDatalayer } from "@/hooks/use-datalayer"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { ClientApiList } from "@/lib/api/client-service"
import { ToastComponent } from "../toast"
import { cn } from "@/lib/utils"
import { iframeHidesNav } from "../iframe/data-hide"
import { useQuery } from "@tanstack/react-query"

function SuspendedAppHeader({
  nav_items = [],
  nav_items_ub = [],
  app_logo,
  title,
  show_exp,
  menu,
  menu_br,
  login_cta,
  loyalty_teaser,
}) {
  const datalayer = useDatalayer()
  const { isLogin, isLoading } = useIsLogin()

  const { data: profile } = useQuery({
    queryKey: ["getProfile"],
    queryFn: async () => {
      const { data } = await ClientApiList.getProfileData()
      return data
    },
    enabled: Boolean(isLogin && !isLoading),
  })

  const { execute: login } = useReqUrl()
  const { execute: register } = useReqUrl({
    page: "register",
  })

  const ref = useRef()
  let { scrollY } = useScroll()
  let { height } = useViewportSize()
  const controls = useAnimation()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const isDesktop = useIsDesktop()

  const [dropdownOpened, setDropdownOpened] = useDropdown(false)
  const [nav, setNav] = useState(nav_items_ub)
  const [menuList, setMenuList] = useState(menu)
  const [pointUb, setPointUb] = useState("")
  const [isSuccess, setIsSuccess] = useState(false)
  const [statusTier, setStatusTier] = useState("")
  const [tier, setTier] = useState("")
  const { replace } = useRouter()

  const { execute: autoLoginPage } = useReqUrl({
    redirectUrl: typeof window !== "undefined" && window.location.href,
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
    page: "login",
  })

  const { execute: autoRegisterPage } = useReqUrl({
    redirectUrl: typeof window !== "undefined" && window.location.href,
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
    page: "register",
  })

  useEffect(() => {
    if (searchParams.get("to") === "allaccess" && !isLoading && !isLogin) {
      autoLoginPage()
    } else if (
      searchParams.get("to") === "allaccess-register" &&
      !isLoading &&
      !isLogin
    ) {
      autoRegisterPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, isLoading, isLogin])

  const contentType = pathname.includes("video") ? "video" : "article"

  const updatePointUb = async () => {
    const { data } = await ClientApiList.getPointUb({
      content_type:
        pathname.includes("article") || pathname.includes("video")
          ? contentType
          : "",
    })
    setPointUb(data?.data?.point)
  }

  useEffect(() => {
    updatePointUb()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pathname])

  useEffect(() => {
    if (searchParams.get("point")) updatePointUb()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [searchParams])

  useEffect(() => {
    if (isLogin && !isLoading) {
      setNav(nav_items)
      setMenuList(menu_br)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLogin, isLoading])

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (latest <= 1) {
      controls.start({
        y: 0,
      })
    } else if (
      latest < scrollY.getPrevious() ||
      height + latest >= document.body.offsetHeight
    ) {
      controls.start({
        y: 0,
        transition: {
          duration: 0.3,
          ease: "easeOut",
        },
      })
    } else {
      controls.start({
        y: -64,
        transition: {
          duration: 0.2,
          ease: "easeOut",
        },
      })
    }
  })

  const hrefToLink = () => {
    let links = { pathname, query: null }
    const newQuery = {
      ...Object.fromEntries(Array.from(searchParams.entries())),
      open: searchParams.get("open") === "true" ? "false" : "true",
    }
    links.query = newQuery
    return links
  }

  const onClickDatalayer = (name) => {
    datalayer.push({
      event: `general_event`,
      event_name: `click_${name}_navigation`,
      feature: "customized navigation",
      button_name: name?.toLowerCase(),
      campaign_name: "all",
      engagement_type: "interaction",
    })
  }

  /* set status tier */
  const getLoyalty = async () => {
    const { data } = await ClientApiList.getLoyaltyTeaser()
    setStatusTier(data?.data?.data?.status_tier)
    setTier(data?.data?.data?.current_tier)
  }

  /* status tier for interaction user */
  const interactionTier = async () => {
    try {
      await ClientApiList.getInteractionUser({
        user_id: !isLoading && isLogin && profile?.data.data.gigya_id,
        event: "general_event",
        campaign: "all",
        event_category: "page_reached_tier",
        event_action: statusTier,
        event_label:
          statusTier === "up" ? `tier up - ${tier}` : `tier down - ${tier}`,
        creative: typeof window !== "undefined" ? window.location.href : "",
      })
    } catch (err) {
      if (err.status === 401) window.location.reload()
    }
  }

  useEffect(() => {
    if (!isLoading && isLogin) getLoyalty()
  }, [isLogin, isLoading])

  useEffect(() => {
    if (statusTier !== "stay" && !pathname.includes("verify-email"))
      interactionTier()
    if (statusTier === "up") setIsSuccess(true)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [statusTier])

  const titleBrand =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? `Selamat! Kamu naik tier menjadi ${tier}. Tambah poin kamu buat dapet benefit ekslusif lainnya!`
      : `Selamat! Lo naik tier menjadi ${tier}. Tambah poin lo buat dapet benefit ekslusif lainnya!`

  const hideNav =
    pathname.includes("/cc-bridging") ||
    pathname.includes("/maintenance") ||
    pathname.includes("/amild-offline")

  return (
    <>
      <ToastComponent
        onClick={() => {
          setIsSuccess(false)
        }}
        title={titleBrand}
        desc=""
        isOpen={isSuccess}
      />
      {!isLoading && !hideNav && (
        <motion.header
          animate={controls}
          ref={ref}
          className={cn(
            "fixed top-0 z-[14] w-full shadow-[0_12px_24px_0_rgba(0,0,0,0.04)]",
            iframeHidesNav.find((path) => pathname.includes(path))
              ? "hidden lg:block"
              : ""
          )}
        >
          <div
            className="absolute inset-[0] z-[-1]"
            style={{ background: "var(--header)" }}
          ></div>
          <div
            className={clsx(
              "px-4 flex items-center max-w-[1920px] mx-auto my-0",
              isDesktop ? "h-[72px]" : "h-[58px]"
            )}
          >
            <div className="w-full flex gap-[20px] items-center font-medium font-font-family-6">
              <Link
                href={isLogin && !isLoading ? "/br" : "/"}
                onClick={() => onClickDatalayer("logo")}
              >
                {!isLogin ? (
                  <div
                    className={clsx(
                      "text-text-1",
                      app_logo?.icon_url_ub &&
                        "relative w-[166px] aspect-[166/40] flex justify-center items-center"
                    )}
                  >
                    {app_logo?.icon_url_ub ? (
                      <Image src={app_logo?.icon_url_ub} alt="logo" />
                    ) : (
                      <span>{title?.label}</span>
                    )}
                  </div>
                ) : (
                  <div
                    className={clsx(
                      "relative",
                      app_logo?.type === "square"
                        ? " w-[36px] aspect-square"
                        : "w-[166px] aspect-[166/40]"
                    )}
                  >
                    <Image src={app_logo?.icon_url_br} alt="logo" />
                  </div>
                )}
              </Link>
              {isDesktop && (
                <div className="flex gap-[15px]">
                  {nav?.map((item) => (
                    <div key={item.label}>
                      {item.type === "open_menu" ? (
                        <DropdownMenu
                          defaultOpen={true}
                          open={dropdownOpened}
                          onOpenChange={(isOpen) => {
                            setDropdownOpened(isOpen)
                            onClickDatalayer("menu")
                          }}
                        >
                          <DropdownMenuTrigger asChild>
                            <div>
                              <NavItem
                                icon={item.icon_url}
                                iconSelected={item.icon_url_selected}
                                label={item.label}
                                href={item.href}
                                type={item.type}
                                className="text-[#646464] basis-auto"
                                rightSlot={
                                  <DropdownIcon open={dropdownOpened} />
                                }
                                id={item.id}
                              />
                            </div>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent className="w-[460px]">
                            <MenuContent
                              branded={isLogin}
                              menu={menuList}
                              login_cta={login_cta}
                            />
                          </DropdownMenuContent>
                        </DropdownMenu>
                      ) : (
                        <NavItem
                          icon={item.icon_url}
                          iconSelected={item.icon_url_selected}
                          label={item.label}
                          href={item.href}
                          type={item.type}
                          className="text-[#646464] basis-auto"
                          id={item.id}
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
              {!isLogin || (isLogin && show_exp?.active) ? (
                <div className="w-fit flex items-center font-bold font-font-family-7 ml-auto mr-0">
                  {pointUb >= 0 && (
                    <>
                      <div
                        className={clsx(
                          "relative w-[28px] aspect-square",
                          isDesktop ? "mr-[8px]" : "mr-[10px]"
                        )}
                      >
                        <Image
                          className="mr-2.5"
                          src={show_exp?.icon_url}
                          alt="icon"
                        />
                      </div>
                      <span className="text-text-2">{pointUb} Poin</span>
                    </>
                  )}
                  {isDesktop && !isLogin && (
                    <>
                      {pointUb >= 0 && (
                        <div className="w-[1px] h-[24px] bg-black mx-[20px]" />
                      )}
                      <div className="flex gap-[10px]">
                        <ButtonAction
                          intent="secondary"
                          className="py-[10px] px-[25px]"
                          data-testid="login-button"
                          onClick={() => {
                            datalayer.push({
                              event: `general_event`,
                              event_name: "click_login_button",
                              feature: "login",
                              button_name: "masuk",
                              page_path: pathname,
                              campaign_name: "all",
                            })
                            login()
                          }}
                        >
                          Masuk
                        </ButtonAction>
                        <ButtonAction
                          intent="primary"
                          className="py-[10px] px-[25px]"
                          data-testid="register-button"
                          onClick={() => {
                            datalayer.push({
                              event: `general_event`,
                              event_name: "click_registration_button",
                              feature: "registration",
                              button_name: "daftar",
                              page_path: pathname,
                              campaign_name: "all",
                            })
                            register()
                          }}
                        >
                          Daftar
                        </ButtonAction>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <>
                  {isDesktop ? (
                    <div className="ml-auto mr-0 w-max">
                      <Loyalty {...loyalty_teaser} />
                    </div>
                  ) : (
                    <Link
                      href={hrefToLink()}
                      data-testid="menu-button"
                      className="w-8 h-8 ml-auto mr-0 flex justify-center items-center"
                      // onClick={() => onClickDatalayer("burger")}
                    >
                      <Menu />
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
        </motion.header>
      )}
    </>
  )
}

SuspendedAppHeader.propTypes = {
  nav_items: PropTypes.array,
  nav_items_ub: PropTypes.array,
  app_logo: PropTypes.object,
  title: PropTypes.object,
  show_exp: PropTypes.object,
  menu: PropTypes.array,
  menu_br: PropTypes.array,
  login_cta: PropTypes.object,
  loyalty_teaser: PropTypes.object,
}

function AppHeaderFallback() {
  return <div>Fallback</div>
}

export function AppHeader(props) {
  return (
    <Suspense fallback={<AppHeaderFallback />}>
      <SuspendedAppHeader {...props} />
    </Suspense>
  )
}

AppHeader.propTypes = {
  nav_items: PropTypes.array,
  app_logo: PropTypes.object,
  title: PropTypes.object,
  show_exp: PropTypes.object,
  menu: PropTypes.array,
  login_cta: PropTypes.object,
  loyalty_teaser: PropTypes.object,
}

const Menu = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_3803_4420)">
        <path
          d="M1 15C0.71667 15 0.479003 14.904 0.287003 14.712C0.0950034 14.52 -0.000663206 14.2827 3.46021e-06 14C3.46021e-06 13.7167 0.0960036 13.479 0.288004 13.287C0.480004 13.095 0.717337 12.9993 1 13H17C17.2833 13 17.521 13.096 17.713 13.288C17.905 13.48 18.0007 13.7173 18 14C18 14.2833 17.904 14.521 17.712 14.713C17.52 14.905 17.2827 15.0007 17 15H1ZM1 10C0.71667 10 0.479003 9.904 0.287003 9.712C0.0950034 9.52 -0.000663206 9.28267 3.46021e-06 9C3.46021e-06 8.71667 0.0960036 8.479 0.288004 8.287C0.480004 8.095 0.717337 7.99934 1 8H17C17.2833 8 17.521 8.096 17.713 8.288C17.905 8.48 18.0007 8.71734 18 9C18 9.28334 17.904 9.521 17.712 9.713C17.52 9.905 17.2827 10.0007 17 10H1ZM1 5C0.71667 5 0.479003 4.904 0.287003 4.712C0.0950034 4.52 -0.000663206 4.28267 3.46021e-06 4C3.46021e-06 3.71667 0.0960036 3.479 0.288004 3.287C0.480004 3.095 0.717337 2.99934 1 3H17C17.2833 3 17.521 3.096 17.713 3.288C17.905 3.48 18.0007 3.71734 18 4C18 4.28334 17.904 4.521 17.712 4.713C17.52 4.905 17.2827 5.00067 17 5H1Z"
          // fill="black"
          className="fill-icon-2"
        />
      </g>
      <defs>
        <clipPath id="clip0_3803_4420">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
