"use client"
import React from "react"
import { Image } from "@/components/shared/image/image"
import { formatedTime } from "@/helper/utils"
import Link from "@/components/shared/link/link"
import PropTypes from "prop-types"
import { useDatalayer } from "@/hooks/use-datalayer"
import { useIsDesktop } from "@/hooks/use-is-mobile"

export default function ExperienceCard({
  title,
  location,
  date,
  openHour,
  closeHour,
  isLastElem,
  index,
  img,
  mobile_img,
}) {
  const datalayer = useDatalayer()
  const isDesktop = useIsDesktop()

  const onClickDatalayer = (category, name, index) => {
    datalayer.push({
      event: "general_event",
      event_name: "click_exclusive_experience_list",
      feature: "event & hop list",
      content_category: category, // e.g next closest
      content_name: name, // e.g event title x subtitle in collaboration with perspiciatis unde omnis
      content_position: index, // e.g 1
      // city: ‘{{City}}’, // e.g North Jakarta
      // page_type: '{{page category}}', // e.g special engagement
      // campaign_name: '{{campaign}}', // e.g ride your flavor 2023
    })
  }

  return (
    <Link href="#" onClick={() => onClickDatalayer("", title, index)}>
      <div
        className={`flex items-center gap-[10px] lg:gap-[15px] bg-bg-3 text-text-2 p-[5px] ${!isLastElem ? "mb-[10px] lg:mb-[15px]" : ""} lg:rounded-[10px] overflow-hidden`}
      >
        <div className="rounded-[5.5px] lg:rounded-[8.5px] bg-[#D9D9D9] w-[61px] lg:w-[90px] h-[61px] lg:h-[90px] relative overflow-hidden">
          <Image
            data-testid="experience-card-image"
            alt="cover"
            style={{ objectFit: "cover" }}
            src={isDesktop ? img : mobile_img}
          />
        </div>
        <div>
          <div
            data-testid="experience-card-title"
            className="text-[14px] lg:text-[20px] font-bold font-font-family-7 mb-[5px] lg:mb-[10px] leading-[19px] lg:leading-[27px]"
          >
            {title}
          </div>
          <div
            data-testid="experience-card-location"
            className="text-[12px] lg:text-[16px] font-medium font-font-family-6 mb-[5px] lg:mb-[10px] text-text-3 leading-[16px] lg:leading-[22px]"
          >
            {location}
          </div>
          <div
            data-testid="experience-card-date"
            className="text-[12px] text-text-1 lg:text-[16px] font-medium font-font-family-6 leading-[16px] lg:leading-[22px]"
          >
            Open {formatedTime(date, openHour)} ∙ Closes at{" "}
            {formatedTime(date, closeHour)}
          </div>
        </div>
      </div>
    </Link>
  )
}

ExperienceCard.propTypes = {
  title: PropTypes.string,
  location: PropTypes.string,
  date: PropTypes.string,
  openHour: PropTypes.string,
  closeHour: PropTypes.string,
  img: PropTypes.string,
  mobile_img: PropTypes.string,
  isLastElem: PropTypes.bool,
  index: PropTypes.index,
}
