"use client"
import React from "react"
import Styles from "./ranks.module.scss"
import RanksCard from "../ranks-card/ranks-card"
import clsx from "clsx"
import { Image } from "@/components/shared/image/image"
import { useIsMobile } from "@/hooks/use-is-mobile"
import PropTypes from "prop-types"

const bgDesktop = "/assets/amild/aspace-leaderboard/bg-rank-aspace-desktop.png"
const bgMobile = "/assets/amild/aspace-leaderboard/bg-rank-aspace.png"

const Ranks = ({ dataRank, dataLeaderboard }) => {
  const isMobile = useIsMobile()
  const bgImg = isMobile ? bgMobile : bgDesktop

  return (
    <section className={Styles.leaderboard__ranks}>
      <div
        className={clsx(
          "absolute rounded-[10px] bottom-0 right-0 w-full overflow-hidden",
          isMobile ? "aspect-[355/427]" : "aspect-[1401/605]"
        )}
      >
        <Image alt="bg" src={bgImg} />
      </div>
      <div className="relative z-[1]">
        <p
          className={clsx(
            "font-bold font-font-family-7 text-center mb-[15px]",
            isMobile ? "text-base" : "text-2xl"
          )}
        >
          Leaderboard Challenge
        </p>
        {dataRank?.data?.data ? (
          <>
            <div
              className={clsx(
                Styles.rank__container,
                isMobile ? "text-xs" : "text-base max-w-[678px]"
              )}
            >
              <div
                data-testid="ranks-header"
                className={Styles.leaderboard__ranks__header}
              >
                Saat ini kamu ada di peringkat{" "}
                <span>{dataRank.data.data.rank}</span> dari{" "}
                {dataRank.data.data.meta.total} pemain
              </div>
            </div>
            <div className={clsx(Styles.leaderboard__rank, "capitalize")}>
              <RanksCard
                top
                user={{
                  index: dataRank.data.data.rank,
                  name: dataRank.data.data.full_name,
                  points: dataRank.data.data.poin,
                  status: dataRank.data.data.status,
                }}
              />
            </div>
          </>
        ) : null}
        <div className={Styles.leaderboard__list}>
          <ol>
            {dataLeaderboard &&
            dataLeaderboard.data.length !== 0 &&
            Object.keys(dataLeaderboard.data.error).length === 0
              ? dataLeaderboard.data.data.map((user) => (
                  <li className="capitalize" key={user._id}>
                    <RanksCard
                      user={{
                        index: user.rank,
                        name: user.full_name,
                        points: user.poin,
                        status: user.status,
                        username: user.username,
                      }}
                    />
                  </li>
                ))
              : [...Array(10).keys()].map((item, i) => (
                  <li key={i}>
                    <RanksCard
                      user={{
                        index: item + 1,
                        name: `Player ${item + 1}`,
                        points: 0,
                      }}
                    />
                  </li>
                ))}
          </ol>
        </div>
      </div>
    </section>
  )
}

export default Ranks

Ranks.propTypes = {
  dataRank: PropTypes.object,
  dataLeaderboard: PropTypes.object,
}
