// 'use client';
import { useEffect, useState } from 'react';
import { ClientApiList } from '@/lib/api/client-service';
import { useRouter, useSearchParams } from 'next/navigation';

export const useRedirect = ({
    redirectWhen,
    path,
    withQuery,
    withHash,
} = {}) => {
    const router = useRouter();
    const searchParams = useSearchParams();

    const [loading, setLoading] = useState(true);

    const loginStatus = async () => {
        const { data } = await ClientApiList.getProfileData();
        setLoading(data === undefined);
    };

    useEffect(() => {
        loginStatus();
    }, []);

    useEffect(() => {
        if (!loading && redirectWhen && !searchParams.get('logout')) {
            const params = new URLSearchParams(searchParams);
            router.replace(`${process.env.NEXT_PUBLIC_META_URL}${path}?${params.toString()}${window.location.hash}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirectWhen, withQuery, withHash, path, loading]);
};
