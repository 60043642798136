import { ClientApiList } from "@/lib/api/client-service"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { useAsync } from "../use-async"
import { getGALinker, getGL } from "@/helper/ga"
import { ClientStorage } from "ClientStorage"
import { useDatalayer } from "../use-datalayer"

export const useLogout = (redirect_url = "/") => {
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const router = useRouter()
  const localStorage = new ClientStorage("localStorage")
  const datalayer = useDatalayer()

  return useAsync(async () => {
    const params = [...searchParams.entries(), ["logout", true]]
    const joinedParams = params.map((param) => param.join("=")).join("&")
    router.push(`${pathname}?${joinedParams}`)
    try {
      const {
        data: { data: auth_data },
      } = await ClientApiList.getAuthData({
        ga: getGALinker(),
        gl: getGL(),
        platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
        redirect_url: `${process.env.NEXT_PUBLIC_META_URL}/`,
      })

      const response = await ClientApiList.logout({
        auth_data: auth_data.data.auth_data,
      })
      if (response?.status === 200) {
        datalayer.push({
          event: "user_info",
          user_id: undefined,
          userId: "0",
        })
        setTimeout(() => {
          let totem = localStorage.get("isSetTotem")
          window.location.assign(redirect_url)
          localStorage.empty()
          if (totem) localStorage.set("isSetTotem", totem, 432000)
        }, 2000)
      }
    } catch (err) {
      // console.log('error logout');
    }
  })
}
