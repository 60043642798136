"use client"
import React, { useEffect, useRef, useState } from "react"
import { Image } from "@/components/shared/image/image"
import { SwiperComp } from "../swiper/swiper"

import { SwiperSlide } from "swiper/react"
import CarouselHeader from "../carousel/carousel-header/carousel-header"
import CarouselFooter from "../carousel/carousel-footer/carousel-footer"
import { ClientApiList } from "@/lib/api/client-service"
import { ToastComponent } from "../toast"
import PropTypes from "prop-types"
import { getPublishDate, useDatalayer } from "@/hooks/use-datalayer"
import { useInView } from "react-intersection-observer"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { formatedDate, formatedDateNoDay } from "@/helper/utils"
import UseQueryParams from "@/hooks/use-query-params";
// import { content } from "html2canvas/dist/types/css/property-descriptors/content";

export function Carousel({ data, engagement_uuid, ...props }) {
  const QUERYPARAM = "point"
  const { handlePushQuery } = UseQueryParams()
  const isMobile = useIsMobile()
  const datalayer = useDatalayer()
  const swiperRef = useRef(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const [poin, setPoin] = useState(0)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isFinishedFetch, setIsFinishedFetch] = useState(false)
  const [dataCarousel, setDataCarousel] = useState(data)
  const [landingRef, inView] = useInView({
    triggerOnce: true,
  })

  const searchParams = useSearchParams()
  const pathname = usePathname()
  const { replace } = useRouter()

  const getDetail = async () => {
    const { data } = await ClientApiList.getEngagementDetail({
      type: "carousel",
      engagement_uuid: engagement_uuid,
    })
    setDataCarousel(data?.data.data)
    setIsFinishedFetch(true)
  }

  useEffect(() => {
    getDetail()
    // eslint-disable-next-line
  }, [])

  const desktopImage = dataCarousel?.carousel?.filter(
    (img) => img.image_type === "desktop"
  )
  const mobileImage = dataCarousel?.carousel?.filter(
    (img) => img.image_type === "mobile"
  )
  const listToRender = isMobile ? mobileImage : desktopImage

  const datalayerView = async () => {
    // const { data: dataProfile } = await ClientApiList.getProfileData()
    datalayer.push({
      event: "general_event",
      event_name: "page_reached_on_simple_engagement",
      feature: "simple engagement",
      page_type: "simple engagement",
      campaign_name: data?.campaign_name.toLowerCase(), // e.g all
      // userld: dataProfile?.data.data.dataProfile,
      event_label: `carousel - ${data?.engagement_title.toLowerCase()}`,
      content_id: engagement_uuid,
    })
  }
  // console.log(data, 'data', dataCarousel);
  const dataLayerString = (string) => {
    return string.toLowerCase()
  }

  const handlePos = (pos, arr) => {
    if (pos === 0) {
      return "start"
    } else if (pos === arr.length - 1) {
      return "swipe end"
    } else {
      return `swipe ${pos + 1}`
    }
  }

  const handleSlideLayer = (pos, arr) => {
    datalayer.push({
      event: "general_event",
      event_name: "carousel",
      feature: "content",
      content_brand: "branded",
      content_type: "carousel",
      content_category: dataLayerString(data?.category_name),
      content_name: dataLayerString(data?.engagement_title),
      content_progress: handlePos(pos, arr),
      publish_date: getPublishDate(dataCarousel?.start_date),
      engagement_type: "content",
      campaign_name: dataLayerString(data?.campaign_name),
    })
  }

  useEffect(() => {
    isFinishedFetch && handleSlideLayer(activeIndex, listToRender)
    // eslint-disable-next-line
  }, [activeIndex, isFinishedFetch])

  useEffect(() => {
    if (inView && data && !searchParams?.get("point")) {
      datalayerView()
    }
    // eslint-disable-next-line
  }, [inView, data])

  const handleGetPoint = async () => {
    datalayer.push({
      event: "general_event",
      event_name: "click_submit_carousel",
      feature: "simple engagement",
      engagement_type: "content",
      engagement_name: "carousel",
      level: 1,
      campaign_name: data?.campaign_name.toLowerCase(), // e.g all
      event_label: `carousel - ${data?.engagement_title.toLowerCase()}`,
      content_id: engagement_uuid,
    })
    try {
      const { data } = await ClientApiList.getCarouselPoint({
        engagement_uuid,
      })
      setPoin(data.data.result.data.point)
      setTimeout(
        () =>
          data.data.result.data.point && data.data.result.data.point !== 0
            ? setIsSuccess(true)
            : null,
        500
      )
      handlePushQuery(false, [QUERYPARAM, true])
    } catch (err) {
      console.log("ERR", err)
    }
  }

  const handleNextSlide = () => {
    if (activeIndex !== listToRender?.length - 1) {
      if (activeIndex === listToRender?.length - 2) {
        handleGetPoint()
      }
      swiperRef.current?.slideNext()
      setActiveIndex((prevState) => prevState + 1)
    } else {
      return
    }
  }

  const handlePrevSlide = () => {
    if (activeIndex !== 0) {
      swiperRef.current?.slidePrev()
      setActiveIndex((prevState) => prevState - 1)
    } else {
      return
    }
  }

  const titleBrand =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? `Kamu berhasil raih ${poin} poin`
      : `Lo berhasil dapetin ${poin} poin!`

  return (
    <>
      <ToastComponent
        onClick={() => {
          setIsSuccess(false)
        }}
        title={titleBrand}
        desc="Lanjut eksplor dan kumpulkan lebih banyak poin!"
        onShow={() =>
          datalayer.push({
            event: "general_event",
            event_name: `page_reached_finish_on_carousel`,
            feature: "simple engagement",
            engagement_type: "content",
            engagement_name: "carousel",
            level: 1,
            campaign_name: data?.campaign_name.toLowerCase(), // e.g all
            event_label: `carousel - ${data?.engagement_title.toLowerCase()}`,
            content_id: engagement_uuid,
          })
        }
        isOpen={isSuccess}
      />
      <div className="bg-black" ref={landingRef}>
        <div className="relative w-full min-h-[595px] md:max-w-[400px] mx-auto bg-bg-3">
          {dataCarousel?.point > 0 && (
            <CarouselHeader
              exp={dataCarousel?.point}
              finished={dataCarousel?.finished}
              paramPoint={searchParams?.get("point")}
              imgUrl
            />
          )}
          {process.env.NEXT_PUBLIC_NAME !== "djisamsoe" ? (
            <div className="z-[2] absolute inset-0 bg-gradient-to-b from-transparent via-[#000]/[0.7] via-[86.46%] to-[#000]/[0.9] to-[96.04%]"></div>
          ) : null}
          <SwiperComp
            spaceBetween={10}
            ref={swiperRef}
            className="!absolute !inset-0 "
          >
            {listToRender?.map((item, index) => {
              return (
                <SwiperSlide key={item.order_id}>
                  <Image
                    alt="carousel"
                    fill
                    objectFit="cover"
                    src={item.image_url}
                  />
                </SwiperSlide>
              )
            })}
          </SwiperComp>
          <CarouselFooter
            handleNextSlide={handleNextSlide}
            handlePrevSlide={handlePrevSlide}
            tag={data?.category_name}
            itemsLength={listToRender?.length}
            activeIndex={activeIndex}
          />
        </div>
      </div>
    </>
  )
}

Carousel.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  carousel: PropTypes.string,
  carousel_items: PropTypes.array,
}
