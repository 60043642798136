"use client"
import React, { useEffect, useState } from "react"
import { Image } from "@/components/shared/image/image"
import clsx from "clsx"
import { useIsMobile } from "@/hooks/use-is-mobile"
import PropTypes from "prop-types"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { usePathname } from "next/navigation"
import { useShowLoginPopup } from "@/hooks/auth/use-login-popup"
import { useRedirect } from "@/hooks/use-redirect"
import { Spinner } from "../shared/spinner"

export function AboutPageLayout({
  children,
  bg_img_url_mobile,
  bg_img_url_desktop,
}) {
  const isMobile = useIsMobile()
  const { isLoading, isLogin } = useIsLogin()
  const pathname = usePathname()

  const [showPage, setShowPage] = useState(false)

  const checkUbPath = pathname.includes("/ub") && isLogin && !isLoading
  const checkBrPath = pathname.includes("/br") && !isLogin && !isLoading
  const bgImg = isMobile ? bg_img_url_mobile : bg_img_url_desktop

  useShowLoginPopup({
    showWhen: checkBrPath,
    closePath: "/",
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
  })

  useRedirect({
    redirectWhen: checkUbPath,
    path: "/br",
    withHash: true,
    withQuery: true,
  })

  useEffect(() => {
    if (!isLoading && !checkUbPath && !checkBrPath) setShowPage(true)
  }, [isLoading, checkBrPath, checkUbPath])

  /* scroll to hash */
  useEffect(() => {
    let t
    const hash = pathname.split("#")[1]

    if (!isLoading && hash) {
      const el = document?.getElementById(pathname.split("#")[1])

      if (el)
        t = setTimeout(() => {
          const offsetHeader = isMobile ? 58 : 72
          const offset = el?.offsetTop - offsetHeader

          window.scrollTo({
            top: offset,
            behavior: "smooth",
          })
        }, 200)
    }

    return () => {
      clearTimeout(t)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLoading, pathname])

  return (
    <div
      className={clsx(
        "relative pb-2.5",
        isMobile ? "mt-[58px]" : "mt-[72px] mx-[15px]"
      )}
    >
      <div
        className={clsx(
          "absolute left-0 right-0 w-[100%] bg-bg-1 z-1 overflow-hidden",
          isMobile
            ? "h-[738px] rounded-b-[10px] top-[0px] "
            : "min-h-[520px] aspect-[1410/520] top-[16px] rounded-[15px]"
        )}
      >
        {bgImg && <Image data-testid="bg-img" alt="bg" src={bgImg} />}
      </div>
      {showPage ? (
        children
      ) : (
        <div className="h-[calc(100vh-170px)] flex items-center">
          <Spinner />
        </div>
      )}
    </div>
  )
}

AboutPageLayout.propTypes = {
  children: PropTypes.any,
  bg_img_url_mobile: PropTypes.string,
  bg_img_url_desktop: PropTypes.string,
}
