"use client"
import React, { useEffect, useState } from "react"
import Image from "next/image"
import TextInput from "@/components/edit-profile/text-input"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { useDebouncedCallback } from "use-debounce"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import PropTypes from "prop-types"

export default function ExclusiveForm({ setIsLoading }) {
  const { replace } = useRouter()
  const searchParams = useSearchParams()
  const isMobile = useIsMobile()
  const pathname = usePathname()
  const size = isMobile ? 24 : 32
  const [search, setSearch] = useState(
    searchParams.get("exclusive-search") || ""
  )

  const handleLoad = useDebouncedCallback(() => {
    const params = new URLSearchParams(searchParams)
    if (search !== "") params.set(`exclusive-search`, search)
    else params.delete(`exclusive-search`)
    params.delete("ex-page")

    replace(`${pathname}?${params.toString()}`, { scroll: false })
  }, 300)

  const handleReset = () => {
    setIsLoading(true)
    setSearch("")
  }

  const handleChange = (value) => {
    setIsLoading(true)
    setSearch(value)
  }

  useEffect(() => {
    handleLoad()
  }, [search])

  const user = process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"

  return (
    <form method="POST" className="bg-bg-3 flex items-center  rounded-[10px] lg:rounded-[15px] gap-[10px] mb-[10px] lg:mb-[15px]">
      <div className="absolute w-[24px] lg:w-[32px] h-[24px] lg:h-[32px] left-[20px] top-[20px]">
        <Image
          alt="cover"
          fill
          style={{ objectFit: "none" }}
          src={`assets/${process.env.NEXT_PUBLIC_NAME}/icons/location.svg`}
          className="max-w-[32px] max-h-[32px]"
        />
      </div>
      <TextInput
        onChange={(e) => handleChange(e.target.value)}
        value={search}
        className="!bg-bg3 !rounded-[10px] lg:!rounded-[15px] border-transparent min-h-[44px] md:min-h-[72px] p-[10px] px-[0] lg:px-[62px] lg:py-[20px] mb-0 px-[44px] text-[12px] lg:text-[16px] grow-[1] font-medium font-font-family-6 placeholder:italic"
        type="text"
        placeholder={`Cari Lokasi Hang Out Favorit ${user} Di Sini`}
      />

      <button
        type="reset"
        onClick={handleReset}
        className="w-[24px] lg:w-[32px] h-[24px] lg:h-[32px] absolute right-[20px] top-[20px]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clipPath="url(#clip0_3705_4588)">
            <path
              d="M12.9398 12L18.4665 6.47334C18.5757 6.34581 18.6328 6.18176 18.6263 6.01397C18.6198 5.84619 18.5503 5.68703 18.4315 5.5683C18.3128 5.44957 18.1536 5.38001 17.9859 5.37353C17.8181 5.36705 17.654 5.42412 17.5265 5.53334L11.9998 11.06L6.47315 5.52667C6.34762 5.40114 6.17735 5.33061 5.99982 5.33061C5.82228 5.33061 5.65202 5.40114 5.52649 5.52667C5.40095 5.65221 5.33042 5.82247 5.33042 6.00001C5.33042 6.17754 5.40095 6.3478 5.52649 6.47334L11.0598 12L5.52649 17.5267C5.4567 17.5864 5.40002 17.66 5.36 17.7427C5.31999 17.8254 5.2975 17.9155 5.29395 18.0073C5.29041 18.0991 5.30588 18.1907 5.33939 18.2762C5.37291 18.3618 5.42375 18.4395 5.48872 18.5044C5.55369 18.5694 5.63139 18.6202 5.71694 18.6538C5.80249 18.6873 5.89404 18.7028 5.98585 18.6992C6.07767 18.6957 6.16776 18.6732 6.25047 18.6332C6.33318 18.5931 6.40672 18.5365 6.46649 18.4667L11.9998 12.94L17.5265 18.4667C17.654 18.5759 17.8181 18.633 17.9859 18.6265C18.1536 18.62 18.3128 18.5504 18.4315 18.4317C18.5503 18.313 18.6198 18.1538 18.6263 17.986C18.6328 17.8183 18.5757 17.6542 18.4665 17.5267L12.9398 12Z"
              fill="#7D7D7D"
            />
          </g>
          <defs>
            <clipPath id="clip0_3705_4588">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </form>
  )
}

ExclusiveForm.propTypes = {
  setIsLoading: PropTypes.bool,
}
