"use client"
import Separator from "@/components/slide-menu/separator/separator"
import React from "react"
import Style from "./detail-description.module.css"
import DOMPurify from "isomorphic-dompurify"
import { cva } from "class-variance-authority"
import PropTypes from "prop-types"
export default function DetailDescription({
  description,
  articles,
  type,
  className,
}) {
  const wrapper = cva("", {
    variants: {
      type: {
        default: ["p-[15px] lg:p-0 lg:pb-[5px] lg:px-[10px] lg:mb-0"],
        article: ["py-[20px]", "px-[15px]"],
      },
    },
    compoundVariants: [{ type: "default" }],
    defaultVariants: {
      type: "default",
    },
  })

  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener');
    }
  });

  return (
    <>
      <div className={wrapper({ type, className })}>
        {!articles && (
          <div className="text-[16px] text-text-2 font-bold font-font-family-7 mb-[7px] leading-[22px] lg:mb-[15px]">
            Deskripsi
          </div>
        )}
        <div
          data-testid="detail-description"
          className={Style.DetailDescription}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
        ></div>
      </div>
      {!articles && (
        <Separator className="lg:hidden my-[5px] bg-[#F6F7F9] h-[5px] mx-[15px] border-box rounded-[0]" />
      )}
    </>
  )
}

DetailDescription.propTypes = {
  description: PropTypes.string,
  articles: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string,
}
