"use client"
import GraphicHealth from "./graphic-health/graphic-health"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper/modules"
import "swiper/css"
import Style from "./intro-iqos-page.module.scss"
import SliderContent from "./slider-content/slider-content"
import { useEffect, useRef, useState } from "react"
import Dots from "./slider-ornament.js/dots"
import { ArrowLeft, ArrowRight } from "./slider-ornament.js/arrow"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { useWindowSize } from "@/hooks/use-window-size"
import PropTypes from "prop-types"

const IntroIqosPage = ({ data }) => {
  const contents = data?.slide
  const dataPage = data?.bridging_name
  const { isLogin, isLoading } = useIsLogin()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isLoading) {
        setLoading(false)
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [isLogin, isLoading])

  const { width } = useWindowSize()

  const iqosDesktop = () => {
    if (width >= 1119) return true
  }

  const [activeIndex, setActiveIndex] = useState(0)
  const swiperRef = useRef(null)
  const [count, setCount] = useState(0)

  const itemsLength = contents?.length

  const nextSlide = () => {
    swiperRef.current?.slideNext()
    setCount(count + 1)
  }

  const prevSlide = () => {
    swiperRef.current?.slidePrev()
    setCount(count - 1)
  }

  return (
    <>
      {loading ? (
        <LoadingOverlay />
      ) : (
        <div className={Style.container}>
          <div className={Style.slider_wrapper}>
            <Swiper
              className={Style.swiper}
              loop={true}
              // initialSlide={0}
              modules={[Autoplay]}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              onSwiper={(swiper) => {
                swiperRef.current = swiper
              }}
              onSlideChange={(swiper) => {
                setActiveIndex(swiper.realIndex)
              }}
            >
              {contents?.map((item) => (
                <SwiperSlide
                  key={item.order_number}
                  className={Style.swiper_slide}
                >
                  <SliderContent
                    bg={
                      iqosDesktop()
                        ? item.desktop_image_file
                        : item.mobile_image_file
                    }
                    // bg={
                    //   iqosDesktop()
                    //     ? "/assets/iqos/background/desktop/bg-1.png"
                    //     : "/assets/iqos/background/mobile/bg-1.png"
                    // }
                    title={item.title}
                    desc={item?.main_caption}
                    shortDesc={item?.sub_caption}
                    textShadow={item.tagline}
                    tagline={item.tagline}
                    btn1={dataPage?.main_cta}
                    btn1Url={dataPage?.main_redirect_url}
                    btn2={dataPage?.other_cta}
                    btn2Url={dataPage?.other_redirect_url}
                    variant="Dark"
                    itemsLength={itemsLength}
                    activeIndex={activeIndex}
                    slideSequence={1}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {!iqosDesktop() && itemsLength > 1 && (
            <Dots itemsLength={itemsLength} activeIndex={activeIndex} />
          )}
          {itemsLength > 1 && (
            <ArrowLeft activeIndex={activeIndex} prevSlide={prevSlide} />
          )}
          {itemsLength > 1 && (
            <ArrowRight
              activeIndex={activeIndex}
              itemsLength={itemsLength}
              nextSlide={nextSlide}
            />
          )}
          <GraphicHealth />
        </div>
      )}
    </>
  )
}

const LoadingOverlay = () => {
  return (
    <div className={Style.overlay_container}>
      <div className={Style.lds_ring}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default IntroIqosPage

IntroIqosPage.propTypes = {
  data: PropTypes.object,
}
