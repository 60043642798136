"use client";

import { useEffect, useState } from "react";
import { Puzzle } from "../puzzle/puzzle";
import { PuzzleIntroComp } from "../puzzle/puzzle-intro";
import { ClientApiList } from "@/lib/api/client-service";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

export function PuzzleIntroNew({ engagement_uuid, type, data, ...props }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isStart, setIsStart] = useState(false);
  const [level, setLevel] = useState(1);
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const {replace} = useRouter()
  const [dataIntro] = useState({
    ...data,
    cta_label: "Mulai Sekarang",
  });
  const [dataPuzzle, setDataPuzzle] = useState({
    time_label: props.time_label || "Sisa Waktu",
    cta_label_puzzle: props.cta_label_puzzle || "Submit",
  });
  const [finished, setFinished] = useState(0);

  const getPuzzleData = async (level) => {
    try {
      const { data } = await ClientApiList.getEngagementPuzzleDetail({
        engagement_uuid,
        type,
        level
      })
      setDataPuzzle({
        ...dataPuzzle,
        ...data.data.data,
        ...data.data.data.puzzle,
        levelCurrent:level
      })
      setFinished(data?.data.data.finished)
      setIsLoading(false)
    } catch (err) {
      console.log("Error", err)
    }
  };

  const handleLevelUp = () => {
    setIsLoading(true);
    getPuzzleData(level);
  };

  useEffect(() => {
    getPuzzleData(level);
    if(searchParams.get("hide-puzzle-rule") === "true"){
      replace(pathname)
    }
  }, []);

  return (
    <div>
      <PuzzleIntroComp
        {...dataIntro}
        finished={finished}
        isLoading={isLoading}
        setIsStart={setIsStart}
        isTNC={dataPuzzle.is_tnc}
        tncValue={dataPuzzle.is_tnc_checked}
        tncText={dataPuzzle.tnc}
      />
      <Puzzle
        setIsStart={setIsStart}
        setLevel={setLevel}
        level={level}
        isStart={isStart}
        handleLevelUp={handleLevelUp}
        {...dataPuzzle}
        instruction={dataIntro.desc_instruction}
        campaign={dataIntro.campaign_name} />
    </div>
  );
}
