/* eslint-disable react-hooks/exhaustive-deps */
import { cn } from "@/lib/utils";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Chevron } from "@/components/sections/bantuan";
import { Description } from "@/components/shared/description/description";
import Style from "@/components/sections/brand-popup.module.css";
import { Controller } from "react-hook-form";

const iconVariants = {
  open: { rotate: 0 },
  closed: { rotate: -180 },
};

// TODO: BELUM DINAMIS -> target key yang mau digunakan untuk value
const FieldSelect = ({ name, control, options = [], placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdown = useRef(null);
  useEffect(() => {
    if (!isOpen) return;
    function handleClick(event) {
      if (dropdown.current && !dropdown.current.contains(event.target))
        setIsOpen(false);
    }
    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, [isOpen]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <div ref={dropdown} className="relative w-full mx-auto">
          <button
            type="button"
            onClick={() => setIsOpen((state) => !state)}
            className={clsx(
              "relative w-full lg:min-w-[120px] p-[10px] text-left text-sm flex justify-between items-center border rounded-[10px] capitalize bg-bg-3",
              isOpen ? "border-cta-1" : "border-cta-4"
            )}
          >
            <p
              className={cn(
                "h-[20px] capitalize",
                !value
                  ? "italic text-text-3"
                  : "text-text-2 not-italic text-ellipsis overflow-hidden"
              )}
            >
              {value || placeholder}
            </p>
            <AnimatePresence>
              <motion.span
                key="icon"
                initial={false}
                animate={isOpen ? "open" : "closed"}
                variants={iconVariants}
                transition={{ duration: 0.2 }}
              >
                <Chevron className={"fill-icon-2"} />
              </motion.span>
            </AnimatePresence>
          </button>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                key={"options"}
                initial={{
                  opacity: 0,
                  y: -10,
                }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
                className={cn(
                  "w-[100%] max-h-[130px] absolute z-[5] top-[42px] border border-cta-1 rounded-[10px] bg-bg-3 overflow-hidden"
                )}
              >
                <div
                  className={cn(
                    "max-h-[130px] overflow-y-scroll flex flex-col gap-[10px] p-[10px]",
                    Style.container
                  )}
                >
                  {options?.map((item, index) => (
                    <button
                      key={index}
                      type="button"
                      onClick={() => {
                        onChange(item?.name);
                        setIsOpen(false);
                      }}
                    >
                      <Description
                        className="text-ellipsis overflow-hidden lg:max-w-[170px] text-text-2 text-left text-sm capitalize"
                        description={item?.name}
                      />
                    </button>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    />
  );
};

FieldSelect.propTypes = {
  name: PropTypes.string,
  control: PropTypes.func,
  options: PropTypes.object,
  placeholder: PropTypes.string,
};

export default FieldSelect;
