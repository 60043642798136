import React, { useEffect, useCallback } from "react"
import Style from "./Keyboard.module.scss"
import Key from "./Key"
import clsx from "clsx"
const keys1 = ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"]
const keys2 = ["A", "S", "D", "F", "G", "H", "J", "K", "L"]
const keys3 = ["Z", "X", "C", "V", "B", "N", "M"]
const allKey = [...keys1, ...keys2, ...keys3]

const Keyboard = ({
  onSelectLetter,
  onDelete,
  onEnter,
  currAttempt,
  correctKey,
  almostKey,
  wrongKey,
}) => {
  const handleKeyboard = useCallback(
    (e) => {
      if (e.key === "Enter") {
        onEnter()
      } else if (e.key === "Backspace") {
        onDelete()
      } else {
        allKey.forEach((key) => {
          if (e.key.toLowerCase() === key.toLocaleLowerCase()) {
            onSelectLetter(key)
          }
        })
      }
    },
    // eslint-disable-next-line
    [currAttempt]
  )

  useEffect(() => {
    document.addEventListener("keydown", handleKeyboard)

    return () => document.removeEventListener("keydown", handleKeyboard)
  }, [handleKeyboard])
  return (
    <div
      className={clsx(
        Style.gameKeyboard,
        (process.env.NEXT_PUBLIC_NAME === "djisamsoe" ||
          process.env.NEXT_PUBLIC_NAME === "sampoernakretek") &&
          Style.gameKeyboardDark
      )}
      onKeyDown={handleKeyboard}
    >
      <div className={Style.gameKeyboardTop}>
        {keys1.map((item, index) => {
          return (
            <Key
              key={index}
              value={item}
              onSelectLetter={onSelectLetter}
              wrongKey={wrongKey.includes(item)}
              almostKey={almostKey.includes(item)}
              correctKey={correctKey.includes(item)}
            />
          )
        })}
      </div>
      <div className={Style.gameKeyboardCenter}>
        {keys2.map((item, index) => {
          return (
            <Key
              key={index}
              value={item}
              onSelectLetter={onSelectLetter}
              wrongKey={wrongKey.includes(item)}
              almostKey={almostKey.includes(item)}
              correctKey={correctKey.includes(item)}
            />
          )
        })}
      </div>
      <div className={Style.gameKeyboardBottom}>
        <Key onDelete={onDelete}>DEL</Key>
        {keys3.map((item, index) => {
          return (
            <Key
              key={index}
              value={item}
              onSelectLetter={onSelectLetter}
              wrongKey={wrongKey.includes(item)}
              almostKey={almostKey.includes(item)}
              correctKey={correctKey.includes(item)}
            />
          )
        })}
        <Key value="ENTER" big onEnter={onEnter} />
      </div>
    </div>
  )
}

Keyboard.defaultProps = {
  color: "white",
  text: "",
  onClick: () => {},
}

export default Keyboard
