"use client"
import React from "react"
import PropTypes from "prop-types"
import { Image } from "@/components/shared/image/image"

export default function HistoryEXPCard({ title, date, status, exp }) {
  const dateTime = new Date(date)
  const yyyy = dateTime.getFullYear()
  let mm = dateTime.getMonth() + 1 // Months start at 0!
  let dd = dateTime.getDate()

  if (dd < 10) dd = "0" + dd
  if (mm < 10) mm = "0" + mm

  const formattedToday = dd + "/" + mm + "/" + yyyy

  return (
    <div className="w-[100%] py-[15px] px-[10px] lg:px-[15px] flex items-center gap-[10px] bg-bg-3 rounded-[10px] max-w-[428px] md:max-w-none text-text-2">
      <div className="grow-[1]">
        <div
          data-testid="history-exp-card-title"
          className="text-[14px] lg:text-[16px] leading-[19px] lg:leading-[22px] font-bold font-font-family-7 mb-[5px]"
        >
          {title}
        </div>
        <div
          data-testid="history-exp-card-date"
          className="text-[12px] lg:text-[14px] leading-[16px] lg:leading-[19px] font-medium font-font-family-6"
        >
          {formattedToday}
        </div>
      </div>
      <div className="flex items-center gap-[5px]">
        <div className="w-[24px] lg:w-[28px] h-[24px] lg:h-[28px] bg-bg-3 flex items-center">
          {String(status).toLowerCase() === "pending" ? (
            <Image alt="reward" src="/assets/amild/icons/clock-gray.svg" />
          ) : (
            <div className="flex items-center text-text-1">
              +{" "}
              <Image
                alt="reward"
                src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/reward-profile.svg`}
              />
            </div>
          )}
        </div>
        <div
          data-testid="history-exp-card-exp"
          className={`text-[12px] whitespace-nowrap lg:text-[14px] leading-[13px] font-bold font-font-family-7 tracking-[0.25px] ${String(status).toLowerCase() === "pending" ? "text-[#7D7D7D]" : ""}`}
        >
          {String(status).toLowerCase() === "pending"
            ? "Pending"
            : `${exp} Poin `}
        </div>
      </div>
    </div>
  )
}

HistoryEXPCard.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
  exp: PropTypes.number,
}
