import ButtonAction from "@/components/shared/button-action/button-action"
import { Image } from "@/components/shared/image/image"
import PopupAction from "@/components/shared/popup-action/popup-action"
import { useIsDesktop } from "@/hooks/use-is-mobile"
import clsx from "clsx"
import { usePathname, useRouter } from "next/navigation"
import React from "react"

const PopupCloseConfirmQuiz = ({
  isShowing,
  titlePopup,
  imgPopup,
  handleExit,
  isFormalYou,
}) => {
  const isDesktop = useIsDesktop()
  const { replace } = useRouter()
  const pathname = usePathname()

  return (
    <PopupAction
      isShowing={isShowing}
      href={pathname}
      onClickBtnX={() => handleExit(false)}
      className={"!p-[10px]"}
      zIndex="z-[20]"
    >
      <div className="w-full h-full p-[15px] rounded-[10px] border-[2px] border-cta-3 border-dashed">
        <div className="h-full relative aspect-[304/177] bg-bg-1">
          <Image alt="banner" objectFit="cover" src={imgPopup} />
        </div>
        <div className="my-[20px] max-w-[304px]">
          <p className="text-xl font-black font-font-family-8 mb-[5px] text-text-2 capitalize">
            {titlePopup}
          </p>
          <p
            className={clsx(
              "text-center font-medium font-font-family-6 text-text-2",
              isDesktop ? "text-base" : "text-sm"
            )}
          >
            Semua <i>progress</i> {isFormalYou ? "kamu" : "lo"} di level ini
            bakal hilang. {isFormalYou ? "Kamu" : "Lo"} harus ulang main dari
            awal lagi buat ngumpulin poin.
          </p>
        </div>
        <div className="flex gap-[5px]">
          <ButtonAction
            data-testid="popup-cancel"
            onClick={() => replace("/br")}
            className="py-[15px] px-[20px]"
            intent="primary"
          >
            {"Keluar"}
          </ButtonAction>
          <ButtonAction
            data-testid="popup-cancel"
            onClick={() => handleExit(false)}
            // onClick={() => replace("/br")}
            className="py-[15px] px-[20px]"
            intent="secondary"
          >
            {"Balik Main"}
          </ButtonAction>
        </div>
      </div>
    </PopupAction>
  )
}

export default PopupCloseConfirmQuiz
