"use client"
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { usePathname } from "next/navigation"
import { useShowLoginPopup } from "@/hooks/auth/use-login-popup"
import { useIsMobile } from "@/hooks/use-is-mobile"

export function UserProfilePageLayout({ children }) {
  const { isLoading, isLogin } = useIsLogin()
  const pathname = usePathname()
  const isMobile = useIsMobile()

  useShowLoginPopup({
    showWhen: !isLogin && !isLoading,
    closePath: "/",
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
  })

  /* scroll to hash */
  useEffect(() => {
    let t
    const hash = pathname.split("#")[1]

    if (!isLoading && hash) {
      const el = document?.getElementById(pathname.split("#")[1])

      if (el)
        t = setTimeout(() => {
          const offsetHeader = isMobile ? 58 : 72
          const offset = el?.offsetTop - offsetHeader

          window.scrollTo({
            top: offset,
            behavior: "smooth",
          })
        }, 200)
    }

    return () => {
      clearTimeout(t)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLoading, pathname])
  return (
    <div className="relative lg:px-[15px] mt-[58px] lg:mt-[72px]">
      {/* <div className="absolute rounded-b-[10px] top-[0px] left-0 right-0 h-[738px] w-[100%] bg-[#646464] z-1"></div> */}
      <div className="relative z-[1] pt-[19px] lg:pt-[48px]">{children}</div>
    </div>
  )
}

UserProfilePageLayout.propTypes = {
  children: PropTypes.object,
}
