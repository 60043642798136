import PropTypes from "prop-types";
import style from "./image.module.scss";
import clsx from "clsx";

export function Image({
  alt = "image",
  src = '#',
  objectFit = "cover",
  className,
  ...props
}) {
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      alt={alt}
      src={src}
      loading="lazy"
      style={{
        objectFit: objectFit,
        width: "100%",
        height: "100%",
      }}
      className={clsx(style.imgSection, className)}
      {...props}
    />
  );
}

Image.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  objectFit: PropTypes.string,
  className: PropTypes.string,
};
