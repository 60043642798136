"use client"
import React from "react"
import clsx from "clsx"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react"
import Portal from "../shared/portal"
import Link from "@/components/shared/link/link"
import { useRouter } from "next/navigation";

/**
 * @typedef {Object} LoyaltyPopupProps
 * @prop {keyof typeof popupType} type
 * @props {boolean} [isOpen]
 * @prop {() => void} onClick
 * @property {Function} onClose - function to be called when overlay is clicked
 */

/**
 * @type {React.FC<LoyaltyPopupProps>}
 */

export const ToastInfo = ({
  desc = "",
  title = "",
  isOpen = false,
  onShow = () => {},
  onClose = () => {},
  onClick
}) => {
  const [stop, setStop] = useState(false)
  const [show, setShow] = useState(false)
  const {back} = useRouter()

  useEffect(() => {
    if (isOpen) {
      onShow?.()
      setStop(false)
      setShow(true)
    } else if (!isOpen) {
      setShow(false)
      onClose?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const variants = {
    open: {
      opacity: 1,
      x: 0,
      y: -110,
      scale: 1,
      rotate: 0,
    },
    closed: {
      opacity: 0,
      x: 0,
      y: 0,
      scale: 1,
      rotate: 0,
    },
  }

  return (
    <AnimatePresence>
      <Portal>
        <div
          className={clsx(
            "w-full max-w-[460px] fixed bottom-[-50px] left-[50%] -translate-x-1/2 z-[19] ",
            show ? "block" : "hidden"
          )}
          onClick={onClick}
        >
          <motion.div animate={isOpen ? "open" : "closed"} variants={variants}>
            <div className="px-[10px] py-[15px] bg-bg-2 mx-[10px] rounded-[5px]">
              <div className="flex items-center gap-[10px]">
                <div className="min-h-[40px] min-w-[5px] rounded-[5px] bg-[#3150EC]"></div>
                <div className="min-w-[24px] h-[24px] rounded-[50%] relative">
                  <div className="bg-white absolute w-[12px] h-[12px] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"></div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 23 22"
                    fill="none"
                    className="absolute inset-0 z-[1]"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.4375 21.9375C14.3383 21.9375 17.1203 20.7852 19.1715 18.734C21.2227 16.6828 22.375 13.9008 22.375 11C22.375 8.09919 21.2227 5.3172 19.1715 3.26602C17.1203 1.21484 14.3383 0.0625 11.4375 0.0625C8.53669 0.0625 5.7547 1.21484 3.70352 3.26602C1.65234 5.3172 0.5 8.09919 0.5 11C0.5 13.9008 1.65234 16.6828 3.70352 18.734C5.7547 20.7852 8.53669 21.9375 11.4375 21.9375ZM12.8047 6.89844C12.8047 7.26104 12.6606 7.60879 12.4042 7.86518C12.1479 8.12158 11.8001 8.26562 11.4375 8.26562C11.0749 8.26562 10.7271 8.12158 10.4708 7.86518C10.2144 7.60879 10.0703 7.26104 10.0703 6.89844C10.0703 6.53584 10.2144 6.18809 10.4708 5.93169C10.7271 5.67529 11.0749 5.53125 11.4375 5.53125C11.8001 5.53125 12.1479 5.67529 12.4042 5.93169C12.6606 6.18809 12.8047 6.53584 12.8047 6.89844ZM10.0703 9.63281C9.79836 9.63281 9.53755 9.74084 9.34525 9.93314C9.15295 10.1254 9.04492 10.3863 9.04492 10.6582C9.04492 10.9302 9.15295 11.191 9.34525 11.3833C9.53755 11.5756 9.79836 11.6836 10.0703 11.6836H10.4121V14.418H9.04492C8.77297 14.418 8.51216 14.526 8.31986 14.7183C8.12756 14.9106 8.01953 15.1714 8.01953 15.4434C8.01953 15.7153 8.12756 15.9761 8.31986 16.1684C8.51216 16.3607 8.77297 16.4688 9.04492 16.4688H13.8301C14.102 16.4688 14.3628 16.3607 14.5551 16.1684C14.7474 15.9761 14.8555 15.7153 14.8555 15.4434C14.8555 15.1714 14.7474 14.9106 14.5551 14.7183C14.3628 14.526 14.102 14.418 13.8301 14.418H12.4629V9.63281H10.0703Z"
                      fill="#3150EC"
                    />
                  </svg>
                </div>
                <div className="grow-[1]">
                  <div
                    data-testid="toast-title"
                    className="text-[14px] leading-[20px] text-text-4 font-bold font-font-family-7"
                  >
                    {title}
                  </div>
                  <div
                    data-testid="toast-desc"
                    className="text-[14px] leading-[20px] text-text-3 font-medium font-font-family-6"
                  >
                    {desc}
                  </div>
                </div>
                <button
                  style={{ background: "#96959440" }}
                  className="flex items-center text-center py-[5px] px-[15px] rounded-[5px] text-white text-[12px] leading-[18px]"
                  onClick={back}
                >
                  Balik
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      </Portal>
    </AnimatePresence>
  )
}
