import React from "react"
import { RemoveScroll } from "react-remove-scroll"
import { AnimatePresence, motion } from "framer-motion"
import PropTypes from "prop-types"

const PopupGame = ({ open, onClick, children }) => {
  const bgCta =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe" ||
    process.env.NEXT_PUBLIC_NAME === "magnum"
      ? "var(--cta_1)"
      : "transparent"

  return (
    <>
      {open && (
        <div className="fixed top-[0] backdrop-blur-[5px] z-[20] bg-black/[.75] bottom-0 right-0 left-0">
          <RemoveScroll className="h-screen">
            <AnimatePresence>
              <motion.div
                initial={{
                  x: "-100%",
                }}
                animate={{
                  x: 0,
                  transition: { delay: 0.2, duration: 0.3 },
                }}
                exit={{
                  x: "100%",
                }}
                transition={{ type: "Inertia" }}
                style={{ originX: 0 }}
                className="relative !bg-bg-3 w-[40%] h-[calc(100vh-56px)] rounded-r-[20px] px-[40px] pt-[112px]"
              >
                <button
                  style={{ background: bgCta }}
                  className="absolute top-[45px] right-[45px] p-[15px] rounded-[10px]"
                  onClick={() => onClick()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M18 18L6 6M18 6L6 18"
                      //   stroke="#2C2A29"
                      style={{ stroke: "#2C2A29" }}
                      className="stroke-cta-2"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                {children}
              </motion.div>
            </AnimatePresence>
          </RemoveScroll>
        </div>
      )}
    </>
  )
}

PopupGame.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.object,
}

export default PopupGame
