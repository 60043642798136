import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

export const useDropdown = (initialState) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const pathname = usePathname();

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  return [isOpen, setIsOpen];
};
