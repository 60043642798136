import clsx from "clsx";
import PropTypes from "prop-types";
import { useState } from "react";
import { Controller } from "react-hook-form";

const FieldTextarea = ({
  name,
  control,
  placeholder,
  rows = 3,
  maxChar,
  ...props
}) => {
  const [char, setChar] = useState(0);
  const [isError, setIsError] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <div className="w-full p-[10px] bg-bg-3 border border-cta-4 rounded-[10px] has-[:focus]:outline-cta-1 has-[:focus]:border-cta-1 has-[:active]:border-cta-1">
          <textarea
            className="w-full text-sm placeholder-text-3 bg-bg-3 outline-none"
            placeholder={placeholder}
            rows={rows}
            value={value}
            onChange={onChange}
            onKeyUp={(e) => {
              setChar(e.target.value.length);
              setIsError(e.target.value.length > maxChar);
            }}
            {...props}
          />
          <div
            className={clsx(
              "flex justify-end w-full text-xs",
              isError ? "text-text-1" : "text-text-3"
            )}
          >
            <span>{`(${char}/${maxChar} karakter)`}</span>
          </div>
        </div>
      )}
    />
  );
};

FieldTextarea.propTypes = {
  name: PropTypes.string,
  control: PropTypes.func,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  maxChar: PropTypes.number,
};

export default FieldTextarea;
