import React, { Fragment, useEffect, useState } from "react";
import FormBox from "./form-box";
import ButtonAction from "../shared/button-action/button-action";
import DOMPurify from "isomorphic-dompurify";
import useRenderForm from "@/hooks/useRenderForm";
import PropTypes from "prop-types";
import { useRouter } from "next/navigation";

export default function EditPhoneForm({
  pageData,
  step,
  register,
  getValues,
  onSubmit,
  handleRequestOTP,
  pathName,
  watch,
  errors,
  isError,
}) {
  const [handleRenderLabel, handleRenderInput] = useRenderForm();
  const [seconds, setSeconds] = useState(0);
  const { back } = useRouter();

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    if (step !== 1) {
      sendOTP();
    }
  }, [step]);

  const sendOTP = () => {
    setSeconds(59);
  };
  const resendOTP = () => {
    setSeconds(59);
  };

  const isDisabledVerification = watch([
    "otp1",
    "otp2",
    "otp3",
    "otp4",
  ]).includes("");
  const isDisabledUpdate = watch(["phone_number", "password"]).includes("");

  const handleRender = () => {
    if (step === 1)
      return (
        <>
          <FormBox
            className="mb-[20px]"
            title={
              step === 1 && pathName.includes("verify")
                ? pageData.formList.section_title_verify
                : pageData.formList.section_title
            }
          >
            {pageData.formList.formItems.map((item) => {
              return (
                <Fragment key={item.label}>
                  {handleRenderLabel(item.label, item.link_label)}
                  {handleRenderInput(item.type, {
                    placeholder: item.placeholder,
                    register,
                    name: item.name,
                    disabled:
                      item.name === "phone_number" &&
                      getValues("phone_number") &&
                      pathName.includes("verifikasi"),
                    getValues,
                  })}
                  {Object.keys(errors).length === 1 && errors[item.name] ? (
                    <div className="my-[5px] flex gap-[5px] items-center text-[12px] text-text-1 font-medium tracking-[0.25px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11ZM5.25 8.25C5.25 7.83579 5.58579 7.5 6 7.5C6.41421 7.5 6.75 7.83579 6.75 8.25C6.75 8.66421 6.41421 9 6 9C5.58579 9 5.25 8.66421 5.25 8.25ZM5.50806 3.41012C5.55039 3.17688 5.75454 3 6 3C6.24546 3 6.44961 3.17688 6.49194 3.41012L6.5 3.5V6L6.49194 6.08988C6.44961 6.32312 6.24546 6.5 6 6.5C5.75454 6.5 5.55039 6.32312 5.50806 6.08988L5.5 6V3.5L5.50806 3.41012Z"
                          fill="#FD2A29"
                        />
                      </svg>
                      <p>{errors[item.name].message}</p>
                    </div>
                  ) : null}
                </Fragment>
              );
            })}
            {errors["phone_number"] && errors["password"] ? (
              <div className="my-[5px] flex gap-[5px] items-center text-[12px] text-text-1 font-medium tracking-[0.25px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11ZM5.25 8.25C5.25 7.83579 5.58579 7.5 6 7.5C6.41421 7.5 6.75 7.83579 6.75 8.25C6.75 8.66421 6.41421 9 6 9C5.58579 9 5.25 8.66421 5.25 8.25ZM5.50806 3.41012C5.55039 3.17688 5.75454 3 6 3C6.24546 3 6.44961 3.17688 6.49194 3.41012L6.5 3.5V6L6.49194 6.08988C6.44961 6.32312 6.24546 6.5 6 6.5C5.75454 6.5 5.55039 6.32312 5.50806 6.08988L5.5 6V3.5L5.50806 3.41012Z"
                    fill="#FD2A29"
                  />
                </svg>
                <p>{errors["phone_number"].message}</p>
              </div>
            ) : null}
          </FormBox>
          <ButtonAction
            className="mt-[20px] mb-[10px] max-w-[355px] mx-auto"
            intent={!isDisabledUpdate ? "primary" : "primary_disable"}
            disabled={isDisabledUpdate}
            type="submit"
            value="Submit"
          >
            {step === 1 && pathName.includes("verify")
              ? pageData.submit_lable_verify
              : pageData.submit_lable}
          </ButtonAction>
          <ButtonAction
            onClick={() => back()}
            className="block max-w-[355px] mx-auto"
            type="reset"
            value="Reset"
            intent="secondary"
          >
            {pageData.cancel_lable}
          </ButtonAction>
        </>
      );
    else
      return (
        <>
          <FormBox
            className="mb-[20px]"
            title={pageData.formList.section_title}
          >
            {pageData.formList.formItems.map((item) => {
              return (
                <Fragment key={item.label}>
                  {handleRenderLabel(item.label, item.link_label)}
                  {handleRenderInput(item.type, {
                    placeholder: item.placeholder,
                    className: "min-h-[74px]",
                    register,
                    getValues,
                  })}
                </Fragment>
              );
            })}
            <div className="text-center text-[12px] tracking-[0.2px] pt-[15px] text-text-5 pb-[10px]">
              {seconds > 0 ? (
                <p>
                  Tunggu <b className="text-text-2">{seconds < 10 ? `0${seconds}` : seconds} detik</b>{" "}
                  untuk mengirim ulang OTP
                </p>
              ) : (
                <div>
                  {pageData.resend_label}{" "}
                  <button
                    type="reset"
                    value="Reset"
                    className="ml-[5px] underline font-bold font-font-family-7"
                    onClick={() => {
                      resendOTP();
                      handleRequestOTP(true);
                    }}
                  >
                    {pageData.resend_link_label}
                  </button>
                </div>
              )}
            </div>
            {errors["otp4"] ? (
              <div className="my-[5px] flex gap-[5px] items-center text-[12px] text-text-1 font-medium tracking-[0.25px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11ZM5.25 8.25C5.25 7.83579 5.58579 7.5 6 7.5C6.41421 7.5 6.75 7.83579 6.75 8.25C6.75 8.66421 6.41421 9 6 9C5.58579 9 5.25 8.66421 5.25 8.25ZM5.50806 3.41012C5.55039 3.17688 5.75454 3 6 3C6.24546 3 6.44961 3.17688 6.49194 3.41012L6.5 3.5V6L6.49194 6.08988C6.44961 6.32312 6.24546 6.5 6 6.5C5.75454 6.5 5.55039 6.32312 5.50806 6.08988L5.5 6V3.5L5.50806 3.41012Z"
                    fill="#FD2A29"
                  />
                </svg>
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(errors["otp4"].message),
                  }}
                ></p>
              </div>
            ) : null}
          </FormBox>
          <ButtonAction
            className="max-w-[355px] mx-auto mt-[20px]"
            disabled={isDisabledVerification}
            intent={!isDisabledVerification ? "primary" : "primary_disable"}
            type="submit"
            value="Submit"
          >
            {pageData.cta_label}
          </ButtonAction>
        </>
      );
  };

  return <form method="POST" onSubmit={onSubmit}>
    {isError ? (
      <div className="bg-cta-3 p-[10px] mb-[10px] rounded-[10px] my-[5px] flex gap-[5px] items-center text-[12px] text-text-1 font-medium font-font-family-6 tracking-[0.25px]">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11ZM5.25 8.25C5.25 7.83579 5.58579 7.5 6 7.5C6.41421 7.5 6.75 7.83579 6.75 8.25C6.75 8.66421 6.41421 9 6 9C5.58579 9 5.25 8.66421 5.25 8.25ZM5.50806 3.41012C5.55039 3.17688 5.75454 3 6 3C6.24546 3 6.44961 3.17688 6.49194 3.41012L6.5 3.5V6L6.49194 6.08988C6.44961 6.32312 6.24546 6.5 6 6.5C5.75454 6.5 5.55039 6.32312 5.50806 6.08988L5.5 6V3.5L5.50806 3.41012Z" fill="#FD2A29" />
        </svg>
        <p>{`Captcha harus diisi`}</p>
      </div>
    ) : null}
    {handleRender()}
  </form>;
}

EditPhoneForm.propTypes = {
  pageData: PropTypes.object,
  step: PropTypes.number,
  register: PropTypes.func,
  getValues: PropTypes.func,
  onSubmit: PropTypes.func,
  handleRequestOTP: PropTypes.func,
  pathName: PropTypes.string,
  watch: PropTypes.func,
  errors: PropTypes.array,
  isError: PropTypes.bool,
};
