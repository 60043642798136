"use client"

import { ClientApiList } from "@/lib/api/client-service"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"

const useEditAccount = ({ setIsSuccess, setIsError, captchaRef }) => {
  const { replace } = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const handleGetData = async () => {
    const { data } = await ClientApiList.getProfileData()
    const dob = new Date(data.data.data.dob)
    const date = dob.getDate()
    const month = dob.getMonth() + 1
    const year = dob.getFullYear()
    const fav_brand = data.data.data.favorite_brand.brand_id
    return {
      ...data.data.data,
      date,
      month,
      year,
      fav_brand,
      login_name: data.data.data.email || data.data.data.phone,
      password: "",
      permanent_delete: false,
      spam: false,
      stop: false,
      uninterest: false,
      "g-recaptcha-response": false,
    }
  }

  const resetFormValue = async () => {
    const result = await handleGetData()
    resetForm(result)
    // captchaRef.current?.execute();
  }

  const {
    register,
    getValues,
    handleSubmit,
    reset: resetForm,
    setValue,
    watch,
    setError,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: async () => handleGetData(),
  })

  const [formError, setFormError] = useState(null)

  const reset = () => {
    setFormError(null)
  }

  const onSubmit = async (data) => {
    captchaRef.current.reset()
    const token = await captchaRef.current.executeAsync()
    setFormError(null)

    if (!token) {
      setIsError(true)
      replace(pathname)
      return
    } else {
      setIsError(false)

      if (data.permanent_delete) {
        // const current = new URLSearchParams(Array.from(searchParams.entries())); // -> has to use this form
        const params = [...searchParams.entries()].filter(
          (param) => param[0] !== "showDeleteAccount"
        )
        const joinedParams = [params, ["showDeleteAccount", "true"]]
          .map((param) => param.join("="))
          .join("&")
        replace(`${pathname}?${joinedParams}`)
        // return;
      } else {
        const body = {
          login_name: data.login_name,
          password: data.password,
          permanent_delete: data.permanent_delete.toString(),
          spam: data.spam.toString(),
          stop: data.stop.toString(),
          uninterest: data.uninterest.toString(),
          "g-recaptcha-response": token,
        }
        try {
          const response = await ClientApiList.updateAccountSetting({ ...body })

          if (response.status === 200) {
            resetFormValue()
            setIsSuccess(true)
            setTimeout(() => {
              replace(`${pathname}`)
            }, 500)
          } else {
            setError("login_name", { message: response?.error.message })
            setError("password", { message: response?.error.message })
            setFocus("login_name")
            // captchaRef.current?.execute();
            return
          }
          // replace(pathname);
        } catch (err) {
          setError("login_name", { message: err?.message })
          setError("password", { message: err?.message })
          setFocus("login_name")
          // captchaRef.current?.execute();
          return
        }
      }
    }
  }

  return {
    register,
    getValues,
    errors,
    formError,
    resetForm,
    reset,
    watch,
    setError,
    setFocus,
    setValue,
    onSubmit: handleSubmit(onSubmit),
  }
}

export default useEditAccount
