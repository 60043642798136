/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { RemoveScroll } from "react-remove-scroll";
import { PreviewAudioContext } from "./preview-audio";
import { Image } from "@/components/shared/image/image";
import {
  IconEmail,
  IconInstagram,
  IconSpotify,
  IconTwitter,
  IconYoutube,
} from "../../../../../public/assets/icons/IconSocialMedias";
import clsx from "clsx";
import { IconClose } from "../../components/submission-icons";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";

// COMPONENT FOR MOBILE ONLY
const PreviewBandAbout = ({ isGallery }) => {
  const {
    isShowBandMobile: open,
    setIsShowBandMobile,
    isShowOfficialImage,
    setIsShowOfficialImage,
    data,
  } = useContext(PreviewAudioContext);
  const [isSosmedExist, setIsSosmedExist] = useState(false);

  const listSocialMedia = ["instagram", "x", "spotify", "youtube"];

  useEffect(() => {
    const isSosmed = checkField(listSocialMedia);
    setIsSosmedExist(isGallery ? true : isSosmed.some((fill) => fill));
  }, [open, data]);

  const checkField = (list) => {
    return list.map((field) => !!data[field]?.length);
  };

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          key="band-about"
          initial={{ x: "100%" }}
          animate={{ x: 0, transition: { delay: 0.2, duration: 0.3 } }}
          exit={{ x: "100%" }}
          transition={{ type: "Inertia" }}
          className="fixed inset-0 z-[20] bg-black"
        >
          <RemoveScroll className="w-full h-[calc(100dvh-56px)]">
            <div
              className={clsx(
                "w-full h-full p-[10px] overflow-hidden",
                !isShowOfficialImage && "overflow-y-auto"
              )}
            >
              <div
                className={clsx(
                  "relative",
                  !data?.official_press_photo?.length && "min-h-[375px]"
                )}
              >
                {data?.official_press_photo?.length ? (
                  <Image
                    src={data?.official_press_photo[0]?.preview}
                    alt="Official Press"
                    data-testid="Official Press"
                    className="rounded-[10px]"
                    onLoad={() => {
                      URL.revokeObjectURL(
                        data?.official_press_photo[0]?.preview
                      );
                    }}
                  />
                ) : null}

                {/* OVERLAY */}
                <button
                  className="absolute inset-0"
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0) 39.83%, rgba(0, 0, 0, 0.8) 82.12%, #000000 100%)",
                  }}
                  disabled={!data?.official_press_photo?.length}
                  onClick={() => setIsShowOfficialImage(true)}
                />

                <button
                  className="absolute top-[5px] right-[5px] p-[6px] text-white"
                  onClick={() => setIsShowBandMobile(false)}
                >
                  <IconClose
                    width={36}
                    height={36}
                    className={"stroke-icon-4"}
                  />
                </button>
              </div>

              <div className="relative -mt-[90px] z-10 px-[10px] pb-[30px]">
                <div className="mb-[30px] flex items-center gap-[15px]">
                  {data?.band_logo?.length ? (
                    <div className="w-[72px] rounded-full overflow-hidden">
                      <Image
                        src={data?.band_logo[0]?.preview}
                        alt="Logo Band"
                        data-testid="Logo Band"
                        onLoad={() => {
                          URL.revokeObjectURL(data?.band_logo[0]?.preview);
                        }}
                      />
                    </div>
                  ) : null}
                  <div className="flex flex-col gap-[5px] text-text-4">
                    <p className="text-xl font-black font-font-family-8">
                      {data?.band_name || data?.band_profile?.band_name}
                    </p>
                    <p className="text-sm font-normal capitalize">
                      {data?.city?.toLowerCase()}
                    </p>
                  </div>
                </div>

                {(data?.band_biography || data?.band_profile?.biography) && (
                  <p className="mb-[40px] text-sm text-text-4 opacity-85 whitespace-pre-line">
                    {data?.band_biography || data?.band_profile?.biography}
                  </p>
                )}

                <div className="flex flex-col gap-[40px]">
                  <div>
                    <span className="block mb-[10px] text-[10px] text-text-3 tracking-[1px]">
                      GENRE
                    </span>
                    <p className="text-sm text-text-4 font-bold font-font-family-7 capitalize">
                      {data?.band_genre || data?.band_profile?.genre}-
                      {data?.sub_genre || data?.band_profile?.subgenre}
                    </p>
                  </div>

                  {(data?.email || data?.band_profile?.email) && (
                    <div>
                      <span className="block mb-[10px] text-[10px] text-text-3 tracking-[1px]">
                        CONTACT US
                      </span>
                      <div className="flex items-center gap-[15px]">
                        <div>
                          <IconEmail
                            className="fill-icon-4"
                            width={24}
                            height={24}
                          />
                        </div>
                        <p className="text-sm text-text-4 font-bold font-font-family-7">
                          {data?.email || data?.band_profile?.email}
                        </p>
                      </div>
                    </div>
                  )}

                  {isSosmedExist && (
                    <div>
                      <span className="block mb-[10px] text-[10px] text-text-3 tracking-[1px]">
                        SOCIAL MEDIA
                      </span>
                      <div className="flex flex-col gap-[25px]">
                        {!isGallery &&
                          listSocialMedia?.map((sosmed) => {
                            return (
                              data[sosmed] && (
                                <div
                                  key={sosmed}
                                  className="flex items-center gap-[15px]"
                                >
                                  <div>{getIconSosmed(sosmed)}</div>
                                  <p className="text-sm text-text-4 font-bold font-font-family-7">
                                    {data[sosmed]}
                                  </p>
                                </div>
                              )
                            );
                          })}
                        {data?.band_profile?.instagram && (
                          <div
                            key={data?.band_profile?.instagram}
                            className="flex items-center gap-[15px]"
                          >
                            <div>{getIconSosmed("instagram")}</div>
                            <p className="text-sm text-text-4 font-bold font-font-family-7">
                              {data?.band_profile?.instagram}
                            </p>
                          </div>
                        )}
                        {data?.band_profile?.x && (
                          <div
                            key={data?.band_profile?.x}
                            className="flex items-center gap-[15px]"
                          >
                            <div>{getIconSosmed("x")}</div>
                            <p className="text-sm text-text-4 font-bold font-font-family-7">
                              {data?.band_profile?.x}
                            </p>
                          </div>
                        )}
                        {data?.band_profile?.spotify && (
                          <div
                            key={data?.band_profile?.spotify}
                            className="flex items-center gap-[15px]"
                          >
                            <div>{getIconSosmed("spotify")}</div>
                            <p className="text-sm text-text-4 font-bold font-font-family-7">
                              {data?.band_profile?.spotify}
                            </p>
                          </div>
                        )}
                        {data?.band_profile?.youtube && (
                          <div
                            key={data?.band_profile?.youtube}
                            className="flex items-center gap-[15px]"
                          >
                            <div>{getIconSosmed("youtube")}</div>
                            <p className="text-sm text-text-4 font-bold font-font-family-7">
                              {data?.band_profile?.youtube}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </RemoveScroll>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
PreviewBandAbout.propTypes = {
  isGallery: PropTypes.bool,
};

const getIconSosmed = (sosmed) => {
  const style = {
    className: "fill-icon-4",
    width: 24,
    height: 24,
  };
  switch (sosmed) {
    case "x":
      return <IconTwitter {...style} />;
    case "spotify":
      return <IconSpotify {...style} />;
    case "youtube":
      return <IconYoutube {...style} />;
    default:
      return <IconInstagram {...style} />;
  }
};

export default PreviewBandAbout;
