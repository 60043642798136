"use client"
import React, { useState } from "react"
import Styles from "./hero.module.scss"
import { useIsMobile } from "@/hooks/use-is-mobile"
import Link from "@/components/shared/link/link"
import clsx from "clsx"
import { Image } from "@/components/shared/image/image"
import { ToastComponent } from "@/components/toast"
import PropTypes from "prop-types"
import HowToLink from "@/components/mgm/how-to-link"

const bgImgMobile = "/assets/amild/mgm/aspace-leaderboard-mobile.png"
const bgImgDesktop = `/assets/amild/mgm/aspace-leaderboard.png`
const bgDesktop = "/assets/amild/aspace-leaderboard/bg-hero-aspace-desktop.png"
const logo = "/assets/amild/aspace-leaderboard/aspace-logo.png"

const HeroLeaderboard = ({ handleOnClick, loadingASpace }) => {
  const isMobile = useIsMobile()

  const bgImg = isMobile ? bgImgMobile : bgImgDesktop

  const [isSuccess, setIsSuccess] = useState(false)

  return (
    <>
      <ToastComponent
        onClick={() => {
          setIsSuccess(false)
        }}
        title={`Berhasil copy Link`}
        desc={`Ajak Teman ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "Kamu" : "Lo"} Sebanyak-banyaknya!`}
        isOpen={isSuccess}
      />
      <section
        className={clsx(
          Styles.leaderboard__header,
          isMobile
            ? "px-[10px] pt-[24px]"
            : "relative z-[1] aspect-[1410/520] overflow-hidden"
        )}
      >
        {!isMobile && (
          <div
            className={clsx(
              "absolute rounded-[10px] top-0 right-0 w-full aspect-[1410/520] overflow-hidden"
            )}
          >
            <Image alt="bg" src={bgDesktop} />
          </div>
        )}
        <div
          className={clsx(
            "relative z-[1]",
            !isMobile && "p-[30px] flex justify-between"
          )}
        >
          <div className={clsx(isMobile && "w-full")}>
            <h1
              className={clsx(
                "font-bold  text-[#0F182C] font-font-family-3 uppercase",
                isMobile
                  ? "text-[32px] leading-[34px]"
                  : "text-[52px] leading-[52px]"
              )}
            >
              Ready To Protect <span>A</span>
            </h1>
            <p
              className={clsx(
                "text-text-2 font-font-family-6 font-medium",
                isMobile
                  ? "text-sm leading-[24px] max-w-[343px] mx-auto my-[16px]"
                  : "text-base max-w-[708px] mt-[20px] mb-[16px]"
              )}
            >
              Eksplor dan ikuti seluruh aktivitas di A Space tanpa kompromi!
              Kumpulkan poin sebanyak-
              {!isMobile && <br />}
              banyaknya di dalam <em>engagement</em> A Space untuk jadi{" "}
              <em>top scorers</em> di <em>leaderboard</em> dan raih kesempatan
              dapatkan <em>rewards</em>* inspirasional.
            </p>
            <button
              className={clsx(
                "border-4 border-cta-1 rounded-[10px] bg-cta-2 font-font-family-7 font-bold text-text-2 p-[10px] flex justify-center items-center",
                isMobile ? "w-[260px] mx-auto" : "w-[300px]"
              )}
              onClick={() =>
                handleOnClick("ikuti keseruannya", "ready to protect a")
              }
              disabled={loadingASpace}
            >
              <span>Join</span>
              <div className={clsx("relative w-[83px] h-[23px]")}>
                <Image alt="aspace" src={logo} />
              </div>
            </button>
            <p
              className={clsx(
                "text-[#728286] mx-auto w-full ",
                isMobile
                  ? "text-xs leading-[20px] mt-[16px]"
                  : "text-sm mt-[30px]"
              )}
            >
              *Untuk kebutuhan internal. Aktivitas dan tautan ini <br />
              hanya untuk perokok dewasa 21+, non-LAU/pengguna IQOS.
            </p>
            <Link
              className={clsx(
                "text-text-1 underline font-bold font-font-family-7 ",
                isMobile ? "text-xs" : "text-sm"
              )}
              href="/aspace/br/syarat-ketentuan"
              passHref
            >
              Syarat & Ketentuan Berlaku
            </Link>
          </div>
          <div
            className={clsx(
              "relative bg-white flex flex-col gap-[20px] p-[15px] overflow-hidden shadow-[0_5px_20px_0px_rgba(125,48,74,0.25)]",
              isMobile
                ? "w-full mt-[30px] rounded-[10px]"
                : "w-[465px] mt-[253px] rounded-[15px]"
            )}
          >
            <div
              className={clsx(
                "absolute rounded-[10px] bottom-0 right-0 w-full overflow-hidden",
                isMobile ? "aspect-[355/226]" : "aspect-[459/257] h-[160px]"
              )}
            >
              <Image alt="bg" src={bgImg} />
            </div>
            <div className="relative z-[1]">
              <HowToLink
                isCustom={true}
                link_description={"Ajak temen lo dengan copy link di bawah ini"}
                cta_label={"Copy Link"}
                setIsSuccess={setIsSuccess}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HeroLeaderboard

HeroLeaderboard.propTypes = {
  handleOnClick: PropTypes.func,
  loadingASpace: PropTypes.func,
}
