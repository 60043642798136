import ButtonAction from "@/components/shared/button-action/button-action"
import { Image } from "@/components/shared/image/image"
import PopupAction from "@/components/shared/popup-action/popup-action"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import React from "react"

const successPopup = {
  title: "Tebakan lo benar!",
  description:
    "Mainkan kembali tantangan untuk bisa berkesempatan menjadi pemenang dengan memiliki EXP tertinggi.",
  cta_label: "Eksplor Tantangan Selanjutnya",
  cta_cancel: "Cek keseruan lainnya",
}
// const failedPopup = {
//   title: "Waktu Lo Habis!",
//   description:
//     "Lo harus lebih cepat buat nyusun gambar yang tepat. Jangan khawatir lo bisa ulang terus sampai dapet gambar yang tepat",
//   cta_label: "Coba Lagi",
// };

export const PopupSubmission = ({ popupData, isOpen, point, nextUrl }) => {
  const isMobile = useIsMobile()
  const pathname = usePathname()
  const { push, replace } = useRouter()
  // const searchParams = useSearchParams();
  // const nextUrl = searchParams.get('next_mission') || '';

  return (
    <PopupAction isShowing={isOpen} href={pathname} zIndex="z-[20]">
      <div className="max-w-[304px]">
        <div className="absolute rounded-[10px] border-[3px] border-cta-3 border-dotted inset-[10px] z-[-1]"></div>
        <div className="min-w-[304px] min-h-[177px] bg-bg-5 rounded-[10px] overflow-hidden relative mb-[20px]">
          <Image
            data-testid="popup-submission-image"
            alt="cover"
            fill
            style={{ objectFit: "cover", objectPosition: "center" }}
            className="absolute inset-0"
            src={
              isMobile
                ? popupData?.mobile_img_url_confirm
                : popupData?.desktop_img_url_confirm
            }
          />
        </div>
        <div
          data-testid="popup-submission-title"
          className="text-[20px] font-black font-font-family-8 leading-[27px] mb-[5px] text-text-2"
        >
          {popupData.subtitle_confirmation}
        </div>
        {point > 0 && (
          <div className="flex items-center gap-[5px] justify-center mb-[5px]">
            {/* <div className="w-fit bg-[#FEE9EA] shadow-[0px_2px_12px_1px_rgba(237,28,36,0.08),0px_1px_2px_0px_rgba(237,28,36,0.10)] text-text-5 mx-auto mb-[15px] py-[10px] px-[20px] rounded-[100px] flex items-center gap-[5px] border-b-[2px] border-text-1"> */}
            <div className="min-h-[20px] w-fit min-w-[20px] relative">
              <Image
                alt="cover"
                fill
                style={{
                  objectFit: "cover",
                  objectPosition: "center",
                  position: "absolute",
                  inset: 0,
                }}
                src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/reward-profile.svg`}
              />
            </div>
            <div className="text-[20px] text-text-1 leading-[27px] font-black font-font-family-8">
              {point} Poin!
            </div>
          </div>
        )}
        <div
          data-testid="popup-submission-description"
          className="text-[14px] text-text-2 md:text-[16px] font-medium font-font-family-6 mb-[20px] leading-[20px] md:leading-[25px]"
        >
          {popupData.desc_confirmation}
        </div>
        {/* {nextUrl && status === "completed" ? <> */}
        {nextUrl ? (
          <>
            <ButtonAction
              onClick={() => replace(nextUrl)}
              className="mb-[10px]"
              intent="primary"
            >
              {successPopup.cta_label}
            </ButtonAction>
            <ButtonAction
              onClick={() => replace("/br")}
              className=""
              intent="secondary"
            >
              {successPopup.cta_cancel}
            </ButtonAction>
          </>
        ) : (
          <ButtonAction
            onClick={() => replace("/br")}
            className="mb-[10px]"
            intent="primary"
          >
            {successPopup.cta_cancel}
          </ButtonAction>
        )}
        {/* </> */}
        {/* :
          <ButtonAction onClick={() => push(pathname)} className="mb-[10px]" intent="primary">
            {successPopup.cta_label}
          </ButtonAction>
        } */}
      </div>
    </PopupAction>
  )
}
