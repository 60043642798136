"use client"
import React, { useState, useEffect } from "react"
import { LandingWordle } from "./LandingWordle"
import GameWordle from "./GameWordle/GameWordle"
import { board4 } from "./helper/Words"
import { ClientApiList } from "@/lib/api/client-service"
import PropTypes from "prop-types"

const gameTime = 120

// theme_1:"Tempat lo stay saat camping di alam bebas"
// theme_2:"(Eng.) Di tempat ini, lo bisa santai di antara pasir dan air laut"
// word:"TENDA"
// word_2:"BEACH"

const Wordle = (props) => {
  const { wordle } = props
  const [board, setBoard] = useState(board4)
  const [timer, setTimer] = useState(gameTime)
  const [currAttempt, setCurrAttempt] = useState({ attempt: 0, letterPos: 0 })
  const [correctLetter, setCorrectLetter] = useState([])
  const [almostLetter, setAlmostLetter] = useState([])
  const [wrongLetter, setWrongLetter] = useState([])
  const [correctKey, setCorrectKey] = useState([])
  const [almostKey, setAlmostKey] = useState([])
  const [wrongKey, setWrongKey] = useState([])
  const [stage, setStage] = useState(1)
  const [wordleRow, setWordleRow] = useState(5)
  const [level, setLevel] = useState(1)
  const [status, setStatus] = useState("")
  const [openGame, setOpenGame] = useState(false)
  const [popup, setPopup] = useState(false)
  const [theme1, setTheme1] = useState("")
  const [wordleId, setWordleId] = useState("")
  const [pause, setPause] = useState(false)
  const [finished, setFinished] = useState(0)
  const [theme2, setTheme2] = useState("")
  const [failTime, setFailTime] = useState(0)
  const [isTnc, setIsTnc] = useState(0)
  const [isTncChecked, setIsTncChecked] = useState(0)
  const [tnc, setTnc] = useState(0)
  const [bgMobile, setBgMobile] = useState("")
  const [bgDesktop, setBgDesktop] = useState("")
  // const [finishWordle, setFinishWordle] = useState(false);
  // const [wordSet, setWordSet] = useState(new Set());

  const propsStage = {
    board,
    setBoard,
    timer,
    setTimer,
    currAttempt,
    setCurrAttempt,
    correctLetter,
    setCorrectLetter,
    almostLetter,
    setAlmostLetter,
    wrongLetter,
    setWrongLetter,
    correctKey,
    setCorrectKey,
    almostKey,
    setAlmostKey,
    wrongKey,
    setWrongKey,
    stage,
    setStage,
    level,
    setLevel,
    status,
    setStatus,
    wordleRow,
    setWordleRow,
    openGame,
    setOpenGame,
    popup,
    setPopup,
    theme1,
    theme2,
    // finishWordle,
    failTime,
    setFailTime,
    gameTime,
    wordleId,
    pause,
    setPause,
    finished,
    isTnc,
    isTncChecked,
    tnc,
    setIsTnc,
    setIsTncChecked,
    setTnc,
    bgMobile,
    bgDesktop,
    ...props,
  }

  const getBoard = (word) => {
    if (word === 4) {
      return setBoard([
        ["", "", "", ""],
        ["", "", "", ""],
        ["", "", "", ""],
        ["", "", "", ""],
        ["", "", "", ""],
        ["", "", "", ""],
      ])
    } else if (word === 5) {
      return setBoard([
        ["", "", "", "", ""],
        ["", "", "", "", ""],
        ["", "", "", "", ""],
        ["", "", "", "", ""],
        ["", "", "", "", ""],
        ["", "", "", "", ""],
      ])
    } else if (word === 6) {
      return setBoard([
        ["", "", "", "", "", ""],
        ["", "", "", "", "", ""],
        ["", "", "", "", "", ""],
        ["", "", "", "", "", ""],
        ["", "", "", "", "", ""],
        ["", "", "", "", "", ""],
      ])
    } else if (word === 7) {
      return setBoard([
        ["", "", "", "", "", "", ""],
        ["", "", "", "", "", "", ""],
        ["", "", "", "", "", "", ""],
        ["", "", "", "", "", "", ""],
        ["", "", "", "", "", "", ""],
        ["", "", "", "", "", "", ""],
      ])
    } else if (word === 8) {
      return setBoard([
        ["", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", ""],
      ])
    } else if (word === 9) {
      return setBoard([
        ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", "", ""],
      ])
    }
  }

  const getDetail = async (level) => {
    const { data } = await ClientApiList.getEngagementDetail({
      type: "wordle",
      engagement_uuid: wordle?.engagement_uuid,
      level: level || 1,
    })
    setTheme1(data?.data.data.wordle.wordle_hint)
    setWordleId(data?.data.data.wordle.engagement_uuid)
    setTimer(data?.data.data.total_time)
    setFinished(data?.data.data.finished)
    setWordleRow(data?.data.data.amount_of_letter)
    getBoard(data?.data.data.amount_of_letter)
    setIsTnc(data?.data?.data?.is_tnc)
    setIsTncChecked(data?.data?.data?.is_tnc_checked)
    setTnc(data?.data?.data?.tnc)
    setBgMobile(data?.data.data.wordle.mobile_image_url)
    setBgDesktop(data?.data.data.wordle.desktop_image_url)
    setStatus("")
    setOpenGame(false)
    setCurrAttempt({ attempt: 0, letterPos: 0 })
    setCorrectLetter([])
    setAlmostLetter([])
    setWrongLetter([])
    setCorrectKey([])
    setAlmostKey([])
    setWrongKey([])
  }

  const replayGame = async () => {
    const { data } = await ClientApiList.getEngagementDetail({
      type: "wordle",
      engagement_uuid: wordle?.engagement_uuid,
      level: level,
    })
    setTheme1(data?.data.data.wordle.wordle_hint)
    setWordleId(data?.data.data.wordle.engagement_uuid)
    setTimer(data?.data.data.total_time)
    setFinished(data?.data.data.finished)
    setWordleRow(data?.data.data.amount_of_letter)
    getBoard(data?.data.data.amount_of_letter)
    setIsTnc(data?.data?.data?.is_tnc)
    setIsTncChecked(data?.data?.data?.is_tnc_checked)
    setTnc(data?.data?.data?.tnc)
    setBgMobile(data?.data.data.wordle.mobile_image_url)
    setBgDesktop(data?.data.data.wordle.desktop_image_url)
    setStatus("")
    setOpenGame(false)
    setCurrAttempt({ attempt: 0, letterPos: 0 })
    setCorrectLetter([])
    setAlmostLetter([])
    setWrongLetter([])
    setCorrectKey([])
    setAlmostKey([])
    setWrongKey([])
  }

  const resetGame = () => {
    getBoard(wordleRow)
    setLevel(1)
    setStatus("")
    setOpenGame(false)
    setCurrAttempt({ attempt: 0, letterPos: 0 })
    setCorrectLetter([])
    setAlmostLetter([])
    setWrongLetter([])
    setCorrectKey([])
    setAlmostKey([])
    setWrongKey([])
  }

  const levelUpGame = (level) => {
    getDetail(level)
  }

  const contentView = () => {
    if (stage === 1) return <LandingWordle {...propsStage} />
    return (
      <GameWordle
        {...propsStage}
        resetGame={resetGame}
        levelUpGame={levelUpGame}
        replayGame={replayGame}
      />
    )
  }

  useEffect(() => {
    if (stage === 1 && !pause) {
      getDetail()
    }
    // eslint-disable-next-line
  }, [stage, pause])

  return <section>{contentView()}</section>
}

export default Wordle

Wordle.propTypes = {
  wordle: PropTypes.object,
}
