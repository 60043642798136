"use client"
import React from "react"
import ProgressBar from "../../shared/progress-bar/progress-bar"
import { Image } from "@/components/shared/image/image"
import PropTypes from "prop-types"
import { usePathname } from "next/navigation";
export default function MissionProgress({
  challengeCompleted,
  challengeTotal,
}) {
  const pathname = usePathname()
  return (
    <>
      <div className="flex justify-between items-center mb-[5px] lg:mb-[10px] text-text-2">
        <div
          data-testid="mission-progress-description"
          className="text-[12px] lg:text-[14px] font-normal leading-[16px] lg:leading-[25px] tracking-[0.2px]"
        >
          {!pathname.includes('multivariant')? `${challengeTotal - challengeCompleted} challenge lagi untuk unlock
          challenge`:'Cek varian favorit lo'}
        </div>
        <div className="flex items-center gap-[5px]">
          <div
            data-testid="mission-progress-count"
            className="text-[13px] lg:text-[16px] font-bold font-font-family-7 leading-[13px]"
          >
            <span className="text-text-1">{challengeCompleted}</span>/
            {challengeTotal}
          </div>
          <div className=" w-[16px] h-[16px] relative">
            <Image
              alt="cover"
              fill
              style={{ objectFit: "cover" }}
              src={`/assets/${process.env.NEXT_PUBLIC_NAME}/mission-page/cup.svg`}
            />
          </div>
        </div>
      </div>
      <ProgressBar
        bgprogress="text-cta-4"
        bgpill="bg-cta-1"
        className="h-[5px] mb-[15px] lg:mb-[12px]"
        current={challengeCompleted}
        total={challengeTotal}
      />
    </>
  )
}

MissionProgress.propTypes = {
  challengeCompleted: PropTypes.number,
  challengeTotal: PropTypes.number,
}
