"use client"
import React from "react"
import PropTypes from "prop-types"

export default function CardNav({ isOutsideUrl }) {
  return (
    <div
      className="w-[24px] h-[24px] rounded-[50%] bg-cta-1 text-cta-1 flex items-center justify-center"
      style={{ background: "var(--cta_1)" }}
    >
      {isOutsideUrl ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
        >
          <path
            d="M7.99996 0.7C7.99996 0.523189 7.92972 0.35362 7.8047 0.228595C7.67967 0.103571 7.5101 0.0333335 7.33329 0.0333335L1.99996 0C1.82315 0 1.65358 0.070238 1.52855 0.195262C1.40353 0.320287 1.33329 0.489856 1.33329 0.666667C1.33329 0.843478 1.40353 1.01305 1.52855 1.13807C1.65358 1.2631 1.82315 1.33333 1.99996 1.33333H5.70663L0.193293 6.86C0.130807 6.92198 0.081211 6.99571 0.0473652 7.07695C0.0135194 7.15819 -0.00390625 7.24533 -0.00390625 7.33333C-0.00390625 7.42134 0.0135194 7.50848 0.0473652 7.58972C0.081211 7.67096 0.130807 7.74469 0.193293 7.80667C0.255268 7.86915 0.329002 7.91875 0.410241 7.95259C0.491481 7.98644 0.578618 8.00387 0.666626 8.00387C0.754634 8.00387 0.841771 7.98644 0.92301 7.95259C1.00425 7.91875 1.07798 7.86915 1.13996 7.80667L6.66663 2.28V6C6.66663 6.17681 6.73686 6.34638 6.86189 6.4714C6.98691 6.59643 7.15648 6.66667 7.33329 6.66667C7.5101 6.66667 7.67967 6.59643 7.8047 6.4714C7.92972 6.34638 7.99996 6.17681 7.99996 6V0.7Z"
            // fill="white"
            className="fill-cta-2"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M6.66667 11.3333L10 8L6.66667 4.66667"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="stroke-bg-3"
          />
        </svg>
      )}
    </div>
  )
}

CardNav.propTypes = {
  isOutsideUrl: PropTypes.bool,
}
