"use client";
import React from "react";
import TextInput from "../text-input";
import PropTypes from 'prop-types';
export default function PhoneInput({ disabled, ...props }) {
  return (
    <div className="flex gap-[5px] items-center mb-[10px]">
      <TextInput
        disabled
        value="+62"
        style={{ background: !disabled ? "var(--cta_1) " : "var(--cta_4)" }}
        className={`${!disabled ? "!bg-cta-1 !text-cta-2" : "!bg-cta-4 !text-text-3"} !w-[46px] mb-0 w-[40px] px-0 text-center`}
      />
      <TextInput className="mb-[0]" disabled={disabled} {...props} />
    </div>
  );
}

PhoneInput.propTypes = {
  disabled: PropTypes.bool,
  props: PropTypes.object
};