"use client";
import React from 'react';
import PropTypes from "prop-types";
import { useIsLogin } from '@/hooks/auth/use-is-login';
import { usePathname } from 'next/navigation';
import { useShowLoginPopup } from '@/hooks/auth/use-login-popup';
import { cn } from '@/lib/utils';
import { iframeHidesNav } from '../iframe/data-hide';

export function JogetPageLayout({ children }) {
  const { isLoading, isLogin } = useIsLogin();
  const pathname = usePathname();

  useShowLoginPopup({
    showWhen: !isLogin && !isLoading,
    closePath: '/',
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
  });

  return (
    <div
      className={cn("relative min-h-[calc(100vh-170px)] pt-[58px] lg:pt-[72px] bg-bg-1", iframeHidesNav.find(path => pathname.includes(path)) ? 'pt-0 min-h-[100vh]' : '')}
    >
      {/* <ButtonBack /> */}
      {children}
    </div>
  );
}

JogetPageLayout.propTypes = {
  children: PropTypes.object
};