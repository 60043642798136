"use client"
import React from "react"
import PropTypes from "prop-types"
import DOMPurify from "isomorphic-dompurify"

export default function EditHeader({ title, description }) {
  return (
    <>
      <div
        data-testid="title"
        className="text-[20px] pl-[5px] font-bold font-font-family-7 text-text-2 leading-[27px] mb-[5px]"
      >
        {title}
      </div>
      <div
        data-testid="description"
        className="text-[16px] pl-[5px] font-medium font-font-family-6 text-text-2 leading-[22px] mb-[20px]"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
      ></div>
    </>
  )
}

EditHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}
