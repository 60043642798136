"use client"
import React from "react"
import { Image } from "@/components/shared/image/image"

// custom props import
import PopupAction from "@/components/shared/popup-action/popup-action"
import ButtonAction from "@/components/shared/button-action/button-action"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { usePathname, useRouter, useSearchParams } from "next/navigation"

export function PopupPuzzleCloseConfirmation({
  isShowing,
  handleIsShowing,
  // popupData,
  isPaused,
  resetPuzzle,
  setIsPaused,
}) {
  // console.log(nextUrl);
  const isMobile = useIsMobile()
  const pathname = usePathname()
  const { push } = useRouter()
  const searchParams = useSearchParams()
  const params = [...searchParams.entries()].filter(
    (param) => param[0] !== "show-puzzle-popup"
  )
  const joinedParams = params.map((param) => param.join("=")).join("&")

  return (
    <PopupAction
      isShowing={isShowing}
      href={`${pathname}?${isPaused ? joinedParams : ""}`}
      onClickBtnX={() => {
        handleIsShowing(false)
        if (isPaused) {
          setIsPaused(false)
        } else {
          resetPuzzle()
        }
      }}
      zIndex="z-[20]"
    >
      <div className="max-w-[304px]">
        <div className="absolute rounded-[10px] overflow-hidden inset-0 z-[-1] "></div>
        <div className="absolute rounded-[10px] border-[3px] border-cta-3 border-dotted inset-[10px] z-[-1]"></div>
        <div className="min-w-[304px] min-h-[177px] bg-bg-1 rounded-[10px] overflow-hidden relative mb-[20px]">
          <Image
            data-testid="popup-image"
            alt="cover"
            fill
            style={{ objectFit: "cover", objectPosition: "center" }}
            className="absolute inset-0"
            src={`/assets/${process.env.NEXT_PUBLIC_NAME}/mission/popup-confirm-exit.png`}
          />
        </div>
        <div
          data-testid="popup-title"
          className="text-[20px] font-black font-font-family-8 leading-[27px] mb-[5px] text-text-2"
        >
          Yakin mau keluar?
        </div>
        <div
          data-testid="popup-description"
          className="text-[14px] text-text-2 md:text-[16px] font-medium font-font-family-6 mb-[20px] leading-[20px] md:leading-[25px]"
        >
          Semua <i>progress</i>{" "}
          {process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"} di
          level ini bakal hilang.{" "}
          {process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "Kamu" : "Lo"} harus
          ulang main dari awal lagi buat ngumpulin poin.
        </div>
        <div className="flex items-center gap-[5px]">
          <ButtonAction
            onClick={() => {
              // resetPuzzle();
              push("/br")
            }}
            className=" mb-[10px]"
            intent="secondary"
          >
            Keluar
          </ButtonAction>
          <ButtonAction
            onClick={() => {
              // resetPuzzle();
              handleIsShowing(false)
              if (isPaused) {
                setIsPaused(false)
              } else {
                resetPuzzle()
              }
            }}
            className=" mb-[10px]"
            intent="primary"
          >
            Balik Main
          </ButtonAction>
        </div>
      </div>
    </PopupAction>
  )
}
