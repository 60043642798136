import jsCookie from 'js-cookie';

export const getGALinker = () => {
	let ga = typeof window !== 'undefined' && window[window['GoogleAnalyticsObject']];
	if (ga && ga.getAll()[0]) {
		return ga.getAll()[0].get('linkerParam')?.split('=')[1];
	}
	return '';
};

export const getGL = () => {
	let ga = typeof window !== 'undefined' && jsCookie.get('_ga')?.substring(6);

	let measurementId = process.env.NEXT_PUBLIC_MEASUREMENT_ID;
	let id = typeof window !== 'undefined' && jsCookie.get(measurementId)?.substring(6);


	if (ga && id) {
		return window.google_tag_data.glBridge.generate({
			_ga: ga,
			[measurementId]: id
		});
	}
	return '';
};

