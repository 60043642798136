"use client"
import { cn } from "@/lib/utils"
import React from "react"

export default function LeaderboardTopUserPos({ children, className }) {
  return (
    <div
      className={cn(
        `relative z-[1] font-font-family-3 order-4 text-[20px] md:text-[22.8px] leading-[22px] md:leading-[31px] font-bold absolute bottom-[-29.4px] w-[40px] md:w-[52px] h-[44.5px] md:h-[57.8px] flex items-center justify-center bg-[url('/assets/${process.env.NEXT_PUBLIC_NAME}/user-profile/hexagon-sm.svg')] origin bg-cover bg-center text-text-2`,
        className
      )}
    >
      {children}
    </div>
  )
}
