"use client"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"

import { useEffect, useRef, useState } from "react"
import { BannerItem } from "../shared/banner/banner-item"
import PropTypes from "prop-types"
import {
  useIsDesktopTablet,
  useIsMobile,
  useIsTablet,
} from "@/hooks/use-is-mobile"
import clsx from "clsx"
import { Image } from "@/components/shared/image/image"
import { cn } from "@/lib/utils"

export function BannerSwiper({ items = [] }) {
  const isDesktopMd = useIsDesktopTablet()
  const isTablet = useIsTablet()
  const isMobile = useIsMobile()
  const swiperRef = useRef()
  const [activeIndex, setActiveIndex] = useState(0)
  const [showBtn, setShowBtn] = useState(true)

  /* hide button see more */
  useEffect(() => {
    activeIndex + (isDesktopMd ? 2 : 3) === items.length && setShowBtn(false)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [activeIndex])

  const nextButton = (
    <button
      onClick={() => {
        swiperRef.current?.slideNext()
      }}
      className="absolute right-2 top-[75px] -translate-y-1/2 z-10 rounded-full overflow-hidden flex justify-center items-center"
      style={{
        fill: "linear-gradient(129deg, rgba(0, 0, 0, 0.50) 15.18%, rgba(0, 0, 0, 0.00) 90.89%)",
        backdropFilter: "blur(25px)",
      }}
    >
      <IconSeeMore />
      <div className="absolute top-1/2 -translate-y-1/2 z-[1] flex flex-col justify-center items-center">
        <div className="relative w-[36px] h-[36px]">
          <Image src="/swipe-icon.gif" alt="swipe" />
        </div>
        <span className="text-white">See More</span>
      </div>
    </button>
  )

  return (
    <div
      className={cn(
        "w-full h-full relative my-[20px]",
        !isMobile && "mt-[30px]  mb-[33px]"
      )}
    >
      <p
        className={cn(
          "w-fit px-[15px] text-xl font-bold mb-[10px] text-text-2",
          process.env.NEXT_PUBLIC_NAME === "amild"
            ? "font-font-family-3"
            : "font-font-family-7"
        )}
      >
        {process.env.NEXT_PUBLIC_NAME === "amild"
          ? String(items[0]?.component_props.data.section_title).toUpperCase()
          : items[0]?.component_props.data.section_title}
      </p>
      {isMobile ? (
        <div className="w-full flex flex-col gap-[10px] px-[10px]">
          {items?.map((item, index) => {
            if (item)
              return (
                <div className="w-full" key={index}>
                  <BannerItem
                    type="secondary"
                    items={item?.component_props?.data?.items || []}
                    autoplay={item?.component_props?.data?.autoplay || []}
                    event="sub_banner"
                    subIndex={index}
                    itemBanner={item.api_config}
                  />
                </div>
              )
            return
          })}
        </div>
      ) : (
        <Swiper
          style={{ padding: "0 10px", overflow: "hidden" }}
          spaceBetween={10}
          slidesPerView={isDesktopMd ? "auto" : 3}
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.activeIndex)
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper
            setActiveIndex(swiper.activeIndex)
          }}
        >
          {items?.map((item, index) => {
            if (item)
              return (
                <SwiperSlide style={{ width: "fit-content" }} key={index}>
                  <div
                    className={clsx(
                      "relative",
                      isTablet && "w-[320px]",
                      isDesktopMd && "w-[460px]"
                    )}
                  >
                    <BannerItem
                      type="secondary"
                      items={item?.component_props?.data?.items || []}
                      autoplay={item?.component_props?.data?.autoplay || []}
                      event="sub_banner"
                      subIndex={index}
                      itemBanner={item.api_config}
                    />
                  </div>
                </SwiperSlide>
              )
            return
          })}
        </Swiper>
      )}
      {items.length > 3 && showBtn && !isMobile && nextButton}
    </div>
  )
}

BannerSwiper.propTypes = {
  items: PropTypes.array.isRequired,
}

const IconSeeMore = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
    >
      <g filter="url(#filter0_b_8665_18881)">
        <circle
          cx="44"
          cy="44"
          r="44"
          fill="url(#paint0_linear_8665_18881)"
          fillOpacity="0.5"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_8665_18881"
          x="-50"
          y="-50"
          width="188"
          height="188"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="25" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_8665_18881"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_8665_18881"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8665_18881"
          x1="5.5"
          y1="22.9167"
          x2="77.9167"
          y2="82.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
