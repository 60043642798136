"use client"
import React, { useEffect, useState } from "react"
import { Image } from "@/components/shared/image/image"
import PropTypes from "prop-types"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { usePathname } from "next/navigation"
import { useShowLoginPopup } from "@/hooks/auth/use-login-popup"
import { Spinner } from "../shared/spinner"
import { useIsMobile } from "@/hooks/use-is-mobile"

export function AspaceBoardPageLayout({
  children,
  bg_img_url_mobile,
  bg_img_url_desktop,
}) {
  const { isLoading, isLogin } = useIsLogin()
  const pathname = usePathname()
  const isMobile = useIsMobile()

  const [showPage, setShowPage] = useState(false)

  const checkBrPath =
    pathname === "/aspace/br/leaderboard" && !isLogin && !isLoading
  const bgImg = isMobile ? bg_img_url_mobile : bg_img_url_desktop

  useShowLoginPopup({
    showWhen: checkBrPath,
    closePath: "/",
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
  })

  useEffect(() => {
    if (!isLoading) setShowPage(true)
  }, [isLoading])

  return (
    <div className="relative pt-[58px] lg:pt-[72px]">
      <div className="absolute rounded-b-[10px] top-[-20px] left-0 right-0 h-[738px] w-[100%] bg-bg-3 z-1 overflow-hidden">
        {bgImg && <Image alt="bg" src={bgImg} />}
      </div>
      {showPage ? (
        children
      ) : (
        <div className="h-[calc(100vh-170px)] flex items-center">
          <Spinner />
        </div>
      )}
    </div>
  )
}

AspaceBoardPageLayout.propTypes = {
  children: PropTypes.object,
  bg_img_url_mobile: PropTypes.string,
  bg_img_url_desktop: PropTypes.string,
}
