"use client"
import React, { Suspense, useEffect, useState } from "react"
import { Image } from "@/components/shared/image/image"
import { motion } from "framer-motion"

// custom comp. imports
import ButtonAction from "../shared/button-action/button-action"
import PuzzlePil from "../puzzle/puzzle-pil/puzzle-pil"
import { useSearchParams } from "next/navigation"
import { ClientApiList } from "@/lib/api/client-service"

const variants = {
  show: {
    opacity: 1,
    display: "block",
  },
  hide: {
    opacity: 0,
    display: "none",
  },
  transition: {
    ease: "easeInOut",
    duration: 0.3,
  },
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

function distributeValues(values) {
  // Initialize rows
  const rowOne = []
  const rowTwo = []

  // Shuffle the array first
  shuffleArray(values)

  // Distribute values to rows
  values.forEach((value, index) => {
    if (index < 2) {
      rowOne.push(value)
    } else {
      rowTwo.push(value)
    }
  })

  return { rowOne, rowTwo }
}

const getShuffledPuzzle = () => {
  const values = [0, 1, 2, 3]

  const { rowOne, rowTwo } = distributeValues(values)

  return [rowOne, rowTwo]
}

const flattenArray = (arr) => {
  return arr.reduce((flatArr, subArr) => flatArr.concat(subArr), [])
}

const getInversionsCount = (arr) => {
  arr = flattenArray(arr).filter((n) => n !== 0)
  // console.log(arr);
  const inversions = []

  for (let i = 0; i < arr.length - 1; i++) {
    const currentValue = arr[i]
    const currentInversions = arr.filter(
      (val, j) => i < j && val < currentValue
    )
    // console.log(currentInversions, currentValue);
    inversions.push(currentInversions.length)
  }

  const inversionsCount = inversions.reduce((total, val) => total + val, 0)
  // console.log(inversionsCount);
  return inversionsCount
}

const checkPos = (puzzle) => {
  // console.log(Math.max(puzzle));
  return (
    Math.max(puzzle[0][0], puzzle[0][1]) -
      Math.min(puzzle[0][0], puzzle[0][1]) ===
      1 &&
    Math.max(puzzle[0][0], puzzle[1][0]) -
      Math.min(puzzle[0][0], puzzle[1][0]) <=
      2 &&
    puzzle[0][0] < puzzle[0][1]
  )
}

const isSolvable = (puzzle) => {
  return getInversionsCount(puzzle) % 2 === 0 && checkPos(puzzle)
}

const getPuzzle = () => {
  let puzzle = getShuffledPuzzle()

  while (!isSolvable(puzzle)) {
    puzzle = getShuffledPuzzle()
  }

  return puzzle
}

function PuzzleFallback() {
  return <div>Puzzle</div>
}

function SuspendedPuzzle({
  time_label,
  time_needed,
  cta_label,
  href,
  time_left_img_url,
  time_right_img_url,
}) {
  const searchParams = useSearchParams()
  const [puzzle, setPuzzle] = useState([])
  const [complete, setComplete] = useState(false)
  const [moves, setMoves] = useState(0)
  const [hideRule, setHideRule] = useState(false)
  const [puzzleData, setPuzzleData] = useState()

  const getPuzzleData = async () => {
    try {
      const { data } = await ClientApiList.getEngagementPuzzleDetail({
        engagement_uuid: "88c30161-6780-4d7d-81a6-f0287ba7f934",
        type: "puzzle",
      })
      // console.log(data.data.data.puzzle, "res")
      setPuzzleData({ ...data.data.data.puzzle })
    } catch (err) {
      console.log("Error", err)
    }
  }

  useEffect(() => {
    setHideRule(searchParams.get("hide-puzzle-rule") === "true")
  }, [searchParams])

  useEffect(() => {
    setPuzzle(getPuzzle())
    getPuzzleData()
  }, [])

  const movePiece = (x, y) => {
    if (!complete) {
      if (checkNeighbours(x, y) || checkNeighbours(x, y, 2)) {
        const emptySlot = checkNeighbours(x, y) || checkNeighbours(x, y, 2)

        const newPuzzle = puzzle.map((row) => row.slice())

        if (x === emptySlot.x && y < emptySlot.y) {
          newPuzzle[emptySlot.x][emptySlot.y] = puzzle[x][y + 1]
          newPuzzle[x][y + 1] = newPuzzle[x][y]
          newPuzzle[x][y] = 0
        } else if (x === emptySlot.x && y > emptySlot.y) {
          newPuzzle[emptySlot.x][emptySlot.y] = puzzle[x][y - 1]
          newPuzzle[x][y - 1] = newPuzzle[x][y]
          newPuzzle[x][y] = 0
        }

        if (y === emptySlot.y && x < emptySlot.x) {
          newPuzzle[emptySlot.x][emptySlot.y] = puzzle[x + 1][y]
          newPuzzle[x + 1][y] = newPuzzle[x][y]
          newPuzzle[x][y] = 0
        } else if (y === emptySlot.y && x > emptySlot.x) {
          newPuzzle[emptySlot.x][emptySlot.y] = puzzle[x - 1][y]
          newPuzzle[x - 1][y] = newPuzzle[x][y]
          newPuzzle[x][y] = 0
        }

        setPuzzle(newPuzzle)

        setMoves(moves + 1)

        checkCompletion(newPuzzle)
      }
    }
  }

  const checkCompletion = (puzzle) => {
    if (flattenArray(puzzle).join("") === "123456780") {
      setComplete(true)
    }
  }

  const checkNeighbours = (x, y, d = 1) => {
    const neighbours = []

    if (puzzle[x][y] !== 0) {
      neighbours.push(
        puzzle[x - d] && puzzle[x - d][y] === 0 && { x: x - d, y: y }
      )
      neighbours.push(puzzle[x][y + d] === 0 && { x: x, y: y + d })
      neighbours.push(
        puzzle[x + d] && puzzle[x + d][y] === 0 && { x: x + d, y: y }
      )
      neighbours.push(puzzle[x][y - d] === 0 && { x: x, y: y - d })
    }

    const emptySlot = neighbours.find((el) => typeof el === "object")

    return emptySlot
  }

  const resetPuzzle = () => {
    setComplete(false)
    setPuzzle(getPuzzle())
    setMoves(0)
  }

  return (
    // <div>hello</div>
    <motion.div
      key="animation-on-state"
      variants={variants}
      animate={hideRule ? "show" : "hide"}
      className="py-[15px] md:pt-[30px] md:pb-[42px] px-[10px] text-white relative"
    >
      {/* <Image
          alt='cover'
          fill
          style={{ objectFit: "cover" }}
          src='/assets/dummy-bg.png'
          className='z-[-1]'
        /> */}
      <PuzzlePil
        label={time_label}
        time={time_needed}
        right_img_url={time_right_img_url}
        left_img_url={time_left_img_url}
      />
      <div className="grid max-w-[355px] gap-[2px] min-h-[355px] relative bg-black mb-[80px] md:mb-[60px] mx-auto">
        {puzzle.map((row, i) => (
          <div
            key={i}
            style={{
              display: "flex",
              gap: "2px",
            }}
          >
            {row.map((col, j) => {
              const color = col === 0 ? "transparent" : "lightgray"
              return (
                <div
                  key={`${i}-${j}`}
                  onClick={() => movePiece(i, j)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "fit-content",
                    height: "fit-content",
                    // margin: 2,
                    backgroundColor: color,
                    borderRadius: 5,
                    cursor: complete ? "not-allowed" : "pointer",
                    userSelect: "none",
                    position: "relative",
                  }}
                >
                  {/* {console.log(puzzleData?.engagement_puzzle_imgs[col]?.puzzle_image_url_desktop)} */}
                  {col !== 0 ? (
                    <div>
                      <Image
                        className="max-h-[177px]"
                        // id={pos}
                        alt="cover"
                        layout="fill"
                        style={{ objectFit: "contain" }}
                        src={
                          puzzleData?.engagement_puzzle_imgs[col - 1]
                            ?.puzzle_image_url_desktop
                        }
                      />
                      {col}
                    </div>
                  ) : null}
                  {/* <span style={{ fontSize: "2rem", fontWeight: "bold" }}>
                    {col !== 0 && col}
                  </span> */}
                </div>
              )
            })}
          </div>
        ))}
        {/* </div> */}
        {complete && (
          <p>
            <button
              onClick={() => {
                resetPuzzle()
              }}
            >
              Play Again
            </button>
          </p>
        )}
      </div>
      <ButtonAction className="max-w-[375px] mx-auto" intent="primary">
        {cta_label}
      </ButtonAction>
    </motion.div>
  )
}

export function Puzzle(props) {
  return (
    <Suspense fallback={<PuzzleFallback />}>
      <SuspendedPuzzle {...props} />
    </Suspense>
  )
}
