"use client"
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { Image } from "@/components/shared/image/image"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
// custom comp. imports
import ButtonAction from "../shared/button-action/button-action"
import PuzzlePil from "../puzzle/puzzle-pil/puzzle-pil"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { ClientApiList } from "@/lib/api/client-service"
import { PopupPuzzle } from "./popup-puzzle/popup-puzzle"
import { useDatalayer } from "@/hooks/use-datalayer"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { getArticlePathname } from "@/hooks/use-pathname"
import { PopupPuzzleUp } from "./popup-puzzle/popup-up"
import clsx from "clsx"
import { InfoPopup } from "../info-popup/info-popup"
import CampaignDescription from "../campaign-intro/campaign-card/components/campaign-description"
import { PopupPuzzleCloseConfirmation } from "./popup-puzzle/popup-up-close-confirmation"
import UseQueryParams from "@/hooks/use-query-params"

const variants = {
  show: {
    opacity: 1,
    display: "block",
  },
  hide: {
    opacity: 0,
    display: "none",
  },
  transition: {
    ease: "easeInOut",
    duration: 0.3,
  },
}

function PuzzleFallback() {
  return <div>Puzzle</div>
}

function SuspendedPuzzle({
  time_label,
  cta_label_puzzle,
  desktop_puzzle_img,
  mobile_puzzle_img,
  engagement_uuid,
  campaign,
  isStart,
  level,
  handleLevelUp,
  setLevel,
  setIsStart,
  total_time,
  type_of_slice,
  levelCurrent,
  ...props
}) {
  const QUERYPARAM = {
    puzzlePopUp: "show-puzzle-popup",
    page: "page_uuid",
    campaign: "campaign",
    point: "point",
  }
  const { handleFilterQuery, handlePushQuery } = UseQueryParams()
  const { push } = useRouter()
  const isMobile = useIsMobile()
  const datalayer = useDatalayer()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const init = useRef(true)
  const levelRef = useRef(1)
  const [puzzle, setPuzzle] = useState([])
  const [complete, setComplete] = useState(false)
  const [moves, setMoves] = useState(0)
  const [hideRule, setHideRule] = useState(false)
  const [nextUrl, setNextUrl] = useState()
  const [isEnd, setIsEnd] = useState(false)
  const [statusEnd, setStatusEnd] = useState("")
  const [popupData, setPopupData] = useState()
  const [openPopup, setOpenPopup] = useState(false)
  const [point, setPoint] = useState(0)
  const [seconds, setSeconds] = useState(total_time)
  const [isPaused, setIsPaused] = useState(false)
  const [isExited, setIsExited] = useState(false)
  const [isShowingLevelUpPopup, setIsShowingLevelUpPopup] = useState(false)

  // Core Puzzle
  let puzzleDimension = type_of_slice || 1

  let blankRows

  const getShuffledPuzzle = () => {
    const values = [...Array(puzzleDimension * puzzleDimension).keys()]
    const rows = [...Array(puzzleDimension).keys()].map((item) => {
      return []
    })

    rows.forEach((item, index) => {
      while (values.length) {
        const random = Math.floor(Math.random() * values.length)

        if (item.length < puzzleDimension) {
          const valueToPush = values.splice(random, 1)[0]
          if (valueToPush === 0) blankRows = index
          item.push(valueToPush)
        } else {
          break
        }
      }
    })
    return rows
  }

  const flattenArray = (arr) => {
    return arr.reduce((flatArr, subArr) => flatArr.concat(subArr), [])
  }

  const getInversionsCount = (arr) => {
    arr = flattenArray(arr).filter((n) => n !== 0)
    const inversions = []

    for (let i = 0; i < arr.length - 1; i++) {
      const currentValue = arr[i]
      const currentInversions = arr.filter(
        (val, j) => i < j && val < currentValue
      )
      inversions.push(currentInversions.length)
    }

    const inversionsCount = inversions.reduce((total, val) => total + val, 0)
    return inversionsCount
  }

  const isSolvable = (puzzle) => {
    const isSolvable =
      puzzleDimension % 2 !== 0
        ? getInversionsCount(puzzle) % 2 === 0
        : (getInversionsCount(puzzle) + blankRows) % 2 === 1
    if (puzzleDimension === 2)
      return isSolvable && !(puzzle[0][0] === 1 && puzzle[1][0] === 3)
    return isSolvable
  }

  const getPuzzle = () => {
    let puzzle = getShuffledPuzzle()

    while (!isSolvable(puzzle)) {
      puzzle = getShuffledPuzzle()
    }

    return puzzle
  }

  const movePiece = (x, y) => {
    if (!complete) {
      if (checkNeighbours(x, y)) {
        const emptySlot = checkNeighbours(x, y)

        const newPuzzle = puzzle.map((row) => row.slice())

        if (x === emptySlot.x && y < emptySlot.y) {
          newPuzzle[emptySlot.x][emptySlot.y] = puzzle[x][y + 1]
          newPuzzle[x][y + 1] = newPuzzle[x][y]
          newPuzzle[x][y] = 0
        } else if (x === emptySlot.x && y > emptySlot.y) {
          newPuzzle[emptySlot.x][emptySlot.y] = puzzle[x][y - 1]
          newPuzzle[x][y - 1] = newPuzzle[x][y]
          newPuzzle[x][y] = 0
        }

        if (y === emptySlot.y && x < emptySlot.x) {
          newPuzzle[emptySlot.x][emptySlot.y] = puzzle[x + 1][y]
          newPuzzle[x + 1][y] = newPuzzle[x][y]
          newPuzzle[x][y] = 0
        } else if (y === emptySlot.y && x > emptySlot.x) {
          newPuzzle[emptySlot.x][emptySlot.y] = puzzle[x - 1][y]
          newPuzzle[x - 1][y] = newPuzzle[x][y]
          newPuzzle[x][y] = 0
        }

        setPuzzle(newPuzzle)

        setMoves(moves + 1)

        checkCompletion(newPuzzle)
      }
    }
  }

  const checkCompletion = (puzzle) => {
    const arrayToBeCheck = [...Array(puzzleDimension * puzzleDimension).keys()]
    arrayToBeCheck.shift()
    arrayToBeCheck.push(0)
    if (flattenArray(puzzle).join("") === arrayToBeCheck.join("")) {
      setComplete(true)
    }
  }

  const checkNeighbours = (x, y, d = 1) => {
    const neighbours = []

    if (puzzle[x][y] !== 0) {
      neighbours.push(
        puzzle[x - d] && puzzle[x - d][y] === 0 && { x: x - d, y: y }
      )
      neighbours.push(puzzle[x][y + d] === 0 && { x: x, y: y + d })
      neighbours.push(
        puzzle[x + d] && puzzle[x + d][y] === 0 && { x: x + d, y: y }
      )
      neighbours.push(puzzle[x][y - d] === 0 && { x: x, y: y - d })
    }

    const emptySlot = neighbours.find((el) => typeof el === "object")

    return emptySlot
  }
  // End of Core

  const timeOutCallback = useCallback(() => {
    setSeconds((prev) => prev - 1)
    // eslint-disable-next-line
  }, [])

  // handle Timer
  useEffect(() => {
    let timeout

    if (isStart) {
      if (!isPaused) {
        if (seconds > 0) {
          timeout = setTimeout(timeOutCallback, 1000)
        } else {
          setStatusEnd("timeout")
          if (seconds === 0) {
            setIsStart(false)
            clearTimeout(timeout)
            handlePushQuery(false, [QUERYPARAM.puzzlePopUp, true])
          }
        }
      }
    } else {
      clearTimeout(timeout)
    }

    return () => clearTimeout(timeout)
    // eslint-disable-next-line
  }, [seconds, timeOutCallback, isPaused, isStart])

  // ENd HandleTimer

  const handleSubmitPuzzle = async (isNextLevel) => {
    setNextUrl()
    try {
      const { data } = await ClientApiList.getEngagementPuzzleAnswer({
        engagement_uuid,
        level,
      })
      if (!isNextLevel) {
        if (searchParams.get(QUERYPARAM.page)) {
          const { data: nextUrlData } = await ClientApiList.getNextChallenge({
            page_uuid: searchParams.get(QUERYPARAM.page),
            mission_order: parseInt(searchParams.get("index")),
          })
          if (
            Object.keys(nextUrlData.data.data).length === 0 &&
            nextUrlData.data.data.constructor === Object
          ) {
            setNextUrl(searchParams.get(QUERYPARAM.campaign))
          } else {
            const nextMissionUrl = getArticlePathname(nextUrlData.data.data)
            setNextUrl(
              `${nextMissionUrl}?page_uuid=${searchParams.get(QUERYPARAM.page)}&index=${parseInt(searchParams.get("index")) + 1}&campaign=${searchParams.get("campaign")}${data?.data?.result?.data?.point ? `&${QUERYPARAM.point}=true` : ""}`
            )
          }
        }
        setIsStart(false)
        setPoint(data?.data?.result?.data?.point)
        if (data) {
          datalayer.push({
            event: "general_event",
            event_name: "page_reached_finish_on_puzzle",
            feature: "simple engagement",
            engagement_type: "games",
            engagement_name: "puzzle",
            level: level,
            campaign_name: campaign.toLowerCase(), // e.g all: ;
            event_label: `puzzle - ${props?.engagement_title.toLowerCase()}`,
            content_id: engagement_uuid,
          })
        }
        handlePushQuery(
          false,
          [QUERYPARAM.point, true],
          [QUERYPARAM.puzzlePopUp, true]
        )
      } else {
        setPoint(data?.data?.result?.data?.point)
        handlePushQuery(false, [QUERYPARAM.point, true])
      }
    } catch (err) {
      console.log("[Error]", err)
    }
  }

  const handleGetConfirmation = async () => {
    console.log(level, "level here")
    datalayer.push({
      event: "general_event",
      event_name: "click_submit_puzzle",
      feature: "simple engagement",
      engagement_type: "games",
      engagement_name: "puzzle",
      level: level,
      campaign_name: campaign.toLowerCase(), // e.g all: ;
      event_label: `puzzle - ${props?.engagement_title.toLowerCase()}`,
      content_id: engagement_uuid,
    })
    try {
      const { data } = await ClientApiList.getEngagementPuzzleConfirmation({
        engagement_uuid,
        status: statusEnd,
        time: seconds,
        level,
      })

      setPopupData({ ...data.data.data })
      if (statusEnd === "completed") {
        await handleSubmitPuzzle(data.data.data.is_next_level)
        if (data.data.data.is_next_level) {
          setIsShowingLevelUpPopup(true)
          setLevel((prev) => prev + 1)
        }
      }
    } catch (err) {
      console.log("[Error]", err)
    }
  }

  useEffect(() => {
    if (statusEnd === "completed" || statusEnd === "timeout")
      handleGetConfirmation()
    // eslint-disable-next-line
  }, [statusEnd])

  useEffect(() => {
    setHideRule(searchParams.get("hide-puzzle-rule") === "true")
    setIsEnd(searchParams.get("show-puzzle-popup") === "true")
  }, [searchParams])

  useEffect(() => {
    if (type_of_slice && init.current) {
      setPuzzle(getPuzzle(type_of_slice))
      init.current = false
    } else if (type_of_slice) {
      resetPuzzle()
    }

    return () => (levelRef.current = 1)
  }, [type_of_slice, levelCurrent])

  useEffect(() => {
    setSeconds(total_time)
  }, [total_time, searchParams])

  const resetPuzzle = () => {
    setTimeout(() => {
      setComplete(false)
      setPuzzle(getPuzzle())
      setMoves(0)
      setSeconds(total_time)
      setIsStart(true)
      setIsShowingLevelUpPopup(false)
      setStatusEnd("")
      levelRef.current = level
    }, 500)

    handleFilterQuery(QUERYPARAM.puzzlePopUp)
  }

  const sourceImg = isMobile ? mobile_puzzle_img : desktop_puzzle_img

  return (
    <>
      <PopupPuzzle
        isShowing={isEnd && !isExited}
        point={point}
        handleShowPopupExit={setIsExited}
        resetPuzzle={resetPuzzle}
        status={statusEnd}
        popupData={popupData}
        nextUrl={nextUrl}
      />
      <PopupPuzzleUp
        isShowing={isShowingLevelUpPopup}
        handleIsShowing={setIsShowingLevelUpPopup}
        popupData={popupData}
        level={levelRef.current}
        point={point}
        handleLevelUp={handleLevelUp}
        setPoint={setPoint}
      />
      <PopupPuzzleCloseConfirmation
        isShowing={isExited}
        handleIsShowing={setIsExited}
        isPaused={isPaused}
        setIsPaused={setIsPaused}
        resetPuzzle={resetPuzzle}
      />
      <motion.div
        key="animation-on-state"
        variants={variants}
        animate={hideRule ? "show" : "hide"}
        className="py-[15px] md:pt-[30px] md:pb-[42px] px-[10px] text-white relative"
      >
        <Image
          alt="cover"
          fill
          style={{ objectFit: "cover" }}
          src={isMobile ? props.mobile_img_url_bg : props.desktop_img_url_bg}
          className="z-[0] absolute inset-0 w-[100%]"
        />
        <PuzzlePil
          handleOpenPopup={setOpenPopup}
          handleIsExited={setIsExited}
          time_label={time_label}
          time_need={seconds}
          setIsPaused={setIsPaused}
          level={levelRef.current}
        />
        <div className="grid w-[355px] gap-[2px] h-[355px] bg-black mb-[80px] md:mb-[60px] mx-auto relative z-[1]">
          {puzzle.map((row, i) => (
            <div
              key={i}
              style={{
                display: "flex",
                gap: "2px",
              }}
            >
              {row.map((col, j) => {
                const color = col === 0 ? "transparent" : "lightgray"
                return (
                  <div
                    key={`${i}-${j}`}
                    onClick={() => movePiece(i, j)}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexGrow: 1,
                      backgroundColor: color,
                      borderRadius: 5,
                      cursor: complete ? "not-allowed" : "pointer",
                      userSelect: "none",
                      position: "relative",
                    }}
                  >
                    {col !== 0 ? (
                      <div className="absolute inset-0">
                        {sourceImg ? (
                          <Image
                            alt="cover"
                            layout="fill"
                            style={{ objectFit: "contain" }}
                            src={sourceImg[col - 1]}
                          />
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                )
              })}
            </div>
          ))}
        </div>
        <ButtonAction
          onClick={() => {
            setIsStart(false)
            setStatusEnd("completed")
          }}
          disabled={!complete}
          className="max-w-[375px] mx-auto relative z-[1]"
          intent={complete ? "primary" : "primary_disable"}
        >
          {cta_label_puzzle}
        </ButtonAction>
      </motion.div>
      <InfoPopup
        isOpen={openPopup}
        onClose={() => {
          setOpenPopup(false)
          setIsPaused(false)
        }}
      >
        <p
          className={clsx(
            "w-full font-font-family-3 font-bold text-2xl text-text-2 px-[20px] border-b border-cta-4",
            isMobile ? "py-[10px] mt-[10px]" : "py-[15px]"
          )}
        >
          INFORMASI
        </p>
        <div
          className={clsx(
            "w-full font-font-family-5 text-sm text-text-2 px-[20px]",
            isMobile ? "pb-[30px] pt-[20px]" : "py-[30px]"
          )}
        >
          <>
            <p
              className={clsx(
                "text-text-2 font-font-family-7 font-bold",
                isMobile ? "text-base" : "text-[20px] leading-[28px]"
              )}
            >
              Game&apos;s Rules
            </p>
            <div
              className={clsx(
                "flex gap-[5px] items-center justify-center lg:min-h-[145px] lg:justify-start",
                isMobile ? "my-[10px]" : "my-[10px] ml-[60px]"
              )}
            >
              <Image
                alt="cover"
                objectFit="contain"
                className="!object-top relative inset-0 w-[100%] h-[100%] max-w-[332px] max-h-[145px]"
                src={`/assets/${process.env.NEXT_PUBLIC_NAME}/engagement/puzzle.png`}
              />
            </div>
            <CampaignDescription
              isList
              className="mb-0 md:mb-0 lg:text-[20px] leading-[20px] lg:leading-[28px]"
            >
              {props.instruction}
            </CampaignDescription>
          </>
        </div>
      </InfoPopup>
    </>
  )
}

export function Puzzle(props) {
  return (
    <Suspense fallback={<PuzzleFallback />}>
      <SuspendedPuzzle {...props} />
    </Suspense>
  )
}

SuspendedPuzzle.propTypes = {
  time_label: PropTypes.string,
  cta_label_puzzle: PropTypes.string,
  engagement_puzzle_imgs: PropTypes.array,
  engagement_uuid: PropTypes.string,
  campaign: PropTypes.string,
  total_time: PropTypes.number,
  props: PropTypes.object,
}
