"use client"
import { ClientApiList } from "@/lib/api/client-service"
import IntroIqosPage from "../iqos/intro-iqos-page"
import PropTypes from "prop-types"
import { useEffect, useRef } from "react"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { useRouter } from "next/navigation"

export function IqosCcBridging(props) {
  const { data } = props
  const { isLogin, isLoading } = useIsLogin()
  const init = useRef(true)
  const { replace } = useRouter()

  const handlePostBridging = async () => {
    try {
      ClientApiList.postBridgingLog({
        uuid: props.uuid,
      })
    } catch (err) {
      if (err.status === 401) window.location.reload()
      console.log(err)
    }
  }

  useEffect(() => {
    if (init.current && !isLoading && isLogin) {
      handlePostBridging()
      init.current = false
    }

    return () => (init.current = true)
  }, [isLoading, isLogin])

  return <IntroIqosPage data={data} />
}

IqosCcBridging.propTypes = {
  data: PropTypes.object,
}
