"use client"

import { useEffect, useState } from "react"
import { Puzzle } from "../puzzle/puzzle"
import { PuzzleIntroComp } from "../puzzle/puzzle-intro"
import { ClientApiList } from "@/lib/api/client-service"

export function PuzzleIntro({ engagement_uuid, type, data, ...props }) {
  const [isLoading, setIsLoading] = useState(true)
  const [dataIntro, setDataIntro] = useState({
    ...data,
    cta_label: "Mulai Sekarang",
  })
  const [dataPuzzle, setDataPuzzle] = useState({
    time_label: props.time_label || "Sisa Waktu",
    // time_need: props.time_need || 180,
    cta_label_puzzle: props.cta_label_puzzle || "Submit",
    // href_puzzle: props.href_puzzle,
    // time_left_img_url: props.time_left_img_url,
    // time_right_img_url: props.time_right_img_url,
    // puzzle_img: props.puzzle_img,
    // total_time: 180
  })
  const [finished, setFinished] = useState(0)

  const getPuzzleData = async () => {
    try {
      const { data } = await ClientApiList.getEngagementPuzzleDetail({
        engagement_uuid,
        type,
      })
      console.log(data, "puzzle")
      setDataPuzzle({
        ...dataPuzzle,
        ...data.data.data,
        ...data.data.data.puzzle,
      })
      setFinished(data?.data.data.finished)
      setIsLoading(false)
    } catch (err) {
      console.log("Error", err)
    }
  }

  useEffect(() => {
    getPuzzleData()
  }, [])

  return (
    <div>
      {/* <PuzzleIntroComp
        {...dataIntro}
        finished={finished}
        isLoading={isLoading}
        isTNC={dataPuzzle.is_tnc}
        tncValue={dataPuzzle.is_tnc_checked}
      /> */}
      <Puzzle {...dataPuzzle} campaign={dataIntro.campaign_name} />
    </div>
  )
}
