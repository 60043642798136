"use client"
import Link from "@/components/shared/link/link"
import React from "react"
import PropTypes from "prop-types"
import { cn } from "@/lib/utils"
import { RemoveScrollBar } from "react-remove-scroll-bar"
export default function PopupAction({
  children,
  href = "#",
  isShowing,
  showCloseBtn = true,
  className = "",
  zIndex = "z-[4]",
  maxWidth = "lg:max-w-[364px]",
  padding = "p-[25px]",
  onClickBtnX = () => {},
  closeHandle,
  isScroll = true,
  isFullImage = false,
}) {
  const defaultClass =
    "relative top-2/4 -translate-y-1/2 lg:w-full text-center mx-auto max-w-[354px]"

  const wrapperClass = cn(
    isFullImage
      ? defaultClass
      : `${defaultClass} bg-bg-3 rounded-[15px] mb-[20px]`,
    `${padding} ${maxWidth} ${className}`
  )

  return (
    <>
      {isShowing ? (
        <div
          className={`fixed top-[0] backdrop-blur-[5px] bg-black/[.75] bottom-0 right-0 left-0 ${zIndex}`}
          onClick={closeHandle}
        >
          <RemoveScrollBar />
          <div className="h-[calc(100dvh-56px)] mx-[10px]">
            <div className={wrapperClass}>
              {showCloseBtn ? (
                <Link href={href} replace scroll={isScroll}>
                  <button
                    className="absolute top-[-53px] right-0"
                    onClick={() => onClickBtnX()}
                  >
                    <CloseIcon />
                  </button>
                </Link>
              ) : null}
              {children}
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

PopupAction.propTypes = {
  children: PropTypes.array,
  href: PropTypes.object,
  isShowing: PropTypes.bool,
  showCloseBtn: PropTypes.bool,
  className: PropTypes.string,
  zIndex: PropTypes.string,
  onClickBtnX: PropTypes.func,
  maxWidth: PropTypes.string,
  padding: PropTypes.string,
  closeHandle: PropTypes.func,
  isScroll: PropTypes.bool,
  isFullImage: PropTypes.bool,
}

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
  >
    <circle
      cx="24"
      cy="24"
      r="19.75"
      fill="url(#paint0_linear_4840_16586)"
      stroke="url(#paint1_linear_4840_16586)"
      strokeWidth="0.5"
    />
    <path
      d="M16.3891 16.4237C16.6386 16.1751 16.9768 16.0354 17.3294 16.0354C17.682 16.0354 18.0202 16.1751 18.2697 16.4237L23.9823 22.1243L29.6948 16.4237C29.8575 16.2489 30.0643 16.1212 30.2936 16.0539C30.5229 15.9866 30.7662 15.9822 30.9977 16.0413C31.2293 16.1004 31.4406 16.2207 31.6094 16.3896C31.7782 16.5585 31.8982 16.7696 31.9568 17.0009C32.0161 17.2317 32.012 17.4742 31.9448 17.7028C31.8777 17.9315 31.7501 18.1379 31.5754 18.3003L25.8628 24.0009L31.5754 29.7014C31.7505 29.8637 31.8786 30.0701 31.946 30.2989C32.0134 30.5278 32.0178 30.7705 31.9586 31.0016C31.8994 31.2326 31.7788 31.4435 31.6096 31.6119C31.4404 31.7804 31.2287 31.9001 30.997 31.9586C30.7657 32.0178 30.5227 32.0136 30.2936 31.9467C30.0644 31.8797 29.8576 31.7523 29.6948 31.578L23.9823 25.8774L18.2697 31.578C18.0173 31.8124 17.6837 31.94 17.3389 31.9341C16.9942 31.9282 16.6652 31.7891 16.4211 31.5461C16.1776 31.3025 16.0382 30.9742 16.0323 30.6302C16.0263 30.2862 16.1542 29.9533 16.3891 29.7014L22.1017 24.0009L16.3891 18.3003C16.14 18.0513 16 17.7139 16 17.362C16 17.0102 16.14 16.6727 16.3891 16.4237Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4840_16586"
        x1="50.5"
        y1="-1.5"
        x2="13.5833"
        y2="41.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.302701" stopColor="white" stopOpacity="0.471684" />
        <stop offset="1" stopColor="white" stopOpacity="0.1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4840_16586"
        x1="6.5"
        y1="41"
        x2="41"
        y2="6.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="0.735223" stopColor="white" stopOpacity="0.2" />
        <stop offset="1" stopColor="white" stopOpacity="0.55" />
      </linearGradient>
    </defs>
  </svg>
)
