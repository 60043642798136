"use client"
import clsx from "clsx"
import { Description } from "../shared/description/description"
import { useIsMobile } from "@/hooks/use-is-mobile"
import PropTypes from "prop-types"

export function AspaceSyaratKetentuan(props) {
  const isMobile = useIsMobile()

  const description = [
    {
      title: "A. Persyaratan Peserta mengikuti Program:",
      desc: "<p>Peserta wajib memenuhi persyaratan Program sebagai berikut:</p><br/><ul><li>Peserta merupakan karyawan PT HM Sampoerna, warga negara Indonesia dan bertempat tinggal di wilayah Indonesia.<li>Peserta adalah perokok dewasa berusia minimal 21 tahun, dan memiliki identitas berupa KTP yang masih berlaku.</li><li>Peserta bukan merupakan LAU (legal age user) / pengguna IQOS.</li><li>Terdaftar dan memiliki akun pada website www.amild.id.</li><li>Peserta dilarang untuk membagikan pengalaman keikutsertaan program ini di media sosial dalam bentuk apapun.</li><li>Bersedia memenuhi seluruh ketentuan dalam Syarat & Ketentuan Program serta Kebijakan Privasi yang berlaku.</li><li>Program ini berlaku di seluruh Indonesia sesuai dengan periode tertera.</li></ul>",
    },
    {
      title: "B. Periode Program:",
      desc: "<ul><li>Program ini dimulai sejak 6 Mei dan berakhir pada 30 Juni 2024 (“Periode Program”).</li></ul>",
    },
    {
      title: "C. Mekanisme Program:",
      desc: "<ul><li>Peserta hanya bisa mengakses program melalui QR/ link yang diberikan melalui internal email blast.</li><li>Peserta wajib terdaftar dan memiliki akun pada website www.amild.id.</li><li>Peserta wajib mengikuti seluruh kegiatan di dalam A Space untuk bisa mengumpulkan poin sebanyak-banyaknya.</li><li>Poin yang dihasilkan melalui akses langsung ke website www.amild.id tanpa melaui QR atau link yang telah ditentukan tidak akan dihitung dalam program ini.</li></ul>",
    },
    {
      title: "D. Hadiah Program:",
      desc: "<p>20 peserta terpilih yang sudah terdaftar dan mengikuti A Space Internal Program akan mendapatkan kesempatan untuk mendapatkan exclusive merchandise setiap bulannya.</p>",
    },
    {
      title: "Ketentuan Peserta terpilih:",
      desc: "<ul><li>Penentuan peserta terpilih dilakukan berdasarkan 45 peserta yang berhasil mencapai top 45 dari A Space Internal Program Leaderboard.</li><li>Pengumuman hadiah akan diumumkan di awal bulan Juni dan Juli.</li><li>Peserta terpilih akan mendapatkan e-mail konfirmasi dari What’s on Sampoerna A dan akan dihubungi oleh penyelenggara.</li><li>Reward tidak dapat dipindahtangankan, tidak untuk diperjual-belikan, dan/atau tidak dapat ditukarkan dengan uang tunai atau dengan apapun ke Penyelenggara, baik secara keseluruhan atau sebagian.</li><li>Peserta terpilih dinyatakan didiskualifikasi apabila peserta tidak dapat dihubungi oleh Penyelenggara untuk keperluan verifikasi dalam jangka waktu 2x24 jam sejak pengumuman Sehubungan dengan hal ini, Peserta dengan ini setuju untuk melepaskan haknya sebagai Peserta terpilih untuk menerima reward dan akan membebaskan Penyelenggara dari klaim dan/atau tuntutan penyerahan reward dalam bentuk apapun di masa mendatang.</li><li>Penyelenggara berhak melakukan verifikasi dan/atau pengecekan validasi data Peserta serta: (i) mendiskualifikasi Peserta atau Peserta terpilih ; dan/atau (ii) menolak atau membatalkan pemberian reward, apabila Peserta terpilih diduga melakukan kecurangan atau pelanggaran Syarat dan Ketentuan ini dalam bentuk apapun.</li><li>Keputusan Penyelenggara bersifat mutlak dan final sehingga tidak dapat diganggu gugat.</li></ul>",
    },
    {
      title: "Ketentuan Umum:",
      desc: "<ul><li>Penyelenggara berhak untuk mengubah tanggal, mekanisme, reward dan/atau Syarat dan Ketentuan atas Program sepanjang konsep dan nilainya kurang lebih sama dengan yang diuraikan saat ini, tanpa pemberitahuan sebelumnya atau kompensasi dalam bentuk apapun kepada para Peserta dan/atau pihak manapun. Dalam hal terjadi peristiwa keadaan memaksa <em></em>(force majeure) atau pertimbangan material lainnya, Penyelenggara dapat membatalkan penyelenggaraan Program tanpa kewajiban untuk memberikan pemberitahuan dan/atau kompensasi kepada pihak manapun.</li><li>Penyelenggara tidak akan bertanggung jawab apabila terjadi penipuan dan/atau tindak pidana lainnya yang mengatasnamakan Program ini. Untuk itu, Peserta diminta untuk berhati-hati dan waspada terhadap modus penipuan dan/atau tindak pidana lainnya yang mungkin akan mengatasnamakan Program ini serta selalu memastikan berhubungan dengan pihak Penyelenggara yang sah terkait Program.</li><li>Setiap Peserta yang mengikuti Program ini menjamin bahwa setiap informasi yang diberikan adalah benar, tepat dan akurat selama periode Program.</li></ul>",
    },
  ]

  return (
    <div
      className={clsx(
        "w-full max-w-[460px] mx-auto relative pt-[30px] ",
        isMobile && "px-3.5"
      )}
    >
      <p className="font-bold font-font-family-7 text-2xl text-text-2 capitalize">
        Syarat & Ketentuan
      </p>
      <p
        className={clsx(
          "font-medium font-font-family-6 mt-[10px] text-text-2 text-base"
        )}
      >
        PENTING: Harap baca syarat dan ketentuan ini (“Syarat & Ketentuan”)
        sebelum mengikuti program ini. Dengan mengikuti Program ini, anda
        mengkonfirmasi bahwa anda telah membaca, memahami dan setuju untuk
        mematuhi Syarat dan Ketentuan termasuk perubahannya yang dibuat oleh
        pihak Penyelenggara dari waktu ke waktu.
      </p>
      <p
        className={clsx(
          "font-medium font-font-family-6 mt-[10px] mb-[20px] text-text-2 text-base"
        )}
      >
        A Space Internal Program adalah program yang ditujukan kepada karyawan
        internal PT HM Sampoerna Tbk. (“Sampoerna”) yang merupakan perokok
        dewasa berusia 21 tahun ke atas, dan bukan merupakan pengguna IQOS.
      </p>
      <div className="flex flex-col gap-[20px]">
        {description.map((item, i) => (
          <div key={i}>
            <p className="font-bold font-font-family-7 text-base text-text-2">
              {item.title}
            </p>
            <Description
              className="text-sm my-[5px] text-text-2 px-[5px]"
              description={item.desc}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

AspaceSyaratKetentuan.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  subtitle: PropTypes.string,
}
