"use client"
import DOMPurify from "isomorphic-dompurify"
import Style from "./description-article.module.css"
import PropTypes from "prop-types"

export const DescriptionArticle = ({ className, description, ...props }) => {
  return (
    <section className={Style.wrapper} {...props}>
      <div
        data-testid="description"
        className={className}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
      />
    </section>
  )
}

DescriptionArticle.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
}
