"use client"
import { Image } from "@/components/shared/image/image"
import React from "react"
import { twMerge } from "tailwind-merge"

export default function LeaderboardTopUserExp({ children, className }) {
  return (
    <div
      className={twMerge(
        "relative z-[1] order-3 text-[12px] md:text-[16px] font-bold font-font-family-7 leading-[16px] md:leading-[22px] flex items-center gap-[2px] text-text-2",
        className
      )}
    >
      <div className="w-[16px] md:w-[22px] h-[16px] md:h-[22px] bg-transparent">
        <Image
          alt={children}
          src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/reward-profile.svg`}
        />
      </div>
      <span>{children}</span>
    </div>
  )
}
