"use client"
import React from "react"
import { Image } from "@/components/shared/image/image"
import { formatedDate, formatedDateNoDay } from "@/helper/utils"
import { formatNumber } from "@/components/edit-profile/date-input/helper"

export default function VoucherCard({
  title,
  validDate,
  exp,
  expIcon,
  bgImg,
  id,
  handleClaim,
}) {
  return (
    // <div onClick={() => handleClaim(id, true)} role='button' tabIndex={0} className="cursor-pointer flex items-end h-[162px] lg:h-[180px] !w-[274px] w-full lg:!w-[304px] bg-[#D9D9D9] rounded-[5px] relative overflow-hidden">
    //     <Image
    //         className='absolute'
    //         alt='cover'
    //         src={bgImg}
    //     />
    //     <div className="p-[10px] relative z-[2] text-white">
    //         <div className="text-[16px] font-bold font-font-family-7 mb-[5px] leading-[22px]">{title}</div>
    //         <div className="flex items-center gap-[10px]">
    //             <div className="p-[5px] font-bold font-font-family-7  rounded-[5px] text-cta-1 leading-[16px] flex items-center gap-[5px]">
    //                 <div className="w-[16px] h-[16px] relative overflow-hidden">
    //                     <Image
    //                         alt='cover'
    //                         src='/assets/amild/icons/reward-profile.svg'
    //                     />
    //                 </div>
    //                 <div>{exp} Points</div>
    //             </div>
    //             <div className="text-[12px] font-medium font-font-family-6 leading-[26px]">Valid Until {formatedDateNoDay(validDate)}</div>
    //         </div>
    //     </div>
    // </div>
    <div
      onKeyDown={() => {
        // Todo: Add keydown event
      }}
      onClick={() => handleClaim(id, true)}
      role="button"
      tabIndex={0}
      className="cursor-pointer border-[1px] flex flex-col border-cta-4 p-[5px] rounded-[10px] bg-bg-3 w-[100vw] h-[100%] max-w-[228px]"
    >
      <div className="w-[100vw] h-[100vh] relative rounded-[5px] max-w-[218px] max-h-[109px] min-h-[109px] overflow-hidden mb-[5px]">
        <Image
          data-testid="voucher-card-bg-img"
          className="absolute aspect-[2/1]"
          alt="cover"
          src={bgImg}
        />
      </div>
      <div className="p-[5px] pb-[10px] grow-[1] flex flex-col">
        <div
          data-testid="voucher-card-title"
          className="text-[16px] font-medium font-font-family-6 mb-[5px] leading-[21px] text-text-2"
        >
          {title}
        </div>
        <div
          data-testid="voucher-card-valid-date"
          className="text-[14px] tracking-[0.25px] font-medium font-font-family-6 leading-[19px] mb-[20px] text-text-3"
        >
          Valid Until {formatedDateNoDay(validDate)}
        </div>
        <div className="mt-auto font-bold font-font-family-7 text-cta-1 text-[14px] leading-[19px] flex items-center gap-[5px] ">
          <div className="w-[16px] h-[16px] relative overflow-hidden">
            <Image
              alt="cover"
              src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/reward-profile.svg`}
            />
          </div>
          <div data-testid="voucher-card-points" className="text-text-1">
            {formatNumber(exp)} Poin
          </div>
        </div>
      </div>
    </div>
  )
}
