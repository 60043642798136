'use client';
// import { usePrevPath } from '@/context/app/prev-path/prev-path-ctx';
// import { useIsLogin } from '@/hooks/auth/use-is-login';
// import { createAppContext } from '@/lib/context/create-context';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PrevPath } from '../use-prev-path-ctx';
// import { createAppContext } from '../create-context';
import { useIsLogin } from './use-is-login';
import { getQueryParams } from '@/helper/queryparam/get-query-params';

const Login = createContext();
// const [Provider, useLoginPopup] = createAppContext('login');

export const LoginPopupProvider = ({ children }) => {
    // const searchParams = useSearchParams();
    const pathname = usePathname();
    const [closePath, setClosePath] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [platform, setPlatform] = useState(null);
    const { prevPath } = useContext(PrevPath);

    // const { isLogin, isLoading: loginLoading } = useIsLogin();
    const router = useRouter();
    const toParams = getQueryParams('to');
    const open = useCallback(
        ({ closePath, platform } = {}) => {

            // console.log(!isLogin && !loginLoading && !searchParams.get('logout'), 'setOpen', !searchParams.get('logout'), !loginLoading, !isLogin);

            // if (!isLogin && !loginLoading && !searchParams.get('logout')) {
            if (!getQueryParams('logout')) {
                if (closePath) setClosePath(closePath);
                setIsOpen(true);
                setPlatform(platform);
            }
        },
        []
    );

    const close = useCallback(() => {
        if (closePath) {
            router.replace(closePath);
            setClosePath(null);
        }

        if (getQueryParams('own_section')) {
            router.replace(`${pathname}#${router.query.own_section}`);
        }

        if (getQueryParams('to') === 'login' && prevPath) {
            // router.replace(prevPath, undefined, {
            //     shallow: true,
            // });
            router.replace(prevPath);
        } else if (getQueryParams('to') === 'login') {
            router.replace(pathname);
        }

        setPlatform(null);
        setIsOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closePath, getQueryParams]);

    useEffect(() => {
        if (toParams === 'login') open();
    }, [open, toParams]);

    const memoizedValue = useMemo(
        () => ({
            isOpen,
            close,
            open,
            platform,
        }),
        [close, isOpen, open, platform]
    );

    return <Login.Provider value={{ ...memoizedValue }}>{children}</Login.Provider>;
};

export { Login };

export const useShowLoginPopup = ({
    showWhen,
    closePath,
    onOpen = () => { },
    platform,
}) => {
    // const { isLogin } = useIsLogin();
    // const [showed, setShowed] = useState(false);
    const { open } = useContext(Login);

    // console.log(is 'isLogin');

    useEffect(() => {
        // console.log(showWhen && 'showwhen', showWhen,);

        if (showWhen) {
            open({ closePath, platform });
            // setShowed(true);
            onOpen();
        }
    }, [showWhen, open, closePath,]);
};
