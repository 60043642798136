"use client";
import React from "react";
import LeaderboardUser from "../leaderboard-user/leaderboard-user";
import { Image } from "@/components/shared/image/image";

export default function RestUserList({
  restUserList = [],
  userPos,
  isLoading,
}) {
  const renderItems = () => {
    if (isLoading)
      return [1, 2, 3, 4, 5, 6, 7].map((item, index) => (
        <li data-testid={`rest-user-skeleton-${index}`} key={item}>
          <Skeleton position={index + 4} />
        </li>
      ));
    else
      return restUserList.map((user, index) => (
        <li data-testid={`rest-user-${index}`} key={index}>
          <LeaderboardUser position={index + 4} user={user} userPos={userPos} />
        </li>
      ));
  };
  return (
    <ol className="flex relative z-[1] flex-col gap-[5px] md:gap-[10px] md:max-w-[935px] md:mx-auto max-h-[366px] md:max-h-[550px] overflow-auto mb-[20px] md:mb-[30px]">
      {renderItems()}
    </ol>
  );
}

function Skeleton({ position }) {
  return (
    <div className={`rounded-[10px] lg:rounded-[15px] p-[1px]`}>
      <div
        className={`relative p-[8px] px-[9px] rounded-[10px] lg:rounded-[15px] lg:pl-[18px] lg:pr-[24px] lg:py-[13px] flex items-center text-text-2`}
      >
        <div className="max-w-[28px] lg:max-w-[40px] min-h-[28px] lg:min-h-[40px] flex items-center justify-center rounded-[10px] order-1 lg:p-0 mr-[10px] lg:mr-[20px] border-b-[1px] border-cta-1 bg-bg-3 w-[50%] text-text-2">
          {position}
        </div>
        <div className="grow-[1] h-[16px] lg:h-[22px] order-3 min-w-[114px] max-w-[200px] animate-pulse bg-[#D9D9D9] mr-auto"></div>
        <div className="min-w-[103.62px] text-[14px] lg:text-[16px] leading-[19px] lg:leading-[22px] font-bold font-font-family-7 order-4 text-text-2 flex jutify start items-center gap-[2px]">
          <div className="bg-bg-1 w-[16px] h-[16px] relative">
            <Image
              className="absolute inset-0"
              alt={0}
              src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/reward-profile.svg`}
            />
          </div>
          <span>{0} Poin</span>
        </div>
        <div className=" min-h-[24px] lg:h-[32px] order-2 min-w-[24px] lg:min-w-[32px] mr-[5px] lg:mr-[15px] rounded-[50%] flex items-center gap-[2px] justify-center">
          <div className="w-[30px] lg:w-[38px] h-[30px] lg:h-[38px] relative flex items-center justify-center">
          <Image className='absolute top-[2px] inset-0 object-center'  src={`/assets/${process.env.NEXT_PUBLIC_NAME}/user-profile/profile-sm.svg`} alt='avatar' />
          </div>
        </div>
      </div>
    </div>
  );
}
