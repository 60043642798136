"use client";
import React from 'react';
import { twMerge } from 'tailwind-merge';

export default function ProgressBar({ current = 2, total = 10, className, bgprogress = '', bgpill = '' }) {
    const calcWidth = (current) / total * 100;

    return (
        <div className={twMerge("relative bg-cta-4 w-[100%] h-[4px] rounded-[2.5px] overflow-hidden", bgprogress, className)}>
            <div style={{ width: calcWidth === 0 ? 4 : `${calcWidth}%`, background:'var(--cta_1)' }} className={twMerge(`absolute top-0 bottom-0 bg-cta-1 w-[${calcWidth}%] rounded-[2.5px]`, bgpill)}></div>
        </div>
    );
}
