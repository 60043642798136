"use client"
import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Image } from "@/components/shared/image/image"
import { useIsDesktop } from "@/hooks/use-is-mobile"
import DOMPurify from "isomorphic-dompurify"

// swiper imports
import { SwiperSlide } from "swiper/react"
import "swiper/css"
import Styles from "./campaign-challenge.module.css"

// components imports
import MissionProgress from "../campaign-intro/mission-progress/mission-progress"
import MissionCard from "../campaign-intro/mission-card/mission-card"
import CampaignTitle from "../campaign-intro/campaign-card/components/campaign-title"
import CampaignDescription from "../campaign-intro/campaign-card/components/campaign-description"
import CampaignCard from "../campaign-intro/campaign-card/campaign-card"
import { SwiperComp } from "../swiper/swiper"
import { getPublishDate, useDatalayer } from "@/hooks/use-datalayer"
import { ClientApiList } from "@/lib/api/client-service"
import { usePathname } from "next/navigation"
import { cn } from "@/lib/utils"

export function CampaignChallenge({ component_props, api_config, isShort }) {
  const pathname = usePathname()
  const swiperRef = useRef(null)
  const isDesktop = useIsDesktop()
  const datalayer = useDatalayer()
  const [isLoading, setIsLoading] = useState(true)
  const [challengeData, setChallengeData] = useState()
  const [activeIndex, setActiveIndex] = useState(0)

  const campaign = pathname.split("/")[1].split("-").join(" ")

  const itemsGap = isDesktop ? 8 : 5

  const handleData = async () => {
    try {
      const { data } = await ClientApiList.getMissionList(api_config)
      const mappedChallenges = [...data.data.data.mission_list].map(
        (challenge, index) => {
          return {
            ...challenge,
            page_uuid: api_config.page_uuid,
            pos: index,
          }
        }
      )
      const completed = [
        ...mappedChallenges.filter((challenge) => challenge.is_completed),
      ]
      const rest = [
        ...mappedChallenges.filter((challenge) => !challenge.is_completed),
      ]
      // console.log(data, "daat challenge")
      setChallengeData({
        ...data.data.data,
        mission_list: [...rest, ...completed],
      })
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const handleDataLayer = (type, category, pos, name, date) => {
    datalayer.push({
      event: "general_event",
      event_name: "click_content",
      feature: "hero engagement page",
      content_section: "campaign page",
      content_brand: "branded", // e.g branded or unbranded
      content_type: type, // e.g article, carousel, video, etc
      content_category: category, // e.g music, travel, automotive, etc
      content_position: pos, // e.g 1, 2, 3, etc (integer)
      content_name: name, // e.g the_ultimate_xperience, etc
      publish_date: getPublishDate(date), // e.g 23-10-2023 (date of content publish)
      campaign_name: campaign, // e.g ride your flavour 2023
      engagement_type: "content",
    })
  }

  const handleRenderList = () => {
    if (isLoading)
      return [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
        <SwiperSlide
          style={{ width: "fit-content", minWidth: "185px" }}
          key={index}
        >
          <Skeleton />
        </SwiperSlide>
      ))
    else
      return challengeData?.mission_list.map((item, index) => (
        <SwiperSlide
          style={{ width: "fit-content", minWidth: "185px" }}
          key={index}
        >
          <MissionCard
            // pos={index + 1}
            handleDataLayer={handleDataLayer}
            item={item}
          />
        </SwiperSlide>
      ))
  }

  useEffect(() => {
    if (pathname) {
      handleData()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pathname])

  const handleNextSlide = () => {
    if (activeIndex < challengeData?.mission_list.length) {
      swiperRef?.current?.slideNext()
      setActiveIndex((prevState) => prevState + 1)
    }
  }
  const handlePrevSlide = () => {
    if (activeIndex !== 0) {
      swiperRef?.current?.slidePrev()
      setActiveIndex((prevState) => prevState - 1)
    }
  }

  const showButton = swiperRef.current
    ? swiperRef.current?.offsetWidth < challengeData?.mission_list.length * 193
    : 0

  return (
    <div
      className={cn(
        "mb-[20px] lg:mb-0 relative rounded-[10px] md:rounded-[15px] overflow-hidden grow-[1]",
        component_props.bg_img_url_mobile ? "bg-transparent" : "bg-bg-3"
      )}
    >
      {component_props.bg_img_url_desktop_short ||
      component_props.bg_img_url_desktop_long ||
      component_props.bg_img_url_mobile ? (
        <Image
          className="absolute w-[100%] h-[100%] z-[1]"
          src={
            isDesktop
              ? isShort
                ? component_props.bg_img_url_desktop_short
                : component_props.bg_img_url_desktop_long
              : component_props.bg_img_url_mobile
          }
          alt="bg"
        />
      ) : null}
      <CampaignCard
        wrapperClass="lg:!h-fit"
        className={cn(
          `w-full md:!p-[20px] lg:col-span-[577px] lg:m-[0] lg:p-[25px] relative lg:max-h-[430px] lg:overflow-y-scroll bg-transparent z-[2] ${Styles.wrapper}`,
          component_props.bg_img_url_mobile ? "!border-transparent" : ""
        )}
      >
        <CampaignTitle
          className={cn(
            "relative z-[1]",
            process.env.NEXT_PUBLIC_NAME === "amild"
              ? "font-font-family-3"
              : "font-font-family-7"
          )}
        >
          {process.env.NEXT_PUBLIC_NAME === "amild"
            ? String(component_props.title).toUpperCase()
            : component_props.title}
        </CampaignTitle>
        <CampaignDescription className="mb-[20px] lg:leading-[25px] lg:mb-[30px] relative z-[1]">
          {component_props.description}
        </CampaignDescription>
        <div
          className={cn(
            "relative z-[1] p-[9px] lg:p-0 border-[1px] rounded-[10px] !border-transparent"
            // component_props.bg_img_url_mobile ? "!border-transparent" : "!border-transparent"
          )}
        >
          <div className="flex items-center mb-[15px] md:mb-[20px] gap-[5px]">
            <div className="w-[20px] h-[20px] relative flex items-center justify-center">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="img-icon">
                  <path
                    id="Vector"
                    d="M10.1406 8.72237L14.4656 13.0124L10.1406 17.3024L11.5486 18.7224L17.3056 13.0124L11.5486 7.30237L10.1406 8.72237Z"
                    className="fill-icon-1"
                  />
                  <path
                    id="Vector_2"
                    d="M6.54862 7.30237L5.14062 8.72237L9.46562 13.0124L5.14062 17.3024L6.54862 18.7224L12.3056 13.0124L6.54862 7.30237Z"
                    className="fill-icon-1"
                  />
                </g>
              </svg>
            </div>
            {!isLoading ? (
              <div
                className={cn(
                  "text-[16px] text-text-2 font-bold font-font-family-7 leading-[22px] lg:text-[24px] lg:leading-[32px]",
                  process.env.NEXT_PUBLIC_NAME === "amild"
                    ? "font-font-family-3"
                    : "font-font-family-7"
                )}
              >
                {process.env.NEXT_PUBLIC_NAME === "amild"
                  ? String(component_props?.instruction).toUpperCase()
                  : component_props?.instruction}
              </div>
            ) : (
              <div className="h-[22px] lg:h-[32px] animate-pulse bg-[#D9D9D9] w-full max-w-[100px]"></div>
            )}
          </div>
          {!isLoading ? (
            <MissionProgress
              flag_img_url={component_props.flag_img_url}
              challengeCompleted={challengeData?.challenge_completed_count}
              challengeTotal={challengeData?.challenge_total_count}
              description={challengeData?.challenge_progress_desc}
            />
          ) : (
            <>
              <div className="flex justify-between items-center mb-[5px] lg:mb-[10px]animate-pulse">
                <div className="h-[16px] lg:h-[25px] w-full max-w-[20%] bg-[#D9D9D9]"></div>
                <div className="h-[16px] lg:h-[25px] w-full max-w-[40px] bg-[#D9D9D9]"></div>
              </div>
              <div className="h-[5px] mb-[15px] lg:mb-[12px] bg-[#D9D9D9] relative w-[100%] h-[4px] rounded-[2.5px] overflow-hidden"></div>
            </>
          )}
          <div className="relative h-[204px]">
            <div className="absolute top-0 bottom-0 left-0 right-0 z-[10]">
              {activeIndex !== 0 ? (
                <div className="absolute z-[10] w-[63px] h-[204px] top-0 bottom-0 left-[-1px]">
                  <button
                    onClick={handlePrevSlide}
                    data-testid="prev-button"
                    className="absolute flex items-center justify-center top-[50%] translate-y-[-50%] z-[10] left-[1px] lg:left-[-16px] w-[32px] h-[32px] rounded-[50%] rotate-180"
                  >
                    <Image
                      src={`/assets/${process.env.NEXT_PUBLIC_NAME}/mission-page/mission-button.svg`}
                      alt="prev"
                      className="relative inset-0"
                    />
                  </button>
                </div>
              ) : null}
              <SwiperComp
                ref={swiperRef}
                slidesPerView={"auto"}
                spaceBetween={itemsGap}
                delay={0}
                speed={100}
              >
                {handleRenderList()}
              </SwiperComp>
              {activeIndex !== challengeData?.mission_list.length - 1 &&
              showButton ? (
                <div className="absolute z-[10] w-[63px] h-[204px] top-0 bottom-0 right-[-1px] lg:right-[-16px]">
                  <button
                    onClick={handleNextSlide}
                    className="absolute flex items-center justify-center top-[50%] translate-y-[-50%] z-[10] right-[1px] w-[32px] h-[32px] rounded-[50%]"
                    data-testid="next-button"
                  >
                    <Image
                      src={`/assets/${process.env.NEXT_PUBLIC_NAME}/mission-page/mission-button.svg`}
                      alt="next"
                      className="relative inset-0"
                    />
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {component_props.tnc_subtitle ? (
          <>
            <div className="mt-[15px] text-[16px] leading-[22px] font-bold font-font-family-7 flex items-center gap-[5px] mb-[10px]">
              <div className="w-[20px] h-[20px] relative">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="img-icon">
                    <path
                      id="Vector"
                      d="M10.1406 8.72237L14.4656 13.0124L10.1406 17.3024L11.5486 18.7224L17.3056 13.0124L11.5486 7.30237L10.1406 8.72237Z"
                      className="fill-icon-1"
                    />
                    <path
                      id="Vector_2"
                      d="M6.54862 7.30237L5.14062 8.72237L9.46562 13.0124L5.14062 17.3024L6.54862 18.7224L12.3056 13.0124L6.54862 7.30237Z"
                      className="fill-icon-1"
                    />
                  </g>
                </svg>
              </div>
              {component_props.tnc_subtitle}
            </div>
            <div
              className="text-[14px] leading-[24px]"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(component_props.tnc_desc),
              }}
            ></div>
          </>
        ) : null}
      </CampaignCard>
    </div>
  )
}

function Skeleton() {
  return (
    <div className="p-[10px] bg-bg-3  h-[178px] w-[100%] max-w-[185px] rounded-[10px] z-[10] overflow-hidden">
      <div className="flex animate-pulse items-center justify-between mb-[10px] gap-[5px]">
        <div className="h-[16px] rounded-[10px] w-[30%] bg-[#D9D9D9]"></div>
        <div className="h-[25px] rounded-[10px] w-[40%] bg-[#D9D9D9]"></div>
      </div>
      <div className="rounded-[10px] animate-pulse overflow-hidden w-[165px] h-[123px] relative bg-[#D9D9D9]"></div>
    </div>
  )
}

CampaignChallenge.propTypes = {
  component_props: PropTypes.object,
  api_config: PropTypes.object,
  isShort: PropTypes.bool,
}
