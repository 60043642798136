/* eslint-disable react-hooks/exhaustive-deps */
import { useIsDesktop, useIsMobile } from "@/hooks/use-is-mobile"
import { cn } from "@/lib/utils"
import clsx from "clsx"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useReducer, useState } from "react"
import { Chevron } from "../sections/bantuan"
import { Description } from "../shared/description/description"
import Style from "../sections/brand-popup.module.css"
import PropTypes from "prop-types"

const iconVariants = {
  open: { rotate: 0 },
  closed: { rotate: -180 },
}

function UserOrigin({ list, setOrigins }) {
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()
  const originLabel = ["Provinsi", "Kota"]
  const [provinceList, setProvinceList] = useState([])
  const [cityList, setCityList] = useState([])
  const [control, setControl] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {}
  )

  useEffect(() => {
    const init = () => {
      const map = originLabel.map((item) => ({
        isOpen: false,
        choice: "",
        label: item,
      }))
      setControl(map.reduce((a, v) => ({ ...a, [v.label]: { ...v } }), {}))

      const newProvince = list.map((item) => item.province_name)
      setProvinceList(newProvince)
    }

    init()
  }, [])

  useEffect(() => {
    setOrigins({
      province: handleValueChoice("Provinsi"),
      city: handleValueChoice("Kota"),
    })
  }, [control])

  const handleOptionList = (origin) => {
    return origin.toLowerCase() === "provinsi" ? provinceList : cityList
  }

  const handleOpener = (origin) => {
    const keys = originLabel.filter((label) => label !== origin)
    const mapped = keys
      .map((key) => ({ ...control[key] }))
      .reduce((a, v) => ({ ...a, [v.label]: { ...v, isOpen: false } }), {})
    return (e) =>
      setControl({ ...mapped, [origin]: { ...control[origin], isOpen: e } })
  }

  const handleChoiceValue = (origin, value) => {
    if (origin.toLowerCase() === "provinsi") {
      handleChoiceValue("Kota", "")
      const province = list.find((item) => item.province_name === value)
      const city = province?.mst_cities.map((item) => item.city_name)
      setCityList(city)
      setOrigins((state) => ({ ...state }))
    }

    setControl({
      [origin]: { ...control[origin], choice: value, isOpen: false },
    })
  }

  const handleValueOpener = (origin) => {
    return control[origin]?.isOpen
  }

  const handleValueChoice = (origin) => {
    return control[origin]?.choice
  }

  const handleDisabled = (origin) => {
    return origin.toLowerCase() === "kota" && !handleValueChoice("Provinsi")
  }

  return (
    <div className="lg:flex flex-wrap justify-center gap-[5px]">
      {originLabel.map((origin, index, arr) => {
        return (
          <div
            key={origin}
            className={cn(
              index !== arr.length - 1 ? "mb-[10px] lg:mb-0" : "",
              "lg:max-w-[170px] lg:grow-[1] lg:flex flex-col justify-between"
            )}
          >
            <p className="text-sm text-left font-black font-font-family-8 text-text-2 mb-[10px] capitalize max-w-[325px] lg:max-w-none mx-auto">
              {origin}
            </p>
            <div className="relative w-full max-w-[325px] lg:max-w-none mx-auto">
              <AnimatePresence>
                <button
                  onClick={() => handleOpener(origin)(!control[origin].isOpen)}
                  disabled={handleDisabled(origin)}
                  className={clsx(
                    "relative w-full lg:min-w-[120px] text-left text-sm font-medium font-font-family-6 flex justify-between items-center border rounded-[10px] capitalize",
                    isMobile ? "p-[10px]" : "p-[15px]",
                    handleValueOpener(origin) ? "border-cta-1" : "border-cta-4",
                    handleDisabled(origin) ? "bg-cta-4" : ""
                  )}
                >
                  <p
                    className={cn(
                      "h-[20px] capitalize",
                      handleValueChoice(origin) === ""
                        ? "italic text-text-3"
                        : "text-text-2 not-italic text-ellipsis overflow-hidden"
                    )}
                  >
                    {handleValueChoice(origin) || `Pilih ${origin}`}
                  </p>
                  <motion.span
                    key="icon"
                    initial={false}
                    animate={handleValueOpener(origin) ? "open" : "closed"}
                    variants={iconVariants}
                    transition={{ duration: 0.2 }}
                  >
                    <Chevron className={"fill-icon-2"} />
                  </motion.span>
                </button>
                {handleValueOpener(origin) && (
                  <motion.div
                    initial={{
                      opacity: 0,
                      y: isDesktop ? -10 : -10,
                    }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: isDesktop ? -10 : -10 }}
                    transition={{ duration: 0.2 }}
                    className={cn(
                      "w-[100%] max-h-[130px] absolute z-[5] top-[47px] lg:top-[57px] border border-cta-1 rounded-[10px] bg-bg-3 overflow-hidden"
                    )}
                  >
                    <div
                      className={cn(
                        "max-h-[130px] overflow-y-scroll flex flex-col gap-[10px] p-[10px]",
                        Style.container
                      )}
                    >
                      {handleOptionList(origin)?.map((item) => (
                        <button
                          key={item}
                          onClick={() => handleChoiceValue(origin, item)}
                        >
                          <Description
                            className="text-ellipsis overflow-hidden lg:max-w-[170px] text-text-2 text-left text-sm capitalize"
                            description={item}
                          />
                        </button>
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        )
      })}
    </div>
  )
}

UserOrigin.propTypes = {
  list: PropTypes.array,
  setOrigins: PropTypes.func,
}

export default UserOrigin
