"use client";
import { Image } from "@/components/shared/image/image";
import { Description } from "../shared/description/description";
import { useState } from "react";
import { cva } from "class-variance-authority";
import { motion, AnimatePresence } from "framer-motion";
import { useIsMobile } from "@/hooks/use-is-mobile";
import clsx from "clsx";
import PropTypes from "prop-types";
import { ClientApiList } from "@/lib/api/client-service";
import { Spinner } from "../shared/spinner";
import { useIsLogin } from "@/hooks/auth/use-is-login";

export function Bantuan(props) {
  const {
    title,
    description,
    subcategory = {},
    category = {},
    help_info = {},
  } = props;
  const isMobile = useIsMobile();
  const { isLogin } = useIsLogin();

  const [list, setList] = useState(subcategory?.categories);
  const [search, setSearch] = useState("");
  const [activeCategory, setActiveCategory] = useState(0);
  const [loading, setLoading] = useState(false);

  const onChange = (e) => setSearch(e.target.value);

  const isBranded = isLogin ? "1" : 0;

  const getListfaq = async (id, word) => {
    setLoading(true);
    const { data } = await ClientApiList.getBantuan({
      is_branded: isBranded,
      topic_id: id || "",
      question: word,
    });
    if (data) {
      setList(data?.data.subcategory?.categories);
      setLoading(false);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { data } = await ClientApiList.getBantuan({
      is_branded: isBranded,
      topic_id: activeCategory || "",
      question: search,
    });
    if (data) {
      setList(data?.data.subcategory?.categories);
      setLoading(false);
    }
  };

  return (
    <div
      className={clsx(
        "w-full max-w-[460px] mx-auto relative text-text-2",
        isMobile ? "pt-[23px] px-3.5 pb-[22px]" : "pt-[30px] pb-[30px]"
      )}
    >
      <p className="font-bold text-2xl font-font-family-3">{title}</p>
      <Description
        className={clsx(
          "mb-[23px] mt-[10px] text-base font-medium font-font-family-6"
        )}
        description={description}
      />
      <div className="mt-[20px]">
        <p className="text-base font-bold font-font-family-7">
          {category?.title}
        </p>
        <div
          className={clsx(
            "w-full mt-[10px] flex flex-col items-start",
            isMobile ? "gap-[5px]" : "gap-[10px]"
          )}
        >
          {category?.categories?.map((item, index) => (
            <ActiveCategory
              setActiveCategory={setActiveCategory}
              key={index}
              item={item}
              mode={item.id == activeCategory ? "active" : "unactive"}
              onClick={() => {
                setActiveCategory(item.id);
                getListfaq(item.id, "");
                setSearch("");
              }}
            />
          ))}
        </div>
      </div>
      <div className="mt-[20px]">
        <p className="text-base font-bold font-font-family-7">
          {subcategory?.title}
        </p>
        <div
          className={clsx(
            "w-full flex items-center gap-[12px] bg-bg-3 border border-cta-4 rounded-[10px]",
            isMobile ? "px-[10px] my-[10px]" : "px-[15px] my-[15px]"
          )}
        >
          <button
            onClick={() => getListfaq(activeCategory, search)}
            className="flex justify-center items-center"
          >
            <IconSearch className={"fill-icon-2"} />
          </button>
          <form method="POST" className="w-full" onSubmit={onSubmit}>
            <input
              className={clsx(
                "w-full bg-bg-3 placeholder-shown:italic placeholder-text-3",
                isMobile ? "text-xs py-[10px]" : "text-sm py-[15px]"
              )}
              type="text"
              placeholder={subcategory?.search_box_placeholder}
              onChange={onChange}
              value={search}
            />
          </form>
          <button
            onClick={() => setSearch("")}
            className=" flex justify-center items-center"
          >
            <IconX className={"fill-icon-3"} />
          </button>
        </div>
        {list?.length > 0 && !loading ? (
          <div className="flex flex-col gap-[5px]">
            {list?.map((item, index) => (
              <div
                key={index}
                className="w-full border border-cta-4 rounded-[10px] bg-bg-3"
              >
                <ListFaq item={item} index={index} />
              </div>
            ))}
          </div>
        ) : (
          <div className="w-full">
            {loading ? (
              <div className="w-full flex ">
                <Spinner />
              </div>
            ) : (
              <p
                className={clsx(
                  "text-text-2 font-medium font-font-family-6 flex justify-between capitalize",
                  isMobile ? "p-[10px] text-xs" : "p-[15px] text-sm"
                )}
              >
                No Data Found
              </p>
            )}
          </div>
        )}
        <div
          className={clsx(
            "bg-bg-6 rounded-[15px] p-[15px]",
            isMobile ? "mt-[20px]" : "mt-[30px]"
          )}
        >
          <p className="text-base font-bold font-font-family-7 mb-[5px]">
            <span>Butuh</span>{" "}
            <span className="text-text-1">Bantuan Lebih Lanjut?</span>
          </p>
          <Description
            className="text-sm"
            description={help_info?.description}
          />
          <a
            className={clsx(
              "flex w-fit border border-cta-1 rounded-[10px] flex justify-center items-center gap-[5px]",
              isMobile
                ? "py-[10px] px-[15px] mb-[5px] mt-[10px]"
                : "py-[15px] px-[20px] mb-[10px] mt-[15px]"
            )}
            href={`mailto:${help_info?.email_brand}`}
          >
            <MailIconFill className={"fill-text-1"} />
            <span
              className={clsx(
                "font-medium font-font-family-6 text-text-1",
                isMobile ? "text-sm" : "text-base"
              )}
            >
              {help_info?.email_brand}
            </span>
          </a>
          <Description
            className="text-sm text-text-5 tracking-[0.2px]"
            description={help_info?.email_info}
          />
        </div>
      </div>
    </div>
  );
}

Bantuan.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  subcategory: PropTypes.object,
  category: PropTypes.object,
  help_info: PropTypes.object,
};

const ActiveCategory = ({ item, mode, ...props }) => {
  const isMobile = useIsMobile();

  const button = cva(
    `w-full ${isMobile ? "p-[10px]" : "p-[15px]"
    } flex items-center gap-[10px] bg-bg-3 border rounded-[10px] text-left font-medium font-font-family-6 tracking-[0.25px]`,
    {
      variants: {
        mode: {
          active: ["border-cta-1"],
          unactive: ["border-[transparent]"],
        },
      },
      compoundVariants: [{ mode: "unactive" }],
      defaultVariants: {
        mode: "unactive",
      },
    }
  );

  return (
    <button
      // onClick={() => setActiveCategory(item.id)}
      className={button({ mode })}
      {...props}
    >
      <div className="relative w-[24px] aspect-square">
        <Image alt="icon" src={item?.url} />
      </div>
      <span className={isMobile ? "text-xs" : "text-sm"}>{item?.title}</span>
    </button>
  );
};

ActiveCategory.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  mode: PropTypes.string,
  setActiveCategory: PropTypes.number,
};

const ListFaq = ({ item, index }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSub, setActiveSub] = useState(0);
  const isMobile = useIsMobile();

  const toggleDropdown = (index) => {
    setIsOpen(!isOpen);
    setActiveSub(index);
  };

  const iconVariants = {
    open: { rotate: 0 },
    closed: { rotate: -180 },
  };

  return (
    <AnimatePresence>
      <button
        onClick={() => toggleDropdown(index)}
        className={clsx(
          "w-full text-left text-text-2 font-medium font-font-family-6 flex justify-between capitalize",
          isMobile ? "p-[10px] text-xs" : "p-[15px] text-sm"
        )}
      >
        <span>{item?.title}</span>
        <motion.span
          key="icon"
          initial={false}
          animate={isOpen && index == activeSub ? "open" : "closed"}
          variants={iconVariants}
          transition={{ duration: 0.2 }}
        >
          <Chevron className={"fill-icon-2"} />
        </motion.span>
      </button>
      {isOpen && index == activeSub && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.2 }}
        >
          <Description
            className="text-text-5 p-[10px] text-sm"
            description={item?.description}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

ListFaq.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
};

export const Chevron = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        className={className}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.19981 10.5106C4.34043 10.6511 4.53106 10.7299 4.72981 10.7299C4.92856 10.7299 5.11918 10.6511 5.25981 10.5106L7.97981 7.7906L10.6998 10.5106C10.7685 10.5843 10.8513 10.6434 10.9433 10.6844C11.0353 10.7254 11.1346 10.7474 11.2353 10.7492C11.336 10.751 11.436 10.7324 11.5294 10.6947C11.6228 10.657 11.7076 10.6009 11.7788 10.5296C11.8501 10.4584 11.9062 10.3736 11.9439 10.2802C11.9817 10.1868 12.0002 10.0868 11.9984 9.98608C11.9966 9.88538 11.9746 9.78606 11.9336 9.69406C11.8926 9.60206 11.8335 9.51926 11.7598 9.4506L8.50981 6.2006C8.36918 6.06015 8.17856 5.98126 7.97981 5.98126C7.78106 5.98126 7.59043 6.06015 7.44981 6.2006L4.19981 9.4506C4.05936 9.59123 3.98047 9.78185 3.98047 9.9806C3.98047 10.1794 4.05936 10.37 4.19981 10.5106Z"
      // fill="black"
      />
    </svg>
  );
};

Chevron.propTypes = {
  className: PropTypes.string,
};

const IconSearch = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        className={className}
        d="M17.8395 16.4605L14.1641 12.7852C15.0489 11.6072 15.5266 10.1733 15.525 8.7C15.525 4.93672 12.4633 1.875 8.7 1.875C4.93672 1.875 1.875 4.93672 1.875 8.7C1.875 12.4633 4.93672 15.525 8.7 15.525C10.1733 15.5266 11.6072 15.0489 12.7852 14.1641L16.4605 17.8395C16.6466 18.0058 16.8893 18.0945 17.1387 18.0876C17.3881 18.0806 17.6255 17.9784 17.8019 17.8019C17.9784 17.6255 18.0806 17.3881 18.0876 17.1387C18.0945 16.8893 18.0058 16.6466 17.8395 16.4605ZM3.825 8.7C3.825 7.73582 4.11091 6.79329 4.64659 5.9916C5.18226 5.18991 5.94363 4.56506 6.83442 4.19609C7.72521 3.82711 8.70541 3.73057 9.65107 3.91867C10.5967 4.10678 11.4654 4.57107 12.1471 5.25285C12.8289 5.93464 13.2932 6.80328 13.4813 7.74894C13.6694 8.69459 13.5729 9.67479 13.2039 10.5656C12.8349 11.4564 12.2101 12.2177 11.4084 12.7534C10.6067 13.2891 9.66418 13.575 8.7 13.575C7.40755 13.5735 6.16847 13.0593 5.25457 12.1454C4.34066 11.2315 3.82655 9.99246 3.825 8.7Z"
      // fill="#413F3F"
      />
    </svg>
  );
};

IconSearch.propTypes = {
  className: PropTypes.string,
};

const IconX = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_4566_16902)">
        <path
          className={className}
          d="M10.7841 9.99995L15.3896 5.39439C15.4806 5.28811 15.5282 5.1514 15.5228 5.01158C15.5174 4.87176 15.4594 4.73913 15.3605 4.64019C15.2616 4.54125 15.1289 4.48329 14.9891 4.47788C14.8493 4.47248 14.7126 4.52004 14.6063 4.61106L10.0007 9.21661L5.39519 4.6055C5.29058 4.50089 5.14869 4.44212 5.00074 4.44212C4.8528 4.44212 4.71091 4.50089 4.6063 4.6055C4.50169 4.71012 4.44292 4.852 4.44292 4.99995C4.44292 5.14789 4.50169 5.28978 4.6063 5.39439L9.21741 9.99995L4.6063 14.6055C4.54814 14.6553 4.50091 14.7166 4.46756 14.7855C4.43422 14.8544 4.41548 14.9295 4.41252 15.006C4.40957 15.0825 4.42246 15.1588 4.45039 15.2301C4.47832 15.3014 4.52069 15.3662 4.57483 15.4203C4.62897 15.4745 4.69372 15.5168 4.76501 15.5447C4.8363 15.5727 4.9126 15.5856 4.98911 15.5826C5.06562 15.5797 5.14069 15.5609 5.20962 15.5276C5.27854 15.4942 5.33983 15.447 5.38963 15.3888L10.0007 10.7833L14.6063 15.3888C14.7126 15.4799 14.8493 15.5274 14.9891 15.522C15.1289 15.5166 15.2616 15.4586 15.3605 15.3597C15.4594 15.2608 15.5174 15.1281 15.5228 14.9883C15.5282 14.8485 15.4806 14.7118 15.3896 14.6055L10.7841 9.99995Z"
        // fill="#7D7D7D"
        />
      </g>
      <defs>
        <clipPath id="clip0_4566_16902">
          <rect width="20" height="20" className={className} />
        </clipPath>
      </defs>
    </svg>
  );
};

IconX.propTypes = {
  className: PropTypes.string,
};

const MailIconFill = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={className}
        d="M16.668 3.33301H3.33464C2.41797 3.33301 1.6763 4.08301 1.6763 4.99967L1.66797 14.9997C1.66797 15.9163 2.41797 16.6663 3.33464 16.6663H16.668C17.5846 16.6663 18.3346 15.9163 18.3346 14.9997V4.99967C18.3346 4.08301 17.5846 3.33301 16.668 3.33301ZM16.3346 6.87467L10.443 10.558C10.1763 10.7247 9.8263 10.7247 9.55964 10.558L3.66797 6.87467C3.58441 6.82777 3.51123 6.76439 3.45288 6.68839C3.39452 6.61238 3.35219 6.52532 3.32845 6.43248C3.30471 6.33964 3.30005 6.24295 3.31477 6.14826C3.32948 6.05357 3.36325 5.96285 3.41404 5.88159C3.46482 5.80033 3.53157 5.73022 3.61024 5.6755C3.68891 5.62079 3.77786 5.5826 3.87171 5.56326C3.96557 5.54391 4.06237 5.54381 4.15626 5.56296C4.25016 5.58212 4.33919 5.62012 4.41797 5.67467L10.0013 9.16634L15.5846 5.67467C15.6634 5.62012 15.7524 5.58212 15.8463 5.56296C15.9402 5.54381 16.037 5.54391 16.1309 5.56326C16.2247 5.5826 16.3137 5.62079 16.3924 5.6755C16.471 5.73022 16.5378 5.80033 16.5886 5.88159C16.6394 5.96285 16.6731 6.05357 16.6878 6.14826C16.7025 6.24295 16.6979 6.33964 16.6742 6.43248C16.6504 6.52532 16.6081 6.61238 16.5497 6.68839C16.4914 6.76439 16.4182 6.82777 16.3346 6.87467Z"
      // fill="#ED1C24"
      />
    </svg>
  );
};

MailIconFill.propTypes = {
  className: PropTypes.string,
};
