"use client"
import React, { useEffect, useRef, useState } from "react"
import DOMPurify from "isomorphic-dompurify"
import ExclusiveList from "../exclusive-experience/exclusive-list/exclusive-list"
import ExclusiveForm from "../exclusive-experience/exclusive-form/exclusive-form"
import BackBanner from "../shared/back-banner"
import ButtonBack from "../shared/button-back/button-back"
import LoadMoreBtn from "../events/event-list/load-more-btn"
import { useSearchParams } from "next/navigation"
import { ClientApiList } from "@/lib/api/client-service"
import { useDebouncedCallback } from "use-debounce"
import { useIsMobile } from "@/hooks/use-is-mobile"
import PropTypes from "prop-types"

export function ExclusiveExperience({ data }) {
  const searchParams = useSearchParams()
  const currentPage = searchParams.get("ex-page") || 0
  const currentSearch = searchParams.get("exclusive-search") || ""
  const isMobile = useIsMobile()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadMore, setIsLoadMore] = useState(false)
  const [listExclusive, setListExclusive] = useState([...data.items])
  const [pagingData, setPagingData] = useState({ ...data.paging })
  const bgImages = { ...data.bg_img_url }
  const { title, description } = data
  const init = useRef(true)
  const prevSearch = useRef("")

  const handleGetExclusive = useDebouncedCallback(async () => {
    try {
      const {
        data: { data },
      } = await ClientApiList.getExclusiveList(currentSearch, currentPage)
      if (
        data.data.paging.currPage === pagingData.currPage &&
        currentSearch === prevSearch.current
      ) {
        setIsLoading(false)
        setIsLoadMore(false)
        return
      }
      if (currentSearch !== prevSearch.current) {
        setListExclusive([...data.data.items])
      } else {
        setListExclusive((prev) => [...prev, ...data.data.items])
      }
      setPagingData(data.data.paging)
      setIsLoading(false)
      setIsLoadMore(false)
    } catch (err) {
      console.log("[Error:]", err)
    }
    prevSearch.current = currentSearch
  }, 300)

  useEffect(() => {
    if (!init.current) {
      handleGetExclusive()
    }

    init.current = false
  }, [searchParams])

  return (
    <div className="relative lg:p-[10px] lg:flex lg:gap-[15px] md:min-h-[629px]">
      <div className="p-[10px] pb-[0] lg:sticky lg:flex flex-col items-center justify-center top-[74px] pt-[85px] lg:p-[0] max-h-[609px] w-full lg:max-w-[50%]">
        <ButtonBack className="lg:top-[15px] lg:left-[15px]" />
        <BackBanner
          bgImg={
            isMobile ? bgImages.mobile_image_url : bgImages.desktop_image_url
          }
          className="lg:max-h-[609px] lg:rounded-[15px] bg-bg-5"
        />
        <div className="font-font-family-3 relative z-[3] mx-auto text-[36px] lg:text-[65px] text-center leading-[36px] lg:leading-[84px] font-bold mb-[15px] lg:mb-[20px] text-white max-w-[544px] capitalize">
          {title}
        </div>
        <div
          className="relative z-[3] text-[14px] lg:text-[24px] text-center leading-[19px] lg:leading-[32px] font-normal text-white max-w-[325px] lg:max-w-[484px] mx-auto mb-[24px] lg:mb-[89px]"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
        ></div>
      </div>
      <div className=" p-[10px] md:max-w-[698px] mx-auto lg:max-w-none lg:p-0 relative z-[3] w-full">
        <ExclusiveForm
          setIsLoading={setIsLoading}
          placeholder={data.placeholder}
          searchIcon={data.search_img_url}
        />
        <div className="lg:max-h-[632px] overflow-auto rounded-[10px] lg:rounded-[15px] lg:pb-[5px]">
          <ExclusiveList
            isLoadMore={isLoadMore}
            isLoading={isLoading}
            items={listExclusive}
          />
          {pagingData.nextPage !== null && !isLoading && !isLoadMore ? (
            <LoadMoreBtn
              setIsLoadMore={setIsLoadMore}
              cat="ex"
              className="!mb-0"
              load_more_btn_label={"Muat Lebih Banyak"}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

ExclusiveExperience.propTypes = {
  data: PropTypes.object,
}
