import { Image } from "@/components/shared/image/image";
import PropTypes from "prop-types";
import { RemoveScroll } from "react-remove-scroll";
import { IconCloseCircle } from "./submission-icons";
import { useEffect, useRef } from "react";
import { getIOSSemever } from "@/lib/utils";

const PreviewFile = ({ submission_type, file_src, file_type, handleClose }) => {
  return (
    <div className="fixed inset-0 z-[20] bg-black/[.75] flex justify-center items-center">
      <RemoveScroll className="w-full h-screen">
        <div className="absolute w-full max-w-[690px] px-[15px] top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2">
          <div className="relative w-full aspect-[345/257] rounded-[10px] overflow-hidden flex justify-center items-center">
            {submission_type == "image" ? (
              <Image
                alt="preview"
                data-testid="preview"
                src={file_src}
                style={{ objectFit: "cover" }}
                /* Revoke data uri after image is loaded */
                onLoad={() => {
                  URL.revokeObjectURL(file_src);
                }}
              />
            ) : (
              <VideoTemplate file_src={file_src} file_type={file_type} />
            )}
          </div>
          <button
            className="absolute top-[-53px] right-[15px] w-[50px] h-[50px] text-white"
            onClick={handleClose}
          >
            <IconCloseCircle />
          </button>
        </div>
      </RemoveScroll>
    </div>
  );
};

PreviewFile.propTypes = {
  submission_type: PropTypes.string,
  file_src: PropTypes.any,
  file_type: PropTypes.any,
  handleClose: PropTypes.func,
};

const VideoTemplate = ({ file_src, file_type }) => {
  const isIOSOver17 = getIOSSemever()?.major >= 17;
  return isIOSOver17 ? (
    <IOSVideo
      controls
      src={file_src}
      type={file_type}
      onLoad={() => {
        URL.revokeObjectURL(file_src);
      }}
    />
  ) : (
    <video
      controls
      onLoad={() => {
        URL.revokeObjectURL(file_src);
      }}
      src={file_src}
    >
      <track kind="captions" />
    </video>
  );
};
VideoTemplate.propTypes = {
  file_src: PropTypes.any,
  file_type: PropTypes.any,
};

function IOSVideo({ src, type, ...props }) {
  const ref = useRef();

  useEffect(() => {
    const video = ref.current;
    if (video) {
      video.load();
    }
  }, []);

  return (
    <video ref={ref} {...props}>
      <track kind="captions" />
      <source src={src} type={type} />
    </video>
  );
}
IOSVideo.propTypes = {
  src: PropTypes.any,
  type: PropTypes.any,
};

export default PreviewFile;
