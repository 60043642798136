"use client"
import React from "react"
import Style from "./detail-tnc.module.css"
import DOMPurify from "isomorphic-dompurify"
import { twMerge } from "tailwind-merge"
import PropTypes from "prop-types"
export default function DetailTnC({ tnc, className }) {
  return (
    <div
      className={twMerge(
        "p-[15px] lg:p-0 lg:pt-[5px] lg:px-[10px] pb-[72px]",
        className
      )}
    >
      <div className="text-text-2 text-[16px] mb-[7px] font-bold font-font-family-7 leading-[22px] lg:mb-[15px]">
        Syarat & Ketentuan
      </div>
      <div
        data-testid="detail-tnc"
        className={Style.DetailTnCText}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tnc) }}
      ></div>
    </div>
  )
}

DetailTnC.propTypes = {
  tnc: PropTypes.string,
  className: PropTypes.string,
}
