"use client"
import { cva } from "class-variance-authority"
import clsx from "clsx"
import { Image } from "@/components/shared/image/image"
import PropTypes from "prop-types"
import { Description } from "../description/description"
import { useInView } from "react-intersection-observer"
import { useEffect, useState } from "react"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { SuccessIcon } from "@/components/sections/article-details"

export function ArticleItem({
  slide = {},
  type = "primary",
  card_size = "medium",
  card_direction = "vertical",
  cover_size = "medium",
  title_size = "medium",
  title_direction = "col",
  horizontal = false,
  firstCard = false,
  onView = () => {},
  inViewOptions = {},
}) {
  const [show, setShow] = useState(false)
  const isMobile = useIsMobile()

  const { ref, inView } = useInView({
    ...inViewOptions,
    rootMargin: "0px 0px -56px 0px",
  })

  useEffect(() => {
    if (inView && !show) {
      onView?.()
      setShow(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, show])

  const articleCard = cva(
    "relative h-full flex items-center rounded-[10px] border-[1px] border-solid overflow-hidden bg-bg-3",
    {
      variants: {
        type: {
          primary: ["border-cta-4"],
        },
        card_size: {
          medium: ["w-[280px]"],
          small: ["w-[177px]"],
          full: ["w-full"],
        },
        card_direction: {
          vertical: ["p-[5px]", "flex-col", "gap-[5px]"],
          horizontal: firstCard
            ? ["p-[10px]", "flex-row", "gap-[20px]"]
            : ["p-[10px]", "flex-row", "gap-[10px]"],
        },
      },
      compoundVariants: [
        { type: "primary", card_size: "medium", card_direction: "vertical" },
      ],
      defaultVariants: {
        type: "primary",
        card_size: "medium",
        card_direction: "vertical",
      },
    }
  )

  const articleCover = cva(
    "relative rounded-[5px] overflow-hidden w-full bg-cta-4",
    {
      variants: {
        cover_size: {
          medium: ["aspect-[270/152]"],
          small: ["aspect-[165/123]"],
          medium_horizontal: ["aspect-[196/146]", "h-[inherit]"],
        },
      },
      compoundVariants: [{ cover_size: "medium" }],
      defaultVariants: {
        cover_size: "medium",
      },
    }
  )

  const articleTitle = cva(
    "w-full flex gap-[5px] font-medium font-font-family-6 leading-normal",
    {
      variants: {
        title_size: {
          medium: ["text-base"],
          small: ["text-sm"],
        },
        title_direction: {
          col: ["flex-col", "p-[5px]"],
          col_reverse: firstCard
            ? [
                "flex-col-reverse",
                "aspect-[inherit]",
                "h-full",
                "max-w-[55%]",
                "py-[10px]",
                !slide.point && "justify-end",
              ]
            : [
                "flex-col-reverse",
                "h-full",
                "aspect-[234/144]",
                "max-h-[322px]",
                "max-w-[55%]",
                "min-w-[196px]",
                "py-[5px]",
                !slide.point && "justify-end",
              ],
        },
      },
      compoundVariants: [{ title_size: "medium", title_direction: "col" }],
      defaultVariants: {
        title_size: "medium",
        title_direction: "col",
      },
    }
  )

  const points = (
    <div
      data-testid="article-points"
      className={clsx(
        "flex items-center gap-[5px]",
        horizontal && "mt-auto mb-0"
      )}
    >
      {slide.finished === 1 ? (
        <SuccessIcon />
      ) : (
        <div className="relative w-[24px] aspect-square">
          <Image
            objectFit="cover"
            alt="icon"
            src={
              slide?.icon_url ||
              `/assets/${process.env.NEXT_PUBLIC_NAME}/icons/reward-profile.svg`
            }
          />
        </div>
      )}
      <span
        className={clsx(
          "text-text-1 tracking-[0.25px] font-bold font-font-family-7",
          firstCard ? "text-sm" : "text-[12px] leading-[13px]"
        )}
      >
        {slide.point} Poin
      </span>
    </div>
  )

  const title = (
    <p
      data-testid="article-title"
      className={clsx(
        "text-text-2 text-ellipsis line-clamp-2 font-medium font-font-family-6 capitalize",
        horizontal && !firstCard && "h-[55px] text-lg",
        firstCard && horizontal
          ? "text-[25px] h-[68px] max-w-[418px]"
          : "text-base h-[48px]"
      )}
    >
      {slide.title}
    </p>
  )

  const setEngagement = slide.format.split("_").join(" ")
  const setCategory =
    slide.format === "article" || slide.format === "video"
      ? slide.category
      : setEngagement

  const category = (
    <span
      data-testid="article-category"
      className={clsx(
        "text-text-3 uppercase",
        firstCard ? "text-sm" : "text-xs"
      )}
    >
      {setCategory}
    </span>
  )

  const desc = (
    <Description
      className="text-sm text-[#959493] h-[80px] text-ellipsis line-clamp-4"
      description={slide.article_content}
    />
  )

  const imgCover = isMobile ? slide.mobile_image_url : slide.desktop_image_url

  return (
    <section
      ref={ref}
      className={articleCard({ type, card_size, card_direction })}
    >
      <div className={articleCover({ cover_size })}>
        <Image
          alt="cover"
          data-testid="article-img"
          src={slide.format === "video" ? slide.tumb_video_url : imgCover}
        />
        {slide.format === "video" && (
          <div
            style={{ background: "var(--cta_1)" }}
            data-testid="article-video-icon"
            className="w-6 h-6 absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 bg-cta-1 rounded-[5px] flex justify-center items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="10"
              viewBox="0 0 8 10"
              fill="none"
            >
              <path
                d="M0.25 0.5V9.5L7.75 5L0.25 0.5Z"
                className={
                  process.env.NEXT_PUBLIC_NAME === "djisamsoe"
                    ? "fill-icon-4"
                    : "fill-cta-2"
                }
              />
            </svg>
          </div>
        )}
        {slide.format === "video" && slide.duration && (
          <div
            data-testid="article-video-duration"
            className="p-[5px] absolute top-1 left-1 text-white font-medium font-font-family-6 text-[10px] leading-[10px] bg-[#616262] bg-opacity-25 rounded-[5px] flex justify-center items-center"
          >
            {slide.duration}
          </div>
        )}
      </div>
      <div className={articleTitle({ title_size, title_direction })}>
        {slide.point ? points : null}
        {firstCard && desc}
        {title}
        {category}
      </div>
    </section>
  )
}

ArticleItem.propTypes = {
  slide: PropTypes.object.isRequired,
  type: PropTypes.string,
  card_size: PropTypes.string,
  card_direction: PropTypes.string,
  cover_size: PropTypes.string,
  title_size: PropTypes.string,
  title_direction: PropTypes.string,
  horizontal: PropTypes.bool,
  firstCard: PropTypes.bool,
  onView: PropTypes.func,
  inViewOptions: PropTypes.func,
}
