import ButtonAction from "@/components/shared/button-action/button-action";
import { Image } from "@/components/shared/image/image";
import PopupAction from "@/components/shared/popup-action/popup-action";
import { handleChances } from "@/helper/utils";
import { useWindowSize } from "@/hooks/use-window-size";
import clsx from "clsx";
import { useRouter } from "next/navigation";
import PropTypes from "prop-types";
import { useRef } from "react";
import DOMPurify from "isomorphic-dompurify";

const SubmissionPopup = ({
  open,
  setOpen,
  isPopupDelete = false,
  deleteAudio,
  setStage,
  popupData,
}) => {
  const brandName = process.env.NEXT_PUBLIC_NAME;
  const { width } = useWindowSize();
  const isDesktop = width >= 1024;
  const { replace } = useRouter();
  const introDesc = useRef(null);
  const availTitle = useRef(null);

  const handleConfirm = () => {
    isPopupDelete ? deleteAudio() : setStage(1);
    setOpen(false);
  };

  const handleCancel = () => {
    if (!isPopupDelete) replace("/br");
    setOpen(false);
  };

  const isDSS = process.env.NEXT_PUBLIC_NAME === "djisamsoe";

  return (
    <PopupAction
      isShowing={open}
      onClickBtnX={() => setOpen(false)}
      className={"!p-[10px]"}
      zIndex="z-[20]"
    >
      <div className="w-full h-full p-[15px] rounded-[10px] border-[2px] border-cta-3 border-dashed">
        {isPopupDelete && (
          <div className="overflow-hidden h-full relative aspect-[304/177] rounded-[15px]">
            <Image
              alt="banner"
              src={`/assets/${brandName}/mission/popup-confirm-exit.png`}
            />
          </div>
        )}
        <div
          className={clsx(
            "my-[20px] max-w-[304px] text-text-2 flex flex-col",
            isPopupDelete ? "gap-[5px]" : "gap-[10px]"
          )}
        >
          <p className="text-xl font-black font-font-family-8 capitalize">
            {isPopupDelete
              ? "Yakin mau hapus?"
              : popupData?.success_intro_title || "THANK YOU!"}
          </p>
          <p
            className={clsx(
              "text-center font-font-family-5",
              isDesktop ? "text-base" : "text-sm"
            )}
          >
            {isPopupDelete ? (
              `${isDSS ? "Kamu" : "Lo"} harus upload ulang file ${isDSS ? "kamu" : "lo"} buat submit karya`
            ) : (
              <div
                className="text-center text-[16px] leading-[24px] mb-[20px]"
                ref={introDesc}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(popupData?.success_intro_desc),
                }}
              ></div>
            )}
          </p>

          {/* CHANCES */}
          {!isPopupDelete && (
            <div className="p-[10px] rounded-[8px] bg-bg-1">
              {popupData?.remainingChances > 0 && (
                <div
                  className="text-[16px] leading-[24px] font-bold text-center mb-[5px] font-font-family-7"
                  ref={availTitle}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      popupData?.isChances
                        ? handleChances(
                            popupData?.success_avail_title,
                            popupData
                          )
                        : popupData?.success_ranout_title
                    ),
                  }}
                ></div>
              )}
              <div
                className="text-[16px] leading-[24px] text-center mb-[30px]"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    popupData?.isChances
                      ? popupData?.success_avail_desc ||
                          `Ayo submit lagi biar peluang karya ${isDSS ? "kamu" : "lo"} terpilih lebih besar.`
                      : popupData?.success_ranout_desc ||
                          `Kesempatan ${isDSS ? "kamu" : "lo"} buat submit ulang submisinya udah habis.`
                  ),
                }}
              ></div>
            </div>
          )}
        </div>

        <div className={clsx("flex gap-[10px]", !isPopupDelete && "flex-col")}>
          <ButtonAction
            onClick={() => handleConfirm()}
            className="py-[13px] px-[20px]"
            intent={
              isPopupDelete
                ? "secondary"
                : popupData?.remainingChances > 0
                  ? "primary"
                  : "primary_disable"
            }
            disabled={!isPopupDelete && popupData?.remainingChances < 1}
          >
            {isPopupDelete ? "Ya" : popupData?.success_cta_1 || "Submit"}
          </ButtonAction>
          <ButtonAction
            data-testid="popup-cancel"
            onClick={() => handleCancel()}
            className="py-[13px] px-[20px]"
            intent={isPopupDelete ? "primary" : "secondary"}
          >
            {isPopupDelete
              ? "Batal"
              : popupData?.success_cta_2 || "Back To Homepage"}
          </ButtonAction>
        </div>
      </div>
    </PopupAction>
  );
};

SubmissionPopup.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isPopupDelete: PropTypes.bool,
  deleteAudio: PropTypes.func,
  setStage: PropTypes.func,
  popupData: PropTypes.object,
};

export default SubmissionPopup;
