"use client"
import { Description } from "@/components/shared/description/description"
import { useIsMobile } from "@/hooks/use-is-mobile"
import clsx from "clsx"
import { Image } from "@/components/shared/image/image"
import Link from "@/components/shared/link/link"
import PropTypes from "prop-types"
import { ClientApiList } from "@/lib/api/client-service"
import { useQuery } from "@tanstack/react-query"

export const MissionActivityItem = ({
  image_url,
  title,
  description,
  isLogin,
  index,
  href,
  cta,
}) => {
  const isMobile = useIsMobile()

  const { data: dataUser } = useQuery({
    queryKey: ["profileData"],
    queryFn: async () => {
      const { data } = await ClientApiList.getProfileData()
      return data?.data.data
    },
    enabled: Boolean(isLogin),
  })

  return (
    <div
      className={clsx(
        "relative w-full h-full flex flex-col gap-[15px] rounded-[10px] overflow-hidden bg-bg-3 text-text-2",
        isMobile ? "p-[10px]" : "p-[15px]"
      )}
    >
      <div
        className={clsx(
          "relative rounded-[15px] overflow-hidden aspect-square",
          isMobile ? "w-[56px]" : "w-[80px]"
        )}
      >
        <Image alt="mission" style={{ objectFit: "cover" }} src={image_url} />
      </div>
      <div
        className={clsx(
          "mb-[5px] h-[56px]",
          isMobile ? "px-[3px]" : "px-[5px]"
        )}
      >
        <Description
          data-testid="title"
          className={clsx(
            "mb-[5px] font-bold font-font-family-7",
            isMobile ? "text-xs" : "text-base"
          )}
          description={title}
        />
        <Description
          className={
            isMobile ? "text-xs leading-[18px]" : "text-sm leading-[20px]"
          }
          description={description}
        />
      </div>
      {isLogin && (
        <div className="mt-auto mb-0 px-[3px]">
          {index === 0 ||
          (index === 1 &&
            dataUser?.profile_completed &&
            dataUser?.email_verified) ? (
            <div className="w-fit flex items-center gap-[5px] bg-cta-4 rounded-[10px] px-[5px] py-[5px] ">
              <DoneIcon />
              <span
                data-testid="completed"
                className={clsx(
                  "text-text-3 font-bold font-font-family-7  tracking-[0.25px]",
                  isMobile ? "text-xs" : "text-sm"
                )}
              >
                Selesai
              </span>
            </div>
          ) : (
            <Link
              data-testid="mission-link"
              href={href || "#"}
              className={clsx(
                "flex item-center gap-[5px] font-bold font-font-family-7 text-xs leading-[20px] text-text-1 underline"
              )}
            >
              <span className={isMobile ? "text-xs" : "text-sm"}>{cta}</span>
              <ArrowIcon color="stroke-icon-1" />
            </Link>
          )}
        </div>
      )}
    </div>
  )
}

MissionActivityItem.propTypes = {
  image_url: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  isLogin: PropTypes.bool,
  index: PropTypes.number,
  href: PropTypes.string,
  cta: PropTypes.string,
}

const ArrowIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        className={color}
        d="M5.83203 14.4906L14.1654 6.15723M14.1654 6.15723H7.4987M14.1654 6.15723V12.8239"
        // stroke="#ED1C24"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

ArrowIcon.propTypes = {
  color: PropTypes.string,
}

const DoneIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8.32422" r="8" fill="#32D74B" />
      <path
        d="M4.5 8.32422L7 11.3242L12 5.32422"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
