import React from "react"
import PropTypes from "prop-types"

const UpvoteIcon = ({ fillColor }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5204 2.05195C8.2664 1.73529 7.73306 1.73529 7.47906 2.05195L2.14573 8.71862C1.98573 8.91929 1.95506 9.19329 2.06573 9.42462C2.17706 9.65462 2.40973 9.80195 2.6664 9.80195H3.99973H5.33306V11.802V14.4686C5.33306 14.8373 5.63106 15.1353 5.99973 15.1353H9.99973C10.3684 15.1353 10.6664 14.8373 10.6664 14.4686V11.1353V9.80195H11.9997H13.3331C13.5897 9.80195 13.8224 9.65462 13.9337 9.42462C14.0444 9.19329 14.0137 8.91929 13.8537 8.71862L8.5204 2.05195ZM9.99973 8.46862H9.33306V9.13529V11.1353V13.802H6.6664V11.802V9.13529V8.46862H5.99973H4.05373L7.99973 3.53595L11.9457 8.46862H9.99973Z"
        // fill="#ED1C24"
        className={fillColor}
      />
    </svg>
  )
}

UpvoteIcon.propTypes = {
  fillColor: PropTypes.string,
}

const UpvoteIconFill = ({ fillColor }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.6664 9.80195H3.99973H5.33306V11.802V14.4686C5.33306 14.8373 5.63106 15.1353 5.99973 15.1353H9.99973C10.3684 15.1353 10.6664 14.8373 10.6664 14.4686V11.1353V9.80195H11.3331H13.3331C13.5897 9.80195 13.8224 9.65462 13.9337 9.42462C14.0444 9.19329 14.0137 8.91929 13.8537 8.71862L8.5204 2.05195C8.2664 1.73529 7.73306 1.73529 7.47906 2.05195L2.14573 8.71862C1.98573 8.91929 1.95506 9.19329 2.06573 9.42462C2.17706 9.65462 2.40973 9.80195 2.6664 9.80195Z"
        className={fillColor}
      />
    </svg>
  )
}

UpvoteIconFill.propTypes = {
  fillColor: PropTypes.string,
}

const DotIcon = ({ fillColor }) => {
  return (
    <svg
      width="3"
      height="3"
      viewBox="0 0 3 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.62 2.13275C1.068 2.13275 0.612 1.67675 0.612 1.12475C0.612 0.57275 1.068 0.11675 1.62 0.11675C2.172 0.11675 2.628 0.57275 2.628 1.12475C2.628 1.67675 2.172 2.13275 1.62 2.13275Z"
        className={fillColor}
      />
    </svg>
  )
}

DotIcon.propTypes = {
  fillColor: PropTypes.string,
}

const UpvoteButton = ({
  onLoad,
  setOnMouseEnter,
  children,
  className,
  style,
  onClick,
  vote,
  onMouseEnter,
}) => {
  return (
    <button
      onClick={(e) => onClick(e)}
      style={style}
      className={className}
      disabled={onLoad}
      onMouseEnter={() => setOnMouseEnter(true)}
      onMouseLeave={() => setOnMouseEnter(false)}
    >
      {vote || onMouseEnter ? (
        <UpvoteIconFill fillColor={"fill-icon-1"} />
      ) : (
        <UpvoteIcon fillColor={"fill-icon-1"} />
      )}
      {children}
    </button>
  )
}

export default UpvoteButton
