"use client"
import clsx from "clsx"
import Style from "./dots.module.scss"
import PropTypes from "prop-types"

const Dots = ({ itemsLength, activeIndex, variant = "Light" }) => {
  return (
    <div className={Style.video_list_slider_dots}>
      {new Array(itemsLength).fill(0).map((_, index) => (
        <div
          key={index}
          className={clsx(
            Style.video_list_slider_dot_wrapper,
            activeIndex === index ? Style.active : undefined
          )}
        >
          <div
            className={clsx(
              Style.video_list_slider_dot,
              activeIndex === index ? Style.active : undefined,
              variant === "Dark" ? Style.dark_dot : undefined
            )}
          />
          {activeIndex === index && (
            <div
              className={clsx(
                Style.video_list_slider_dot,
                Style.overlay,
                variant === "Dark" ? Style.dark_dot : undefined
              )}
            />
          )}
        </div>
      ))}
    </div>
  )
}

export default Dots

Dots.propTypes = {
  variant: PropTypes.string,
  activeIndex: PropTypes.number,
  itemsLength: PropTypes.number,
}
