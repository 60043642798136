"use client"

import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper/modules"
import "swiper/css"

import { useEffect, useRef, useState } from "react"
import { cn } from "@/lib/utils"
import Link from "@/components/shared/link/link"
import { cva } from "class-variance-authority"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { useDatalayer } from "@/hooks/use-datalayer"
import PropTypes from "prop-types"
import clsx from "clsx"
import { Image } from "@/components/shared/image/image"
import { ClientApiList } from "@/lib/api/client-service"
import { useReqUrl } from "@/hooks/auth/use-req-url"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { usePathname } from "next/navigation"

export function BannerItem({
  items = [],
  autoplay,
  show_navigation,
  type = "primary",
  event = "hero_banner",
  subIndex,
  itemBanner,
}) {
  const swiperRef = useRef()
  const [activeIndex, setActiveIndex] = useState(0)
  const [dataBanner, setDataBanner] = useState(items)
  const [urlAspace, setUrlAspace] = useState("")
  const [isAspaceLoading, setIsAspaceLoading] = useState(false)
  const { isLogin, isLoading } = useIsLogin()
  const pathname = usePathname()

  const { execute: login } = useReqUrl({
    redirectUrl: urlAspace,
  })

  const handleSetUrl = (url) => {
    const params = new URLSearchParams()
    params.set("aspace-url", url)
    let urlToSave =
      !isLoading && isLogin
        ? url
        : `${process.env.NEXT_PUBLIC_META_URL}/br?aspace-url=${url}`
    setUrlAspace(urlToSave)
  }

  const handleRedirectASpace = async () => {
    // setIsAspaceLoading(true)
    const {
      data: { data: auth_data },
    } = await ClientApiList.getAuthData({
      platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
      redirect_url: `${process.env.NEXT_PUBLIC_META_URL}${pathname}`,
    })

    // eslint-disable-next-line no-control-regex
    const base = urlAspace.replace(/[^\x00-\x7F]/g, "")
    const urlRedirect = `${base}?auth-data=${auth_data.data.auth_data}`.trim()

    window.open(urlRedirect, "_self").focus()
  }

  useEffect(() => {
    if (urlAspace.length > 0 && !isLoading && !isLogin) login()
    else if (urlAspace.length > 0 && !isLoading && isLogin)
      handleRedirectASpace()

    return () => setUrlAspace("")
  }, [urlAspace])

  const getSubBanner = async () => {
    try{

      const { data } = await ClientApiList.getSubBanner({
        is_branded: itemBanner.is_branded,
        section_name: itemBanner.section_name,
      })
      // setDataBanner(undefined)
      setDataBanner(data?.data?.data?.items)
    }catch(err){
      console.log(err, 'this is the error from sub banner')
      throw err
    }
    // setDataBanner(undefined)
  }

  useEffect(() => {
    if (type === "secondary") getSubBanner()
  }, [type])

  const isMobile = useIsMobile()
  const datalayer = useDatalayer()

  const nextButton = (
    <button
      data-testid="next-btn"
      onClick={() => {
        swiperRef.current?.slideNext()
      }}
      className="absolute z-10 -translate-y-1/2 right-2 top-1/2"
    >
      <IconNextSGV />
    </button>
  )

  const prevButton = (
    <button
      data-testid="prev-btn"
      onClick={() => {
        swiperRef.current?.slidePrev()
      }}
      className="absolute z-10 -translate-y-1/2 left-2 top-1/2"
    >
      <IconPrevSGV />
    </button>
  )

  const dots = (
    <div
      className={clsx(
        "absolute z-10 flex gap-2",
        type != "primary" || isMobile
          ? "bottom-4 left-1/2 -translate-x-1/2 "
          : "top-[20px] right-[20px] bg-[rgba(0,0,0,0.10)] p-[10px] rounded-[10px]"
      )}
    >
      {dataBanner?.map((_, index) => (
        <div
          key={index}
          data-testid={`dots-${index}`}
          className={cn(
            "w-[5px] h-[5px] bg-bg-3 rounded-full",
            index != activeIndex && "opacity-20",
            !isMobile && "w-[7px] h-[7px]",
            index === activeIndex && isMobile && "w-[20px]",
            index === activeIndex && !isMobile && "w-[28px]"
          )}
        />
      ))}
    </div>
  )

  const bannerStyle = cva(
    isMobile
      ? "w-full h-full relative bg-cta-4 rounded-[10px] overflow-hidden"
      : "w-full h-full relative bg-cta-4 rounded-[15px] overflow-hidden",
    {
      variants: {
        type: {
          primary: [isMobile ? "aspect-[360/270]" : "aspect-[1410/480]"], // hero banner
          secondary: [isMobile ? "aspect-[355/133]" : "aspect-[461/150]"], // supporting banner (iqos banner, campaign banner)
          tertiary: [
            "min-h-[199px]",
            "border-2",
            "border-solid",
            "border-white",
          ], // event banner
        },
      },
      compoundVariants: [{ type: "primary" }],
      defaultVariants: {
        type: "primary",
      },
    }
  )

  const onClickDatalayer = (index, slide) => {
    datalayer.push({
      event: "general_event",
      event_name: `click_${event}_${event === "sub_banner" ? subIndex + 1 : index + 1}`, // e.g. click_hero_banner_1
      feature: "banner",
      banner_position: event === "sub_banner" ? subIndex + 1 : index + 1, // e.g 1, 2, 3, etc (integer)
      banner_name:
        event === "sub_banner"
          ? slide?.sub_banner_title?.toLowerCase()
          : slide?.banner_title?.toLowerCase(),
      campaign_name: slide?.campaign_name?.toLowerCase(),
      engagement_type: "content",
    })
  }

  const delayDefault = autoplay === "Medium" ? 5000 : 3000

  return (
    <div className={bannerStyle({ type })}>
      <div className="absolute inset-0 h-full">
        <Swiper
          modules={[Autoplay]}
          autoplay={{
            delay: autoplay === "Slow" ? 10000 : delayDefault,
            disableOnInteraction: false,
          }}
          speed={1000}
          className="absolute inset-0 h-full"
          spaceBetween={0}
          slidesPerView={1}
          onSlideChange={(swiper) => {
            setActiveIndex(swiper.activeIndex)
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper
            setActiveIndex(swiper.activeIndex)
          }}
        >
          {dataBanner?.map((slide, index) => (
            <SwiperSlide key={index}>
              {slide.outside_source_url ? (
                <a
                  data-testid={`slide-outside-link-${index}`}
                  href={slide.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => onClickDatalayer(index, slide)}
                >
                  <div className="relative h-full">
                    <Image
                      data-testid={`slide-img-${index}`}
                      alt="banner"
                      src={
                        isMobile
                          ? slide.mobile_image_url
                          : slide.desktop_image_url
                      }
                    />
                  </div>
                </a>
              ) : (
                <Link
                  data-testid={`slide-link-${index}`}
                  href={
                    slide.href && !slide.href.includes("aspace")
                      ? slide.href
                      : pathname
                  }
                  onClick={() => {
                    onClickDatalayer(index, slide)
                    slide.href.includes("aspace") && handleSetUrl(slide.href)
                  }}
                  passHref
                  scroll={false}
                >
                  <div className="relative h-full">
                    <Image
                      data-testid={`slide-img-${index}`}
                      alt="banner"
                      src={
                        isMobile
                          ? slide.mobile_image_url
                          : slide.desktop_image_url
                      }
                    />
                  </div>
                </Link>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {show_navigation && (
        <>
          {activeIndex !== 0 && prevButton}
          {activeIndex + 1 !== dataBanner?.length && nextButton}
        </>
      )}
      {dataBanner?.length > 1 && dots}
    </div>
  )
}

BannerItem.propTypes = {
  items: PropTypes.array,
  subIndex: PropTypes.number,
  autoplay: PropTypes.string,
  show_navigation: PropTypes.bool,
  type: PropTypes.string,
  event: PropTypes.string,
}

const IconPrevSGV = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <g filter="url(#filter0_b_8755_11898)">
        <path
          d="M26.9943 3.33301H13.0273C6.9605 3.33301 3.34375 6.94976 3.34375 13.0166V26.9669C3.34375 33.0504 6.9605 36.6671 13.0273 36.6671H26.9776C33.0444 36.6671 36.6612 33.0504 36.6612 26.9835V13.0166C36.6778 6.94976 33.0611 3.33301 26.9943 3.33301Z"
          fill="url(#paint0_linear_8755_11898)"
          fillOpacity="0.4"
        />
        <path
          d="M36.3487 13.0157V13.0166V26.9835C36.3487 29.9548 35.4642 32.2887 33.8735 33.8794C32.2827 35.4701 29.9489 36.3546 26.9776 36.3546H13.0273C10.0561 36.3546 7.72229 35.4701 6.13159 33.8775C4.54084 32.2847 3.65626 29.9467 3.65626 26.9669V13.0166C3.65626 10.0453 4.54071 7.71145 6.13145 6.12071C7.72219 4.52997 10.056 3.64551 13.0273 3.64551H26.9943C29.9656 3.64551 32.2993 4.53 33.8879 6.12057C35.4764 7.71108 36.3568 10.0446 36.3487 13.0157Z"
          stroke="url(#paint1_linear_8755_11898)"
          strokeOpacity="0.4"
          strokeWidth="0.625014"
        />
      </g>
      <path
        d="M22.0981 27.1339C21.7814 27.1339 21.4647 27.0172 21.2147 26.7672L15.3313 20.8838C14.8479 20.4004 14.8479 19.6004 15.3313 19.1171L21.2147 13.2336C21.6981 12.7503 22.4981 12.7503 22.9814 13.2336C23.4648 13.7169 23.4648 14.517 22.9814 15.0003L17.9813 20.0004L22.9814 25.0005C23.4648 25.4839 23.4648 26.2839 22.9814 26.7672C22.7481 27.0172 22.4314 27.1339 22.0981 27.1339Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_b_8755_11898"
          x="-2.90639"
          y="-2.91713"
          width="45.8206"
          height="45.8343"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.12507" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_8755_11898"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_8755_11898"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8755_11898"
          x1="33.7613"
          y1="3.74973"
          x2="5.01066"
          y2="34.3754"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.8" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8755_11898"
          x1="3.76063"
          y1="3.74973"
          x2="36.2614"
          y2="36.2505"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="1" stopColor="white" stopOpacity="0.1" />
        </linearGradient>
      </defs>
    </svg>
  )
}

const IconNextSGV = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <g filter="url(#filter0_b_8755_10736)">
        <path
          d="M26.9943 3.33301H13.0273C6.9605 3.33301 3.34375 6.94976 3.34375 13.0166V26.9669C3.34375 33.0504 6.9605 36.6671 13.0273 36.6671H26.9776C33.0444 36.6671 36.6612 33.0504 36.6612 26.9835V13.0166C36.6778 6.94976 33.0611 3.33301 26.9943 3.33301Z"
          fill="url(#paint0_linear_8755_10736)"
          fillOpacity="0.4"
        />
        <path
          d="M36.3487 13.0157V13.0166V26.9835C36.3487 29.9548 35.4642 32.2887 33.8735 33.8794C32.2827 35.4701 29.9489 36.3546 26.9776 36.3546H13.0273C10.0561 36.3546 7.72229 35.4701 6.13159 33.8775C4.54084 32.2847 3.65626 29.9467 3.65626 26.9669V13.0166C3.65626 10.0453 4.54071 7.71145 6.13145 6.12071C7.72219 4.52997 10.056 3.64551 13.0273 3.64551H26.9943C29.9656 3.64551 32.2993 4.53 33.8879 6.12057C35.4764 7.71108 36.3568 10.0446 36.3487 13.0157Z"
          stroke="url(#paint1_linear_8755_10736)"
          strokeOpacity="0.4"
          strokeWidth="0.625014"
        />
      </g>
      <path
        d="M17.9099 27.1339C17.5932 27.1339 17.2766 27.0172 17.0266 26.7672C16.5432 26.2839 16.5432 25.4839 17.0266 25.0005L22.0267 20.0004L17.0266 15.0003C16.5432 14.517 16.5432 13.7169 17.0266 13.2336C17.5099 12.7503 18.3099 12.7503 18.7933 13.2336L24.6767 19.1171C25.1601 19.6004 25.1601 20.4004 24.6767 20.8838L18.7933 26.7672C18.5433 27.0172 18.2266 27.1339 17.9099 27.1339Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_b_8755_10736"
          x="-2.90639"
          y="-2.91713"
          width="45.8206"
          height="45.8343"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.12507" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_8755_10736"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_8755_10736"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8755_10736"
          x1="33.7613"
          y1="3.74973"
          x2="5.01066"
          y2="34.3754"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.8" />
          <stop offset="1" stopColor="white" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8755_10736"
          x1="5.01066"
          y1="34.3754"
          x2="36.2614"
          y2="4.99976"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.1" />
          <stop offset="1" stopColor="white" stopOpacity="0.5" />
        </linearGradient>
      </defs>
    </svg>
  )
}
