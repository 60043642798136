"use client"
import React from "react"

export default function Overlay({ children, className, ...props }) {
  return (
    <div className={`bg-[#646464] text-white ${className}`} {...props}>
      {children}
    </div>
  )
}
