"use client"
import React, { useState, useRef } from "react"
import { SwiperSlide } from "swiper/react"
import { Image } from "@/components/shared/image/image"
// import { cn } from "@/lib/utils"
import Link from "@/components/shared/link/link"

import "swiper/css"
import "swiper/css/pagination"

// custom comp. imports
import { useIsDesktop } from "@/hooks/use-is-mobile"
import { SwiperComp } from "@/components/swiper/swiper"

export default function ExclusiveReward({
  rewardItems = [],
  isLoading,
  setSelectedItem,
}) {
  const [activeIndex, setActiveIndex] = useState(0)
  const isDesktop = useIsDesktop()
  const swiperRef = useRef(null)

  const handleRenderItems = () => {
    if (isLoading)
      return [1, 2, 3, 4, 5, 6].map((item, index) => (
        <SwiperSlide key={item + index} className="!w-fit">
          <div className="w-[175px] lg:w-[226px] h-[310px] lg:h-[390px] rounded-[10px] lg:rounded-[15px] bg-[#D9D9D9] animate-pulse relative overflow-hidden"></div>
        </SwiperSlide>
      ))
    else
      return rewardItems.map((item, index) => {
        return (
          <SwiperSlide key={item + index} className="!w-fit">
            {/* <Link href={item.redirect_url} className="block"> */}
            <div
              onKeyDown={() => {
                // Todo: Add keydown event
              }}
              role="button"
              tabIndex={0}
              onClick={() => setSelectedItem(null, false, item.redirect_url)}
              className="w-[175px] lg:w-[226px] h-[310px] lg:h-[390px] rounded-[10px] lg:rounded-[15px] bg-bg-5 relative overflow-hidden"
            >
              <Image
                alt={item.banner_name}
                src={
                  !isDesktop ? item.mobile_image_url : item.desktop_image_url
                }
              />
            </div>
            {/* </Link> */}
          </SwiperSlide>
        )
      })
  }

  if(!isLoading && rewardItems.length === 0)
  return null

  return (
    <>
      <div className="text-text-2 text-[20px] lg:text-[20px] leading-[27px] lg:leading-[27px] font-medium font-font-family-6 mb-[10px] lg:mb-[16px]">
      Spesial Buat Lo Bulan Ini
      </div>
      <div className="relative h-[310px] lg:h-[390px] mb-[30px]">
        <div className="absolute left-0 right-[-15px] lg:right-0">
          <SwiperComp
            setActiveIndex={setActiveIndex}
            ref={swiperRef}
            slidesPerView={"auto"}
            spaceBetween={isDesktop ? 10 : 5}
          >
            {handleRenderItems()}
          </SwiperComp>
        </div>
      </div>
    </>
  )
}
