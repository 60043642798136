"use client"
import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import CatWithIcon from "@/components/shared/cat-with-icon/cat-with-icon"
import { usePathname } from "next/navigation"

const menus = [
  {
    id: 1,
    label: "Ubah Profil",
    href: "/profil/ubah-profil/br",
    icon: "/assets/amild/icons/Grey-Ubah-Profil.svg",
    active_icon: "/assets/amild/icons/White-Ubah-Profil.svg",
    icontag: "profile",
  },
  {
    id: 2,
    label: "Ubah Password",
    href: "/profil/br/ganti-password",
  icon: "/assets/amild/icons/Grey-Ubah-Password.svg",
    active_icon: "/assets/amild/icons/White-Ubah-Password.svg",
    icontag: "password",
  },
  {
    id: 3,
    label: "Pengaturan Akun",
    href: "/profil/br/pengaturan-akun",
    icon: "/assets/amild/icons/Grey-Pengaturan-Akun.svg",
    active_icon: "/assets/amild/icons/White-Pengaturan-Akun.svg",
    icontag: "account",
  },
]

const slideClass = (index, end) => {
  if (index === 0) {
    return "!pl-[15px] md:!pl-[10px]"
  } else if (index === end) {
    return "!pr-[10px] md:!pr-[10px]"
  } else {
    return ""
  }
}

export default function EditNav() {
  const pathName = usePathname()
  const currentPath = pathName
  const activeNav = menus.findIndex((menu) => menu.href === currentPath)
  return (
    <div className="relative w-[100%] min-h-[72px] md:min-h-[70px] py-[20px] md:py-[10px] !bg-bg-3">
      <div className="absolute w-full left-0 bottom-[10px] md:max-w-[462px] md:left-[50%] md:translate-x-[-50%]">
        <Swiper slidesPerView={"auto"} spaceBetween={5}>
          {menus.map((menu, index) => (
            <SwiperSlide
              key={menu.id}
              className={`!w-fit ${slideClass(index, menus.length - 1)} `}
            >
              <CatWithIcon
                icon={menu.icon}
                icontag={menu.icontag}
                activeIcon={menu.active_icon}
                href={menu.href}
                intent={index === activeNav ? "active" : "tertiary"}
                className={`py-[14px] md:!tracking-normal md:!py-[13px] md:!px-[10px] md:!text-[14px] ${index === activeNav ? " !font-bold font-font-family-7 " : ""}`}
              >
                {menu.label}
              </CatWithIcon>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
