"use client"
import PropTypes from "prop-types"
import { useShowLoginPopup } from "@/hooks/auth/use-login-popup"
import { useIsLogin } from "@/hooks/auth/use-is-login"

export function EventCalendarPageLayout({ children }) {
  const { isLoading, isLogin } = useIsLogin()

  useShowLoginPopup({
    showWhen: !isLogin && !isLoading,
    closePath: "/",
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
  })
  // const router = useRouter()
  return (
    <div className="relative lg:p-[15px] bg-bg-1 lg:flex lg:gap-[15px] mt-[58px] lg:mt-[72px]">
      {children}
    </div>
  )
}

EventCalendarPageLayout.propTypes = {
  children: PropTypes.object,
}
