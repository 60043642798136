"use client"
import React, { useEffect, useState } from "react"
import UGCIntroComp from "../ugc/intro"
import UGCEng from "../ugc/ugc-engagament"
import { ClientApiList } from "@/lib/api/client-service"

const UGCIntro = ({ data, ...props }) => {
  const [dataDetail, setDataDetail] = useState()

  const handleGetDetail = async (cb) => {
    try {
      const { data } = await ClientApiList.getEngagementDetail({
        engagement_uuid: props.engagement_uuid,
        type: props.type,
      })

      setDataDetail(data.data.data)
      if (typeof cb === "function") cb()
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    handleGetDetail()
  }, [])

  const propsIntro = {
    data,
    tnc: {
      is_tnc: dataDetail?.is_tnc,
      is_tnc_checked: dataDetail?.is_tnc_checked,
      tnc: dataDetail?.tnc,
    },
    finished: dataDetail?.finished,
    ...props,
  }

  const reset = (cb) => {
    handleGetDetail(cb)
  }

  const propsDetail = {
    dataDetail,
    engagement_uuid: data.engagement_uuid,
    instruction: {
      subtitle_instruction: data.subtitle_instruction,
      desc_instruction: data.desc_instruction,
    },
    handleReset: reset,
    data
  }
  return (
    <>
      <UGCIntroComp {...propsIntro} />
      <UGCEng {...propsDetail} />
    </>
  )
}

export default UGCIntro
