"use client"
import Styles from "./footer.module.scss"
import clsx from "clsx"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { Image } from "@/components/shared/image/image"
import { usePathname } from "next/navigation";

const bgDesktop = "/assets/amild/aspace-leaderboard/bg-last-aspace-desktop.png"
const bgMobile = "/assets/amild/aspace-leaderboard/bg-last-aspace.png"
const logo = "/assets/amild/aspace-leaderboard/aspace-logo.png"

const Footer = ({ handleOnClick, loadingASpace }) => {
  const isMobile = useIsMobile()
  const bgImg = isMobile ? bgMobile : bgDesktop
  const pathname = usePathname()

  if(pathname.includes('/amild-offline'))
    return null

  return (
    <section className={Styles.bottom}>
      <div
        className={clsx(
          "absolute top-0 right-0 overflow-hidden",
          isMobile ? "aspect-[355/216] w-full" : "aspect-[410/430] w-[410px]"
        )}
      >
        {bgImg && <Image data-testid="bg-image" alt="bg" src={bgImg} />}
      </div>
      <div className={clsx("relative z-[1]", !isMobile && "flex gap-[40px]")}>
        <div
          className={clsx(
            "flex flex-col justify-center ",
            isMobile
              ? "px-[15px] py-[20px] items-center"
              : "px-[30px] h-[430px]"
          )}
        >
          <p
            className={clsx(
              "text-white font-bold font-font-family-3",
              isMobile
                ? " text-[22px] leading-[28px] text-center"
                : " text-[32px] leading-[36px]"
            )}
          >
            EKSPLOR DUNIA VIRTUAL {!isMobile && <br />} BUAT{" "}
            {isMobile && <br />} DAPAT REWARDS* INSPIRASIONAL!
          </p>
          <p
            className={clsx(
              "text-[#F0F0F0]",
              isMobile
                ? "mb-[15px] mt-[5px] text-xs text-center"
                : "mb-[32px] mt-[10px] text-sm leading-[20px]"
            )}
          >
            *Syarat & Ketentuan Berlaku <br />
            Untuk kebutuhan internal. Aktivitas dan tautan ini{" "}
            {!isMobile && <br />} hanya untuk perokok dewasa 21+,
            non-LAU/pengguna IQOS.
          </p>
          <button
            className={Styles.btnAspace}
            onClick={() =>
              handleOnClick(
                "Enter A Space",
                "EKSPLOR DUNIA VIRTUAL BUAT DAPAT REWARDS INSPIRASIONAL!"
              )
            }
            disabled={loadingASpace}
          >
            <span className="font-black font-font-family-8">Enter</span>
            <div className={clsx("relative w-[83px] h-[23px]")}>
              <Image alt="aspace" src={logo} />
            </div>
          </button>
        </div>
      </div>
    </section>
  )
}

export default Footer