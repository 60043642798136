import ButtonAction from "@/components/shared/button-action/button-action"
import { Image } from "@/components/shared/image/image"
import PopupAction from "@/components/shared/popup-action/popup-action"
import { useIsDesktop } from "@/hooks/use-is-mobile"
import clsx from "clsx"
import { usePathname, useSearchParams } from "next/navigation"
import React from "react"

const PopupNextQuestion = ({
  isShowing,
  imgPopup,
  titlePopup,
  point,
  subPopup,
  handleExit,
  handleSuccesSend,
}) => {
  const isDesktop = useIsDesktop()
  const pathname = usePathname()

  return (
    <PopupAction
      isShowing={isShowing}
      href={pathname}
      onClickBtnX={() => {
        handleSuccesSend(false)
      }}
      className={"!p-[10px]"}
      zIndex="z-[20]"
    >
      <div className="w-full h-full p-[15px] rounded-[10px] border-[2px] border-cta-3 border-dashed">
        <div className="h-full relative aspect-[304/177]">
          <Image alt="banner" objectFit="cover" src={imgPopup} />
        </div>
        <div className="my-[20px] max-w-[304px]">
          <p className="text-xl font-black font-font-family-8 mb-[5px] text-text-2 capitalize">
            {titlePopup}
          </p>
          {point > 0 && (
            <div className="flex items-center gap-[5px] justify-center mb-[5px]">
              <div className="min-h-[20px] w-fit min-w-[20px] relative">
                <Image
                  alt="cover"
                  fill
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                    position: "absolute",
                    inset: 0,
                  }}
                  src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "reward.png" : "reward-profile.svg"}`}
                />
              </div>
              <div className="text-[20px] text-text-1 leading-[27px] font-black font-font-family-8">
                {point} Poin!
              </div>
            </div>
          )}
          <p
            className={clsx(
              "text-center font-medium font-font-family-6 text-text-2",
              isDesktop ? "text-base" : "text-sm"
            )}
          >
            {subPopup}
          </p>
        </div>
        <div className="flex flex-col gap-[5px]">
          <ButtonAction
            data-testid="popup-cancel"
            onClick={() => {
              handleSuccesSend(false)
            }}
            // onClick={() => replace("/br")}
            className="py-[15px] px-[20px]"
            intent="primary"
          >
            {"Pertanyaan Selanjutnya"}
          </ButtonAction>
          <ButtonAction
            data-testid="popup-cancel"
            // onClick={() => replace("/br")}
            onClick={() => {
              handleSuccesSend(false)
              handleExit(true)
            }}
            className="py-[15px] px-[20px]"
            intent="secondary"
          >
            {"Keluar"}
          </ButtonAction>
        </div>
      </div>
    </PopupAction>
  )
}

export default PopupNextQuestion
