"use client"
import React from "react"
import { twMerge } from "tailwind-merge"
import PropTypes from "prop-types"
export default function FormBox({ title, children, className }) {
  return (
    <div
      data-testid="form-box"
      style={{ clipPath: "inset(-4px 2px 0px 2px)" }}
      className={twMerge(
        "py-[20px] pt-[16px] px-[15px] rounded-[10px] bg-bg-3 mb-[10px] shadow-[0px_-4px_0px_0px] shadow-cta-1 border-[0px] border-transparent",
        className
      )}
    >
      <div
        data-testid="title"
        className="text-[16px] font-bold font-font-family-7 leading-[22px] mb-[10px] text-text-2"
      >
        {title}
      </div>
      {children}
    </div>
  )
}

FormBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arr,
  title: PropTypes.string,
}
