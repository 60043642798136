"use client"
import React from "react"
import { Image } from "@/components/shared/image/image"
import Link from "@/components/shared/link/link"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { getArticlePathname } from "@/hooks/use-pathname"

export default function ChallengeCard({ item }) {
  const {
    mobile_image_url,
    desktop_image_url,
    title,
    tumb_video_url,
    title_thumb_desktop,
    title_thumb_mobile,
    mission_page_name,
    duration,
    format,
    point,
  } = item

  const isMobile = useIsMobile()
  const href = getArticlePathname(item)
  const imgCover = isMobile
    ? mobile_image_url || title_thumb_mobile
    : desktop_image_url || title_thumb_desktop

  return (
    <Link href={`/${item.campaign_string_id}/br`} className="block h-full">
      <div className="h-full bg-bg-3 p-[4px] md:p-[9px] rounded-[10px] md:rounded-[15px] border-[1px] border-[#e6e6e6] md:flex gap-[10px]">
        <div className="grow-[1] relative overflow-hidden bg-[#e6e6e6] rounded-[10px] h-full min-h-[123px] !max-h-[123px] md:!max-h-[146px] w-full md:max-w-[196px] min-w-[16px] mb-[5px] md:mb-0">
          <Image
            // className={'absolute inset-0 z-[0]'}
            alt={title}
            data-testid="challenge-card-cover"
            src={format === "video" ? tumb_video_url : imgCover}
          />

          {format === "video" && (
            <div
              data-testid="challenge-card-play"
              className="w-6 h-6 absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 bg-bg-5 rounded-[5px] flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="10"
                viewBox="0 0 8 10"
                fill="none"
              >
                <path
                  d="M0.25 0.5V9.5L7.75 5L0.25 0.5Z"
                  fill="none"
                  className={
                    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
                      ? "fill-icon-4"
                      : "fill-cta-2"
                  }
                />
              </svg>
            </div>
          )}
          {format === "video" && duration && (
            <div
              data-testid="challenge-card-duration"
              className="p-[5px] absolute top-1 left-1 text-white font-medium font-font-family-6 text-[10px] leading-[10px] bg-[#616262] bg-opacity-25 rounded-[5px] flex justify-center items-center"
            >
              {duration}
            </div>
          )}
        </div>
        <div className="p-[5px] flex flex-col gap-[5px] md:gap-[10px]">
          {point > 0 && (
            <div
              data-testid="challenge-card-point"
              className="flex items-center gap-[5px] lg:order-3 text-[12px] font-bold font-font-family-7 tracking-[0.25px] leading-[16px] text-text-1 lg:mt-auto"
            >
              <Image
                className="!w-[16px] !h-[16px] lg:!w-[20px] lg:!h-[20px]"
                alt="points"
                src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/reward-profile.svg`}
              />
              {point} Poin
            </div>
          )}
          <div
            data-testid="challenge-card-title"
            className="capitalize text-text-2 text-[14px] md:text-[16px] py-[5px] md:p-0 px-[2px] leading-[20px] md:leading-[22px] font-medium font-font-family-6 lg:order-2"
          >
            {title || mission_page_name}
          </div>
          <div
            data-testid="challenge-card-format"
            className="text-[10px] leading-[14px] text-text-3 font-bold font-font-family-7 tracking-[0.025px] uppercase lg:order-1"
          >
            {format}
          </div>
        </div>
      </div>
    </Link>
  )
}
