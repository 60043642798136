"use client"
import React from 'react';

export default function Card({ children, className, ...props }) {
    return (
        <div className={`bg-[#D9D9D9] ${className}`} {...props}>
            {children}
        </div>
    );
}
