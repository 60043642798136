"use client"
import { useIsMobile } from "@/hooks/use-is-mobile"
import clsx from "clsx"
import PropTypes from "prop-types"
import { Image } from "../shared/image/image"

export function VerifyEmailLayout({
  children,
  bg_img_url_desktop,
  bg_img_url_mobile,
}) {
  const isMobile = useIsMobile()
  const bgImg = isMobile ? bg_img_url_mobile : bg_img_url_desktop

  return (
    <div className="relative mt-[58px] lg:mt-[72px] min-h-[calc(100vh-170px)]">
      <div
        className={clsx(
          "absolute left-0 right-0 top-[0px] w-full z-1 overflow-hidden",
          isMobile ? "h-[738px]" : "min-h-[520px] aspect-[1410/700]"
        )}
      >
        {bgImg && <Image alt="bg" src={bgImg} />}
      </div>
      {children}
    </div>
  )
}

VerifyEmailLayout.propTypes = {
  children: PropTypes.array,
  bg_img_url_desktop: PropTypes.array,
  bg_img_url_mobile: PropTypes.array,
}
