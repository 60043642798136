"use client"
import React from "react"
import { twMerge } from "tailwind-merge"
import Style from "./campaign-section.module.css"
import DOMPurify from "isomorphic-dompurify"
import PropTypes from "prop-types"
export default function CampaignSection({ children, className }) {
  return (
    <div
      data-testid="campaign-section"
      className={twMerge(
        `${Style.sectionTitle} text-[12px] tracking-[0.25px] leading-[13px] font-bold font-font-family-7 mb-[10px]`,
        className
      )}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(children) }}
    ></div>
  )
}

CampaignSection.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}
