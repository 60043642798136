import { useEffect } from 'react';

export const useElementWidthtVar = (ref, varName) => {
	useEffect(() => {
		if (ref.current) {
			const width = ref.current.clientWidth;
			const root = document.querySelector(':root');
			root.style.setProperty(varName, `${width}px`);
		}
	}, [ref, varName, ref?.current?.clientWidth]);
};
