"use client"
import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import ChallengeCard from "./components/challenge-card/challenge-card"
import "swiper/css"
import LoadMoreBtn from "@/components/events/event-list/load-more-btn"

function Skeleton() {
  return (
    <div className="block animate-pulse  h-full bg-bg-3 p-[4px] md:p-[9px] rounded-[10px] md:rounded-[15px] border-[1px] border-[#e6e6e6] md:flex gap-[10px]">
      <div className="grow-[1] relative overflow-hidden bg-[#D9D9D9] rounded-[10px] min-h-[123px] md:min-h-[146px] md:max-h-none w-full md:max-w-[196px] min-w-[16px] mb-[5px] md:mb-0"></div>
      <div className="p-[5px] flex flex-col gap-[5px] md:gap-[10px] w-full">
        <div className="flex max-w-[20%] items-center gap-[5px] bg-[#D9D9D9] lg:order-3 text-[12px] font-bold font-font-family-7 tracking-[0.25px] h-[16px] text-text-1 lg:mt-auto"></div>
        <div className="capitalize bg-[#D9D9D9] text-[14px] md:text-[16px] py-[5px] md:p-0 px-[2px] h-[20px] md:h-[22px] font-medium lg:order-2"></div>
        <div className="capitalize max-w-[50%] bg-[#D9D9D9] text-[14px] md:text-[16px] py-[5px] md:p-0 px-[2px] h-[20px] md:h-[22px] font-medium lg:order-2"></div>
        <div className="text-[10px] max-w-[30%] bg-[#D9D9D9] h-[14px] text-text-3 font-bold font-font-family-7 tracking-[0.025px] uppercase lg:order-1"></div>
      </div>
    </div>
  )
}

export default function UserChallenge({
  challengeItems = [],
  challengePaging,
  isLoadMore,
}) {
  // console.log("challengeItems", challengeItems)
  if (!isLoadMore && challengeItems.length === 0) return

  return (
    <>
      <div
        id="challenge"
        className="text-text-2 pl-[5px] text-[20px] font-medium font-font-family-6 mb-[10px] leading-[27px]"
      >
        Ikuti Tantangannya!
      </div>
      <ul className="relative mb-[30px] lg:mb-[40px] grid grid-cols-2 gap-[5px] lg:gap-[15px]">
        {/* <div className="absolute left-[-15px] right-[-15px]"> */}
        {challengeItems.map((item, index) => {
          return (
            <li data-testid={`challenge-card-${index}`} key={item.id}>
              <ChallengeCard
                item={item}
                // img={item.img}
                // description={item.description}
              />
            </li>
            // <SwiperSlide key={index} className={`!w-fit ${index === challengeItems.length - 1 ? '!mr-[10px]' : ''}`}>
            // </SwiperSlide>
          )
        })}
        {isLoadMore ? [1, 2, 3].map((item) => <Skeleton key={item} />) : null}
        {/* </div> */}
      </ul>
      {challengePaging.nextPage != null && !isLoadMore ? (
        <LoadMoreBtn
          cat="user-challenge"
          load_more_btn_label="Muat lebih banyak"
        />
      ) : null}
    </>
  )
}
