"use client"
import ButtonAction from "@/components/shared/button-action/button-action"
import React, { useEffect, useState } from "react"
import { Image } from "@/components/shared/image/image"
import DOMPurify from "isomorphic-dompurify"
import PropTypes from "prop-types"

import Styles from "./index.module.css"
import { useDatalayer } from "@/hooks/use-datalayer"
import { usePathname } from "next/navigation"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { Description } from "@/components/shared/description/description"
import { cn } from "@/lib/utils"
import { ClientApiList } from "@/lib/api/client-service"

export default function HowToLink({
  link_description,
  cta_label,
  setIsSuccess,
  isCustom = false,
}) {
  const datalayer = useDatalayer()
  const pathname = usePathname()
  const isMobile = useIsMobile()
  const [dataLink, setDataLink] = useState()

  const handleGetLink = async () => {
    try {
        const { data } = await ClientApiList.getMgMLink()
        setDataLink({ ...data.data.data })
    } catch (err) {
      console.log("[Error]", err)
    }
  }

  useEffect(() => {
    setIsSuccess(false)
    handleGetLink()

    return () => setIsSuccess(false)
  }, [])

  const handleCopy = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
      setIsSuccess(true)
      return //codes below wont be executed
    }
    const textArea = document.createElement("textarea")
    textArea.value = text

    document.body.appendChild(textArea)

    textArea.focus()
    textArea.select()

    document.execCommand("copy")

    document.body.removeChild(textArea)
    setIsSuccess(true)
  }

  return (
    <div
      className={cn(
        "text-text-4 border-transparent w-[calc(100%-20px)] lg:w-full xl:border-[1px] max-w-[609px] mx-auto lg:max-w-none xl:min-w-[463px] text-black rounded-[10px] p-[15px] xl:p-[14px] xl:m-0 xl:ml-[20px] xl:mb-[20px] xl:row-start-1 xl:col-start-1 self-end mb-[8px] relative overflow-hidden",
        isCustom ? "!p-0 !m-0 lg:p-0 lg:m-0 w-[100%] xl:min-w-[429px]" : ""
      )}
    >
      {!isCustom ? (
        <Image
          className="absolute inset-0 z-[-1] w-[100%]"
          alt="cover"
          fill
          style={{ objectFit: "cover" }}
          src={`/assets/${process.env.NEXT_PUBLIC_NAME}/mgm/bg-copy-${isMobile ? "mobile" : "desktop"}.png`}
        />
      ) : null}
      <div
        className={cn(
          `text-[16px] leading-[22px] xl:text-[24px] xl:leading-[32px] font-bold font-font-family-7 mb-[10px] xl:mb-[5px] text-text-4 ${Styles.title}`,
          isCustom ? `text-left ${Styles.custom}` : ""
        )}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            "<p><span><i>Copy</i></span> <i>Link</i> Ini</p>"
          ),
        }}
      ></div>
      <Description
        data-testid="how-to-link-description"
        className={cn(
          "text-[14px] xl:text-[16px] leading-[24px] mb-[10px] text-text-4",
          isCustom ? "text-left" : ""
        )}
        description={link_description}
      />
      <div className="xl:flex xl:items-center xl:gap-[10px]">
        <div
          className={cn(
            "!bg-bg-3/[0.2] py-[24px] xl:py-[15px] px-[14px] xl:px-[20px] rounded-[12px] bg-black/[0.2] flex items-center gap-[5px] mb-[10px] xl:m-0 xl:max-w-[300px] text-text-4 bg-white/[0.2]",
            isCustom ? "" : ""
          )}
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="13"
              viewBox="0 0 24 13"
              fill="none"
            >
              <path
                d="M9.5 9.42318H7C5.16667 9.42318 3.66667 7.92318 3.66667 6.08984C3.66667 4.25651 5.16667 2.75651 7 2.75651H9.5C10 2.75651 10.3333 2.42318 10.3333 1.92318C10.3333 1.42318 10 1.08984 9.5 1.08984H7C4.25 1.08984 2 3.33984 2 6.08984C2 8.83984 4.25 11.0898 7 11.0898H9.5C10 11.0898 10.3333 10.7565 10.3333 10.2565C10.3333 9.75651 10 9.42318 9.5 9.42318Z"
                className="fill-text-4"
              />
              <path
                d="M17.0003 1.08984H14.5003C14.0003 1.08984 13.667 1.42318 13.667 1.92318C13.667 2.42318 14.0003 2.75651 14.5003 2.75651H17.0003C18.8337 2.75651 20.3337 4.25651 20.3337 6.08984C20.3337 7.92318 18.8337 9.42318 17.0003 9.42318H14.5003C14.0003 9.42318 13.667 9.75651 13.667 10.2565C13.667 10.7565 14.0003 11.0898 14.5003 11.0898H17.0003C19.7503 11.0898 22.0003 8.83984 22.0003 6.08984C22.0003 3.33984 19.7503 1.08984 17.0003 1.08984Z"
                className="fill-text-4"
              />
              <path
                d="M7.83301 6.08974C7.83301 6.58974 8.16634 6.92308 8.66634 6.92308H15.333C15.833 6.92308 16.1663 6.58974 16.1663 6.08974C16.1663 5.58974 15.833 5.25641 15.333 5.25641H8.66634C8.16634 5.25641 7.83301 5.58974 7.83301 6.08974Z"
                className="fill-text-4"
              />
            </svg>
          </div>
          <div
            data-testid="how-to-link-label"
            className="overflow-hidden truncate text-ellipsis"
          >
            {dataLink?.mgm_url || ""}
          </div>
        </div>
        <ButtonAction
          data-testid="how-to-link-button"
          onClick={() => {
            datalayer.push({
              event: "general_event",
              event_name: `click_${cta_label.split("<p><em>").join("").split("</em></p>").join("").split(" ").join("_").toLowerCase()}`, // e.g click_copy_link
              feature: "special landing page",
              page_section: "mgm",
              page_path: pathname,
              campaign_name: "all", // e.g ride your flavour 2023
              engagement_type: "interaction",
            })
            handleCopy(dataLink?.mgm_url || "")
          }}
          className="py-[14px] leading-[22px] xl:py-[15px] xl:min-w-[123px] xl:max-w-none w-full"
          intent={isCustom ? "secondary" : "primary"}
        >
          <Description description={cta_label} />
        </ButtonAction>
      </div>
    </div>
  )
}

HowToLink.propTypes = {
  link_title: PropTypes.string,
  link_description: PropTypes.string,
  link: PropTypes.string,
  bg_link_img_url: PropTypes.string,
  cta_label: PropTypes.string,
}
