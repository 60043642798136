"use client"

import { useIsDesktop } from "@/hooks/use-is-mobile";
import clsx from "clsx";

export function NotFoundPageLayout() {
  const isDesktop = useIsDesktop(); 
  return (
    <div className={clsx("grid place-items-center text-4xl", isDesktop ? "pt-[72px] min-h-[calc(100vh-170px)]" : "pt-[58px]")}>404</div>
  );
}
