"use client"
import React, { useEffect, useState } from "react"
import PopupAction from "../shared/popup-action/popup-action"
import ButtonAction from "../shared/button-action/button-action"
import useRenderForm from "@/hooks/useRenderForm"
import { toast } from "react-toastify"
import { ToastComponent } from "../toast"
import UseSoftgate from "@/hooks/use-softgate"
import { isObjEmpty } from "@/helper/utils"
import { usePathname } from "next/navigation";

export function SoftGate({
  title,
  description,
  form_label,
  form_type,
  cta_label,
}) {
  const [mounted, setMounted] = useState(false)
  const [, handleRenderInput] = useRenderForm()
  const pathname = usePathname()
  const {
    isOpen,
    register,
    getValues,
    errors,
    formError,
    reset,
    monthOptions,
    yearOptions,
    onSubmit,
    watch,
  } = UseSoftgate({
    key: "soft_age_gate",
    pathname
  })
  const toastId = React.useRef(null)

  useEffect(() => {
    setTimeout(() => {
      toastId.current = toast(<ToastComponent />)
    }, 500)
  }, [])

  /* handle-nextjs-13-hydration-failed  */
  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) return <></>

  return (
    <PopupAction
      className={"lg:max-w-[460px] !bg-bg-3"}
      isShowing={isOpen}
      href="#"
      showCloseBtn={false}
      zIndex="z-20"
    >
      {formError ? (
        <>
          <div className="text-text-2 text-[14px] leading-[20px] lg:leading-[24px] mb-[20px]">
            {formError}
          </div>
          <ButtonAction onClick={reset} type="submit" intent="primary">
            Tutup
          </ButtonAction>
        </>
      ) : (
        <>
          <div
            data-testid="softgate-title"
            className="text-text-2 text-[20px] lg:text-[24px] lg:leading-[32px] font-bold font-font-family-7 mb-[10px]"
          >
            {title}
          </div>
          <div
            data-testid="softgate-description"
            className="text-text-2 text-[14px] leading-[20px] lg:leading-[24px] mb-[20px]"
          >
            {description}
          </div>
          <div
            data-testid="softgate-form-label"
            className="text-text-2 text-[14px] leading-[20px] mb-[20px] font-bold font-font-family-7"
          >
            {form_label}
          </div>
          <form
            method="POST"
            className="lg:max-w-[304px] mx-auto"
            onSubmit={onSubmit}
          >
            {handleRenderInput(form_type, {
              register,
              getValues,
              monthOptions,
              yearOptions,
              containerClass: "mb-[0]",
            })}

            <div
              className={`${isObjEmpty(errors) ? "hidden" : "block"} p-[10px] text-text-1 flex gap-[5px] items-center rounded-[5px] relative`}
            >
              <div className="absolute inset-0 z-[-1] bg-text-1 opacity-50"></div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <rect x="5" y="5" width="10" height="10" fill="white" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.61717 2.18836C7.28565 2.18778 6.96748 2.31891 6.73264 2.55292L2.88501 6.38709C2.65017 6.6211 2.51792 6.93881 2.51734 7.27033L2.50781 12.7022C2.50723 13.0337 2.63837 13.3519 2.87238 13.5867L6.70655 17.4343C6.94056 17.6692 7.25827 17.8014 7.58979 17.802L13.0216 17.8115C13.3532 17.8121 13.6713 17.681 13.9062 17.447L17.7538 13.6128C17.9886 13.3788 18.1209 13.0611 18.1215 12.7296L18.131 7.29771C18.1316 6.96619 18.0004 6.64802 17.7664 6.41319L13.9323 2.56555C13.6983 2.33072 13.3805 2.19846 13.049 2.19788L7.61717 2.18836ZM7.49823 6.0937L6.41316 7.17877L9.23434 9.99995L6.41316 12.8211L7.49823 13.9062L10.3194 11.085L13.1406 13.9062L14.2257 12.8211L11.4045 9.99995L14.2257 7.17877L13.1406 6.0937L10.3194 8.91488L7.49823 6.0937Z"
                  className="fill-text-1"
                />
              </svg>
              <div>Pilih Bulan dan Tahun Lahir Anda</div>
            </div>
            <ButtonAction
              data-testid="softgate-cta"
              className="mt-[20px]"
              type="submit"
              intent={
                watch("month") === "" || watch("year") === ""
                  ? "primary_disable"
                  : "primary"
              }
              disabled={watch("month") === "" || watch("year") === ""}
            >
              {cta_label}
            </ButtonAction>
          </form>
        </>
      )}
    </PopupAction>
  )
}
