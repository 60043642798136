"use client";
import React from "react";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";

export default function TextInput({
  className,
  disabled = false,
  value,
  register,
  name,
  validation,
  readOnly,
  ...props
}) {
  const registerField = register
    ? {
      ...register(name, { ...validation }),
      disabled,
      readOnly,
      ...props,
    }
    : { disabled, value, readOnly, ...props };

  return (
    <input
      className={twMerge(
        `w-[100%] bg-bg-3 ${readOnly ? '!bg-cta-4 !text-text-3' : ''} disabled:bg-cta-4 focus:outline-cta-1 border-cta-4 hover:border-cta-4 focus:border-cta-1 active:border-cta-1 text-[14px] disabled:text-text-3 placeholder:text-text-3 focus:text-text-2 hover:text-text-2  active:text-text-2 leading-[20px] p-[10px] border-[1px] rounded-[5px] mb-[10px] text-text-3`,
        className
      )}
      {...registerField}
    />
  );
}

TextInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  register: PropTypes.func,
  name: PropTypes.string,
};
