"use client"
import { Image } from "@/components/shared/image/image"
import { MissionActivityItem } from "../about/mission-card/mission-card"
import { Description } from "../shared/description/description"
import { useIsMobile } from "@/hooks/use-is-mobile"
import clsx from "clsx"
import PropTypes from "prop-types"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { ClientApiList } from "@/lib/api/client-service"
import { useEffect, useState } from "react"
import { cn } from "@/lib/utils"
import { useQuery } from "@tanstack/react-query"

export function AboutMission(props) {
  const { icon_url, title, subtitle, description, data = {} } = props
  const isMobile = useIsMobile()
  const { isLogin, isLoading } = useIsLogin()

  const bgImg = isMobile ? data?.bg_img_url_mobile : data?.bg_img_url_desktop

  const [pointUb, setPointUb] = useState("")

  const { data: dataUser } = useQuery({
    queryKey: ["profileData"],
    queryFn: async () => {
      const { data } = await ClientApiList.getProfileData()
      return data?.data.data
    },
    enabled: Boolean(isLogin && !isLoading),
  })

  const updatePointUb = async () => {
    const { data } = await ClientApiList.getPointUb()
    setPointUb(data?.data?.point)
  }

  useEffect(() => {
    if (!isLogin && !isLoading) updatePointUb()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLogin, isLoading])

  return (
    <div
      className={clsx(
        "w-full h-full px-[10px] ",
        isMobile ? "mt-[20px] mb-[58px]" : "my-[30px]"
      )}
    >
      <div className="w-full h-full relative bg-bg-6 rounded-[15px] text-text-2 py-[20px]">
        {bgImg && (
          <div className={clsx("absolute top-0 bottom-0 left-0 right-0 z-1")}>
            {bgImg && <Image alt="bg" src={bgImg} />}
          </div>
        )}
        <div className="relative z-1 w-full">
          <div className="flex items-center gap-[10px] px-[15px]">
            <div
              className={clsx(
                "relative aspect-square",
                isMobile ? "w-[72px]" : "w-[88px]"
              )}
            >
              <Image alt="about-icon" src={icon_url} />
            </div>
            <h2
              className={clsx(
                "font-bold tracking-[-1px] text-text-2 uppercase font-font-family-3",
                isMobile
                  ? cn(
                      "text-[30px] leading-[32px] w-[230px]",
                      process.env.NEXT_PUBLIC_NAME === "sampoernakretek"
                        ? "text-[24px] leading-[28px]"
                        : ""
                    )
                  : "text-[40px] leading-[48px] w-[300px]"
              )}
            >
              {title}
            </h2>
          </div>
          <div
            className={clsx(
              "px-[15px] text-text-2",
              isMobile ? "mt-[15px]" : "mt-[20px]"
            )}
          >
            {((isLogin && !isLoading && dataUser?.tier_id) ||
              (!isLogin && !isLoading && pointUb >= 0)) && (
              <p
                className={clsx(
                  "font-bold font-font-family-7 capitalize",
                  isMobile ? "text-base" : "text-lg"
                )}
              >
                {subtitle}
              </p>
            )}
            {((isLogin && !isLoading && dataUser?.tier_id) ||
              (!isLogin && !isLoading && pointUb >= 0)) && (
              <Description
                className={clsx(
                  "mt-[5px]",
                  isMobile ? "text-sm w-[325px]" : "text-base w-[750px]"
                )}
                description={description}
              />
            )}
          </div>
          <div
            className={clsx(
              "w-full h-full px-[15px] flex flex-wrap ",
              isMobile
                ? "justify-center gap-[10px] mt-[15px]"
                : "justify-start gap-[15px] mt-[20px]"
            )}
          >
            {data?.mission_activity?.map((item, index) => (
              <div
                key={index}
                className={clsx(
                  isMobile ? "basis-[calc(50%-5px)]" : "basis-normal w-[258px]",
                  isLogin && isMobile && "min-h-[220px]",
                  !isLogin && isMobile && "h-[166px]",
                  isLogin && !isMobile && "h-[245px]",
                  !isLogin && !isMobile && "h-[200px]"
                )}
              >
                <MissionActivityItem
                  image_url={item?.image_url}
                  title={item?.title}
                  description={item?.description}
                  isLogin={isLogin}
                  index={index}
                  href={item?.href}
                  cta={item?.cta}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

AboutMission.propTypes = {
  icon_url: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.object,
}
