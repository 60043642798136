"use client"
import React from "react"
import PropTypes from "prop-types"
export default function FormLabelGroup({ children, className }) {
  return (
    <div
      data-testid="form-label-group"
      className="text-[14px] leading-[20px] font-medium mb-[7px] flex items-center justify-between"
    >
      {Array.isArray(children) ? (
        children
      ) : (
        <label className={className}>{children}</label>
      )}
    </div>
  )
}

FormLabelGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arr,
}
