"use client"
import DOMPurify from "isomorphic-dompurify"
import Style from "./description.module.css"
import PropTypes from "prop-types"

export const Description = ({ className, description, ...props }) => {
  return (
    <div className={Style.wrapper} {...props}>
      <div
        data-testid="description"
        className={className}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
      ></div>
    </div>
  )
}

Description.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
}
