import React from "react"
import Style from "./slide-radio.module.css"

const SlideRadio = ({ value, handleChange }) => {
  return (
    <label
      className={Style.switch}
    >
      <input type="checkbox" value={value} onClick={() => handleChange((prev) => !prev)} checked={value}/>
      <span className={`${Style.slider} ${Style.round}`}></span>
    </label>
  )
}

export default SlideRadio
