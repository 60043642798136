"use client"
import React from "react"
import CardFlex from "../card-flex/card-flex"
import CardNav from "../card-flex/component/card-nav"
import ButtonAction from "@/components/shared/button-action/button-action"
import Card from "../card/card"
import { useIsMobile } from "@/hooks/use-is-mobile"
import clsx from "clsx"
import PropTypes from "prop-types"
import { Image } from "@/components/shared/image/image"
import { useReqUrl } from "@/hooks/auth/use-req-url"
import { useDatalayer } from "@/hooks/use-datalayer"
import { usePathname } from "next/navigation"
import Link from "@/components/shared/link/link"

export default function MenuContent({ login_cta, menu, branded = false }) {
  const isMobile = useIsMobile()
  const datalayer = useDatalayer()
  const pathname = usePathname()

  const { execute: login } = useReqUrl({
    redirectUrl: pathname.split("?")[0],
  })
  const { execute: register } = useReqUrl({
    page: "register",
    redirectUrl: pathname.split("?")[0],
    // platform: platform ? platform : router.query.all_access_platform,
    // redirectUrl: router?.asPath
  })

  const menuImg = isMobile
    ? `/assets/${process.env.NEXT_PUBLIC_NAME}/menu-slide/menu-bg-mobile.png`
    : `/assets/${process.env.NEXT_PUBLIC_NAME}/menu-slide/menu-bg-desktop.png`

  return (
    <div className={clsx(!isMobile && "bg-bg-3 p-[15px]")}>
      {!branded ? (
        <Card
          className={clsx(
            "rounded-[10px] mb-[20px] text-text-4 bg-bg-1 relative overflow-hidden w-full ",
            isMobile
              ? "pt-[11px] px-[5px] pb-[10px] text-center aspect-[355/306] flex flex-col items-center"
              : "py-[20px] px-[13px] aspect-[430/173]"
          )}
        >
          <div className="absolute top-0 right-0 bottom-0 z-[0]">
            <Image src={menuImg} alt="bg" />
          </div>
          <div
            className={clsx(
              "relative z-[1] mb-[10px] text-[15px] capitalize font-black font-font-family-8",
              isMobile && "px-[5px] mt-[135px]"
            )}
            data-testid="login-cta-title"
          >
            {login_cta?.title}
          </div>
          <div
            data-testid="login-cta-description"
            className={clsx(
              "relative z-[1] lg:mb-[20px] text-[14px] leading-[22px] font-medium font-font-family-6",
              isMobile && `mx-auto px-[5px] ${process.env.NEXT_PUBLIC_NAME === 'djisamsoe'? 'mb-[5px]':'mb-[10px]'}`
            )}
          >
            {login_cta?.description}
          </div>
          <div
            className={clsx(
              "relative z-[5] flex gap-[10px] w-full",
              isMobile && "items-center justify-between px-[5px]"
            )}
          >
            <ButtonAction
              intent="primary"
              data-testid="register-button"
              className={!isMobile && "w-[136px]"}
              onClick={() => {
                datalayer.push({
                  event: `general_event`,
                  event_name: "click_registration_button",
                  feature: "registration",
                  button_name: "daftar",
                  page_path: pathname,
                  campaign_name: "all",
                })
                register()
              }}
            >
              {login_cta?.signup_btn_label}
            </ButtonAction>
            <ButtonAction
              data-testid="login-button"
              intent="secondary"
              onClick={() => {
                // console.log("cleck")
                datalayer.push({
                  event: `general_event`,
                  event_name: "click_login_button",
                  feature: "login",
                  button_name: "masuk",
                  page_path: pathname,
                  campaign_name: "all",
                })
                login()
              }}
              className={!isMobile && "w-[136px]"}
            >
              {login_cta?.signin_btn_label}
            </ButtonAction>
          </div>
        </Card>
      ) : null}
      <ul className="grid grid-rows-1 mb-[20px] gap-[10px]">
        {menu?.map((menu, index) => (
          <Link href={menu?.href} key={menu?.title}>
            <li
              data-testid={`menu-item-${index}`}
              className="shadow-[1px_1px_24px_0_rgba(204,204,204,0.10),_0_1px_2px_0_rgba(204,204,204,0.10)] rounded-[10px]"
            >
              <CardFlex
                title={menu?.title}
                description={menu?.description}
                icon_url={menu?.icon_url}
              >
                <CardNav isOutsideUrl={menu?.is_outside_url} />
              </CardFlex>
            </li>
          </Link>
        ))}
      </ul>
    </div>
  )
}

MenuContent.propTypes = {
  login_cta: PropTypes.object,
  menu: PropTypes.array,
  branded: PropTypes.bool,
}
