"use client"
import React from "react"
import clsx from "clsx"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react"
import Portal from "../shared/portal"

/**
 * @typedef {Object} LoyaltyPopupProps
 * @prop {keyof typeof popupType} type
 * @props {boolean} [isOpen]
 * @prop {() => void} onClick
 * @property {Function} onClose - function to be called when overlay is clicked
 */

/**
 * @type {React.FC<LoyaltyPopupProps>}
 */

export const ToastComponent = ({
  desc = "",
  title = "",
  onClick = () => {},
  isOpen = false,
  onShow = () => {},
  onClose = () => {},
}) => {
  const [stop, setStop] = useState(false)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (isOpen) {
      onShow?.()
      setStop(false)
      setShow(true)
    } else if (!isOpen) {
      setShow(false)
      onClose?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const variants = {
    open: {
      opacity: 1,
      x: 0,
      y: -100,
      scale: 1,
      rotate: 0,
    },
    closed: {
      opacity: 0,
      x: 0,
      y: 0,
      scale: 1,
      rotate: 0,
    },
  }

  return (
    <AnimatePresence>
      <Portal>
        <div
          className={clsx(
            "w-full max-w-[460px] fixed bottom-[-50px] left-[50%] -translate-x-1/2 z-50 ",
            show ? "block" : "hidden"
          )}
        >
          <motion.div animate={isOpen ? "open" : "closed"} variants={variants}>
            <div className="px-[10px] py-[15px] bg-bg-2 mx-[10px] rounded-[5px]">
              <div className="flex items-center gap-[10px]">
                <div className="min-h-[40px] min-w-[5px] rounded-[5px] bg-[#32D74B]"></div>
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="mdi:success-circle">
                    <rect
                      id="Rectangle 22027"
                      x="5"
                      y="7"
                      width="18"
                      height="13"
                      fill="white"
                    />
                    <path
                      id="Vector"
                      d="M13.9997 2.33325C7.58301 2.33325 2.33301 7.58325 2.33301 13.9999C2.33301 20.4166 7.58301 25.6666 13.9997 25.6666C20.4163 25.6666 25.6663 20.4166 25.6663 13.9999C25.6663 7.58325 20.4163 2.33325 13.9997 2.33325ZM11.6663 19.8333L5.83301 13.9999L7.47801 12.3549L11.6663 16.5316L20.5213 7.67659L22.1663 9.33325L11.6663 19.8333Z"
                      fill="#32D74B"
                    />
                  </g>
                </svg>
                <div className="grow-[1]">
                  <div
                    data-testid="toast-title"
                    className="text-[14px] leading-[20px] text-text-4 font-bold font-font-family-7"
                  >
                    {title}
                  </div>
                  <div
                    data-testid="toast-desc"
                    className="text-[14px] leading-[20px] text-text-3 font-medium font-font-family-6"
                  >
                    {desc}
                  </div>
                </div>
                <button
                  onClick={() => {
                    onClick()
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="Frame 62718" opacity="0.75">
                      <path
                        id="Vector"
                        d="M3.65728 3.68636C3.86519 3.47915 4.14701 3.36277 4.44084 3.36277C4.73468 3.36277 5.0165 3.47915 5.2244 3.68636L9.98489 8.43681L14.7454 3.68636C14.8809 3.54071 15.0533 3.43423 15.2444 3.37815C15.4355 3.32207 15.6382 3.31846 15.8311 3.36769C16.0241 3.41693 16.2002 3.51719 16.3408 3.65791C16.4815 3.79864 16.5815 3.97462 16.6303 4.1673C16.6798 4.35965 16.6763 4.56172 16.6204 4.75228C16.5644 4.94283 16.4581 5.11483 16.3125 5.25017L11.552 10.0006L16.3125 14.7511C16.4584 14.8863 16.5651 15.0584 16.6213 15.249C16.6775 15.4397 16.6812 15.642 16.6318 15.8346C16.5825 16.0271 16.482 16.2028 16.341 16.3432C16.2 16.4836 16.0236 16.5834 15.8305 16.6321C15.6378 16.6814 15.4353 16.678 15.2443 16.6221C15.0534 16.5663 14.881 16.4602 14.7454 16.3149L9.98489 11.5644L5.2244 16.3149C5.01408 16.5102 4.73606 16.6166 4.44878 16.6117C4.1615 16.6067 3.88733 16.4908 3.68389 16.2883C3.48099 16.0853 3.36485 15.8117 3.35989 15.5251C3.35493 15.2384 3.46153 14.961 3.65728 14.7511L8.41777 10.0006L3.65728 5.25017C3.44964 5.04271 3.33301 4.76148 3.33301 4.46826C3.33301 4.17505 3.44964 3.89382 3.65728 3.68636Z"
                        fill="#FEE9EA"
                      />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      </Portal>
    </AnimatePresence>
  )
}
