"use client"
import React, { useEffect, useState } from "react"
import { Image } from "@/components/shared/image/image"
import PropTypes from "prop-types"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { usePathname } from "next/navigation"
import { useShowLoginPopup } from "@/hooks/auth/use-login-popup"
import { useRedirect } from "@/hooks/use-redirect"
import { Spinner } from "../shared/spinner"
import { useIsMobile } from "@/hooks/use-is-mobile"
import clsx from "clsx"

export function ArticleListLayout({
  children,
  bg_img_url_mobile,
  bg_img_url_desktop,
}) {
  const { isLoading, isLogin } = useIsLogin()
  const pathname = usePathname()
  const isMobile = useIsMobile()

  const [showPage, setShowPage] = useState(false)

  const checkUbPath = pathname.includes("/ub") && isLogin && !isLoading
  const checkBrPath = pathname.includes("/br/archive") && !isLogin && !isLoading
  const bgImg = isMobile ? bg_img_url_mobile : bg_img_url_desktop

  useShowLoginPopup({
    showWhen: checkBrPath,
    closePath: "/ub",
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
  })

  useRedirect({
    redirectWhen: checkUbPath,
    path: "/br/archive",
    withHash: true,
    withQuery: true,
  })

  useEffect(() => {
    if (!isLoading) setShowPage(true)
  }, [isLoading])

  return (
    <div className="relative pt-[58px] lg:pt-[72px]">
      <div
        className={clsx(
          "absolute left-0 right-0 w-full bg-bg-1 z-1 overflow-hidden",
          isMobile
            ? "h-[738px] rounded-b-[10px] top-[0px] "
            : "min-h-[520px] aspect-[1410/520] top-[16px] rounded-[15px]"
        )}
      >
        {bgImg && <Image alt="bg" src={bgImg} />}
      </div>
      {showPage ? (
        children
      ) : (
        <div className="h-[calc(100vh-170px)] flex items-center">
          <Spinner />
        </div>
      )}
    </div>
  )
}

ArticleListLayout.propTypes = {
  children: PropTypes.object,
  bg_img_url_mobile: PropTypes.string,
  bg_img_url_desktop: PropTypes.string,
}
