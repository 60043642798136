"use client"
import { useIsTablet } from "@/hooks/use-is-mobile"
import Style from "./graphic-health.module.scss"
import { useRef } from "react"
import { Image } from "@/components/shared/image/image"
import { useElementWidthtVar } from "@/hooks/use-element-height-var-2"

const ghwMobile = "/assets/iqos/graphic-health/GHW-mobile.svg"
const ghwDesktop = "/assets/iqos/graphic-health/GHW-desktop.svg"

const GraphicHealth = () => {
  const isTablet = useIsTablet()

  const ref = useRef()
  useElementWidthtVar(ref, "--graphic-width")

  return (
    <div ref={ref} className={`${Style.container} border-t-[1.5px] !bg-white`} id="graphic-health-iqos">
      <Image
        src={isTablet ? ghwMobile : ghwDesktop}
        alt="ghw image"
        layout="fill"
        objectFit="contain"
        className='relative -top-[1px]'
      />
    </div>
  )
}

export default GraphicHealth
