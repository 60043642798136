"use client"
import PropTypes from "prop-types"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { useShowLoginPopup } from "@/hooks/auth/use-login-popup"

export function UserProfileLeaderboardPageLayout({ children }) {
  const { isLoading, isLogin } = useIsLogin()

  useShowLoginPopup({
    showWhen: !isLogin && !isLoading,
    closePath: "/",
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
  })
  return (
    <div className="relative lg:flex flex-row-reverse mt-[58px] lg:mt-[72px]">
      {/* <div className="absolute rounded-b-[10px] top-[0px] left-0 right-0 h-[679px] w-[100%] bg-[#646464] z-1"></div> */}
      {children}
    </div>
  )
}

UserProfileLeaderboardPageLayout.propTypes = {
  children: PropTypes.object,
}
