"use client"
import { Image } from "@/components/shared/image/image"
import React from "react"
import PropTypes from "prop-types"

export default function CardFlex({ title, description, icon_url, children }) {
  return (
    <div className="group p-[10px] rounded-[10px] bg-bg-3 flex justify-between items-center text-text-2 gap-[10px] hover:bg-bg-6">
      <div className="p-[1px] w-[48px] h-[48px] flex items-center justify-center rounded-[10px] bg-cta-2 group-hover:bg-bg-5">
        <div className="relative w-[46px] h-[46px] rounded-[9px] overflow-hidden">
          <Image data-testid="icon" alt="index" src={icon_url} />
        </div>
      </div>
      <div className="grow">
        <div
          data-testid="title"
          className="text-[16px] font-bold font-font-family-7 text-text-2 group-hover:text-text-1"
        >
          {title}
        </div>
        {description ? (
          <div
            data-testid="description"
            className="text-[12px] tracking-[0.25px] text-text-3 font-medium font-font-family-6 group-hover:text-text-2"
          >
            {description}
          </div>
        ) : null}
      </div>
      {children}
    </div>
  )
}

CardFlex.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon_url: PropTypes.string,
  children: PropTypes.object,
}
