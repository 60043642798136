"use client"
import React from "react"
import PropTypes from "prop-types"
import { Image } from "@/components/shared/image/image"
import { useIsMobile } from "@/hooks/use-is-mobile"
import clsx from "clsx"
import { Description } from "@/components/shared/description/description"

export default function HowToIntro({ title, imgUrl }) {
  const isMobile = useIsMobile()
  const imageBg = isMobile ? "how-to-bg.png" : "how-to-bg-desktop.png"
  return (
    <div className="relative border-[1px] border-bg-6 h-fit bg-bg-3 text-text-2 mb-[10px] lg:mb-0 z-[3] flex items-center lg:ml-0 gap-[10px] lg:gap-[20px] p-[9px] lg:p-[19px] rounded-[10px] lg:col-start-1 lg:row-start-2 max-w-[609px] w-[calc(100%-20px)] lg:w-full mx-auto lg:max-w-none overflow-hidden">
      {process.env.NEXT_PUBLIC_NAME === "magnum" ? (
        <Image
          className="absolute inset-0 z-[0]"
          alt="toa"
          objectFit="cover"
          src={`/assets/${process.env.NEXT_PUBLIC_NAME}/mgm/${imageBg}`}
        />
      ) : null}
      <div className="flex items-center justify-center min-w-[47px] lg:w-[50px] h-[47px] lg:h-[50px] rounded-[10px] relative overflow-hidden">
        <Image
          className="w-[100%] h-[100%]"
          alt="toa"
          objectFit="contain"
          src={`/assets/${process.env.NEXT_PUBLIC_NAME}/mgm/icon.png`}
        />
      </div>
      <Description
        data-testid="how-to-intro-title"
        className={clsx(
          "text-[16px] lg:text-[18px] leading-[23px] lg:leading-[27px] font-bold font-font-family-7 relative z-[1]",
          process.env.NEXT_PUBLIC_NAME === "magnum"
            ? "text-cta-2"
            : "text-text-2"
        )}
        description={title}
      />
    </div>
  )
}

HowToIntro.propTypes = {
  title: PropTypes.string,
  imgUrl: PropTypes.string,
}
