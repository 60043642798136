"use client";
import React from "react";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";
import { Image } from "@/components/shared/image/image";
export default function CampaignCard({ className, children, wrapperClass, img }) {
  return (
    <div
      data-testid="campaign-card"
      className={twMerge(
        "p-[14px] border-[1px] lg:p-[25px] rounded-[10px] md:rounded-[15px] mb-[20px] z-2 relative overflow-hidden md:h-[100%] bg-bg-3 border-cta-4 text-text-2",
        className
      )}
    >
      <div className="absolute left-[0] right-[0] top-[0] bottom-[0] z-[1]">
        {img ?
          <div className="absolute inset-0">
            <Image src={img} alt="cover" />
          </div>
          : null}
      </div>
      <div className={twMerge('relative z-[3] w-[100%] h-[100%]', wrapperClass)}>{children}</div>
    </div>
  );
}

CampaignCard.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  wrapperClass: PropTypes.string
};
