"use client"
import Style from "./arrow.module.scss"
import clsx from "clsx"
import PropTypes from "prop-types"

export const ArrowLeft = ({ prevSlide, activeIndex }) => {
  return (
    <button
      className={clsx(Style.slider_button)}
      disabled={activeIndex === 0}
      onClick={() => {
        prevSlide()
      }}
    >
      <SvgArrowLeft opacity={activeIndex === 0 ? "0.25" : "1"} />
    </button>
  )
}

export const ArrowRight = ({ nextSlide, activeIndex, itemsLength }) => {
  return (
    <button
      className={clsx(Style.slider_button, Style.right)}
      disabled={activeIndex === itemsLength - 1}
      onClick={() => {
        nextSlide()
      }}
    >
      <SvgArrowRight opacity={activeIndex === itemsLength - 1 ? "0.25" : "1"} />
    </button>
  )
}

const SvgArrowLeft = ({ opacity }) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={opacity}
        d="M20 29C20 29.4032 20.1472 29.7581 20.4581 30.0645L33.4315 42.5645C33.7096 42.8548 34.0695 43 34.4949 43C35.3456 43 36 42.371 36 41.5323C36 41.1129 35.82 40.7581 35.5583 40.4839L23.6483 29L35.5583 17.5161C35.82 17.2419 36 16.871 36 16.4677C36 15.629 35.3456 15 34.4949 15C34.0695 15 33.7096 15.1452 33.4315 15.4194L20.4581 27.9355C20.1472 28.2258 20 28.5968 20 29Z"
        fill="#FFFDFB"
      />
    </svg>
  )
}

const SvgArrowRight = ({ opacity }) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={opacity}
        d="M36 29C36 29.4032 35.8528 29.7581 35.5419 30.0645L22.5685 42.5645C22.2904 42.8548 21.9305 43 21.5051 43C20.6544 43 20 42.371 20 41.5323C20 41.1129 20.18 40.7581 20.4417 40.4839L32.3517 29L20.4417 17.5161C20.18 17.2419 20 16.871 20 16.4677C20 15.629 20.6544 15 21.5051 15C21.9305 15 22.2904 15.1452 22.5685 15.4194L35.5419 27.9355C35.8528 28.2258 36 28.5968 36 29Z"
        fill="white"
      />
    </svg>
  )
}

ArrowLeft.propTypes = {
  nextSlide: PropTypes.func,
  activeIndex: PropTypes.number,
}

ArrowRight.propTypes = {
  nextSlide: PropTypes.func,
  activeIndex: PropTypes.number,
  itemsLength: PropTypes.number,
}

SvgArrowLeft.propTypes = {
  opacity: PropTypes.string,
}

SvgArrowRight.propTypes = {
  opacity: PropTypes.string,
}
