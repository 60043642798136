"use client"
import React from "react"
import ExperienceCard from "../experience-card/experience-card"
import PropTypes from "prop-types"

export default function ExclusiveList({ items, isLoading, isLoadMore }) {
  const itemsToRender = () => {
    if (isLoading)
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index, arr) => (
        <li data-testid={`skeleton-card-${index}`} key={item}>
          <SkeletonCard index={index} arr={arr} />
        </li>
      ))
    else if (items.length > 0)
      return items.map((item, index, arr) => (
        <li data-testid={`experience-card-${index}`} key={item.uuid}>
          <ExperienceCard
            title={item.place}
            location={item.location_desc}
            date={item.ex_date}
            openHour={item.start_time}
            closeHour={item.end_time}
            img={item.mobile_image_url}
            mobile_img={item.desktop_image_url}
            isLastElem={index === items.length - 1}
            index={index}
          />
        </li>
      ))
    else
      return (
        <p
          data-testid="empty"
          className="w-full text-base mt-[32px] text-center"
        >
          No Exclusive Item
        </p>
      )
  }

  return (
    <ul className="bg-bg-3 p-[10px] lg:p-[20px] mb-[10px]">
      {itemsToRender()}
      {isLoadMore
        ? [1, 2, 3].map((item, index, arr) => {
            return (
              <li key={item.uuid}>
                <SkeletonCard index={index} arr={arr} />
              </li>
            )
          })
        : null}
    </ul>
  )
}

function SkeletonCard({ index, arr }) {
  return (
    <div
      className={`animate-pulse flex items-center gap-[10px] lg:gap-[15px] bg-bg-3 ${!index == arr.length - 1 ? "mb-[10px] lg:mb-[15px]" : ""} text-text-2 p-[5px] lg:rounded-[10px] overflow-hidden`}
    >
      <div className="rounded-[5.5px] lg:rounded-[8.5px] bg-[#D9D9D9] w-[61px] lg:w-[90px] h-[61px] lg:h-[90px] relative overflow-hidden"></div>
      <div className="grow-[1]">
        <div className="rounded-[5.5px] w-[50%] bg-[#D9D9D9] mb-[5px] lg:mb-[10px] h-[19px] lg:h-[27px]"></div>
        <div className="rounded-[5.5px] w-[80%] bg-[#D9D9D9] mb-[5px] lg:mb-[10px] h-[16px] lg:h-[22px]"></div>
        <div className="rounded-[5.5px] bg-[#D9D9D9] w-[70%] h-[16px] lg:h-[22px]"></div>
      </div>
    </div>
  )
}

ExclusiveList.propTypes = {
  items: PropTypes.array,
  isLoading: PropTypes.bool,
  isLoadMore: PropTypes.bool,
}
