"use client"
import React from "react"
import PropTypes from "prop-types"
import CampaignCard from "../campaign-intro/campaign-card/campaign-card"
import CampaignSection from "../campaign-intro/campaign-card/components/campaign-section"
import CampaignTitle from "../campaign-intro/campaign-card/components/campaign-title"
import CampaignDescription from "../campaign-intro/campaign-card/components/campaign-description"
import ButtonAction from "../shared/button-action/button-action"
import { Image } from "../shared/image/image"
import Link from "@/components/shared/link/link"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { cn } from "@/lib/utils"
import { useDatalayer } from "@/hooks/use-datalayer"
import { usePathname } from "next/navigation"
import { urlToCampaign } from "@/helper/utils";
export function CampaignExtraPoin({
  section_title,
  href,
  bg_img_url_mobile,
  bg_img_url_desktop,
  title,
  description,
  btn_label,
  is_dark,
}) {
  const isMobile = useIsMobile()
  const datalayer = useDatalayer()
  const pathname = usePathname()
  // console.log(typeof is_dark, is_dark, 'is-dark');
  return (
    <CampaignCard
      className={`max-w-[802px] lg:max-w-none lg:col-span-2 !p-0 lg:my-[20px] lg:max-h-[227px] xl:max-h-[197px] !border-cta-4 ${String(is_dark).toLowerCase() === "false" ? "text-text-4" : "!text-text-2"}`}
    >
      <div className="lg:flex lg:items-center lg:gap-[42px] p-[14px] md:p-[20px]  lg:py-[23px] lg:pl-[30px] lg:pr-[38px]">
        <Image
          src={isMobile ? bg_img_url_mobile : bg_img_url_desktop}
          alt="Extra Poin"
          className="absolute inset-0 z-[-1]"
        />
        <div className="hidden relative lg:block w-[164px] h-[149px] bg-bg-3 ml-[17px] bg-transparent"></div>
        <div>
          <CampaignSection
            className={cn(
              `text-[20px] leading-[27px] md:text-[16px] md:leading-[22px] md:mb-[5px] ${String(is_dark).toLowerCase() === "false" ? "text-text-4" : "!text-text-2"}`,
              process.env.NEXT_PUBLIC_NAME === "amild"
                ? "font-font-family-3"
                : "font-font-family-7"
            )}
          >
            {process.env.NEXT_PUBLIC_NAME === "amild"
              ? String(section_title).toUpperCase()
              : section_title}
          </CampaignSection>
          {/* {`<p><span>${section_title.split(" ")[0]}</span> ${section_title.split(" ")[1]}</p>`}</CampaignSection> */}
          <CampaignTitle
            className={`text-[14px] md:text-[24px] leading-[20px] md:leading-[32px] font-medium font-font-family-6 mb-[5px] ${String(is_dark).toLowerCase() === "false" ? "text-text-4" : "!text-text-2"}`}
          >
            {title}
          </CampaignTitle>
          <CampaignDescription
            className={`text-[12px] md:text-[16px] leading-[16px] mb-[20px] md:mb-[18px] md:leading-[25px] ${String(is_dark).toLowerCase() === "false" ? "text-text-4" : "!text-text-2"}`}
          >
            {description}
          </CampaignDescription>
          <Link href="/mgm" className="block">
            <ButtonAction
              onClick={() =>
                datalayer.push({
                  event: "general_event",
                  event_name: `click_${btn_label.toLowerCase().split(" ").join("_")}`,
                  feature: "hero engagement page",
                  page_section: "campaign page",
                  page_path: pathname,
                  engagement_type: "interaction",
                  campaign_name: pathname.split("/")[1].split("-").join(" "),
                })
              }
              intent={`${String(is_dark).toLowerCase() === "false" ? "secondary" : "primary"}`}
              className="leading-[21px] lg:max-w-[279px] lg:py-[9px] lg:leading-[20px] lg:text-[14px] lg:max-w-[214px]"
            >
              {btn_label}
            </ButtonAction>
          </Link>
        </div>
      </div>
    </CampaignCard>
  )
}

CampaignExtraPoin.propTypes = {
  section_title: PropTypes.string,
  href: PropTypes.string,
  bg_img_url: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  btn_label: PropTypes.string,
}
