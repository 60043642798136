"use client"
import React, { useEffect, useRef, useState } from "react"
import {
  // Dots,
  NextButton,
  SwiperComp,
  PrevButton,
} from "../swiper/swiper"
import Link from "@/components/shared/link/link"
// import PrevButton from '../carousel/buttons/prevButton';
import { SwiperSlide } from "swiper/react"
// import Link from "@/components/shared/link/link"
import { Image } from "../shared/image/image"
import ButtonAction from "../shared/button-action/button-action"
import { useIsMobile } from "@/hooks/use-is-mobile"
import PropTypes from "prop-types"
import { ClientApiList } from "@/lib/api/client-service"
import { useRouter } from "next/navigation"
import { usePathname, useSearchParams } from "next/navigation"
import { getGALinker, getGL } from "@/helper/ga"
import { useReqUrl } from "@/hooks/auth/use-req-url"
import { cn } from "@/lib/utils"
import { useDatalayer } from "@/hooks/use-datalayer"
export default function CampaignRedirect(props) {
  const { replace } = useRouter()
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const isMobile = useIsMobile()
  const datalayer = useDatalayer()
  const swiperRef = useRef()
  const [activeIndex, setActiveIndex] = useState(0)
  const [isAspaceLoading, setIsAspaceLoading] = useState(false)
  const [campaingData, setCampaignData] = useState(props?.data?.doc || [])

  const { execute: autoLogin } = useReqUrl({
    redirectUrl: `${pathname}?to=aspace`,
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
    page: "login",
  })

  const handleDataLayer = () => {
    datalayer.push({
      event: "general_event",
      event_name: "click_content",
      feature: "hero engagement page",
      content_section: "campaign page",
      content_brand: "branded", // e.g branded or unbranded
      content_type: "not available", // e.g article, carousel, video, etc
      content_category: "not available", // e.g music, travel, automotive, etc
      content_position: 1, // e.g 1, 2, 3, etc (integer)
      content_name: campaingData[activeIndex]?.doc_content_title?.toLowerCase(), // e.g the_ultimate_xperience, etc
      publish_date: "not available", // e.g 23-10-2023 (date of content publish)
      campaign_name: campaingData[activeIndex]?.campaign?.toLowerCase(), // e.g ride your flavour 2023
      engagement_type: "content",
    })
  }

  const handleRedirectASpace = async () => {
    handleDataLayer()
    setIsAspaceLoading(true)
    const {
      data: { data: auth_data },
    } = await ClientApiList.getAuthData({
      ga: getGALinker(),
      gl: getGL(),
      platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
      redirect_url: `${process.env.NEXT_PUBLIC_META_URL}${pathname}`,
    })
    const { data, error } = await ClientApiList.getAspaceToken({
      auth_data: auth_data.data.auth_data,
    })
    const redirectASpace = data?.data.redirect_url

    if (redirectASpace) {
      window
        .open(
          redirectASpace,
          "_self"
          // "_blank" // <- This is what makes it open in a new window.
        )
        .focus()
      setIsAspaceLoading(false)
      replace(pathname)
    } else if (error?.code === 500) {
      autoLogin()
    } else {
      setIsAspaceLoading(false)
    }
  }

  useEffect(() => {
    if (searchParams.get("to") === "aspace") {
      handleRedirectASpace()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  return (
    <div className="relative z-[1] bg-bg-3 border-[1px] md:border-cta-4 rounded-[10px] md:rounded-[15px] overflow-hidden md:grid grid-cols-2 grid-rows-[72px_1fr] md:w-[100%] col-span-2">
      <div className="py-[21px] px-[10px] md:p-[20px] flex justify-between items-center row-start-1 col-start-1">
        <div
          className={cn(
            "pl-[4px] text-[16px] md:text-[24px] font-bold font-font-family-7 leading-[22px] md:leading-[32px] text-text-2",
            process.env.NEXT_PUBLIC_NAME === "amild"
              ? "font-font-family-3"
              : "font-font-family-7"
          )}
        >
          {process.env.NEXT_PUBLIC_NAME === "amild"
            ? String(props?.section_title).toUpperCase()
            : props?.section_title}
        </div>
        <div className="flex items-center gap-[5px]">
          {[1, 2, 3].map((item) => (
            // <Image
            //   key={item?.key}
            //   className="!w-[22px] !h-[22px]"
            //   src="/assets/amild/icons/star.svg"
            //   alt="redirect"
            // />
            <div key={item?.key}>
              <StarIcon className={"fill-icon-1"} />
            </div>
          ))}
        </div>
      </div>
      {/* <div> */}
      <div className="relative bg-bg-2 min-h-[200px] md:min-h-[393px] w-[100%] row-start-1 col-start-2 row-span-2">
        {campaingData.length > 1 ? (
          <PrevButton className="" swiperRef={swiperRef} />
        ) : null}
        <SwiperComp
          spaceBetween={10}
          // autoplay={autoplay}
          setActiveIndex={setActiveIndex}
          ref={swiperRef}
          // slidesPerView={slidesPerView}
          // spaceBetween={spaceBetween}
          className="h-[100%]"
        >
          {campaingData.map((item, index) => {
            return (
              <SwiperSlide key={item}>
                {/* <Link href={item?.href} onClick={() => onClickDatalayer('test banner', index)}> */}
                <div
                  className={`h-[100%] min-h-[200px] md:min-h-[393px] h-full relative overflow-hidden`}
                >
                  <Image
                    // objectFit="contain"
                    alt={item?.doc_card_title}
                    className="z-[1] object-center absolute"
                    // src="/assets/dummy-bg.png"
                    src={isMobile ? item?.doc_mobile : item?.doc_desktop}
                  />
                </div>
                {/* </Link> */}
              </SwiperSlide>
            )
          })}
        </SwiperComp>
        {/* <Dots activeIndex={activeIndex} items={items.map((item, index) => ({ id: index }))} /> */}
        {campaingData.length > 1 ? <NextButton swiperRef={swiperRef} /> : null}
      </div>
      <div className="p-[15px] md:py-[40px] md:px-[20px] relative grid-cols row-start-2 col-start-1">
        <div className="absolute z-[0] inset-[0] bg-cta-4">
          <Image
            className="absolute inset-0"
            src={
              isMobile
                ? campaingData[activeIndex]?.doc_bg_mobile
                : campaingData[activeIndex]?.doc_bg_desktop
            }
            alt="redirect"
          />
        </div>
        <div className="relative w-fit font-medium font-font-family-6 p-[10px] md:text-[14px] md:leading-[19px] rounded-[10px] bg-cta-3 text-text-1 mb-[10px] md:mb-[15px]">
          {campaingData[activeIndex]?.doc_card_title}
        </div>
        <div className="relative text-[20px] md:text-[32px] md:leading-[43px] font-bold font-font-family-7 text-text-2 capitalize max-w-[80%] md:max-w-none mb-[10px]">
          {campaingData[activeIndex]?.doc_content_title}
        </div>
        <div className="relative text-[12px] md:text-[16px] md:leading-[24px] text-text-2 tracking-[0.25px] font-medium font-font-family-6 mb-[20px] md:mb-[30px]">
          {campaingData[activeIndex]?.doc_desc}
        </div>
        {!campaingData[activeIndex]?.doc_content_title
          .toLowerCase()
          .includes("a space") ? (
          <Link href={campaingData[activeIndex]?.doc_redirect_url || "#"}>
            <ButtonAction
              className="relative flex justify-center md:justify-start items-center gap-[5px] md:max-w-[217px] md:px-[39px]"
              intent="primary"
              onClick={() => handleDataLayer()}
            >
              <div className="flex items-center gap-[5px]">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Arrow">
                    <path
                      id="Vector"
                      d="M7 17L17 7M17 7H9M17 7V15"
                      // stroke="white"
                      className="stroke-cta-2"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
                Cek Sekarang
              </div>
            </ButtonAction>
          </Link>
        ) : (
          <ButtonAction
            className="relative flex justify-center md:justify-start items-center gap-[5px] md:max-w-[217px] md:px-[39px]"
            intent="primary"
            onClick={handleRedirectASpace}
            disabled={isAspaceLoading}
          >
            {/* <Image
              className="!w-[22px] !h-[22px]"
              src="/assets/amild/icons/arrow-btn.svg"
              alt="redirect"
            /> */}
            <div className="flex items-center gap-[5px]">
              <svg
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Arrow">
                  <path
                    id="Vector"
                    d="M7 17L17 7M17 7H9M17 7V15"
                    // stroke="white"
                    className="stroke-cta-2"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
              Cek Sekarang
            </div>
          </ButtonAction>
        )}
      </div>
      {/* </div> */}
    </div>
  )
}

CampaignRedirect.propTypes = {
  section_title: PropTypes.string,
  data: PropTypes.object,
  doc: PropTypes.array,
}

const StarIcon = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7918 0.735436C11.2988 -0.245145 12.7012 -0.245145 13.2082 0.735436L16.4368 6.97973C16.5661 7.22995 16.77 7.43383 17.0203 7.56322L23.2645 10.7918C24.2452 11.2988 24.2452 12.7012 23.2645 13.2082L17.0203 16.4368C16.77 16.5661 16.5661 16.77 16.4368 17.0203L13.2082 23.2645C12.7012 24.2452 11.2988 24.2452 10.7918 23.2645L7.56322 17.0203C7.43383 16.77 7.22995 16.5661 6.97973 16.4368L0.735436 13.2082C-0.245145 12.7012 -0.245145 11.2988 0.735436 10.7918L6.97973 7.56322C7.22995 7.43383 7.43383 7.22995 7.56322 6.97973L10.7918 0.735436Z"
        className={className}
      />
    </svg>
  )
}

StarIcon.propTypes = {
  className: PropTypes.string,
}
