"use client";
const monthList = [
    // { id: 0, name: 'Bulan' },
    { id: 1, name: 'Januari' },
    { id: 2, name: 'Februari' },
    { id: 3, name: 'Maret' },
    { id: 4, name: 'April' },
    { id: 5, name: 'Mei' },
    { id: 6, name: 'Juni' },
    { id: 7, name: 'Juli' },
    { id: 8, name: 'Agustus' },
    { id: 9, name: 'September' },
    { id: 10, name: 'Oktober' },
    { id: 11, name: 'November' },
    { id: 12, name: 'Desember' },
];

const getMonth = () =>
    monthList.map((item) => {
        const option = {};
        option.value = item.id;
        option.label = item.name;
        return option;
    });

const getYear = ({ minYear, maxYear }) => {
    let i = maxYear;
    // const years = [{ value: 0, label: 'Tahun' }];
    const years = [];
    for (i;i >= minYear;i--) {
        const option = {};
        option.value = i.toString();
        option.label = i.toString();
        years.push(option);
    }
    return years;
};

const getDays = () => {
    const days = [];

    for (let index = 1;index <= 31;index++) {
        const option = {
            value: index,
            label: index,
        };

        days.push(option);
    }

    return days;
};

export { getYear, getMonth, getDays };


export function nFormatter(num, digits) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
    const item = lookup.findLast(item => num >= item.value);
    return item ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol) : "0";
}

export function formatNumber(number, fraction = 0) {
    return number.toLocaleString('id-ID', { minimumFractionDigits: fraction });
}