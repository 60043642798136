import clsx from "clsx";
import styles from "./loading-overlay.module.scss";
import { Spinner } from "../spinner";
import PropTypes from 'prop-types';

export const LoadingOverlay = ({ visible = false, fixed = true }) => {
  if (!visible) return null;

  return (
    <div
      data-testid="loading-overlay"
      className={clsx(styles.loading_overlay, fixed && styles.fixed)}
    >
      <Spinner />
    </div>
  );
};

LoadingOverlay.propTypes = {
  visible: PropTypes.bool,
  fixed: PropTypes.bool
};
