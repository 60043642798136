import { cn } from "@/lib/utils"
// import clsx from "clsx"
import React from "react"
import Style from "./quiz-range.module.css"

const QuizRange = ({ answer, setAnswer, labels, maxRating = 10 }) => {
  const ranges = [...Array(maxRating+1).keys()]
  const handleChange = (e) => {
    setAnswer(e.target.value)
  }

  //   console.log(answer, labels)
  return (
    <div className="flex flex-col mt-[45px] mb-[50px]">
      <input
        type="range"
        min="0"
        max={maxRating}
        // value="50"
        className={cn(Style.slider)}
        id="myRange"
        value={answer}
        onChange={handleChange}
      />
      <div className="mt-[5px] flex justify-between">
        {ranges.map((range) => {
          return (
            <div
              key={range}
              className={cn("flex flex-col items-center", "w-[24px]")}
            >
              <div
                className={cn(
                  "w-[1px] bg-text-3 transition ease-in-out duration-300",
                  range == answer ? "h-[10px]" : "h-[5px]"
                )}
              ></div>
              <div
                className={cn(
                  "text-text-2 font-medium font-font-family-7 transition ease-in-out duration-300 text-[24px] leading-[32px]",
                  range == answer
                    ? "text-[24px] leading-[32px] "
                    : "text-[16px] leading-[24px] "
                )}
              >
                {range}
              </div>
            </div>
          )
        })}
      </div>
      <div className="mt-[15px] flex items-center justify-between text-text-2 text-[12px] lg:text-[14px] leading-[18px] lg:leading-[20px]">
        <div>{labels.min || "Ga Semangat"}</div>
        <div>{labels.max || "Semangat Banget"}</div>
      </div>
    </div>
  )
}

export default QuizRange
