"use client"
import React from "react"
import PropTypes from "prop-types"
import CampaignCard from "../campaign-intro/campaign-card/campaign-card"
import CampaignSection from "../campaign-intro/campaign-card/components/campaign-section"
import CampaignTitle from "../campaign-intro/campaign-card/components/campaign-title"
import CampaignDescription from "../campaign-intro/campaign-card/components/campaign-description"
import ButtonAction from "../shared/button-action/button-action"

export function CampaignExtraMission({
  section_title,
  title,
  description,
  submit_btn_label,
  mission_placeholder,
}) {
  return (
    <CampaignCard className="">
      <CampaignSection className="mb-[7px]">{section_title}</CampaignSection>
      <CampaignTitle className="text-[20px] capitalize mb-[7px] leading-normal">
        {title}
      </CampaignTitle>
      <CampaignDescription className="mb-[10px]">
        {description}
      </CampaignDescription>
      <form method="POST" className="mb-[6px]">
        <textarea
          placeholder={mission_placeholder}
          className="pt-[10px] pl-[14px] rounded-[8px] w-[100%] h-[129px] bg-[#f2f2f2]"
        ></textarea>
      </form>
      <ButtonAction className="">{submit_btn_label}</ButtonAction>
    </CampaignCard>
  )
}

CampaignExtraMission.propTypes = {
  section_title: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  submit_btn_label: PropTypes.string,
  mission_placeholder: PropTypes.string,
}
