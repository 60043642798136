"use client"
import React, { useEffect, useState } from "react"
import { Image } from "@/components/shared/image/image"
import CampaignCard from "../campaign-intro/campaign-card/campaign-card"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { ClientApiList } from "@/lib/api/client-service"
import PropTypes from "prop-types"
import Link from "@/components/shared/link/link"
import { getArticlePathname } from "@/hooks/use-pathname"
import { getPublishDate, useDatalayer } from "@/hooks/use-datalayer"
import { usePathname } from "next/navigation"
import { urlToCampaign } from "@/helper/utils"
import { cn } from "@/lib/utils"
export function CampaignAdditional({ api_config, isSingle, ...props }) {
  const pathname = usePathname()
  const [data, setData] = useState()
  const datalayer = useDatalayer()
  const [isLoading, setIsLoading] = useState(true)
  const isMobile = useIsMobile()

  const engagementName = () => {
    if (pathname.includes("petualangan-besar")) {
      return "quiz"
    } else {
      return "not available"
    }
  }

  const cta = () => {
    if (pathname.includes("petualangan-besar")) {
      return "ikutan_sekarang"
      // } else if (urlToCampaign(pathname).includes('hari_besar_musik_keras')) {
      //   return 'ikutan_sekarang';
      // } else if(urlToCampaign(pathname).includes('tantangan_besar') || urlToCampaign(pathname).includes('magnum_studio')) {
      //   return 'ikuti tantanganya sekarang';
    } else {
      return "not available"
    }
  }

  const handleDataLayer = (type, category, pos, name, date, isSpecial) => {
    datalayer.push({
      event: "general_event",
      event_name: "click_content",
      feature: "hero engagement page",
      content_section: "campaign page",
      content_brand: "branded", // e.g branded or unbranded
      content_type: type, // e.g article, carousel, video, etc
      content_category: category, // e.g music, travel, automotive, etc
      content_position: pos, // e.g 1, 2, 3, etc (integer)
      content_name: name, // e.g the_ultimate_xperience, etc
      publish_date: getPublishDate(date), // e.g 23-10-2023 (date of content publish)
      campaign_name:urlToCampaign(pathname)
    })

    if (
      process.env.NEXT_PUBLIC_NAME === "magnum" &&
      isSpecial &&
      pathname.includes("petualangan-besar")
    ) {
      datalayer.push({
        event: "general_event",
        event_name: `click_${cta()}_${engagementName()}`,
        feature: "special engagement",
        engagement_type: "games",
        engagement_name: engagementName(),
        area: "not available",
        mission: "mission 1",
        campaign_name:urlToCampaign(pathname)        // e.g ride your flavour 2023
      })
    }
  }

  const handleGetData = async () => {
    try {
      const { data } = await ClientApiList.getFinalChallenge({ ...api_config })
      setData(data.data.data)
      setIsLoading(false)
    } catch (err) {
      console.log("[Error]", err)
    }
  }

  useEffect(() => {
    handleGetData()
  }, [])

  const href = getArticlePathname({
    ...data,
    format: data?.format || data?.engagement_type,
  })

  const setImage = data
    ? data.lock_active
      ? { mobile: data.lock_image_mobile, desktop: data.lock_image_desktop }
      : {
          mobile: data.final_challenge_banner_mobile,
          desktop: data.final_challenge_banner_desktop,
        }
    : ""

  return (
    <>
      {isLoading ? (
        <CampaignCard className="w-full lg:max-w-[401px] min-h-[340px] lg:mb-[0] animate-pulse bg-[#D9D9D9] !p-0"></CampaignCard>
      ) : (
        <CampaignCard
          className={cn(
            "w-full lg:max-w-[401px] min-h-[340px] lg:min-h-[430px] lg:mb-[0] bg-bg-5 !p-0",
            isSingle ? "lg:col-span-2 lg:max-w-none" : ""
          )}
        >
          {/* <div className='absolute inset-0 h-100'> */}
          <Link
            className={
              data?.lock_active
                ? "pointer-events-none cursor-not-allowed min-h-[340px] block"
                : "min-h-[340px] block"
            }
            aria-disabled={data?.lock_active}
            tabIndex={data?.lock_active ? -1 : undefined}
            href={href}
            onClick={() =>
              handleDataLayer(
                data?.engagement_type,
                data?.category_string_id,
                1,
                data?.string_id,
                data?.start_date,
                data?.final_engagement_type === "special_engagement"
              )
            }
          >
            <Image
              className="absolute inset-[0] object-top"
              src={isMobile ? setImage.mobile : setImage.desktop}
              alt="cover"
            />
          </Link>
          {/* </div> */}
        </CampaignCard>
      )}
    </>
  )
}

CampaignAdditional.propTypes = {
  lock_image_mobile: PropTypes.string,
  lock_image_desktop: PropTypes.string,
  final_challenge_banner_mobile: PropTypes.string,
  final_challenge_banner_desktop: PropTypes.string,
  final_engagement_type: PropTypes.string,
}
