"use client"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { RemoveScroll } from "react-remove-scroll"
import styles from "./popup.module.scss"
import clsx from "clsx"

/**
 * @typedef {Object} PopupProps
 * @property {boolean} isOpen - if popup should be open
 * @property {string} overlayClass - class name to be added to overlay
 * @property {Function} onClose - function to be called when overlay is clicked
 * @property {React.ReactNode} children - children to be rendered inside popup
 */

/**
 * Popup with overlay
 * @type {React.FC<PopupProps>} - Popup
 * @example
 * const [isOpen, setIsOpen] = useState(false);
 *
 * <Popup isOpen={isOpen} onClose={() => setIsOpen(false)}>
 *  <PopupContent>
 *   <h1>Hello</h1>
 *  </PopupContent>
 * </Popup>
 */

export const Popup = ({
  isOpen,
  children,
  overlayClass,
  onClose,
  order = 50,
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <div
          style={{
            zIndex: order,
          }}
          className={styles.wrapper}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className={overlayClass ? overlayClass : styles.overlay}
            onClick={() => {
              onClose?.()
            }}
          />
          {children}
        </div>
      )}
    </AnimatePresence>
  )
}

export const POPUP_CONTENT_VARIANTS = {
  visible: { opacity: 1, y: "-50%", scale: 1, x: "-50%" },
  hidden: { opacity: 0, scale: 0.98, y: "-45%", x: "-50%" },
  exit: { opacity: 0, scale: 0.98 },
}

/**
 * @typedef {Object} PopupContentProps
 * @prop {number?} duration - duration of animation in seconds
 */

/**
 * @type {React.FC<import('react').HTMLAttributes & PopupContentProps & import('framer-motion').MotionProps>} - PopupContent
 */

export const PopupContent = ({
  children,
  className,
  duration = 0.2,
  ...props
}) => {
  return (
    <RemoveScroll>
      <motion.div
        variants={POPUP_CONTENT_VARIANTS}
        initial="hidden"
        animate="visible"
        exit="exit"
        transition={{ duration: duration }}
        role="dialog"
        className={clsx(styles.content, className)}
        {...props}
      >
        {children}
      </motion.div>
    </RemoveScroll>
  )
}
