"use client"
import React, { Suspense, useEffect, useState } from "react"
import Link from "@/components/shared/link/link"
import { motion, AnimatePresence } from "framer-motion"
import { usePathname, useSearchParams } from "next/navigation"

// Custom comp. imports
import Overlay from "../slide-menu/overlay/overlay"
import NavItem from "../nav/nav-item/navItem"
import Separator from "../slide-menu/separator/separator"
import ButtonAction from "../shared/button-action/button-action"
import { useIsDesktop } from "@/hooks/use-is-mobile"
import MenuContent from "../slide-menu/menu-content/menu-content"
import PropTypes from "prop-types"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { useDatalayer } from "@/hooks/use-datalayer"
import { useLogout } from "@/hooks/auth/use-logout"
import { Image } from "../shared/image/image"

const sideVariants = {
  closed: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
  open: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: 1,
    },
  },
}

export const SideMenuFallback = () => {
  return <div>Fallback</div>
}

const SuspendedSideMenu = ({
  nav_item = [],
  footer_nav_item = [],
  login_cta = {},
  nav_item_br = [],
  footer_nav_item_ub = [],
}) => {
  const datalayer = useDatalayer()
  const { execute: logout } = useLogout()
  const { isLogin, isLoading } = useIsLogin()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const isOpen = searchParams.get("open") === "true"
  const isDesktop = useIsDesktop()

  const [nav, setNav] = useState(nav_item)
  const [footer, setFooter] = useState(footer_nav_item_ub)

  useEffect(() => {
    if (isLogin && !isLoading) {
      setNav(nav_item_br)
      setFooter(footer_nav_item)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLogin, isLoading])

  const handleCloseMenu = () => {
    let links = { pathname: pathname, query: null }
    const newQuery = {
      open: false,
    }
    links.query = newQuery
    return links
  }

  return (
    <AnimatePresence>
      {isOpen && !isDesktop && (
        <motion.div
          initial={{
            x: "100%",
          }}
          animate={{
            x: 0,
          }}
          exit={{
            x: "100%",
            transition: { delay: 0.7, duration: 0.3 },
          }}
          transition={{ type: "Inertia" }}
          style={{ originX: 1 }}
          className="fixed overflow-auto w-[100%] h-[100vh] top-0 z-[14] bg-bg-1"
        >
          <motion.div initial="closed" animate="open" exit="closed">
            <motion.div>
              <Overlay
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
                className=" relative h-[100%] pt-[20px] px-[10px] pb-[5px] rounded-b-[10px] mb-[15px] bg-bg-1"
              >
                <div className="text-center text-text-2 mb-[31px] text-[20px] font-medium font-font-family-6">
                  Menu
                </div>
                <Link
                  data-testid="close-button"
                  href={handleCloseMenu()}
                  className="absolute top-[10px] right-[10px] overflow-hidden w-[54px] h-[48px] rounded-[10px] text-black flex justify-center items-center bg-cta-4"
                  style={{ background: "var(--cta_4)" }}
                >
                  <div
                    className="bg-bg-1 absolute inset-[-1px] z-[0]"
                    style={{ background: "var(--background_1)" }}
                  ></div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    className="absolute z-[1]"
                  >
                    <path
                      d="M1 1L11 11M1 11L11 1"
                      // stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="stroke-icon-2"
                    />
                  </svg>
                </Link>
                <MenuContent
                  branded={isLogin}
                  menu={nav}
                  login_cta={login_cta}
                />
                {isLogin ? (
                  <>
                    <Separator className="mb-[15px]" />
                    <ButtonAction
                      onClick={() => {
                        logout()
                        datalayer.push({
                          event: "general_event",
                          event_name: "click_logout_button",
                          feature: "logout",
                          button_name: "keluar", // e.g., logout
                          page_path: pathname,
                          campaign_name: "all",
                        })
                      }}
                      className="mb-[15px]"
                      intent="secondary"
                    >
                      Keluar
                    </ButtonAction>
                  </>
                ) : null}
                <Separator />
                <div className="pt-[10px] px-[20px] pb-[140px]">
                  <ul>
                    {footer.map((menu) => (
                      <li key={menu.label}>
                        <NavItem
                          icon={menu.icon}
                          iconSelected={menu.icon}
                          isSlideItem={true}
                          className="flex-row gap-[10px] text-[14px] leading-[22px] text-medium !text-text-2"
                          href={menu?.href}
                          label={menu.label}
                          eventMenu={"click_burger_navigation"}
                        />
                      </li>
                    ))}
                    {/* <li className="flex gap-[5px] items-center">
                      <div className={`w-[24px] h-[24px] relative`}>
                        <Image
                          src={`/assets/${process.env.NEXT_PUBLIC_NAME}/menu-slide/kuki.png`}
                          alt={"Pemberitahuan Kuki"}
                          className="absolute inset-0"
                        />
                      </div>
                      <button className="text-[10px] leading-[22px] text-medium py-[10px] !text-text-2 ot-sdk-show-settings">
                        <span>Pemberitahuan Kuki</span>
                      </button>
                    </li> */}
                  </ul>
                </div>
              </Overlay>
            </motion.div>
            {/* <motion.div variants={itemVariants}>
              <div className="pt-[10px] px-[20px] pb-[180px]">
                <ul>
                  {footer_nav_item.map((menu) => (
                    <li key={menu.title}>
                      <NavItem
                        className="flex-row gap-[10px] text-[14px] leading-[22px] text-medium text-[#525252]"
                        href="#"
                        label={menu.title}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </motion.div> */}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

SuspendedSideMenu.propTypes = {
  nav_item: PropTypes.array,
  footer_nav_item: PropTypes.array,
  login_cta: PropTypes.object,
  nav_item_br: PropTypes.array,
  footer_nav_item_ub: PropTypes.array,
}

export function SideMenu(props) {
  return (
    <Suspense fallback={<SideMenuFallback />}>
      <SuspendedSideMenu {...props} />
    </Suspense>
  )
}
