import { useIsDesktop } from "@/hooks/use-is-mobile"
import clsx from "clsx"
import React from "react"
import { Image } from "../shared/image/image"

const QuizAnswer = ({
  game,
  number,
  answer,
  handleBorder,
  setAnswer,
  handleBG,
  handleBGNotChosen,
  images,
}) => {
  const isDesktop = useIsDesktop()
  return (
    <div className="flex flex-col gap-[10px] mt-[20px]">
      {game[number]?.answer_in_array?.map((item, idx) => (
        <button
          data-testid={`quiz-answer-${idx}`}
          key={item?.text}
          style={{
            background:
              answer > 0 && answer !== idx + 1 ? "" : handleBorder(idx),
            padding: answer > 0 && answer !== idx + 1 ? "0" : "1.5px",
          }}
          className={`rounded-[11.5px] overflow-hidden shadow-[0_1px_12px_0_rgba(143,166,192,0.15)]`}
          onClick={() => {
            setAnswer(idx + 1)
          }}
        >
          <div
            className={clsx(
              "w-full relative flex text-left bg-bg-1 rounded-[10px] overflow-hidden",
              isDesktop ? "text-base h-[100px]" : "text-sm h-[70px]"
            )}
          >
            {item?.bg_img_url && (
              <Image
                alt="bg-answer"
                objectFit="cover"
                src={item?.bg_img_url}
                className="absolute top-0 right-0"
              />
            )}
            {answer > 0 && answer !== idx + 1 && (
              <div
                className={clsx(
                  "w-full absolute top-0 right-0 bg-bg-3 mix-blend-color",
                  isDesktop ? "h-[100px]" : "h-[70px]"
                )}
              />
            )}
            <div
              className={`absolute w-full z-[0] h-[100%] right-0 top-0 ${process.env.NEXT_PUBLIC_NAME === "magnum" || process.env.NEXT_PUBLIC_NAME === "amild" ? "bg-bg-1" : ""}`}
            >
              {process.env.NEXT_PUBLIC_NAME !== "magnum" &&
              process.env.NEXT_PUBLIC_NAME !== "amild" ? (
                <Image
                  objectFit="cover"
                  alt={item}
                  src={`/assets/${process.env.NEXT_PUBLIC_NAME}/engagement/quiz-active.png`}
                />
              ) : null}
            </div>
            {images[number + 1] && images[number + 1][idx + 1] ? (
              <div className="absolute w-full z-[1] h-[100%] right-0 top-0">
                <Image
                  objectFit="cover"
                  alt={item}
                  src={
                    !isDesktop
                      ? images[number + 1][idx + 1].images.mobile
                      : images[number + 1][idx + 1].images.desktop
                  }
                />
              </div>
            ) : null}
            {answer > 0 && answer !== idx + 1 && (
              <div
                className="absolute w-full z-[2] h-[100%] right-0 top-0"
                style={{
                  background: handleBGNotChosen(),
                  opacity: "0.25",
                }}
              ></div>
            )}
            <div className="z-[2] max-w-[calc(100%_-_86px)] md:max-w-[calc(100%_-_142px)] w-full relative h-full flex items-center gap-[10px] lg:gap-[15px] overflow-hidden p-[15px] text-[14px] lg:text-[16px] leading-[20px] lg:leading-[24px]">
              <span
                style={{ background: handleBG(idx) }}
                className={clsx(
                  "font-bold font-font-family-7 rounded-[6px] overflow-hidden  flex items-center justify-center text-text-4",
                  isDesktop ? "w-[28px] h-[28px]" : "w-[24px] h-[24px]"
                )}
              >
                {idx + 1}
              </span>
              <p
                className={clsx(
                  "text-text-2 font-medium font-font-family-6",
                  isDesktop ? "w-[260px]" : "w-[210px]",
                  answer > 0 && answer !== idx + 1 && "text-[#413F3E]"
                )}
              >
                {item}
              </p>
            </div>
          </div>
        </button>
      ))}
    </div>
  )
}

export default QuizAnswer
