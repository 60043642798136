import React from "react"
import PropTypes from "prop-types"
export default function CarouselFooter({
  tag,
  itemsLength,
  activeIndex,
  handleNextSlide,
  handlePrevSlide,
}) {
  return (
    <div className="z-[3] flex text-[white] items-center justify-between py-[30px] px-[15px] absolute bottom-0 w-full">
      <div
        data-testid="tag"
        className="w-fit text-[12px] tracking-[0.12px] font-bold font-font-family-7 leading-[9px] p-[9px] rounded-[10px] border-[1px] border-[#FFF] uppercase"
      >
        {tag}
      </div>
      <div
        data-testid="items-count"
        className="text-[14px] leading-[20px] font-bold font-font-family-7 "
      >
        {activeIndex + 1}/{itemsLength}
      </div>
      <div className="flex items-center gap-[10px]">
        <button
          className="w-[28px] h-[28px]"
          onClick={handlePrevSlide}
          disabled={activeIndex === 0}
        >
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="vuesax/bulk/arrow-square-left">
              <g id="arrow-square-left">
                <g id="Vector" filter="url(#filter0_b_7828_11600)">
                  <path
                    d="M19.389 2.83325H9.61232C5.36565 2.83325 2.83398 5.36492 2.83398 9.61159V19.3766C2.83398 23.6349 5.36565 26.1666 9.61232 26.1666H19.3773C23.624 26.1666 26.1557 23.6349 26.1557 19.3883V9.61159C26.1673 5.36492 23.6357 2.83325 19.389 2.83325Z"
                    fill="url(#paint0_linear_7828_11600)"
                    fillOpacity="0.4"
                  />
                  <path
                    d="M25.864 9.61078V9.61159V19.3883C25.864 21.4536 25.2495 23.0669 24.1527 24.1637C23.056 25.2604 21.4426 25.8749 19.3773 25.8749H9.61232C7.54699 25.8749 5.93372 25.2604 4.83702 24.1624C3.74028 23.0642 3.12565 21.448 3.12565 19.3766V9.61159C3.12565 7.54626 3.74015 5.9329 4.83689 4.83616C5.93363 3.73942 7.54699 3.12492 9.61232 3.12492H19.389C21.4544 3.12492 23.0676 3.73944 24.1628 4.83603C25.258 5.93255 25.8697 7.54564 25.864 9.61078Z"
                    stroke="url(#paint1_linear_7828_11600)"
                    strokeOpacity="0.4"
                    strokeWidth="0.583333"
                  />
                </g>
                <path
                  id="Vector_2"
                  d="M15.9709 19.4933C15.7492 19.4933 15.5276 19.4116 15.3526 19.2366L11.2342 15.1183C10.8959 14.7799 10.8959 14.2199 11.2342 13.8816L15.3526 9.76327C15.6909 9.42494 16.2509 9.42494 16.5892 9.76327C16.9276 10.1016 16.9276 10.6616 16.5892 10.9999L13.0892 14.4999L16.5892 17.9999C16.9276 18.3383 16.9276 18.8983 16.5892 19.2366C16.4259 19.4116 16.2042 19.4933 15.9709 19.4933Z"
                  fill="white"
                />
              </g>
            </g>
            <defs>
              <filter
                id="filter0_b_7828_11600"
                x="-2.99935"
                y="-3.00008"
                width="34.9889"
                height="34.9999"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur
                  in="BackgroundImageFix"
                  stdDeviation="2.91667"
                />
                <feComposite
                  in2="SourceAlpha"
                  operator="in"
                  result="effect1_backgroundBlur_7828_11600"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_backgroundBlur_7828_11600"
                  result="shape"
                />
              </filter>
              <linearGradient
                id="paint0_linear_7828_11600"
                x1="24.1258"
                y1="3.12495"
                x2="4.00079"
                y2="24.5625"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" stopOpacity="0.8" />
                <stop offset="1" stopColor="white" stopOpacity="0.2" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_7828_11600"
                x1="3.12579"
                y1="3.12495"
                x2="25.8758"
                y2="25.875"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" stopOpacity="0.5" />
                <stop offset="1" stopColor="white" stopOpacity="0.1" />
              </linearGradient>
            </defs>
          </svg>
        </button>
        <button
          className="w-[28px] h-[28px]"
          onClick={handleNextSlide}
          disabled={activeIndex + 1 === itemsLength}
        >
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="vuesax/bulk/arrow-square-right">
              <g id="arrow-square-right">
                <g id="Vector" filter="url(#filter0_b_7828_10183)">
                  <path
                    d="M19.389 2.83325H9.61232C5.36565 2.83325 2.83398 5.36492 2.83398 9.61159V19.3766C2.83398 23.6349 5.36565 26.1666 9.61232 26.1666H19.3773C23.624 26.1666 26.1557 23.6349 26.1557 19.3883V9.61159C26.1673 5.36492 23.6357 2.83325 19.389 2.83325Z"
                    fill="url(#paint0_linear_7828_10183)"
                    fillOpacity="0.4"
                  />
                  <path
                    d="M25.864 9.61078V9.61159V19.3883C25.864 21.4536 25.2495 23.0669 24.1527 24.1637C23.056 25.2604 21.4426 25.8749 19.3773 25.8749H9.61232C7.54699 25.8749 5.93372 25.2604 4.83702 24.1624C3.74028 23.0642 3.12565 21.448 3.12565 19.3766V9.61159C3.12565 7.54626 3.74015 5.9329 4.83689 4.83616C5.93363 3.73942 7.54699 3.12492 9.61232 3.12492H19.389C21.4544 3.12492 23.0676 3.73944 24.1628 4.83603C25.258 5.93255 25.8697 7.54564 25.864 9.61078Z"
                    stroke="url(#paint1_linear_7828_10183)"
                    strokeOpacity="0.4"
                    strokeWidth="0.583333"
                  />
                </g>
                <path
                  id="Vector_2"
                  d="M13.0303 19.4935C12.8086 19.4935 12.587 19.4118 12.412 19.2368C12.0736 18.8985 12.0736 18.3385 12.412 18.0002L15.912 14.5002L12.412 11.0002C12.0736 10.6618 12.0736 10.1018 12.412 9.76352C12.7503 9.42518 13.3103 9.42518 13.6486 9.76352L17.767 13.8818C18.1053 14.2202 18.1053 14.7802 17.767 15.1185L13.6486 19.2368C13.4736 19.4118 13.252 19.4935 13.0303 19.4935Z"
                  fill="white"
                />
              </g>
            </g>
            <defs>
              <filter
                id="filter0_b_7828_10183"
                x="-2.99935"
                y="-3.00008"
                width="34.9889"
                height="34.9999"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur
                  in="BackgroundImageFix"
                  stdDeviation="2.91667"
                />
                <feComposite
                  in2="SourceAlpha"
                  operator="in"
                  result="effect1_backgroundBlur_7828_10183"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_backgroundBlur_7828_10183"
                  result="shape"
                />
              </filter>
              <linearGradient
                id="paint0_linear_7828_10183"
                x1="24.1258"
                y1="3.12495"
                x2="4.00079"
                y2="24.5625"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" stopOpacity="0.8" />
                <stop offset="1" stopColor="white" stopOpacity="0.2" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_7828_10183"
                x1="4.00079"
                y1="24.5625"
                x2="25.8758"
                y2="3.99995"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" stopOpacity="0.1" />
                <stop offset="1" stopColor="white" stopOpacity="0.5" />
              </linearGradient>
            </defs>
          </svg>
        </button>
      </div>
    </div>
  )
}

CarouselFooter.propTypes = {
  tag: PropTypes.string,
  itemsLength: PropTypes.number,
  activeIndex: PropTypes.number,
  handleNextSlide: PropTypes.func,
  handlePrevSlide: PropTypes.func,
}
