import React from "react"
import Styles from "./check-radio.module.css"

const CheckRadio = ({ label, onChange, disabled, ...props }) => {
  return (
    <label
      className={`${Styles.container} text-left text-text-2 lg:text-center ${disabled && !label.includes("berhenti") ? "pointer-events-none cursor-not-allowed" : ""}`}
      aria-disabled={disabled && !label.includes("berhenti")}
      tabIndex={disabled && !label.includes("berhenti") ? -1 : undefined}
      onChange={() => onChange(label)}
    >
      <input
        type="checkbox"
        checked={props.choices.findIndex((choice) => choice === label) !== -1}
      />
      <span className={Styles.checkmark}></span>
      {label}
    </label>
  )
}

export default CheckRadio
