"use client";
import React from 'react';

export default function PilLink() {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 17" fill="none">
                <path d="M6.40039 4.98047L10.2404 8.82047L6.40039 12.6605" stroke="#2D2D2D" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    );
}
