'use client';
import React, { useEffect, useState, } from 'react';
import { ClientApiList } from '@/lib/api/client-service';
// import { decryptData, encryptGame, generateRandomString } from '@/helper/utils';
// import { usePathname } from 'next/navigation';
import { getGALinker, getGL } from '@/helper/ga';
import { Spinner } from '../shared/spinner';
import { useIsLogin } from '@/hooks/auth/use-is-login';
import { useRouter } from 'next/navigation';

export const IframeJoget = () => {
    // const iframeRef = useRef();
    // const pathname = usePathname();
    const [isLoadingAuth, setIsLoadingAuth] = useState(true);
    const [url, setUrl] = useState(true);
    const { isLogin, isLoading } = useIsLogin();
    const { replace } = useRouter();


    async function handleEvent(url) {
        const {
            data: { data: auth_data },
        } = await ClientApiList.getAuthData({
            detail_page: 'eyJyZWRpcmVjdFRvIjoiL2pvZ2V0LWFtYmlsLWFzaWtueWEvYnIifQ==',
            ga: getGALinker(),
            gl: getGL(),
            platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
            redirect_url: `${process.env.NEXT_PUBLIC_META_URL}/br`,
        })
        // console.log(auth_data.data.auth_data, 'auth-data');
        // let newuUrl = url + `?auth-data=${auth_data.data.url.split('?')[1].split('=')[1]}`;
        let newuUrl = url + `?redirectTo=/joget-ambil-asiknya/br&auth-data=${auth_data.data.auth_data}`;
        setUrl(newuUrl);
        setIsLoadingAuth(false);
    }

    function handleOrigin(event) {
        // console.log(event.data, 'event handle');
        if (String(event.data) === 'directLink')
            // replace('/thank-you-joget-asyik/br');
            replace('/br');
    }

    useEffect(() => {
        let url = 'https://campaign.sampoernakretek.id/auth/allaccess';

        isLogin && !isLoading && handleEvent(url);

        window.addEventListener("message", handleOrigin, false);

        return () => {
            window.removeEventListener("message", handleOrigin, false);
        };
    }, [isLoading, isLogin]);

    if (isLoadingAuth)
        return <div className="h-[calc(100vh-170px)] flex items-center">
            <Spinner />
        </div>;

    return (
        // <template>
        <div className='h-[calc(100dvh-56px)] w-[100%]'>
            <iframe
                className='h-[100%] w-[100%]'
                src={url}
                title="jjoget-asiknya"
                allow="camera; microphone"
                frameBorder="0"
            // src="https://campaign.sampoernakretek.id/auth/allaccess?auth-data=CODE"
            />
        </div>
        // </template>
    );
};
