"use client"
import ButtonAction from "@/components/shared/button-action/button-action"
import { usePathname, useSearchParams, useRouter } from "next/navigation"
import React from "react"
import { useDebouncedCallback } from "use-debounce"
import PropTypes from "prop-types"

export default function LoadMoreBtn({
  className,
  load_more_btn_label,
  cat,
  disabled = false,
  setIsLoadMore,
}) {
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const { replace } = useRouter()

  const handleLoad = useDebouncedCallback((cat = "event", page) => {
    typeof setIsLoadMore === "function" ? setIsLoadMore(true) : null

    const params = new URLSearchParams(searchParams)
    params.set(`${cat}-page`, page)

    replace(`${pathname}?${params.toString()}`, { scroll: false })
  }, 300)

  return (
    <ButtonAction
      onClick={() =>
        handleLoad(cat, parseInt(searchParams.get(`${cat}-page`) || 0) + 1)
      }
      className={`mb-[20px] max-w-[355px] mx-auto ${className}`}
      intent={disabled ? "primary_disable" : "secondary"}
      disabled={disabled}
      data-testid="load-more-btn"
    >
      {load_more_btn_label}
    </ButtonAction>
  )
}

LoadMoreBtn.propTypes = {
  cat: PropTypes.string,
  load_more_btn_label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  setIsLoadMore: PropTypes.func,
}
