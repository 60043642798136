"use client"
export const data = {
  title: "Update Nomor HP",
  title_verify: "Verifikasi Nomor HP",
  description: `Masukkan nomor handphone ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"} yang baru dan password akun ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"} buat lanjut ubah nomor hp`,
  description_verify: `Masukkan password ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"} untuk verifikasi nomor HP`,
  formList: {
    section_title: "Update Nomor HP",
    section_title_verify: "Verifikasi Nomor HP",
    formItems: [
      {
        label: "Nomor Handphone",
        type: "phone",
        placeholder: "Contoh: 81234567890",
        name: "phone_number",
      },
      {
        label: "Password",
        type: "password",
        placeholder: `Masukkan password ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"} di sini`,
        name: "password",
      },
    ],
  },
  submit_lable: "Simpan Perubahan",
  submit_lable_verify: "Verifikasi Nomor",
  cancel_lable: "Batal",
}
