import React, { useRef } from "react"
import PopupAction from "../shared/popup-action/popup-action"
import { usePathname } from "next/navigation"
import ButtonAction from "../shared/button-action/button-action"
import { useRouter } from "next/navigation"
import DOMPurify from "isomorphic-dompurify"

const PopupUGCSubmit = ({ isShowing, popupData, handleReset }) => {
  const pathname = usePathname()
  const introDesc = useRef(null)
  const availTitle = useRef(null)
  const { push, back } = useRouter()

  const handleChances = (string) => {
    const availArray = string.split(" ")
    const chancesIndex = availArray.findIndex(
      (word) => word.toLowerCase() === "kesempatan"
    )
    if (chancesIndex !== -1) {
      if (
        !isNaN(availArray[chancesIndex - 1]) &&
        !isNaN(parseInt(availArray[chancesIndex - 1]))
      ) {
        availArray[chancesIndex - 1] = popupData?.remainingChances
      } else {
        availArray.splice(chancesIndex, 0, popupData?.remainingChances)
      }
      return availArray.join(" ")
    } else {
      return string
    }
  }

  return (
    <PopupAction
      isShowing={isShowing}
      href={`${popupData?.isChances ? pathname : "/br"}`}
      onClickBtnX={() => {
        popupData?.isChances ? handleReset() : push("/br")
      }}
      zIndex="z-[20]"
    >
      <div className="max-w-[304px] text-text-2">
        <div className="text-center text-[20px] leading-[28px] font-black mb-[10px] font-font-family-7">
          {popupData?.success_intro_title || "THANK YOU!"}
        </div>
        <div
          className="text-center text-[16px] leading-[24px] mb-[20px]"
          ref={introDesc}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(popupData?.success_intro_desc),
          }}
        ></div>
        {/* {popupData?.isChances ? ( */}
        <div
          className="text-[16px] leading-[24px] font-bold text-center mb-[5px] font-font-family-7"
          ref={availTitle}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              popupData?.isChances
                ? handleChances(popupData?.success_avail_title)
                : popupData?.success_ranout_title
            ),
          }}
        ></div>
        {/* ) : null} */}
        <div
          className="text-[16px] leading-[24px] text-center mb-[30px]"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              popupData?.isChances
                ? popupData?.success_avail_desc ||
                    "Ayo submit lagi biar peluang karya lo terpilih lebih besar."
                : popupData?.success_ranout_desc ||
                    "Kesempatan lo buat submit ulang submisinya udah habis."
            ),
          }}
        ></div>
        <ButtonAction
          className="mb-[10px]"
          intent={popupData?.isChances ? "primary" : "primary_disable"}
          disabled={popupData?.isChances ? false : true}
          onClick={handleReset}
        >
          {popupData?.success_cta_1}
        </ButtonAction>
        <ButtonAction
          onClick={() => push("/br")}
          className=""
          intent="secondary"
        >
          {popupData?.success_cta_2}
        </ButtonAction>
      </div>
    </PopupAction>
  )
}

export default PopupUGCSubmit
