"use client"
import React from "react"
import Styles from "./reward.module.scss"
import RewardCard from "../reward-card/reward-card"
import clsx from "clsx"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { Image } from "@/components/shared/image/image"

const bgDesktop =
  "/assets/amild/aspace-leaderboard/bg-reward-aspace-desktop.png"
const bgMobile = "/assets/amild/aspace-leaderboard/bg-reward-aspace.png"
const title = "/assets/amild/aspace-leaderboard/reward-title.png"
const rewardsMobile = "/assets/amild/aspace-leaderboard/rewards.png"
const rewardsDesktop = "/assets/amild/aspace-leaderboard/rewards-desktop.png"

// const rewards = [
//   {
//     // position: '#RANK 1',
//     id: 2,
//     title: (
//       <>
//         2 Tiket ke Bangkok <br /> untuk peringkat #1
//       </>
//     ),
//     titleDesk: (
//       <>
//         2 Tiket ke Bangkok <br /> untuk peringkat #1
//       </>
//     ),
//     img: "/assets/leaderboard/prize-1.png",
//   },
//   {
//     // position: "#RANK 2 - 4",
//     id: 1,
//     title: <>3 Smart Tablet</>,
//     titleDesk: (
//       <>
//         3 Smart <br /> Tablet
//       </>
//     ),
//     img: "/assets/leaderboard/prize-2.png",
//   },
//   {
//     // position: "#RANK 5 - 8",
//     id: 3,
//     title: (
//       <>
//         4 turntable <br /> set player
//       </>
//     ),
//     titleDesk: (
//       <>
//         4 Turntable <br /> set player
//       </>
//     ),
//     img: "/assets/leaderboard/prize-3.png",
//   },
// ]

const Reward = () => {
  const isMobile = useIsMobile()
  const bgImg = isMobile ? bgMobile : bgDesktop
  const rewards = isMobile ? rewardsMobile : rewardsDesktop
  return (
    <section className={Styles.leaderboard__prizes}>
      <div
        className={clsx(
          "absolute bottom-0 right-0 w-full overflow-hidden",
          isMobile ? "aspect-[355/449]" : "aspect-[959/430]"
        )}
      >
        <Image alt="bg" src={bgImg} />
      </div>
      <div className={clsx("relative z-[1]", isMobile && "w-full")}>
        <div
          className={clsx(
            "relative z-[1]",
            isMobile
              ? "max-w-[355px] aspect-[355/111]"
              : "max-w-[500px] aspect-[500/142]"
          )}
        >
          <Image alt="special-reward" src={title} />
        </div>
        <div
          className={clsx(
            "text-text-2",
            isMobile
              ? "max-w-[343px] mx-[15px] mb-[15px] text-sm"
              : "max-w-[470px] mb-0 ml-[30px]"
          )}
        >
          Tingkatin terus poinmu untuk jadi 20 peserta dengan peringkat teratas
          yang akan terpilih mendapatkan <em>rewards</em>* inspirasional.
          Pengumuman akan dilakukan pada tanggal{" "}
          <b className="text-text-1 font">30 Juni 2024</b>.
        </div>
        {!isMobile && (
          <div className="relative z-[1] text-xs text-text-2 max-w-[470px] leading-[18px] mx-[30px] mt-[70px] mb-0">
            <span>*Syarat & Ketentuan Berlaku</span>
            <br />
            <span>
              Untuk kebutuhan internal. Aktivitas dan tautan ini hanya untuk
              perokok dewasa 21+, non-LAU/pengguna IQOS.
            </span>
          </div>
        )}
      </div>
      <div
        className={clsx(
          "relative z-[1]",
          isMobile
            ? "mx-auto max-w-[306px] aspect-[306/242]"
            : "w-[395px] h-[300px] mr-[30px] ml-auto"
        )}
      >
        <Image alt="reward" src={rewards} />
      </div>
      {isMobile && (
        <div className="relative z-[1] text-xs text-[#F0F0F0] leading-[18px] px-[15px]">
          <span>*Syarat & Ketentuan Berlaku</span>
          <br />
          <span>
            Untuk kebutuhan internal. Aktivitas dan tautan ini hanya untuk
            perokok dewasa 21+, non-LAU/pengguna IQOS.
          </span>
        </div>
      )}
    </section>
  )
}

export default Reward
