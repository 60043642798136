import React, { useCallback, useEffect, useRef, useState } from "react";
import ButtonBack from "@/components/shared/button-back/button-back";
import { cn } from "@/lib/utils";
import Link from "@/components/shared/link/link";
import ButtonAction from "@/components/shared/button-action/button-action";
import { motion } from "framer-motion";
import { useIsMobile } from "@/hooks/use-is-mobile";
import { useSearchParams } from "next/navigation";
import { Image as ImageComp } from "../shared/image/image";
import CustomizeTab from "./customize-tab";
import CampaignDescription from "../campaign-intro/campaign-card/components/campaign-description";
import { InfoPopup } from "../info-popup/info-popup";
import UGCNotif from "./ugc-notif";
import { PopupUGCConfirmExit } from "./ugc-exit";
import PopupUGCSubmit from "./ugc-submit";
import { ClientApiList } from "@/lib/api/client-service";
import DOMPurify from "isomorphic-dompurify";
import Styles from "./ugc-engagement.module.css";
import Head from "next/head";
import _debounce from "lodash/debounce";
import { useDatalayer } from "@/hooks/use-datalayer";
import html2canvas from "html2canvas-pro";
import { toProxy } from "@/helper/utils";

const variants = {
  show: {
    opacity: 1,
    display: "block",
  },
  hide: {
    opacity: 0,
    display: "none",
  },
  transition: {
    ease: "easeInOut",
    duration: 0.3,
  },
};

const UGCEng = ({
  dataDetail,
  engagement_uuid,
  instruction,
  handleReset,
  data,
}) => {
  let _PLACEHOLDER = "MEMBAWA MULTIPURPOSE POUCH PUNTUNG GUE SENDIRI.";
  const menus = ["Layout", "Font Style", "Photo"];
  const [position] = useState(["template1", "template2", "template3"]);
  const [fonts, setFonts] = useState(["oswald", "caveat brush", "roboto"]);
  const [images] = useState([0, 1, 2]);
  const textAreaRef = useRef(null);
  const cardRef = useRef(null);
  const isMobile = useIsMobile();
  const searchParams = useSearchParams();

  const [hideRule, setHideRule] = useState(false);
  const [isShowCustom, setIsShowCustom] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(menus[0]);
  const [selectedTemplate, setSelectedTemplate] = useState(position[0]);
  const [selectedFont, setSelectedFont] = useState(fonts[0]);
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [isUnderline, setIsUnderline] = useState(false);
  const [text, setText] = useState("");
  const [isInfoShow, setIsInfoShow] = useState(false);
  const [isPopupShow, setIsPopupShow] = useState(false);
  const [isExit, setIsExit] = useState(false);
  const [isPosterExist, setIsPosterExist] = useState(0);
  const [popupData, setPopupData] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const datalayer = useDatalayer();
  const [imageCats, setImageCats] = useState([]);
  const [selectedCat, setSelectedCat] = useState();
  const [placeholder, setPlaceholder] = useState(_PLACEHOLDER);
  const textRef = useRef(null);
  const shadowTextRef = useRef(null);

  useEffect(() => {
    if (textRef.current)
      [
        ...textRef.current.querySelectorAll("span[style*='rgb(0,0,0)']"),
      ].forEach((el) => {
        el.style.color =
          selectedTemplate === position[1]
            ? process.env.NEXT_PUBLIC_NAME === "sampoernakretek"
              ? "var(--icon_1)"
              : "var(--text_4)"
            : "var(--text_2)";
      });
    if (shadowTextRef.current)
      [
        ...shadowTextRef.current.querySelectorAll("span[style*='rgb(0,0,0)']"),
      ].forEach((el) => {
        el.style.color =
          selectedTemplate === position[1]
            ? process.env.NEXT_PUBLIC_NAME === "sampoernakretek"
              ? "var(--icon_1)"
              : "var(--text_4)"
            : "var(--text_2)";
      });
  });

  const reset = () => {
    handleReset(() => {
      setSelectedMenu(menus[0]);
      setSelectedTemplate(position[0]);
      setSelectedFont(fonts[0]);
      setSelectedImage(images[0]);
      setIsUnderline(false);
      setText("");
      setIsPopupShow(false);
      setIsShowCustom(false);
      setIsInfoShow(false);
      setIsExit(false);
      setPopupData();
      setIsSubmitting(false);
    });
  };

  const getPosterLength = async () => {
    try {
      const { data } = await ClientApiList.getGallerySubmission({
        type: "ugc",
      });
      setIsPosterExist(data.data.data.paging.totalData);
      // setIsPosterExist(2)
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (dataDetail && dataDetail.fonts) {
      setFonts(dataDetail.fonts.map((font) => font.font.replaceAll("+", " ")));
      let sc1 = document.createElement("link");
      sc1.setAttribute("href", "https://fonts.googleapis.com");
      sc1.setAttribute("rel", "preconnect");
      sc1.crossOrigin = "anonymous";
      document.head.appendChild(sc1);
      let sc2 = document.createElement("link");
      sc2.setAttribute("href", "https://fonts.gstatic.com");
      sc2.setAttribute("rel", "preconnect");
      sc2.crossOrigin = "anonymous";
      document.head.appendChild(sc2);
      dataDetail.fonts.forEach((font) => {
        let sc = document.createElement("link");
        sc.setAttribute("href", font.href);
        sc.setAttribute("rel", "stylesheet");
        document.head.appendChild(sc);
        sc.crossOrigin = "anonymous";
      });
      setSelectedFont(dataDetail.fonts[0].font.replaceAll("+", " "));

      setImageCats(
        dataDetail?.ugc.selection_desktop_img.map((image) => image.category)
      );
      setSelectedCat(dataDetail?.ugc.selection_desktop_img[0].category);
    }
    if (dataDetail) setPlaceholder(dataDetail?.ugc?.ugc_placeholder);
  }, [dataDetail]);

  useEffect(() => {
    getPosterLength();
  }, []);

  const handleImgStyle = () => {
    switch (selectedTemplate) {
      case position[0]:
        return "max-h-[249px] !h-[100dvh] w-full";
      case position[1]:
        return "max-w-[61%] max-h-[356.39px] w-full !h-[100dvh] m-[5px] rounded-[4.62px] overflow-hidden";
      case position[2]:
        return "mx-[15px] h-[100dvh] max-h-[229px]";
    }
  };

  const handleInputBox = () => {
    switch (selectedTemplate) {
      case position[0]:
        return "mt-[0px] mb-[20px] mx-[10px]";
      case position[1]:
        return "mt-[22px] mb-[20px] mx-[10px]";
      case position[2]:
        return "mb-[20px] mx-[15px]";
    }
  };

  function textAreaAdjust(e) {
    if (
      (e.target.value.length === 1 &&
        (e.key.match(/^[a-zA-Z0-9!@#$%^&*()_+-=<>,./?'":;]$/g) ||
          e.keyCode == 8 ||
          e.keyCode == 46)) ||
      e.key === "Enter" ||
      e.keyCode === 13
    ) {
      textAreaRef.current.style.height = "48px";
      return;
    }
    if (
      (e.target.value.length !== 0 &&
        (e.key.match(/^[a-zA-Z0-9!@#$%^&*()_+-=<>,./?'":;]$/g) ||
          e.keyCode == 8 ||
          e.keyCode == 46)) ||
      e.key === "Enter" ||
      e.keyCode === 13
    ) {
      textAreaRef.current.style.height = "1px";
      setTimeout(() => {
        textAreaRef.current.style.height =
          textAreaRef.current.scrollHeight + "px";
      }, 100);
    }
  }

  const debounceFn = useCallback(_debounce(textAreaAdjust, 500), []);

  const handleGetConfirmation = async () => {
    try {
      datalayer.push({
        event: "general_event",
        event_name: "click_submit_ugc",
        feature: "simple engagement",
        engagement_type: "games",
        engagement_name: "ugc",
        level: 1,
        campaign_name: dataDetail?.campaign_name.toLowerCase(), // e.g all: ;
        event_label: `ugc - ${data?.engagement_title.toLowerCase()}`,
        content_id: data?.engagement_uuid,
      });
      const { data: dataPopup } = await ClientApiList.getGameConfirmation({
        engagement_uuid,
        type: "ugc",
        status: "completed",
      });

      setPopupData({ ...dataPopup.data.data });
    } catch (err) {
      console.log("[Error]", err);
    }
  };

  const delay = (time) => {
    return new Promise((res) => {
      setTimeout(res, time);
    });
  };

  const buildPng = async () => {
    await delay(300);
    const cavas = await html2canvas(cardRef.current, {
      useCORS: true,
      scale: 3,
      width: 375,
      height: 640,
    });

    return cavas;
  };

  const handleToImg = async () => {
    setIsSubmitting(true);
    try {
      const canvas = await buildPng();
      canvas.toBlob(async (blob) => {
        const myFile = new File([blob], "image.png", {
          type: blob.type,
        });
        const { data: dataAnswer } = await ClientApiList.getAnswerUGC({
          engagement_uuid,
          ugc: myFile,
          text,
        });
        if (dataAnswer.data.result.data.point) await handleGetConfirmation();
        setIsPopupShow(true);
        datalayer.push({
          event: "general_event",
          event_name: "page_reached_finish_on_ugc",
          feature: "simple engagement",
          engagement_type: "games",
          engagement_name: "ugc",
          level: 1,
          campaign_name: dataDetail?.campaign_name.toLowerCase(), // e.g all: ;
          event_label: `ugc - ${data?.engagement_title.toLowerCase()}`,
          content_id: data?.engagement_uuid,
        });
      });
    } catch (err) {
      console.log(err, "err", err.message);
    } finally {
      setTimeout(() => {
        setIsSubmitting(false);
      }, 500);
    }
  };

  const handleImportFont = () => {
    let string = "";
    dataDetail.fonts.forEach((font) => {
      string += `@import url(${font.href});\n`;
    });
    return string;
  };

  useEffect(() => {
    if (text.length === 1) {
      textAreaRef.current.style.height = "48px";
      return;
    }
    if (text.length !== 0) {
      textAreaRef.current.style.height = "1px";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  }, [selectedFont]);

  useEffect(() => {
    setHideRule(searchParams.get("hide-sub-rule") === "true");
  }, [searchParams]);

  return (
    <motion.div
      key="animation-on-state"
      variants={variants}
      animate={hideRule ? "show" : "hide"}
      className="relative"
    >
      <Head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        {dataDetail?.fonts.map((font) => {
          return <link key={font.font} href={font.href} rel="stylesheet" />;
        })}
      </Head>

      <style jsx global>{`
        ${dataDetail ? handleImportFont() : null}
      `}</style>

      <ButtonBack
        className={`${isPosterExist > 0 ? "mt-[46px] lg:mt-[0px] " : "mt-[0] "}flex lg:top-[20px] lg:mt-0 `}
        onClick={() => setIsExit(true)}
      />
      <UGCNotif isShow={isPosterExist > 0} />
      <div className="lg:min-h-[649px] lg:flex">
        {/* desktop left menu */}
        <div className="mt-[110px] ml-[35px] hidden lg:block  lg:relative z-[1] lg:max-w-[326px]">
          <div
            className={cn(
              "h-[126px] text-[40px] leading-[41.6px] font-black font-font-family-3 uppercase mb-[14.6px] text-text-2",
              process.env.NEXT_PUBLIC_NAME !== "djisamsoe" ? "w-[200px]" : ""
            )}
          >
            {process.env.NEXT_PUBLIC_NAME !== "djisamsoe" ? (
              <svg
                width="200"
                height="126"
                viewBox="0 0 200 126"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.56174 0H13.2657L16.8368 18.8586L20.6887 0H28.1118L28.6735 32.4319H23.5777L22.9758 10.8507L19.2041 32.4319H14.67L10.6576 10.6905L10.1761 32.4319H5L5.56174 0Z"
                  className="fill-icon-2"
                />
                <path
                  d="M41.3283 32.9124C38.8941 32.9124 36.9681 32.5254 35.5503 31.7513C34.1326 30.9505 33.1295 29.7893 32.541 28.2678C31.9793 26.7197 31.6984 24.7577 31.6984 22.382V0H38.8004V23.4631C38.8004 24.7711 38.9609 25.8121 39.2819 26.5862C39.6297 27.3603 40.3118 27.7473 41.3283 27.7473C42.3448 27.7473 43.0135 27.3603 43.3345 26.5862C43.6555 25.8121 43.816 24.7711 43.816 23.4631V0H50.9582V22.382C50.9582 24.7577 50.6639 26.7197 50.0754 28.2678C49.4869 29.7893 48.4838 30.9505 47.0661 31.7513C45.6751 32.5254 43.7625 32.9124 41.3283 32.9124Z"
                  className="fill-icon-2"
                />
                <path
                  d="M54.095 0H61.2773V27.5872H68.7404V32.4319H54.095V0Z"
                  className="fill-icon-2"
                />
                <path
                  d="M75.0258 0H83.2112L89.27 32.4319H82.6494L81.5661 25.5852H76.7913L75.6678 32.4319H68.8867L75.0258 0ZM80.884 21.301L79.1586 8.64852L77.4332 21.301H80.884Z"
                  className="fill-icon-2"
                />
                <path
                  d="M91.1585 0H98.3408V32.4319H91.1585V0Z"
                  className="fill-icon-2"
                />
                <path
                  d="M5.12037 46.5438H12.3027V74.131H19.7658V78.9757H5.12037V46.5438Z"
                  className="fill-icon-2"
                />
                <path
                  d="M26.0512 46.5438H34.2366L40.2954 78.9757H33.6748L32.5915 72.129H27.8166L26.6932 78.9757H19.9121L26.0512 46.5438ZM31.9094 67.8448L30.184 55.1923L28.4586 67.8448H31.9094Z"
                  className="fill-icon-2"
                />
                <path
                  d="M42.1438 46.5438H47.1995L53.8602 62.1592V46.5438H59.7986V78.9757H54.9435L48.2829 62.1592V78.9757H42.1438V46.5438Z"
                  className="fill-icon-2"
                />
                <path
                  d="M71.6688 79.4562C68.4588 79.4562 66.1717 78.4819 64.8075 76.5333C63.4432 74.5847 62.7611 71.822 62.7611 68.2452V57.3544C62.7611 53.6975 63.4967 50.9081 64.968 48.9862C66.466 47.0643 68.9804 46.1034 72.5114 46.1034C75.9086 46.1034 78.3428 46.9308 79.8141 48.5858C81.2853 50.2408 82.0209 52.5764 82.0209 55.5927V57.6748H74.9991V55.0322C74.9991 53.7509 74.852 52.7899 74.5578 52.1493C74.2635 51.482 73.6081 51.1483 72.5917 51.1483C71.5484 51.1483 70.8663 51.5087 70.5453 52.2294C70.2511 52.9501 70.1039 53.9511 70.1039 55.2324V70.2471C70.1039 71.5284 70.2912 72.5427 70.6657 73.2901C71.0669 74.0109 71.7758 74.3712 72.7923 74.3712C74.6648 74.3712 75.601 72.9698 75.601 70.1671V66.3633H72.6719V62.1592H82.0611V78.9757H77.3264L76.9251 76.2931C75.9086 78.4018 74.1565 79.4562 71.6688 79.4562Z"
                  className="fill-icon-2"
                />
                <path
                  d="M85.1232 46.5438H92.3055V59.7968L97.7625 46.5438H104.784L98.7656 61.1982L105.065 78.9757H97.7625L93.0679 64.6416L92.3055 65.9229V78.9757H85.1232V46.5438Z"
                  className="fill-icon-2"
                />
                <path
                  d="M111.03 46.5438H119.216L125.275 78.9757H118.654L117.571 72.129H112.796L111.672 78.9757H104.891L111.03 46.5438ZM116.889 67.8448L115.163 55.1923L113.438 67.8448H116.889Z"
                  className="fill-icon-2"
                />
                <path
                  d="M127.243 46.5438H134.426V59.6767H139.441V46.5438H146.664V78.9757H139.441V64.8018H134.426V78.9757H127.243V46.5438Z"
                  className="fill-icon-2"
                />
                <path
                  d="M159.013 46.5438H166.196V74.131H173.659V78.9757H159.013V46.5438Z"
                  className="fill-icon-2"
                />
                <path
                  d="M184.169 79.4562C180.665 79.4562 178.164 78.562 176.666 76.7736C175.168 74.9585 174.419 72.2892 174.419 68.7657V56.7138C174.419 53.1903 175.168 50.5477 176.666 48.786C178.164 46.9976 180.665 46.1034 184.169 46.1034C187.7 46.1034 190.215 46.9976 191.713 48.786C193.238 50.5744 194 53.217 194 56.7138V68.7657C194 72.2892 193.238 74.9585 191.713 76.7736C190.215 78.562 187.7 79.4562 184.169 79.4562ZM184.169 74.3712C185.186 74.3712 185.855 74.0375 186.176 73.3702C186.497 72.6762 186.657 71.7419 186.657 70.5675V54.9521C186.657 53.7509 186.497 52.8166 186.176 52.1493C185.855 51.482 185.186 51.1483 184.169 51.1483C183.206 51.1483 182.564 51.4953 182.243 52.1894C181.922 52.8567 181.762 53.7776 181.762 54.9521V70.5675C181.762 71.7419 181.909 72.6762 182.203 73.3702C182.498 74.0375 183.153 74.3712 184.169 74.3712Z"
                  className="fill-icon-2"
                />
                <path
                  d="M5.12037 93.0876H13.3459C16.1546 93.0876 18.3481 93.4346 19.9263 94.1286C21.5046 94.8226 22.6147 95.917 23.2567 97.4118C23.9254 98.88 24.2598 100.855 24.2598 103.338V115.109C24.2598 117.618 23.9254 119.62 23.2567 121.115C22.6147 122.61 21.5046 123.718 19.9263 124.438C18.3749 125.159 16.2081 125.52 13.4262 125.52H5.12037V93.0876ZM13.4262 120.555C14.4694 120.555 15.2318 120.408 15.7133 120.114C16.2215 119.794 16.5425 119.353 16.6763 118.793C16.81 118.206 16.8769 117.351 16.8769 116.23V102.096C16.8769 101.055 16.7966 100.268 16.6361 99.7341C16.5024 99.1736 16.1814 98.7598 15.6731 98.4929C15.1916 98.226 14.4293 98.0925 13.386 98.0925H12.3027V120.555H13.4262Z"
                  className="fill-icon-1"
                />
                <path
                  d="M27.238 93.0876H34.4203V125.52H27.238V93.0876Z"
                  className="fill-icon-1"
                />
                <path
                  d="M55.2936 126C52.1906 126 49.8767 125.186 48.352 123.558C46.854 121.903 46.0515 119.287 45.9445 115.71L52.0836 114.789C52.1104 116.844 52.3511 118.326 52.8058 119.233C53.2873 120.114 53.9962 120.555 54.9324 120.555C56.1362 120.555 56.7381 119.767 56.7381 118.192C56.7381 116.938 56.4438 115.857 55.8553 114.949C55.2936 114.042 54.3975 113.067 53.167 112.026L50.3582 109.584C48.9673 108.409 47.8839 107.168 47.1082 105.86C46.3592 104.526 45.9847 102.951 45.9847 101.136C45.9847 98.4128 46.7872 96.3174 48.3921 94.8493C50.0239 93.3812 52.2575 92.6472 55.0929 92.6472C58.1692 92.6472 60.2556 93.5414 61.3524 95.3298C62.4759 97.1182 63.0777 99.2937 63.158 101.856L56.9788 102.617C56.9253 100.935 56.7648 99.7341 56.4973 99.0134C56.2566 98.266 55.7082 97.8923 54.8522 97.8923C54.237 97.8923 53.7555 98.1593 53.4077 98.6931C53.0867 99.2003 52.9262 99.7742 52.9262 100.415C52.9262 101.509 53.167 102.43 53.6485 103.178C54.1567 103.925 54.9592 104.779 56.0559 105.74L58.7443 108.102C60.3493 109.464 61.5931 110.905 62.4759 112.427C63.3586 113.948 63.8 115.75 63.8 117.832C63.8 119.353 63.4389 120.741 62.7166 121.996C62.0211 123.224 61.0314 124.198 59.7474 124.919C58.4634 125.64 56.9788 126 55.2936 126Z"
                  className="fill-icon-1"
                />
                <path
                  d="M65.8848 93.0876H73.0671V125.52H65.8848V93.0876Z"
                  className="fill-icon-1"
                />
                <path
                  d="M76.4803 93.0876H81.536L88.1967 108.703V93.0876H94.1351V125.52H89.2801L82.6194 108.703V125.52H76.4803V93.0876Z"
                  className="fill-icon-1"
                />
                <path
                  d="M97.5791 93.0876H104.761V125.52H97.5791V93.0876Z"
                  className="fill-icon-1"
                />
                <path
                  d="M107.733 93.1677H114.234L111.866 117.271H109.619L107.733 93.1677ZM107.613 119.434H113.792V125.52H107.613V119.434Z"
                  className="fill-icon-1"
                />
              </svg>
            ) : (
              <ImageComp
                objectFit="contain"
                src="/assets/djisamsoe/ugc/title.png"
                alt="ugc title"
              />
            )}
          </div>
          <div className="text-[13px] leading-[18px] text-text-2 max-w-[234px] mb-[15px]">
            Bikin poster yang sesuai dengan gaya{" "}
            {process.env.NEXT_PUBLIC_NAME !== "djisamsoe" ? "lo" : "kamu"} di
            sini!
          </div>
          <ButtonAction
            onClick={() => setIsInfoShow(true)}
            intent="primary"
            className="rounded-[30px] max-w-[160px]"
          >
            <div className="flex justify-center items-center gap-[8px]">
              <div className="bg-cta-2 h-[24px] w-[24px] rounded-[50%] flex items-center justify-center">
                <ImageComp
                  src={`/assets/${process.env.NEXT_PUBLIC_NAME}/ugc/question.svg`}
                  alt="how to"
                />
              </div>
              Cara Ikutan
            </div>
          </ButtonAction>
        </div>
        {/*
         *
         * Card with input         *
         */}
        <div
          className={cn(
            "grow-[1] lg:mt-0",
            isPosterExist ? "mt-[60px]" : "mt-0"
          )}
        >
          <div className="flex flex-col max-w-[375px] min-h-[640px] mx-auto lg:mr-[8%] 2xl:mr-auto lg:my-[41px] relative">
            <div className={"absolute inset-0 z-[0]"}>
              <ImageComp
                alt="bg"
                src={
                  isMobile
                    ? dataDetail?.ugc?.mobile_image_bg
                    : dataDetail?.ugc?.desktop_image_bg
                }
              />
            </div>
            {/* image */}
            <div
              className={`relative ease-out duration-300 ${handleImgStyle()} ${selectedTemplate === position[2] ? "order-3" : "order-1"}`}
            >
              {selectedTemplate === position[2] ? (
                <div className="w-full max-w-[57%] h-[5px] absolute top-[-5px] left-0 bg-bg-5"></div>
              ) : null}
              <ImageComp
                alt="image"
                className={cn(
                  "absolute inset-0",
                  selectedTemplate === position[2] ? "center" : "bottom"
                )}
                src={
                  dataDetail && imageCats.length !== 0
                    ? isMobile
                      ? dataDetail?.ugc.selection_mobile_img.filter(
                          (obj) => obj.category === selectedCat
                        )[0]?.img_list[selectedImage]
                      : dataDetail?.ugc.selection_desktop_img.filter(
                          (obj) => obj.category === selectedCat
                        )[0]?.img_list[selectedImage]
                    : "/assets/amild/ugc/game-bg.png"
                }
              />
              {selectedTemplate === position[2] ? (
                <div className="w-full max-w-[57%] h-[5px] absolute bottom-[-5px] right-0 bg-bg-5"></div>
              ) : null}
            </div>
            {/* text static*/}
            <div
              style={{
                background:
                  selectedTemplate === position[1]
                    ? "linear-gradient(90deg, rgba(44, 42, 41, 0) 0%, rgba(44, 42, 41, 0.8) 19.91%, #2C2A29 49.35%, #2C2A29 100%)"
                    : "transparent",
                color:
                  selectedTemplate === position[1]
                    ? process.env.NEXT_PUBLIC_NAME === "sampoernakretek"
                      ? "var(--icon_1)"
                      : "var(--text_4)"
                    : "var(--icon_2)",
              }}
              className={cn(
                `relative ease-out  duration-300 mb-[10px] p-[10px] text-[16px] leading-[24px] font-medium
            ${selectedTemplate === position[1] ? "text-right max-w-[297px] ml-auto mt-[-88px] relative z-[2]" : selectedTemplate === position[2] ? "mt-[23px] mb-[20px] mr-[10px] leading-[18px] text-right" : "mt-[5px] text-left"}
            ${selectedTemplate === position[2] ? "order-1" : "order-2"}`,
                Styles.question
              )}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(dataDetail?.ugc?.ugc_question),
              }}
              ref={textRef}
            ></div>
            <div
              className={`${handleInputBox()}
              ease-out duration-300 relative z-[1]
            ${selectedTemplate === position[2] ? "order-2" : "order-3"}
          `}
            >
              <div
                style={{ fontFamily: "var(--ABeeZee-ugc)" }}
                className=" relative z-[1] uppercase text-[12px] leading-[18px] font-medium text-text-2 mb-[5px]"
              >
                {dataDetail?.ugc?.ugc_instruction}
              </div>
              <div className="flex relative z-[1] items-stretch">
                {selectedTemplate === position[0] ? (
                  <div
                    // ref={blockRef}
                    style={{
                      background:
                        text.length !== 0 ? "var(--cta_1)" : "var(--text_3)",
                      maxHeight:
                        text.length > 0 ? textAreaRef.current.height : "unset",
                    }}
                    className={cn(
                      "w-[5px] ease-out duration-300",
                      text.length !== 0
                        ? `bg-cta-1 ${textAreaRef.current.height} min-h-[48px]`
                        : "min-h-[124px] bg-text-3"
                    )}
                  ></div>
                ) : null}
                <textarea
                  onKeyUp={(e) => debounceFn(e)}
                  style={{
                    fontFamily: selectedFont,
                    // lineHeight: "36px",
                  }}
                  ref={textAreaRef}
                  className={cn(
                    " relative z-[1] ease-out duration-300 p-[5px] border-b-[2px] w-full max-w-[336px] text-[32px] uppercase placeholder:text-text-3 mb-[5px] outline-none text-text-2 bg-[transparent]",
                    text.length !== 0
                      ? "overflow-auto min-h-[48px]"
                      : "min-h-[124px] max-h-[124px]",
                    isUnderline ? "underline" : "",
                    Styles.text
                  )}
                  placeholder={`CONTOH: ${placeholder.substring(0, Math.min(placeholder.length, 42))}`}
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                  maxLength={50}
                />
              </div>
              <div className="text-[12px] relative z-[1] leading-[18px] text-text-3 font-medium">
                {text.length !== 0 ? `${text.length} /` : "MAKSIMAL"} 50
                KARAKTER
              </div>
            </div>
          </div>
        </div>
        {/*
         *
         * End of the Card
         *
         */}
        {/*
         *
         * Shadow Card To Print
         *
         */}
        <div
          className="grow-[1] max-w-[375px] h-[640px] absolute z-[-1] inset-0"
          ref={cardRef}
          id="shadow-card"
        >
          <div className="flex flex-col max-w-[375px] min-h-[640px] mx-auto relative">
            <div className={"absolute inset-0 z-[-1]"}>
              <ImageComp
                id="image1"
                alt="bg"
                src={
                  isMobile
                    ? toProxy(dataDetail?.ugc?.mobile_image_bg)
                    : toProxy(dataDetail?.ugc?.desktop_image_bg)
                  // "/assets/amild/ugc/game-bg.png"
                }
              />
            </div>
            {/* image */}
            <div
              className={`relative z-[1] ease-out duration-300 ${handleImgStyle()} ${selectedTemplate === position[2] ? "order-3" : "order-1"}`}
            >
              {selectedTemplate === position[2] ? (
                <div className="w-full max-w-[57%] h-[5px] absolute top-[-5px] left-0 bg-bg-5"></div>
              ) : null}
              <ImageComp
                // crossorigin="anonymous"
                // ref={image1}
                alt="image"
                className="absolute inset-0"
                src={
                  dataDetail && imageCats.length !== 0
                    ? isMobile
                      ? toProxy(
                          dataDetail?.ugc.selection_mobile_img.filter(
                            (obj) => obj.category === selectedCat
                          )[0]?.img_list[selectedImage]
                        )
                      : toProxy(
                          dataDetail?.ugc.selection_desktop_img.filter(
                            (obj) => obj.category === selectedCat
                          )[0]?.img_list[selectedImage]
                        )
                    : "/assets/amild/ugc/game-bg.png"
                  // "/assets/amild/ugc/thumbnail.png"
                }
              />
              {selectedTemplate === position[2] ? (
                <div className="w-full max-w-[57%] h-[5px] absolute bottom-[-5px] right-0 bg-bg-5"></div>
              ) : null}
            </div>
            {/* text static*/}
            <div
              style={{
                background:
                  selectedTemplate === position[1]
                    ? "linear-gradient(90deg, rgba(44, 42, 41, 0) 0%, rgba(44, 42, 41, 0.8) 19.91%, #2C2A29 49.35%, #2C2A29 100%)"
                    : "transparent",
                color:
                  selectedTemplate === position[1]
                    ? process.env.NEXT_PUBLIC_NAME === "sampoernakretek"
                      ? "var(--icon_1)"
                      : "var(--text_4)"
                    : "var(--icon_2)",
              }}
              className={cn(
                ` relative z-[1] ease-out duration-300 mb-[10px] p-[10px] text-[16px] leading-[24px] font-medium
            ${selectedTemplate === position[1] ? "text-right max-w-[297px] ml-auto mt-[-88px] relative z-[2]" : selectedTemplate === position[2] ? "mt-[23px] mb-[20px] mr-[10px] leading-[18px] text-right" : "mt-[5px] text-left"}
            ${selectedTemplate === position[2] ? "order-1" : "order-2"}`,
                Styles.question
              )}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(dataDetail?.ugc?.ugc_question),
              }}
              ref={shadowTextRef}
            ></div>
            {/* text input */}
            <div
              className={cn(
                `${handleInputBox()}
              ease-out duration-300  relative z-[1]
            ${selectedTemplate === position[2] ? "order-2" : "order-3"}
          `,
                "mb-0 flex flex-col bg-transparent"
              )}
            >
              <div className="flex  relative z-[1] ">
                {selectedTemplate === position[0] ? (
                  <div
                    // ref={blockRef}
                    style={{
                      background:
                        text.length !== 0 ? "var(--cta_1)" : "var(--text_3)",
                      maxHeight:
                        text.length > 0 ? textAreaRef.current.height : "unset",
                    }}
                    className={cn(
                      "w-[5px] ease-out duration-300",
                      text.length !== 0
                        ? `bg-cta-1 ${textAreaRef.current.height} min-h-[48px]`
                        : "min-h-[124px] bg-text-3"
                    )}
                  ></div>
                ) : null}

                <div
                  style={{
                    fontFamily: selectedFont,
                    textDecoration: isUnderline ? "underline" : "none",
                    // lineHeight: "36px",
                  }}
                  className={cn(
                    "relative z-[1] ease-out duration-300 p-[5px] w-full max-w-[336px] text-[32px] uppercase placeholder:text-text-3 mb-[5px] outline-none text-text-2 break-word",
                    text.length !== 0 ? "overflow-auto" : "min-h-[124px]",
                    isUnderline ? "underline" : "",
                    Styles.text
                  )}
                >
                  {text}
                </div>
              </div>
            </div>
            <div className=" relative z-[1] w-full max-w-[248px] h-[55px] px-[15px] py-[10px] ml-auto mt-auto order-[4]">
              <ImageComp
                src={
                  dataDetail
                    ? isMobile
                      ? toProxy(dataDetail?.ugc.mobile_image_wtm)
                      : toProxy(dataDetail?.ugc.desktop_image_wtm)
                    : "/assets/amild/ugc/thumbnail.png"
                  // "/assets/amild/ugc/thumbnail.png"
                }
                alt="logo"
              />
            </div>
          </div>
        </div>
        {/*
         *
         * End of the Card
         *
         */}
        <CustomizeTab
          isShow={isShowCustom}
          handleMenu={setSelectedMenu}
          selectedMenu={selectedMenu}
          menus={menus}
          handleClose={() => setIsShowCustom(false)}
          handleTemplate={setSelectedTemplate}
          selectedTemplate={selectedTemplate}
          templates={position}
          fonts={fonts}
          handleFont={setSelectedFont}
          selectedFont={selectedFont}
          handleUnderline={setIsUnderline}
          isUnderline={isUnderline}
          handleImage={setSelectedImage}
          selectedImage={selectedImage}
          images={images}
          mobileImages={dataDetail?.ugc.selection_mobile_img || []}
          dekstopImages={dataDetail?.ugc.selection_desktop_img || []}
          handleSubmit={handleToImg}
          isActive={
            text.length !== 0 && dataDetail?.ugc.chances && !isSubmitting
          }
          isPosterExist={isPosterExist}
          isSubmitting={isSubmitting}
          imageCats={imageCats}
          handleCat={setSelectedCat}
          selectedCat={selectedCat}
        />
        <div
          className={cn(
            "fixed bottom-[154px] right-[10px] z-[20] lg:hidden",
            !hideRule ? "hidden" : "fixed"
          )}
        >
          <button
            style={{ background: "var(--cta_2)" }}
            className="w-[40px] h-[40px] rounded-[50%] border-[1px] border-[#e7e7e7] bg-cta-2 flex items-center justify-center drop-shadow-[0px_4px_4px_#00000040]"
            onClick={() => setIsInfoShow(true)}
          >
            <ImageComp
              src={`/assets/${process.env.NEXT_PUBLIC_NAME}/ugc/question.svg`}
              alt="how to"
            />
          </button>
        </div>
        <div
          className={cn(
            "fixed z-[19] bottom-[56px] left-2/4 -translate-x-1/2 w-full px-[10px] pb-[20px] pt-[65px] flex items-center gap-[10px] lg:hidden",
            !hideRule ? "hidden" : "fixed"
          )}
          style={{
            background:
              "linear-gradient(180deg,rgba(255,255,255,0), var(--background_3) 40%)",
          }}
        >
          <Link className="grow-[1] block w-full" onClick={() => {}} href={"#"}>
            <ButtonAction
              intent={"secondary"}
              onClick={() => setIsShowCustom(true)}
            >
              <div className="flex items-center justify-center">
                <div className="w-[24px] h-[24px] mr-[5px]">
                  <ImageComp
                    src={`/assets/${process.env.NEXT_PUBLIC_NAME}/ugc/custom.svg`}
                    alt="custom"
                  />
                </div>
                Customize
              </div>
            </ButtonAction>
          </Link>
          <ButtonAction
            className="grow-[1]"
            intent={
              text.length !== 0 && dataDetail?.ugc.chances && !isSubmitting
                ? "primary"
                : "primary_disable"
            }
            disabled={
              text.length !== 0 && dataDetail?.ugc.chances && !isSubmitting
                ? false
                : true
            }
            onClick={handleToImg}
          >
            {isSubmitting ? "Loading..." : "Submit"}
          </ButtonAction>
        </div>

        <InfoPopup
          isOpen={isInfoShow}
          onClose={() => {
            setIsInfoShow(false);
          }}
        >
          <p
            className={cn(
              "w-full font-font-family-3 font-bold text-2xl text-text-2 px-[20px] border-b border-cta-4",
              isMobile ? "py-[10px] mt-[10px]" : "py-[15px]"
            )}
          >
            INFORMASI
          </p>
          <div
            className={cn(
              "w-full font-font-family-5 text-sm text-text-2 px-[20px]",
              isMobile ? "pb-[30px] pt-[20px]" : "py-[30px]"
            )}
          >
            <>
              <p
                className={cn(
                  "text-text-2 font-font-family-7 font-bold",
                  isMobile ? "text-base" : "text-[20px] leading-[28px]"
                )}
              >
                {instruction.subtitle_instruction}
              </p>
              <CampaignDescription
                isList
                className="mb-0 md:mb-0 lg:text-[20px] leading-[20px] lg:leading-[28px]"
              >
                {instruction.desc_instruction}
              </CampaignDescription>
            </>
          </div>
        </InfoPopup>
        <PopupUGCConfirmExit isShowing={isExit} handleIsShowing={setIsExit} />
        <PopupUGCSubmit
          isShowing={isPopupShow}
          handleIsShowing={setIsPopupShow}
          poin={dataDetail?.point}
          popupData={popupData}
          handleReset={reset}
        />
      </div>
    </motion.div>
  );
};

export default UGCEng;
