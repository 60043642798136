"use client"
import React from "react"
import PropTypes from "prop-types"
import DetailBanner from "../shared/detail-sections/detail-banner/detail-banner"
import DetailHeader from "../shared/detail-sections/detail-header/detail-header"
import DetailDescription from "../shared/detail-sections/detail-description/detail-description"
import DetailTnC from "../shared/detail-sections/detail-tnc/detail-tnc"
import ButtonAction from "../shared/button-action/button-action"

export function ExclusiveExperienceDetail({
  bg_img_url,
  category,
  title,
  date,
  time,
  location,
  description,
  tnc,
  reserve_btn_label,
  date_img_url = "",
  time_img_url = "",
  location_img_url = "",
  href = "",
}) {
  return (
    <div className="lg:p-[15px] lg:grid lg:grid-cols-[1fr_401px] lg:gap-[30px_15px]">
      <DetailBanner image={bg_img_url} />
      <DetailHeader
        {...{
          category,
          title,
          date,
          time,
          location,
          dateImg: date_img_url,
          timeImg: time_img_url,
          locationImg: location_img_url,
        }}
      />
      <DetailDescription
        className="col-start-1 row-start-2 lg:mb-[10px]"
        description={description}
      />
      <DetailTnC className="col-start-1 row-start-3" tnc={tnc} />
      <div className="px-[10px] lg:hidden">
        <ButtonAction className="mt-[35px] mb-[20px]" intent="tertiary">
          {reserve_btn_label}
        </ButtonAction>
      </div>
    </div>
  )
}

ExclusiveExperienceDetail.propTypes = {
  bg_img_url: PropTypes.string,
  category: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  time: PropTypes.string,
  location: PropTypes.string,
  description: PropTypes.string,
  tnc: PropTypes.string,
  reserve_btn_label: PropTypes.string,
  date_img_url: PropTypes.string,
  time_img_url: PropTypes.string,
  location_img_url: PropTypes.string,
  href: PropTypes.string,
}
