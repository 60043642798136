"use client"
import React from "react"
import { Image } from "@/components/shared/image/image"
import { formatedDateNoDay } from "@/helper/utils"
import { formatNumber } from "@/components/edit-profile/date-input/helper"
import PropTypes from "prop-types"

export default function ItemCard({
  title,
  itemImg,
  exp,
  end_date,
  id,
  handleClaim,
  is_aspace,
  aldmic_id
}) {
  const splitedDate = formatedDateNoDay(end_date+" 00:00:00").split(
    " "
  )
  const dateToShow = `${splitedDate[0]} ${splitedDate[1]}, ${splitedDate[2]}`

  return (
    <div
      onClick={() =>
        handleClaim(aldmic_id, false, null, { title, img: itemImg, is_aspace }, id)
      }
      onKeyDown={() => {
        // Todo: Add keydown event
      }}
      role="button"
      tabIndex={0}
      className="cursor-pointer border-[1px] flex flex-col border-cta-4 p-[5px] rounded-[10px] bg-bg-3 w-[100vw] h-[100%] max-w-[228px]"
    >
      <div className="w-[100vw] h-[100vh] relative rounded-[5px] max-w-[218px] max-h-[109px] min-h-[109px] overflow-hidden mb-[5px]">
        <Image
          data-testid="item-card-img"
          className="absolute aspect-[2/1]"
          alt="cover"
          src={itemImg}
        />
      </div>
      <div className="p-[5px] pb-[10px] grow-[1] flex flex-col">
        <div
          data-testid="item-card-title"
          className="text-[16px] font-medium font-font-family-6 mb-[5px] leading-[21px] text-text-2"
        >
          {title}
        </div>
        <div
          data-testid="item-card-valid-date"
          className="text-[14px] tracking-[0.25px] font-medium font-font-family-6 leading-[19px] mb-[20px] text-text-3"
        >
          Valid Until {dateToShow}
        </div>
        <div className="mt-auto font-bold font-font-family-7 text-cta-1 text-[14px] leading-[19px] flex items-center gap-[5px] ">
          <div className="w-[16px] h-[16px] relative overflow-hidden">
            <Image
              alt="cover"
              src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/reward-profile.svg`}
            />
          </div>
          <div data-testid="item-card-exp" className="text-text-1">{formatNumber(exp)} Poin</div>
        </div>
      </div>
    </div>
  )
}

ItemCard.propTypes = {
  title: PropTypes.string,
  itemImg: PropTypes.string,
  exp: PropTypes.number,
  end_date: PropTypes.string,
  id: PropTypes.number,
  handleClaim: PropTypes.func,
  is_aspace: PropTypes.bool,
}
