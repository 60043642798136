import React from "react"
import Letter from "./Letter"
import Style from "./Board.module.scss"

const Board = ({
  board,
  level,
  currAttempt,
  dummyWord,
  correctLetter,
  almostLetter,
  wordleRow,
  shake,
  setShake,
  flipAnimation,
  setFlipAnimation,
  stage,
  failTime,
  wrongLetter,
}) => {
  const letterProps = {
    board,
    level,
    currAttempt,
    dummyWord,
    correctLetter,
    almostLetter,
    wordleRow,
    shake,
    setShake,
    flipAnimation,
    setFlipAnimation,
    stage,
    failTime,
    wrongLetter,
  }

  const levelBoard = wordleRow + 1

  return (
    <div className={`${Style.boardWordle}`}>
      {(() => {
        const boardRow = []

        for (let i = 0; i < 6; i++) {
          boardRow.push(
            <div key={i} className={Style.boardRow}>
              {Array.from({ length: wordleRow }).map((_, index) => {
                return (
                  <Letter
                    key={index}
                    {...letterProps}
                    size="big"
                    letterPos={index}
                    attemptVal={i}
                  />
                )
              })}
            </div>
          )
        }
        return boardRow
      })()}
    </div>
  )
}

export default Board
