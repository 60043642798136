"use client"
import PropTypes from "prop-types"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { useShowLoginPopup } from "@/hooks/auth/use-login-popup"

export function ExclusiveExperienceDetailPageLayout({ children }) {
  const { isLoading, isLogin } = useIsLogin()

  useShowLoginPopup({
    showWhen: !isLogin && !isLoading,
    closePath: "/",
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
  })

  return <div className="mt-[58px] lg:mt-[72px]">{children}</div>
}

ExclusiveExperienceDetailPageLayout.propTypes = {
  children: PropTypes.object,
}
