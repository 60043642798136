import { useEffect, useMemo, useState } from "react"
import { createPortal } from "react-dom"

export default function Portal({ children }) {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])
  // Create div to contain everything
  const el = useMemo(
    () =>
      typeof document !== "undefined"
        ? document.getElementById("brand-portal")
        : null,
    []
  )

  if (!el) return null

  return mounted ? createPortal(children, el) : null
}
