import React, { useCallback, useRef, useState } from "react"
import _debounce from "lodash/debounce"

const QuizTextAnswer = ({ maxChar, answer, setAnswer }) => {
  const [currentLength, setCurrentLength] = useState(0)
  const textAreaRef = useRef(null)

  function textAreaAdjust(e) {
    // console.log(e.target.value, e.key, e.keyCode)
    if (
      (e.target.value.length <= 1 &&
        (e.key.match(/^[a-zA-Z0-9!@#$%^&*()_+-=<>,./?'":;]$/g) ||
          e.keyCode == 8 ||
          e.keyCode == 46)) ||
      e.key === "Enter" ||
      e.keyCode === 13
    ) {
      textAreaRef.current.style.height = "192px"
      return
    }
    if (
      (e.target.value.length !== 0 &&
        (e.key.match(/^[a-zA-Z0-9!@#$%^&*()_+-=<>,./?'":;]$/g) ||
          e.keyCode == 8 ||
          e.keyCode == 46)) ||
      e.key === "Enter" ||
      e.keyCode === 13 ||
      e.keyCode == 17
    ) {
      textAreaRef.current.style.height = "1px"
      setTimeout(() => {
        textAreaRef.current.style.height =
          textAreaRef.current.scrollHeight + "px"
      }, 100)
    }
  }

  const debounceFn = useCallback(_debounce(textAreaAdjust, 500), [])

  const onKeyUp = (e) => {
    setCurrentLength(e.target.value.length)
  }

  return (
    <div className="flex flex-col gap-[10px] mt-[20px]">
      <div className="relative flex rounded-[5px] p-[1px] bg-cta-4 w-full h-full min-h-[192px]">
        <textarea
          placeholder="Tulis jawabanmu disini..."
          className="w-full h-full min-h-[192px] p-[10px] rounded-[4px] text-[14px] lg:text-[16px] leading-[20px] lg:leading-[24px] bg-bg-3"
          onKeyUp={(e) => {
            onKeyUp(e)
            debounceFn(e)
          }}
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          name="answer"
          maxLength={maxChar}
          ref={textAreaRef}
        ></textarea>
        <div className="absolute pr-[9px] right-[10px] min-h-[20px] bottom-[15px] origin-bottom text-[12px] leading-[20px] text-right text-[#CCC]">
          ({currentLength}/{maxChar} karakter)
        </div>
      </div>
    </div>
  )
}

export default QuizTextAnswer
