"use client"
import React, { Suspense, useEffect, useRef, useState } from "react"
import { usePathname, useSearchParams } from "next/navigation"
import { motion } from "framer-motion"
import Link from "@/components/shared/link/link"
import PropTypes from "prop-types"
import CampaignCard from "../campaign-intro/campaign-card/campaign-card"
import CampaignTitle from "../campaign-intro/campaign-card/components/campaign-title"
import CampaignDescription from "../campaign-intro/campaign-card/components/campaign-description"
import ButtonAction from "../shared/button-action/button-action"
import { Image } from "../shared/image/image"
import { ClientApiList } from "@/lib/api/client-service"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { useDatalayer } from "@/hooks/use-datalayer"
import { useInView } from "react-intersection-observer"
import ButtonBack from "../shared/button-back/button-back"
import CampaignPoint from "../campaign-intro/campaign-card/components/campaign-point"
import { SuccessIcon } from "./article-details"
import CheckBox from "../edit-profile/checkbox"
import DOMPurify from "isomorphic-dompurify"
import { useForm } from "react-hook-form"

function SubmissionIntroFallback() {
  return <div>SubmissionIntroFallback</div>
}

function SuspendedSubmissionIntro({ cta_label, data }) {
  const init = useRef(false)
  const linkRef = useRef(null)
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const [hideRule, setHideRule] = useState(false)
  const [finished, setFinished] = useState(0)
  const datalayer = useDatalayer()
  const isMobile = useIsMobile()
  const [dataDetail, setDataDetail] = useState()
  const [landingRef, inView] = useInView({
    triggerOnce: true,
  })

  const hrefParse = () => {
    const current = new URLSearchParams(Array.from(searchParams.entries())) // -> has to use this form

    return {
      pathname,
      query: {
        ...Object.fromEntries(current.entries()),
        "hide-sub-rule": true,
        // campaign: searchParams.get('campaign') || data?.campaign_name,
      },
    }
  }

  const handleDataLayer = () => {
    datalayer.push({
      event: "general_event",
      event_name: "click_mulai_sekarang_text_submission",
      feature: "simple engagement",
      engagement_type: "submission",
      engagement_name: "text",
      campaign_name: data?.campaign_name.toLowerCase(), // e.g all: ;
      event_label: `text submission - ${data?.subtitle_introduction.toLowerCase()}`,
      level: 1,
      content_id: data?.engagement_uuid,
    })
  }

  const onViewData = async () => {
    datalayer.push({
      event: "general_event",
      event_name: "page_reached_on_simple_engagement",
      feature: "simple engagement",
      page_type: "simple engagement",
      campaign_name: data?.campaign_name.toLowerCase(), // e.g all
      // userld: dataProfile?.data.data.dataProfile,
      event_label: `text submission - ${data?.subtitle_introduction.toLowerCase()}`,
      content_id: data?.engagement_uuid,
    })
  }

  useEffect(() => {
    if (data && inView) {
      onViewData()
    }
    // eslint-disable-next-line
  }, [data, inView])

  useEffect(() => {
    setHideRule(searchParams.get("hide-sub-rule") === "true")
  }, [searchParams])

  const variants = {
    show: {
      opacity: 1,
      y: 0,
      display: "block",
      transition: {
        ease: "easeInOut",
        duration: 0.3,
      },
    },
    hide: {
      opacity: 0,
      y: "-100px",
      display: "none",
      transition: {
        ease: "easeInOut",
        duration: 0.3,
        display: {
          delay: 0.3,
          duration: 0.1,
        },
      },
    },
  }

  const { register, watch, setValue } = useForm({
    isAgree: false,
  })

  const handleTNC = async () => {
    try {
      const { data: dataTNC } = await ClientApiList.setTncEngagement({
        engagement_uuid: data?.engagement_uuid,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleBtnAria = () => {
    return dataDetail?.is_tnc ? dataDetail?.is_tnc && !watch("isAgree") : false
  }

  const handleBtnIndex = () => {
    if (!dataDetail?.is_tnc) return undefined
    else return dataDetail?.is_tnc && !watch("isAgree") ? -1 : undefined
  }

  const handleBtnIntent = () => {
    if (!dataDetail?.is_tnc) return "primary"
    else return watch("isAgree") ? "primary" : "primary_disable"
  }

  const handleBtnStyleOnDisabled = () => {
    if (!dataDetail?.is_tnc) return "cursor-pointer"
    else
      return dataDetail?.is_tnc && !watch("isAgree")
        ? "pointer-events-none cursor-not-allowed"
        : "cursor-pointer"
  }

  useEffect(() => {
    if (dataDetail?.is_tnc_checked && !init.current) {
      setValue("isAgree", dataDetail.is_tnc_checked)
      init.current = true
    }
  }, [dataDetail])

  useEffect(() => {
    if (
      linkRef.current &&
      dataDetail?.tnc &&
      String(dataDetail?.tnc).includes("target=_blank") &&
      linkRef.current.querySelector("a") &&
      !String(linkRef.current.querySelector("a")).includes("target=_blank")
    ) {
      linkRef.current.querySelector("a")?.setAttribute("target", "_blank")
      linkRef.current.querySelector("a")?.setAttribute("rel", "noopener")
    }
  })

  const getDetail = async () => {
    const { data: dataEngagement } = await ClientApiList.getEngagementDetail({
      type: "text-submission",
      engagement_uuid: data?.engagement_uuid,
    })
    setFinished(dataEngagement?.data.data.finished)
    setDataDetail({ ...dataEngagement?.data.data })
  }
  useEffect(() => {
    getDetail()
    // eslint-disable-next-line
  }, [])

  return (
    <motion.div
      key="animation-on-state"
      variants={variants}
      animate={!hideRule ? "show" : "hide"}
      className="py-[20px] px-[10px] relative"
    >
      <ButtonBack />
      <div className="md:flex" ref={landingRef}>
        <div className="mb-[30px] text-[20px] leading-[27px] text-center md:hidden text-text-2">
          Submission Challenge
        </div>
        <div className="bg-bg-5 rounded-[10px] md:rounded-[0] md:rounded-l-[20px] overflow-hidden mb-[10px] md:mb-[0] w-[100%] max-w-[604px] left-0 right-0 min-h-[266.5px] relative">
          <Image
            alt="cover"
            className="object-center absolute"
            src={
              isMobile
                ? data?.mobile_image_introduction_url
                : data?.desktop_image_introduction_url
            }
          />
        </div>
        <CampaignCard className=" md:mb-0 md:min-h-[604px] md:flex flex-cols items-center lg:rounded-[0] md:!rounded-r-[20px] md:!px-[50px] w-full">
          <div className="flex gap-[20px] items-center">
            <div className="hidden w-fit lg:block text-[14px] font-medium font-font-family-6 tracking-[0.14px] leading-[10px] bg-cta-3 text-text-1 py-[10px] px-[15px] rounded-[10px] mb-[20px]">
              Submission Challenge
            </div>
            {!isMobile && data?.point > 0 && (
              <CampaignPoint
                className="hidden lg:flex"
                asset={
                  !finished &&
                  `/assets/${process.env.NEXT_PUBLIC_NAME}/icons/${process.env.NEXT_PUBLIC_NAME === "amild" ? "reward-profile-effect.png" : process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "reward.png" : "reward-profile.svg"}`
                }
                icon={finished && <SuccessIcon className="hidden lg:block" />}
              >
                {data.point}
              </CampaignPoint>
            )}
          </div>
          <CampaignTitle className="md:text-[40px] md:mb-[20px] leading-[24px] mb-[10px] lg:mb-[20px] lg:text-[40px] lg:leading-[44px] lg:font-font-family-3">
            {data?.subtitle_introduction}{" "}
          </CampaignTitle>
          {isMobile && data?.point > 0 && (
            <CampaignPoint
              className="lg:hidden flex"
              asset={
                !finished &&
                `/assets/${process.env.NEXT_PUBLIC_NAME}/icons/${process.env.NEXT_PUBLIC_NAME === "amild" ? "reward-profile-effect.png" : process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "reward.png" : "reward-profile.svg"}`
              }
              icon={finished && <SuccessIcon className="lg:hidden block" />}
            >
              {data?.point}
            </CampaignPoint>
          )}
          <CampaignDescription className="mb-0 md:'text-[20px] md:leading-[32px] md:mb-[20px] lg:text-[20px] leading-[20px] lg:leading-[28px] ">
            {data?.desc_introduction || "a"}
          </CampaignDescription>
          {dataDetail && dataDetail?.is_tnc ? (
            <form
              method="POST"
              className="flex gap-[5px] mt-[10px] md:mb-[30px]"
            >
              <CheckBox
                register={register}
                name={"isAgree"}
                disabled={dataDetail?.is_tnc_checked}
                isCenter={true}
                className="top-[calc(50%_-_10px)]"
              />
              <div
                ref={linkRef}
                className="text-[12px] lg:text-[14px] font-medium underline leading-[18px] font-medium text-text-1"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(dataDetail.tnc),
                }}
              ></div>
            </form>
          ) : null}
          <Link
            aria-disabled={handleBtnAria()}
            tabIndex={handleBtnIndex()}
            className={`hidden md:block w-[100%] max-w-[343px] ${handleBtnStyleOnDisabled()}`}
            onClick={() => {
              handleTNC()
              handleDataLayer()
            }}
            href={hrefParse()}
          >
            <ButtonAction intent={handleBtnIntent()}>{cta_label}</ButtonAction>
          </Link>
        </CampaignCard>
        {!isMobile ? (
          <Link
            aria-disabled={handleBtnAria()}
            tabIndex={handleBtnIndex()}
            className={`md:hidden ${handleBtnStyleOnDisabled()}`}
            href={hrefParse()}
            onClick={() => {
              handleTNC()
              handleDataLayer()
            }}
          >
            <ButtonAction intent={handleBtnIntent()}>{cta_label}</ButtonAction>
          </Link>
        ) : (
          <div
            className="fixed z-[19] bottom-[56px] left-2/4 -translate-x-1/2 w-full px-[10px] pt-[80px] pb-[20px]"
            style={{
              background:
                "linear-gradient(180deg,rgba(255,255,255,0), var(--background_3) 40%)",
            }}
          >
            <Link
              aria-disabled={handleBtnAria()}
              tabIndex={handleBtnIndex()}
              className={`md:hidden ${handleBtnStyleOnDisabled()}`}
              href={hrefParse()}
              onClick={() => {
                handleTNC()
                handleDataLayer()
              }}
            >
              <ButtonAction intent={handleBtnIntent()}>
                {cta_label}
              </ButtonAction>
            </Link>
          </div>
        )}
      </div>
    </motion.div>
  )
}

export function SubmissionIntro(props) {
  return (
    <Suspense fallback={<SubmissionIntroFallback />}>
      <SuspendedSubmissionIntro {...props} />
    </Suspense>
  )
}

SuspendedSubmissionIntro.propTypes = {
  cta_label: PropTypes.string,
  data: PropTypes.object,
}
