"use client";
import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";

export const SelectInput = forwardRef(function SelectPreset(
  {
    children,
    className,
    onChange,
    onBlur,
    name,
    value,
    options,
    placeholder,
    register,
    validation,
    ...props
  },
  ref
) {
  const registerField = register
    ? {
      ...register(name, validation ? { ...validation } : {}),
      ...props,
    }
    : { onChange, onBlur, value, placeholder, ...props };

  return (
    <select
      className={twMerge(
        "cursor-pointer text-text-3 w-[100%] text-[14px] leading-[20px] lg:leading-[22px] p-[10px] lg:p-[11px] border-[1px] rounded-[10px] mb-[10px] border-cta-4 disabled:bg-cta-4 focus:outline-cta-1 hover:border-cta-4 focus:border-cta-1 active:border-cta-1 disabled:text-text-3 placeholder:text-text-3 focus:text-text-2 hover:text-text-2  active:text-text-2 bg-bg-3",
        className
      )}
      {...registerField}
    >
      <option value="">
        {placeholder ? placeholder : "Select your option"}
      </option>
      {children ||
        options?.map((option) => {
          return (
            <option
              data-testid={`option-${option.value}`}
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          );
        })}
    </select>
  );
});

SelectInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  register: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.any,
  options: PropTypes.array,
  config: PropTypes.object,
  ref: PropTypes.any,
  props: PropTypes.object
};
