import { ClientApiList } from "@/lib/api/client-service"
import { usePathname, useRouter } from "next/navigation"
import { useState } from "react"
import { useForm } from "react-hook-form"

const useEditPassword = ({ setIsSuccess, captchaRef, setIsError }) => {
  const { replace } = useRouter()
  const pathname = usePathname()

  const handleGetData = async () => {
    const { data } = await ClientApiList.getProfileData()
    const dob = new Date(data.data.data.dob)
    const date = dob.getDate()
    const month = dob.getMonth() + 1
    const year = dob.getFullYear()
    const fav_brand = data.data.data.favorite_brand.brand_id
    return {
      ...data.data.data,
      date,
      month,
      year,
      fav_brand,
      old_password: "",
      new_password: "",
      confirm_password: "",
      "g-recaptcha-response": false,
    }
  }

  const {
    reset: formReset,
    setValue,
    register,
    getValues,
    handleSubmit,
    watch,
    setError,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: async () => handleGetData(),
  })

  const [formError, setFormError] = useState(null)

  const reset = () => {
    setFormError(null)
  }

  const onSubmit = async (data) => {
    captchaRef.current.reset()
    const token = await captchaRef.current.executeAsync()

    setFormError(null)

    if (!token) {
      setIsError(true)
      // captchaRef.current?.execute();
      replace(pathname)
      return
    } else {
      setIsError(false)

      try {
        const body = {
          old_password: data.old_password,
          new_password: data.new_password,
          confirm_password: data.confirm_password,
          login_name: data.email || data.phone,
          "g-recaptcha-response": token,
        }
        const result = await ClientApiList.updatePassword({ ...body })
        console.log(result, "res")
        if (result.status === 200) {
          formReset()
          setIsSuccess(true)
          setTimeout(() => {
            replace(pathname)
          }, [500])
        } else {
          if (result.status === 401) window.location.reload()
          setError("old_password", { message: result.error.message })
          setError("new_password", { message: result.error.message })
          setError("confirm_password", { message: result.error.message })
          setFocus("old_password")
          // captchaRef.current?.execute();
          return
        }
      } catch (err) {
        if (err.status === 401) window.location.reload()
        setError("old_password", { message: err?.message })
        setError("new_password", { message: err?.message })
        setError("confirm_password", { message: err?.message })
        setFocus("old_password")
        // captchaRef.current?.execute();
        return
      }
    }
  }

  return {
    register,
    getValues,
    errors,
    formError,
    reset,
    watch,
    setValue,
    onSubmit: handleSubmit(onSubmit),
  }
}

export default useEditPassword
