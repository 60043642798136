"use client"
import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { ClientApiList } from "@/lib/api/client-service"
// import { decryptData, encryptGame } from '@/helper/utils';
import crypto from "crypto"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { ToastComponent } from "../toast"
import { useDatalayer } from "@/hooks/use-datalayer"
import UseQueryParams from "@/hooks/use-query-params"

export const encryptGame = (data) => {
  // console.log(data, 'data encrypt');
  let dechiper = crypto.createCipheriv(
    "aes-256-ctr",
    process.env.NEXT_PUBLIC_GAME_KEY,
    process.env.NEXT_PUBLIC_GAME_IV
  )
  let dec = dechiper.update(data, "utf8", "hex")
  dec += dechiper.final("hex")
  // console.log(dec);
  return dec
}

export const decryptData = (data) => {
  let dechiper = crypto.createDecipheriv(
    "aes-256-ctr",
    process.env.NEXT_PUBLIC_GAME_KEY,
    process.env.NEXT_PUBLIC_GAME_IV
  )
  let dec = dechiper.update(data, "hex", "utf8")
  dec += dechiper.final("utf8")
  return JSON.parse(dec)
}

export const IframeAdventoure = () => {
  const QUERYPARAM = "point"
  const { handlePushQuery } = UseQueryParams()
  const datalayer = useDatalayer()
  const [point, setPoint] = useState()
  const [isSuccess, setIsSuccess] = useState(false)
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const { replace } = useRouter()
  const campaign = pathname.split("/")[2]
  // console.log(pathname.split('/')[2])
  // console.log(encryptGame(JSON.stringify([{ pi: 3.14 }])), 'pi', decryptData('630be1506120e2318a994f440288f5a6e5a2e528be3a44a70fc42a882b8435469055a29af352d709a8549b2e2f4f9cf8150ca63419baf741a9cd601dff4e01ba5cef0e55cab06df22eaaf5533d10137cf76cc0e0fe414c24f3b88416da460903a21c9bf1f22c4016d33b96ec3be3ca977ed7e2831dc02e9893fdce41debd168e7921a27e8662f394e43fb65d02b2f81263c6d5bc85c26c0e58b69d08ae8dc62b04cfcadf6aeb91f73705654ab06b3ca3e68a5ecb24290ebf67c2ddc2db177bc51da12450159ae89cc0ef3aef0a4e9403df955e93f2a155d774b64c0caad8a28fe1bb75bb78427876e315a7278522633b185c62f7ba89571f0a5769a4083976ec7e8db15ae85112515fa16624f7cd3df96e7e7f4134bebefee700f8706a1c5208de5c09c7b9c6c41ef863d47485b7bd4f215247fc2c8935e385b35aae04bb6ce7f5bd4189528f012f439dfcb96b4324adea7550d385357893107ca00a6da2cf52d4a12a6d6b868a925a4359828a5fe3ea8e006e1236634b40cb473b7a54946191f0a477703713e90dfc5068af3625b7ca961c3b8953077feef1db244b7752f47c644d8958c890ea7d5836c0d3660d4b1f8b3bebcb55758d1afc7b8d91e7b62b419b4c8870458588264a0b5311efe6a18f3f7efef583dc56d40d752abaa8b2d31d940ee9b7aa23148d6abbd4f920b5a040cbf033bd5a11ae36f04229abe0783f32000957814aadff32c0fe3c819d6edfd55a672dd1995f08eaac606843f24bfe8e084e25fff3c19b77b22c9e9555c9b176c978162db23792631e05fc2f411082660ebfb64cb271bd142fb65e30cf39d5bcde376f95123bd03f96d9b2b1ef3ac1e83eaeb49c121e52bb9242b12a1674421aa80aec0568af9b6490c1aa521dc1e7df355763c8f3ec8f74cdd8ca76f5ef906c0d128cc7080531e1d567f5ec07ba73ec3c30c8e79337237cb4575e76a63ca799d95e9dd866a4aa5a47a387e8699b55bd8d4dedabd617e7ac79b761566aa5a02850e7cd87d390f873239c3abb87754fe9fb111ef95460e65a16f88d8c2ea781644751c92af3d7fc3af242b79232aa1a424d7217f65551cdb0c7407b9935d8f5e9c1e223f13f8416d6fef052e409bc889b7e6dd3261064fb6af17ab50c4993e7c94daac30f1dd0bf301a01240a18a5c6fbc66d83b011e7f7c2551ba5385bf44c'));

  function handlePoint(point) {
    if (point !== 0) {
      setPoint(point)
      handlePushQuery(false, [QUERYPARAM, true])
      setIsSuccess(true)
    }
  }

  function handleDataLayer(data) {
    datalayer.push(data)
  }

  // useEffect(() => {
    // var rand = Math.floor((Math.random()*1000000)+1);
    // iframe.current.contentDocument.location.reload(true)
    // iframe.current.src = "https://g.minigim.fun/magnum-adventour/index.html?uid="+rand
    // iframe.current.src = "https://g.minigim.fun/magnum-adventour/index.html?uid="+rand
  // }, [])

  function parseStringToArray(answer) {
    let data = []
    if (!Array.isArray(answer)) {
      data.push(answer)
      return data
    }
    return answer
  }

  async function parseAdventourAnswers(answers, eventData) {
    const data = answers
    const parsedData = []
    for (let i = 0; i < data.length; i++) {
      const answer = data[i]
      if (answer.q1)
        parsedData.push({
          chapter: answer.chapter,
          question: 1,
          answer: parseStringToArray(answer.q1),
        })
      if (answer.q2)
        parsedData.push({
          chapter: answer.chapter,
          question: 2,
          answer: parseStringToArray(answer.q2),
        })
      if (answer.q3)
        parsedData.push({
          chapter: answer.chapter,
          question: 3,
          answer: parseStringToArray(answer.q3),
        })
      if (answer.q4)
        parsedData.push({
          chapter: answer.chapter,
          question: 4,
          answer: parseStringToArray(answer.q4),
        })
    }
    const encryptD = encryptGame(JSON.stringify(parsedData))
    const decryptD = decryptData(encryptD)
    // console.log({ ...eventData, answer: parsedData });
    // console.log(encryptD, 'encrypted data', decryptD);
    const { data: dataReturn } = await ClientApiList.postIframeData({
      data: encryptGame(JSON.stringify({ ...eventData, answer: parsedData })),
    })
    // console.log(dataReturn, 'return');
    handlePoint(dataReturn.data.data.point_get)
  }

  function eventHandler(event) {
    // console.log(event.origin, 'origin');
    // console.log(event.data, 'event handle');
    const data = JSON.parse(atob(event.data))
    // console.log(
    //   data,
    //   "data atob",
    //   data.action,
    //   data.action === "datalayer",
    //   count
    // )
    // count.current = count.current + 1
    if (data.action === "datalayer") handleDataLayer(data.value[0])
    else if (data.action == "submit") parseAdventourAnswers(data.answer, data)
    else if (data.action == "cekRecap") {
      replace(`${process.env.NEXT_PUBLIC_META_URL}/${campaign}/br`)
      // replace(`http://localhost:3000/${campaign}/br`)
    } else console.log(data)
  }

  const events = (e) => {
    if (e.origin !== "https://g.minigim.fun") return
    eventHandler(e)
  }

  useEffect(() => {
    window.addEventListener(
      "message",
      events,
      false
    )

    return () => {
      window.removeEventListener("message", events, false)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const titleBrand =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? `Kamu berhasil raih ${point} poin`
      : `Lo berhasil dapetin ${point} poin!`

  return (
    <div className="w-[100%] h-[100%] min-h-[calc(100vh-170px)] min-w-[100vw]">
      <ToastComponent
        onClick={() => {
          setIsSuccess(false)
        }}
        title={titleBrand}
        desc="Lanjut eksplor dan kumpulkan lebih banyak poin!"
        isOpen={isSuccess}
      />
      <iframe
        className="block w-[100dvw] h-[100dvh]"
        seamless="seamless"
        title="game-view"
        src="https://g.minigim.fun/magnum-adventour/index.html"
        frameBorder="0"
      ></iframe>
    </div>
  )
}
