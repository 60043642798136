"use client"
import React, { useEffect, useState } from "react"
import ButtonBack from "../shared/button-back/button-back"
import PropTypes from "prop-types"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { useShowLoginPopup } from "@/hooks/auth/use-login-popup"
import { useScrollTracker } from "react-scroll-tracker"
import { useDatalayer } from "@/hooks/use-datalayer"
import { usePathname } from "next/navigation"

export function MGMPageLayout({ children }) {
  const { isLoading, isLogin } = useIsLogin()
  const datalayer = useDatalayer()
  const { scrollY } = useScrollTracker()
  const pathname = usePathname()

  const [firstScroll, setFirstScroll] = useState(false)
  const [secondScroll, setSecondScroll] = useState(false)
  const [thirdScroll, setThirdScroll] = useState(false)
  const [finishScroll, setFinishScroll] = useState(false)

  const bottom =
    typeof window !== "undefined" &&
    window.innerHeight + window.scrollY >= document.body.offsetHeight

  const scrollDepth = (progress) => {
    datalayer.push({
      event: "general_event",
      event_name: "scroll_depth_non_article",
      feature: "special landing page",
      page_path: pathname,
      content_progress: progress,
      engagement_type: "interaction", // e.g., home
      campaign_name: "all", // e.g all
    })
  }

  useEffect(() => {
    if (((scrollY >= 25 && scrollY < 50) || bottom) && !firstScroll) {
      scrollDepth("25%")
      setFirstScroll(true)
    } else if (
      ((scrollY >= 50 && scrollY < 75 && firstScroll) ||
        (bottom && firstScroll)) &&
      !secondScroll
    ) {
      scrollDepth("50%")
      setSecondScroll(true)
    } else if (
      ((scrollY >= 75 && scrollY < 100 && secondScroll) ||
        (bottom && secondScroll)) &&
      !thirdScroll
    ) {
      scrollDepth("75%")
      setThirdScroll(true)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [scrollY, firstScroll, secondScroll, thirdScroll])

  useEffect(() => {
    if (bottom && !finishScroll && thirdScroll) {
      scrollDepth("100%")
      setFinishScroll(true)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [bottom, finishScroll, thirdScroll])

  useShowLoginPopup({
    showWhen: !isLogin && !isLoading,
    closePath: "/",
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
  })

  const datalayerView = async () => {
    datalayer.push({
      event: "general_event",
      event_name: `page_reached_on_special_landing_page`,
      feature: "special landing page",
      page_type: "special landing page",
      campaign_name: "all", // e.g all
    })
  }

  useEffect(() => {
    if (!isLoading) datalayerView()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLoading])

  // const page = typeof window !== "undefined" && document.body.offsetHeight > 0

  return (
    <main className="relative lg:p-[15px] mt-[58px] lg:mt-[72px]">
      <ButtonBack />
      {/* <div className="absolute rounded-b-[10px] top-[0px] left-0 right-0 h-[679px] w-[100%] bg-[#646464] z-1"></div> */}
      <div className="relative z-[1] pt-[462px] lg:pt-0 lg:grid lg:grid-cols-[minmax(200px,_459px)_minmax(200px,_393px)_minmax(350px,_1fr)] lg:grid-rows-[519px_94px_1fr] lg:gap-[15px]">
        {children}
      </div>
    </main>
  )
}

MGMPageLayout.propTypes = {
  children: PropTypes.object,
}
