"use client"
import { ScrollArea } from "@/components/shared/scroll-area"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import clsx from "clsx"
import { motion } from "framer-motion"
import { forwardRef } from "react"
import styles from "./dropdown.module.scss"
import PropTypes from "prop-types"

export const DropdownMenu = DropdownMenuPrimitive.Root
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger

/**
 * @typedef {object} DropdownContentProps
 * @property {boolean} noArrow
 */

/**
 * @type {React.FC<DropdownContentProps & DropdownMenuPrimitive.DropdownMenuContentProps>}
 * @example
 * <DropdownContent noArrow>
 *  <p>This is a dropdown content</p>
 * </DropdownContent>
 *
 */

export const DropdownMenuContent = forwardRef(
  ({ children, className, noArrow, stickyBanner, ...props }, forwardedRef) => {
    return (
      <DropdownMenuPrimitive.Content
        sideOffset={noArrow ? 0 : 12}
        ref={forwardedRef}
        className={clsx(
          styles.content,
          className,
          stickyBanner && styles.content_sticky
        )}
        {...props}
      >
        <ScrollArea stickyBanner={stickyBanner}>
          <div>{children}</div>
        </ScrollArea>
        {!noArrow && (
          <DropdownMenuPrimitive.Arrow
            // fill="white"
            width={20}
            height={10}
            offset={10}
            className={clsx("fill-bg-3", styles.arrow)}
          />
        )}
      </DropdownMenuPrimitive.Content>
    )
  }
)
DropdownMenuContent.displayName = "DropdownMenuContent"

DropdownMenuContent.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  noArrow: PropTypes.bool,
  stickyBanner: PropTypes.bool,
}

export const DropdownIcon = ({ open, ...props }) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    width="20"
    height="21"
    style={{ originX: "50%", originY: "50%" }}
    initial={false}
    animate={open ? { rotate: 180 } : { rotate: 0 }}
    {...props}
  >
    <path
      d="M15.8346 8L10.0013 13.8333L4.16797 8"
      // stroke="#ED1C24"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-icon-1"
    />
  </motion.svg>
)

DropdownIcon.propTypes = {
  open: PropTypes.bool,
}

export const DropdownMenuLabel = DropdownMenuPrimitive.Label
export const DropdownMenuItem = DropdownMenuPrimitive.Item
export const DropdownMenuGroup = DropdownMenuPrimitive.Group
export const DropdownMenuSeparator = DropdownMenuPrimitive.Separator
