"use client";
import React, { useEffect, useState } from "react";

import "swiper/css";
import "swiper/css/pagination";
import BackBanner from "../shared/back-banner";
import UserPassport from "../user-profile/user-passport/user-passport";
import { ShowQR } from "../user-profile/show-qr";
import { ClientApiList } from "@/lib/api/client-service";
import ButtonBack from "../shared/button-back/button-back";
import ButtonLogout from "../shared/button-logout";
import { useIsDesktop } from "@/hooks/use-is-mobile";

export function UserProfileHeader(props) {
  const [dataProfile, setDataProfile] = useState({ ...props });
  const [isLoading, setIsLoading] = useState(true);
  const isDesktop = useIsDesktop();

  const getData = async () => {
    try {
      const { data } = await ClientApiList.getProfileHeader();
      // console.log(data, "a data");
      setDataProfile({ ...data.data.data });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // const userPassportProps = {
  //   accumulated_exp: dataProfile.accumulated_exp,
  //   accumulated_img_url: dataProfile.accumulated_img_url,
  //   bg_img_url: dataProfile.bg_img_url,
  //   cta_label: dataProfile.cta_label,
  //   current_tier: dataProfile.current_tier,
  //   current_tier_img_url: dataProfile.current_tier_img_url,
  //   name: dataProfile.name,
  //   next_tier: dataProfile.next_tier,
  //   next_tier_exp: dataProfile.next_tier_exp,
  //   next_tier_img_url: dataProfile.next_tier_img_url,
  //   passport_bottom_img_url: dataProfile.passport_bottom_img_url,
  //   passport_top_img_url: dataProfile.passport_top_img_url,
  //   qr_img_url: dataProfile.qr_img_url,
  //   tier_badge_img_url: dataProfile.tier_badge_img_url,
  //   user_id: dataProfile.user_id,
  //   user_img_url: dataProfile.user_img_url,
  //   account_label: dataProfile.account_label,
  //   registered_account: dataProfile.registered_account,
  // }

  return (
    <>
      <ButtonBack className="lg:top-[35px] lg:left-[35px] z-[2]" />
      <ButtonLogout />
      <BackBanner
        bgImg={`/assets/${process.env.NEXT_PUBLIC_NAME}/user-profile/bg-profile-${isDesktop ? "desktop" : "mobile"}.png`}
        className=" z-[-1] lg:top-[15px] bg-bg-5"
      />
      <div className="text-white text-center text-[20px] leading-[27px] lg:w-fit mx-auto lg:text-[32px] lg:leading-[23px] font-medium font-font-family-6 mb-[30px] lg:mb-[32px]">
        Profile
      </div>
      <UserPassport {...{ ...props, ...dataProfile, isLoading }} />
      <ShowQR
        {...{
          qrImage: dataProfile.qr_img_url,
          account_label: dataProfile.account_label,
          registered_account: dataProfile.registered_account,
          ctaLabel: dataProfile.cta_label,
        }}
      />
    </>
  );
}
