import React from "react"

// background: linear-gradient(90deg, #191919 0%, #404040 31.3%, #191919 78.67%, #404040 115.08%);
//border-bottom: 1px solid;
// border-image-source: linear-gradient(105deg, #808080 12.84%, #FFFFFF 38.51%, #989898 68.81%, #FFFFFF 98.59%);

// background: linear-gradient(180deg, #F7E086 0%, #DF730F 100%);
// border-bottom: 1px solid;
// border-image-source: linear-gradient(281.67deg, #654D2C 0%, #DFD59A 50.09%, #654D2C 100%, #654D2C 100%);
// background: linear-gradient(90deg, #15130C 0%, #110C02 29.17%, #15130C 81.01%);

// background: linear-gradient(90deg, #F5F5F5 0%, #EEEEEE 29.17%, #F5F5F5 81.01%);
// background: linear-gradient(0deg, var(--Color-Text-2, #2C2A29), var(--Color-Text-2, #2C2A29)),
// linear-gradient(303.99deg, #FF4D53 7.51%, #ED1C24 50.19%, #DB0C14 90.63%);
// cta-1

// background: linear-gradient(180deg, #C7D943 0%, #89A627 100%);
// bg-4
//background: linear-gradient(90deg, #FDF7DE 0%, #E2DDCA 29.17%, #FDF7DE 81.01%);

const handleBG = () => {
  switch (process.env.NEXT_PUBLIC_NAME) {
    case "magnum":
      return "linear-gradient(90deg, #191919 0%, #404040 31.3%, #191919 78.67%, #404040 115.08%)"
    case "djisamsoe":
      return "linear-gradient(90deg, #15130C 0%, #110C02 29.17%, #15130C 81.01%)"
    case "sampoernakretek":
      return "linear-gradient(90deg, #FDF7DE 0%, #E2DDCA 29.17%, #FDF7DE 81.01%)"
    default:
      return "linear-gradient(90deg, #F5F5F5 0%, #EEEEEE 29.17%, #F5F5F5 81.01%)"
  }
}

const handleNumber = () => {
  switch (process.env.NEXT_PUBLIC_NAME) {
    case "magnum":
      return "black"
    case "djisamsoe":
      return "linear-gradient(180deg, #F7E086 0%, #DF730F 100%)"
    case "sampoernakretek":
      return "linear-gradient(180deg, #C7D943 0%, #89A627 100%)"
    default:
      return "linear-gradient(303.99deg, #FF4D53 7.51%, #ED1C24 50.19%, #DB0C14 90.63%)"
  }
}

const handleBorder = () => {
  switch (process.env.NEXT_PUBLIC_NAME) {
    case "magnum":
      return "linear-gradient(105deg, #808080 12.84%, #FFFFFF 38.51%, #989898 68.81%, #FFFFFF 98.59%) 30"
    case "djisamsoe":
      return "linear-gradient(281.67deg, #654D2C 0%, #DFD59A 50.09%, #654D2C 100%, #654D2C 100%) 30"
    case "sampoernakretek":
      return "var(--background_4) 30"
    default:
      return "var(--cta_1) 30"
  }
}

const WinnerCard = ({ pos, name }) => {
  return (
    <div
      className="rounded-[10px] p-[10px] lg:p-[20px] lg:px-[30px] flex gap-[10px] lg:w-full"
    style={{background:handleBG(), backgroundRepeat:'no-repeat', backgroundSize:'cover'}}
    >
      <div
        className=" border-b-[1px] border-cta-1 w-[28px] min-w-[28px] lg:w-[40px] lg:min-w-[40px] h-[28px] lg:h-[40px] rounded-[5px] lg:rounded-[10px] flex items-cetner justify-center font-bold text-[14px] lg:text-[26px] leading-[28px] lg:leading-[40px] text-text-4"
        style={{
          background: handleNumber(),
          borderImageSource: handleBorder(),
          backgroundRepeat:'no-repeat', backgroundSize:'cover'
        }}
      >
        {pos}
      </div>
      <div className="text-[14px] text-wrap break-all lg:text-[24px] leading-[28px] lg:leading-[40px] font-medium text-text-2 font-font-family-2">
        {name}
      </div>
    </div>
  )
}

export const WinnerCardSkeleton = ({ pos }) => {
  return (
    <div
      style={{ background: handleBG() }}
      className="animate-pulse rounded-[10px] p-[10px] lg:p-[20px] lg:px-[30px] flex gap-[10px] lg:w-full bg-[#D9D9D9]"
    >
      <div
        style={{
          background: handleNumber(),
          borderImageSource: handleBorder(),
        }}
        className="border-[1px] border-cta-1 bg-[linear-gradient(303.99deg,_#FF4D53_7.51%,_#ED1C24_50.19%,_#DB0C14_90.63%)] w-[28px] lg:w-[40px] h-[28px] lg:h-[40px] rounded-[5px] lg:rounded-[10px] flex items-cetner justify-center font-bold text-[14px] lg:text-[26px] leading-[28px] lg:leading-[40px] text-text-4"
      >
        {pos}
      </div>
      <div className="w-full h-[28px] lg:h-[40px] bg-[#D9D9D9]">
        {/* {name} */}
      </div>
    </div>
  )
}

export default WinnerCard
