import { useIsTablet } from "@/hooks/use-is-mobile";
import { useViewportSize } from "@mantine/hooks";
import {
  useAnimation,
  useMotionValueEvent,
  useScroll,
  motion,
} from "framer-motion";
import React from "react";

const BackToTop = () => {
  let { scrollY } = useScroll();
  let { height } = useViewportSize();
  const controls = useAnimation();
  const isTablet = useIsTablet();

  useMotionValueEvent(scrollY, "change", (latest) => {
    const bottoms = height + latest >= document.body.offsetHeight;

    if ((latest < scrollY.getPrevious() && latest > 300) || bottoms) {
      // IF SCROLL UP AND BOTTOMS
      controls.start({
        y: isTablet ? -65 : 0,
        transition: {
          duration: 0.3,
          ease: "easeOut",
        },
      });
    } else if (latest > scrollY.getPrevious() && latest > 300) {
      // IF SCROLL DOWN
      controls.start({
        y: 0,
        transition: {
          duration: 0.3,
          ease: "easeOut",
        },
      });
    } else {
      controls.start({
        y: 136,
        transition: {
          duration: 0.3,
          ease: "easeOut",
        },
      });
    }
  });

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <motion.div
      initial={{ y: 136 }}
      animate={controls}
      className="fixed z-[15] bottom-[71px] right-[35px] flex"
    >
      <button type="button" onClick={() => goToTop()}>
        <div
          style={{
            background:
              process.env.NEXT_PUBLIC_NAME === "sampoernakretek"
                ? "var(--background_1)"
                : "var(--cta_2)",
          }}
          className="rounded-[50%]"
        >
          <IconToTop />
        </div>
      </button>
    </motion.div>
  );
};

const IconToTop = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 25C0.5 11.469 11.469 0.5 25 0.5C38.531 0.5 49.5 11.469 49.5 25C49.5 38.531 38.531 49.5 25 49.5C11.469 49.5 0.5 38.531 0.5 25Z"
      // fill="white"
      className={"fill-transparent"}
    />
    <path
      d="M0.5 25C0.5 11.469 11.469 0.5 25 0.5C38.531 0.5 49.5 11.469 49.5 25C49.5 38.531 38.531 49.5 25 49.5C11.469 49.5 0.5 38.531 0.5 25Z"
      // stroke="#ED1C24"
      className="stroke-icon-1"
    />
    <path
      d="M25 32L25 18M25 18L19 24M25 18L31 24"
      // stroke="#ED1C24"
      className="stroke-icon-1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BackToTop;
