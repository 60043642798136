"use client"
import React from "react"
import BackBanner from "../shared/back-banner"
import EditHeader from "../edit-profile/edit-header"
import FormBox from "../edit-profile/form-box"
// import TextInput from "../edit-profile/text-input"
import ButtonAction from "../shared/button-action/button-action"
import Link from "@/components/shared/link/link"
import { data } from "../otp/datas/data"
import useRenderForm from "@/hooks/useRenderForm"
import PropTypes from "prop-types"

export function OTP({ getValues, register }) {
  const [handleRenderLabel, handleRenderInput] = useRenderForm()

  return (
    // <>
    <div className="py-[20px] lg:py-[32px] px-[10px] relative">
      <BackBanner className="h-[607px] bg-[crimson]" />
      <div className="relative z-[3] max-w-[462px] mx-auto">
        <EditHeader title={data.title} description={data.description} />
        <FormBox className="mb-[20px]" title={data.formList.section_title}>
          {/* <div className="flex items-center justify-between gap-[10px] mb-[20px]"> */}
          {data.formList.formItems.map((item) => {
            // 'min-h-[74px]'
            return (
              <>
                {handleRenderLabel(item.label, item.link_label)}
                {handleRenderInput(item.type, {
                  placeholder: item.placeholder,
                  className: "min-h-[74px]",
                  register,
                  getValues,
                })}
              </>
            )
          })}
          {/* </div> */}
          <div className="text-center text-[12px] text-text-5 tracking-[0.2px] pt-[15px] pb-[10px]">
            {data.resend_label}{" "}
            <Link
              className="ml-[5px] underline font-bold font-font-family-7 text-text-2 "
              href="#"
            >
              {data.resend_link_label}
            </Link>
          </div>
        </FormBox>
        <ButtonAction
          className="max-w-[355px] mx-auto mt-[20px]"
          intent="primary"
        >
          {data.cta_label}
        </ButtonAction>
      </div>
    </div>
    // </>
  )
}

OTP.propTypes = {
  getValues: PropTypes.func,
  register: PropTypes.func,
}
