import { Controller } from "react-hook-form";
import PropTypes from "prop-types";

const FieldText = ({ name, control, icon, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <div className="group overflow-hidden w-full bg-bg-3 flex items-center border-[1px] border-cta-4 rounded-[10px] has-[:focus]:outline-cta-1 has-[:focus]:border-cta-1 has-[:active]:border-cta-1">
          {icon && <div className="ml-[10px]">{icon}</div>}
          <input
            className="p-[10px] w-full bg-transparent disabled:bg-cta-4 text-[14px] text-text-3 leading-[20px] disabled:text-text-3 placeholder:text-text-3 placeholder:italic focus:text-text-2 active:text-text-2 group-hover:text-text-2 outline-none"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            {...props}
          />
        </div>
      )}
    />
  );
};

FieldText.propTypes = {
  name: PropTypes.string,
  control: PropTypes.func,
  icon: PropTypes.any,
};

export default FieldText;
