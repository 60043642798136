import EventCard from "../event-card/event-card"
import Separator from "@/components/slide-menu/separator/separator"
import PropTypes from "prop-types"

function EventList({ items, isLoading, isLoadMore, selectedCat }) {
  const itemsToRender = () => {
    if (isLoading)
      return [1, 2, 3].map((item, index, arr) => (
        <li data-testid={`skeleton-card-${index}`} key={item}>
          <SkeletonCard index={index} arr={arr} />
          {index !== arr.length - 1 ? (
            <Separator className="h-[5px] bg-cta-4 opacity-[0.3] my-[10px] lg:my-[20px]" />
          ) : null}
        </li>
      ))
    else if (items.length > 0)
      return items.map((item, index, arr) => (
        <li data-testid={`event-card-${index}`} key={item.uuid}>
          <EventCard
            uuid={item.uuid}
            title={item.event_title}
            location={item.location_desc}
            date={item.event_date}
            time={item.start_time}
            image_url={item.mobile_image_url}
            desktop_image_url={item.desktop_image_url}
            index={index}
            selectedCategory={selectedCat}
          />
          {index !== arr.length - 1 ? (
            <Separator className="h-[5px] bg-cta-4 opacity-[0.3] my-[10px] lg:my-[20px]" />
          ) : null}
        </li>
      ))
    else
      return (
        <p
          data-testid="no-event"
          className="w-full text-base mt-[32px] text-center text-text-2"
        >
          No Event
        </p>
      )
  }

  return (
    <ol className="mb-[22px] lg:mb-[30px] max-w-[650px] mx-auto">
      {itemsToRender()}
      {isLoadMore
        ? [1, 2, 3].map((item, index, arr) => {
            return (
              <li key={item}>
                {index === 0 ? (
                  <Separator className="h-[5px] bg-cta-4 opacity-[0.3] my-[10px] lg:my-[20px]" />
                ) : null}
                <SkeletonCard index={index} arr={arr} />
                {index !== arr.length - 1 ? (
                  <Separator className="h-[5px] bg-cta-4 opacity-[0.3] my-[10px] lg:my-[20px]" />
                ) : null}
              </li>
            )
          })
        : null}
    </ol>
  )
}

function SkeletonCard() {
  return (
    <div className="animate-pulse flex gap-[10px] p-[5px] md:p-0 justify-between items-start w-[100%] mx-auto">
      <div className="grow-[1]">
        <div className="rounded-[10px] bg-[#D9D9D9] h-[13px] md:h-[16px] mb-[10px] md:mb-[15px] max-w-[50%]"></div>
        <div className="rounded-[10px] h-[22px] md:h-[27px] mb-[10px] md:mr-[54px] md:max-w-none bg-[#D9D9D9]"></div>
        <div className="rounded-[10px] h-[22px] md:h-[27px] mb-[10px] md:mb-[15px] md:mr-[54px] bg-[#D9D9D9] max-w-[60%]"></div>
        <div className="rounded-[10px] h-[18px] md:h-[22px] md:mb-[10px] mr-[10px] md:mr-0 bg-[#D9D9D9] max-w-[60%]"></div>
      </div>
      <div>
        <div className="w-[134px] md:max-w-[165px] h-[100px] md:min-h-[124px] bg-[#D9D9D9] rounded-[10px] row-start-1 col-start-2 row-span-2 md:row-span-4 relative md:self-center overflow-hidden"></div>
      </div>
      <div className="max-w-[134px] min-h-[100px] bg-[#D9D9D9] rounded-[10px] row-span-2 lg:row-span-4 relative overflow-hidden"></div>
    </div>
  )
}

export default EventList

EventList.propTypes = {
  // selectedCategory: PropTypes.string,
  items: PropTypes.array,
  isLoading: PropTypes.bool,
  isLoadMore: PropTypes.bool,
}
