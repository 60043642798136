"use client"

import { useEffect } from "react"
import { ClientAllAccessApi } from "@/lib/api/client-service"
import { getGALinker, getGL } from "@/helper/ga"
import { useAsync } from "../use-async"
import { useSearchParams } from "next/navigation"
import { ClientStorage } from "ClientStorage"

export const useReqUrl = ({ page, platform, redirectUrl, utm } = {}) => {
  const searchParams = useSearchParams()
  const localStorage = new ClientStorage("localStorage")
  const checkUtmQr = searchParams.get("utm_source")?.includes("qrcode")
  const checkQuery = typeof window !== "undefined" ? window.location.search : ""
  const checkHash = typeof window !== "undefined" ? window.location.hash : ""

  const defaultParams = {
    page: "login",
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
    redirectUrl:
      process.env.NEXT_PUBLIC_META_URL + "/br" + checkQuery + checkHash,
    utm: utm || "",
  }

  const handlePlatform = () => {
    if (checkQuery.includes("oename=inspiraksi"))
      return `${process.env.NEXT_PUBLIC_NAME} x inspiraksi 2024`
    return platform || defaultParams.platform
  }

  const { execute, status, value } = useAsync(async () =>
    ClientAllAccessApi.getUrl({
      ga: getGALinker(),
      gl: getGL(),
      detail_page: redirectUrl || defaultParams.redirectUrl,
      first_page: page || defaultParams.page,
      platform: handlePlatform(),
      auth_data: "",
      ref: "",
      redirect_url: `${process.env.NEXT_PUBLIC_META_URL}/allaccess-loading`,
      utm: checkUtmQr ? searchParams.get("utm_source") : defaultParams.utm,
    })
  )

  useEffect(() => {
    if (value?.data?.data?.data?.url) {
      /* set cookies, reused to get auth-data*/
      localStorage.set("platform", platform || defaultParams.platform)
      localStorage.set("detail_page", redirectUrl || defaultParams.redirectUrl)
      localStorage.set("action", page || defaultParams.page)

      window.location.href = value?.data?.data?.data?.url
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [value])

  return { execute, status }
}
