"use client"
import { BannerItem } from "../shared/banner/banner-item"
import PropTypes from "prop-types"

export function HeroBannerSlide({ data = {} }) {
  return (
    <div className="mt-[10px] mb-[20px] px-[10px]">
      <BannerItem
        items={data?.items}
        autoplay={data?.autoplay}
        show_navigation={true}
        event="hero_banner"
      />
    </div>
  )
}

HeroBannerSlide.propTypes = {
  data: PropTypes.object,
}
