import clsx from "clsx"
import { Image } from "@/components/shared/image/image"
import { Description } from "../shared/description/description"
import Link from "@/components/shared/link/link"
import ButtonBack from "../shared/button-back/button-back"
import PropTypes from "prop-types"
import { useDatalayer } from "@/hooks/use-datalayer"
import { useInView } from "react-intersection-observer"
import { useEffect, useRef, useState } from "react"
import { useWindowSize } from "@/hooks/use-window-size"
import { SuccessIcon } from "../sections/article-details"
import ButtonAction from "../shared/button-action/button-action"
import { useForm } from "react-hook-form"
import CheckBox from "../edit-profile/checkbox"
import DOMPurify from "isomorphic-dompurify"
import { ClientApiList } from "@/lib/api/client-service"
import { Spinner } from "../shared/spinner"

const LandingSubmission = (props) => {
  const {
    stage,
    setStage,
    data,
    finished,
    isTnc,
    isTncChecked,
    tnc,
    setIsTncChecked,
  } = props
  const init = useRef(false)
  const linkRef = useRef(null)

  const [loading, setLoading] = useState(false)

  const { width } = useWindowSize()
  const isDesktop = width >= 1024

  const datalayer = useDatalayer()

  const { register, watch, setValue } = useForm({
    isAgree: false,
  })

  useEffect(() => {
    if (isTncChecked && !init.current) {
      setValue("isAgree", isTncChecked)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isTncChecked])

  useEffect(() => {
    if (
      linkRef.current &&
      tnc &&
      tnc.includes("target=_blank") &&
      linkRef.current.querySelector("a") &&
      !String(linkRef.current?.querySelector("a")).includes("target=_blank")
    )
      linkRef.current?.querySelector("a")?.setAttribute("target", "_blank")
      linkRef.current?.querySelector("a")?.setAttribute("rel", "noopener")
  })

  const onGameStart = async () => {
    if (isTnc && !isTncChecked) {
      setLoading(true)
      const { data: tncData } = await ClientApiList.setTncEngagement({
        engagement_uuid: data?.engagement_uuid,
      })
      if (tncData?.code === 200) {
        setLoading(false)
        setIsTncChecked(true)
        setStage(2)
      }
    } else {
      setStage(2)
    }
    datalayer.push({
      event: "general_event",
      event_name: "click_mulai_sekarang_submission_file",
      feature: "simple engagement",
      engagement_type: "submission",
      engagement_name: `${data?.submission_type?.toLowerCase()}`,
      campaign_name: data?.campaign_name?.toLowerCase(), // e.g all
      event_label: `submission ${data?.submission_type?.toLowerCase()} - ${data?.subtitle_introduction?.toLowerCase()}`,
      level: 1,
      content_id: data?.engagement_uuid,
    })
  }

  const StartGameCta = (
    <ButtonAction
      intent={isTnc && !watch("isAgree") ? "primary_disable" : "primary"}
      data-testid="start-game-cta"
      className="w-full"
      onClick={() => {
        onGameStart()
      }}
      disabled={isTnc && !watch("isAgree")}
    >
      Mulai Sekarang
    </ButtonAction>
  )

  const [landingRef, inView] = useInView({
    triggerOnce: true,
  })

  const datalayerView = async () => {
    // const { data: dataProfile } = await ClientApiList.getProfileData()
    datalayer.push({
      event: "general_event",
      event_name: "page_reached_on_simple_engagement",
      feature: "simple engagement",
      page_type: "simple engagement",
      campaign_name: data?.campaign_name?.toLowerCase(), // e.g all
      event_label: `submission ${data?.submission_type?.toLowerCase()} - ${data?.subtitle_introduction?.toLowerCase()}`,
      // userld: dataProfile?.data.data.dataProfile,
      content_id: data?.engagement_uuid,
    })
  }

  useEffect(() => {
    if (inView) {
      datalayerView()
    }
    // eslint-disable-next-line
  }, [inView])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [stage])

  const wPointDesk =
    process.env.NEXT_PUBLIC_NAME === "amild" ? " w-[32px]" : " w-[24px]"
  const wPointMob =
    process.env.NEXT_PUBLIC_NAME === "amild" ? " w-[28px]" : " w-[16px]"

  const pointGet = data?.point && (
    <div
      className={clsx(
        "flex gap-[5px] items-center text-text-1 font-bold font-font-family-7",
        isDesktop ? "text-base" : "text-sm mt-[10px]"
      )}
    >
      {finished === 1 ? (
        <SuccessIcon />
      ) : (
        <div
          className={clsx(
            "relative aspect-square",
            isDesktop ? wPointDesk : wPointMob
          )}
        >
          <Image
            objectFit="cover"
            alt="icon"
            src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/${process.env.NEXT_PUBLIC_NAME === "amild" ? "reward-profile-effect.png" : "reward-profile.svg"}`}
          />
        </div>
      )}
      <span>{data?.point}</span> <span>Poin</span>
    </div>
  )

  const blackGradient =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe" ||
    process.env.NEXT_PUBLIC_NAME === "magnum"

  return (
    <div className={clsx("relative w-full px-[10px]", isDesktop && "flex")}>
      <div ref={landingRef} />
      <Link href="/" passHref>
        <ButtonBack className="top-[-10px] left-[6px]" />
      </Link>
      {!isDesktop && (
        <h2 className="text-center text-text-2 text-xl font-medium font-font-family-6">
          Submission Challenge
        </h2>
      )}
      <div
        className={clsx(
          "relative w-full overflow-hidden rounded-[10px] bg-bg-1",
          isDesktop
            ? "mt-0 max-w-[838px] aspect-[638/604] rounded-r-none"
            : "mt-[30px] aspect-[355/267]"
        )}
      >
        <Image
          data-testid="bg-img"
          alt="submission-cover"
          objectFit="cover"
          src={
            isDesktop
              ? data?.desktop_image_introduction_url
              : data?.mobile_image_introduction_url
          }
        />
      </div>
      <div
        className={clsx(
          "bg-bg-3 rounded-[10px] border-[1px] border-cta-4 p-[15px]",
          isDesktop
            ? "flex flex-col justify-center rounded-l-none py-[63px] px-[55px]"
            : "my-[10px]"
        )}
      >
        {isDesktop && (
          <div className="flex gap-[20px]">
            <p className="w-fit bg-cta-3 rounded-[10px] py-[10px] px-[15px] font-medium font-font-family-6 text-center text-text-1 text-sm tracking-[0.14px]">
              Submission Challenge
            </p>
            {pointGet}
          </div>
        )}
        <p
          data-testid="subtitle-introduction"
          className={clsx(
            "font-bold text-text-2 capitalize font-font-family-3",
            isDesktop ? "text-[40px] mt-[20px]" : "text-base"
          )}
        >
          {data?.subtitle_introduction}
        </p>
        {!isDesktop && pointGet}
        <Description
          className={clsx(
            "text-text-2",
            isDesktop
              ? "text-xl leading-[32px] my-[20px]"
              : "text-sm leading-[24px] my-[10px]"
          )}
          description={data?.desc_introduction}
        />
        {isTnc ? (
          <div>
            <form method="POST" className="flex gap-[5px]">
              <CheckBox
                register={register}
                name={"isAgree"}
                disabled={isTncChecked}
                isCenter={true}
                // value={isChecked}
                // options={{onChange:(e) => setIsChecked(e.target.value)}}
                className="top-[calc(50%_-_10px)]"
              />
              <div
                ref={linkRef}
                className="text-[12px] lg:text-[14px] font-medium underline leading-[18px] font-medium text-text-1"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(tnc),
                }}
              ></div>
            </form>
          </div>
        ) : null}
        {isDesktop && (
          <div className="w-[343px] mt-[30px]">
            {loading ? (
              <div className="w-full flex justify-center items-center">
                <Spinner />
              </div>
            ) : (
              StartGameCta
            )}
          </div>
        )}
      </div>
      {!isDesktop && (
        <div
          className={clsx(
            "fixed z-[19] bottom-[56px] left-2/4 -translate-x-1/2 w-full px-[10px] pt-[80px] pb-[20px] ",
            blackGradient
              ? "bg-[linear-gradient(180deg,_rgba(0,0,0,0)_0,_#000_40%)]"
              : "bg-[linear-gradient(180deg,_rgba(255,255,255,0)_0,_#FFF_40%)]"
          )}
        >
          {loading ? (
            <div className="w-full flex justify-center items-center">
              <Spinner />
            </div>
          ) : (
            StartGameCta
          )}
        </div>
      )}
    </div>
  )
}

export default LandingSubmission

LandingSubmission.propTypes = {
  setStage: PropTypes.func,
  href: PropTypes.string,
  data: PropTypes.object,
  finished: PropTypes.bool,
}
