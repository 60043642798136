import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";

const AudioPlayerVolume = ({
  audioRef,
  currentVolume,
  setCurrentVolume,
  getIconVolume,
}) => {
  const min = 0;
  const max = 100;
  const containerRef = useRef(null);
  const volumeRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) return;

    // CLOSE WHEN CLICK OTHER
    function handleClick(event) {
      if (!containerRef?.current?.contains(event.target)) setIsOpen(false);
    }
    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, [isOpen]);

  const getCurrentVolume = (event) => {
    if (!volumeRef.current) setCurrentVolume(currentVolume);

    const sliderRect = volumeRef.current.getBoundingClientRect();
    const sliderHeight = sliderRect.height;
    const offsetY = sliderRect.bottom - event.clientY; // Distance from the bottom

    let newValue = Math.round((offsetY / sliderHeight) * (max - min) + min);
    newValue = Math.max(min, Math.min(max, newValue)); // Clamp the value within the min-max range

    const newVolume = (newValue / 100).toFixed(1);
    audioRef.current.audio.current.volume = newVolume;
    setCurrentVolume({ value: newVolume, position: newValue });
  };

  const handleMouseMove = (event) => {
    if (event instanceof MouseEvent) event.preventDefault();
    event.stopPropagation();
    // Prevent Chrome drag selection bug
    const windowSelection = window.getSelection();
    if (windowSelection && windowSelection.type === "Range") {
      windowSelection.empty();
    }

    getCurrentVolume(event);
  };

  const handleMouseDown = (event) => {
    event.stopPropagation();
    getCurrentVolume(event.nativeEvent);

    if (event.nativeEvent instanceof MouseEvent) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.addEventListener("touchmove", handleMouseMove);
      window.addEventListener("touchend", handleMouseUp);
    }
  };

  const handleMouseUp = (event) => {
    event.stopPropagation();

    if (event instanceof MouseEvent) {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("touchmove", handleMouseMove);
      window.removeEventListener("touchend", handleMouseUp);
    }
  };

  const handleClickVolumeButton = (event) => {
    event.stopPropagation();
    const isVolume = Number(currentVolume?.value) > 0;
    const newVolume = isVolume ? 0 : 0.5;

    audioRef.current.audio.current.volume = newVolume;
    setCurrentVolume({ value: newVolume, position: newVolume * 100 });
  };

  return (
    <div ref={containerRef} className="relative flex">
      <button type="button" onClick={() => setIsOpen(!isOpen)}>
        {getIconVolume()}
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            key="volume_bar"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.2, duration: 0.3 } }}
            exit={{ opacity: 0 }}
            transition={{ type: "Inertia" }}
            className="absolute -bottom-2 left-1/2 -translate-x-1/2 flex flex-col gap-[10px] p-2 pt-3 rounded-full bg-[#6b6969]/50 backdrop-blur-xl"
          >
            <div
              ref={volumeRef}
              role="progressbar"
              tabIndex={0}
              className="h-[96px] relative flex justify-center"
              onMouseDown={handleMouseDown}
              onTouchStart={handleMouseDown}
            >
              <div className="relative w-1 h-full rounded-lg bg-[#6b6969] cursor-pointer">
                <div
                  className="w-full absolute bottom-0 z-[2] bg-[--icon_4] rounded-sm transition-all duration-100 ease-linear"
                  style={{ height: `${currentVolume?.position}%` }}
                />
              </div>
            </div>
            <button type="button" onClick={handleClickVolumeButton}>
              {getIconVolume(true)}
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
AudioPlayerVolume.propTypes = {
  audioRef: PropTypes.any,
  currentVolume: PropTypes.object,
  setCurrentVolume: PropTypes.func,
  getIconVolume: PropTypes.func,
};

export default AudioPlayerVolume;
