"use client"
import React, { useEffect, useCallback, useState, useContext } from "react"
import Board from "./Board"
import Keyboard from "./Keyboard"
import Style from "./GameWordle.module.scss"
import PopupAction from "@/components/shared/popup-action/popup-action"
import { Image } from "@/components/shared/image/image"
import ButtonAction from "@/components/shared/button-action/button-action"
import { useIsMobile } from "@/hooks/use-is-mobile"
import clsx from "clsx"
import { ClientApiList } from "@/lib/api/client-service"
import { useDatalayer } from "@/hooks/use-datalayer"
import { Spinner } from "@/components/shared/spinner"
import PropTypes from "prop-types"
import { usePathname, useSearchParams, useRouter } from "next/navigation"
import ButtonBack from "@/components/shared/button-back/button-back"
import { getArticlePathname } from "@/hooks/use-pathname"
import { InfoPopup } from "@/components/info-popup/info-popup"
import { Description } from "@/components/shared/description/description"
import { cva } from "class-variance-authority"
import UseQueryParams from "@/hooks/use-query-params"
import { useWindowSize } from "@/hooks/use-window-size"

const GameWordle = (props) => {
  const {
    board,
    setBoard,
    timer,
    setTimer,
    currAttempt,
    setCurrAttempt,
    correctLetter,
    setCorrectLetter,
    almostLetter,
    setAlmostLetter,
    wrongLetter,
    setWrongLetter,
    correctKey,
    setCorrectKey,
    almostKey,
    setAlmostKey,
    wrongKey,
    setWrongKey,
    stage,
    setStage,
    level,
    status,
    setStatus,
    isActivity,
    dummyWord,
    wordleRow,
    openGame,
    setOpenGame,
    resetGame,
    theme1,
    failTime,
    wordle,
    setPause,
    pause,
    levelUpGame,
    setLevel,
    replayGame,
    bgMobile,
    bgDesktop,
  } = props

  const QUERYPARAM = "point"
  const { handlePushQuery } = UseQueryParams()

  const openClass = openGame ? Style.open : ""
  const [shake, setShake] = useState(false)
  const [flipAnimation, setFlipAnimation] = useState(false)
  const [success, setSuccess] = useState(false)
  const [timeoutGame, setTimeoutGame] = useState(false)
  const [gameOver, setGameOver] = useState(false)
  const [isShowing, setIsShowing] = useState(true)
  const [point, setPoint] = useState(0)
  const [loading, setLoading] = useState(false)
  const [titlePopup, setTitlePopup] = useState("")
  const [subPopup, setSubPopup] = useState("")
  const [imgPopup, setImgPopup] = useState("")
  const [nextUrl, setNextUrl] = useState("")
  const [openPopup, setOpenPopup] = useState(false)
  const [infoHow, setInfoHow] = useState(true)
  const [closeGame, setCloseGame] = useState(false)

  const isMobile = useIsMobile()
  const datalayer = useDatalayer()
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const { replace } = useRouter()

  const { width } = useWindowSize()
  const isDesktop = width >= 1024

  const WordleBox = ({ word, color, size }) => {
    const wordBox = cva(
      "font-bold font-font-family-7 border-[1px] border-solid flex items-center justify-center",
      {
        variants: {
          color: {
            red: [
              "bg-cta-1",
              `${process.env.NEXT_PUBLIC_NAME === "sampoernakretek" ? "text-text-2" : "text-bg-1"}`,
              "border-cta-1",
            ],
            black: ["bg-bg-2", "text-text-4", "border-bg-2"],
            white: ["bg-bg-1", "text-text-1", "border-cta-1"],
          },
          size: {
            small: [
              `${isMobile ? "w-[20px]" : "w-[26px]"}`,
              `${isMobile ? "h-[20px]" : "h-[26px]"}`,
              "rounded-[4px]",
              "shadow-[0px_-1.786px_0_0_rgba(0,0,0,0.15)_inset,_0_1.786px_0_0_rgba(255,255,255,0.20)_inset]",
              `${isMobile ? "text-[9px]" : "text-[11px]"}`,
            ],
            medium: [
              `${isMobile ? "w-[46px]" : "w-[54px]"}`,
              `${isMobile ? "h-[46px]" : "h-[54px]"}`,
              "rounded-[8px]",
              "shadow-[0_4px_5px_rgba(0,0,0,0.2),_inset_0_4px_0_rgba(255,255,255,0.2),_inset_0_-4px_0_rgba(0,0,0,0.25)]",
              `${isMobile ? "text-[20px]" : "text-[23px]"}`,
            ],
          },
        },
        compoundVariants: [{ color: "red", size: "medium" }],
        defaultVariants: {
          color: "red",
          size: "medium",
        },
      }
    )
    const bgWhite =
      process.env.NEXT_PUBLIC_NAME === "magnum" ? "var(--cta_3)" : "#fff"

    const bgBlack =
      process.env.NEXT_PUBLIC_NAME === "magnum"
        ? "#ED1C24"
        : "var(--background_2)"

    const bgRed =
      process.env.NEXT_PUBLIC_NAME === "magnum"
        ? "var(--cta_1)"
        : "var(--icon_1)"

    const borderBlack =
      process.env.NEXT_PUBLIC_NAME === "magnum"
        ? "1px solid #ED1C24"
        : "1px solid var(--background_2)"

    const borderWhite =
      process.env.NEXT_PUBLIC_NAME === "magnum"
        ? "1px solid var(--text_2)"
        : "1px solid var(--text_1)"

    const borderRed =
      process.env.NEXT_PUBLIC_NAME === "magnum"
        ? "1px solid var(--cta_1)"
        : "1px solid var(--icon_1)"

    const switchBG = () => {
      switch (color) {
        case "red":
          return bgRed
        case "black":
          return bgBlack
        case "white":
          return bgWhite
        default:
          return "transparent"
      }
    }

    const switchBorder = () => {
      switch (color) {
        case "red":
          return borderRed
        case "black":
          return borderBlack
        case "white":
          return borderWhite
        default:
          return "transparent"
      }
    }

    return (
      <div
        style={{ background: switchBG(), border: switchBorder() }}
        className={wordBox({ color, size })}
      >
        {word}
      </div>
    )
  }

  const timeOutCallback = useCallback(() => {
    setTimer((prev) => prev - 1)
    // eslint-disable-next-line
  }, [])

  const onSelectLetter = (value) => {
    if (currAttempt.letterPos === wordleRow) return
    const newBoard = [...board]
    newBoard[currAttempt.attempt][currAttempt.letterPos] = value
    setBoard(newBoard)
    setCurrAttempt({ ...currAttempt, letterPos: currAttempt.letterPos + 1 })
  }

  const getNextUrl = async (point) => {
    const { data: nextUrlData } = await ClientApiList.getNextChallenge({
      page_uuid: searchParams.get("page_uuid"),
      mission_order: parseInt(searchParams.get("index")),
    })
    if (
      Object.keys(nextUrlData.data.data).length === 0 &&
      nextUrlData.data.data.constructor === Object
    ) {
      setNextUrl(searchParams.get("campaign"))
    } else {
      const nextMissionUrl = getArticlePathname({ ...nextUrlData.data.data })
      setNextUrl(
        `${nextMissionUrl}?page_uuid=${searchParams.get("page_uuid")}&index=${parseInt(searchParams.get("index")) + 1}&campaign=${searchParams.get("campaign")}${point ? `&${QUERYPARAM}=true` : ""}`
      )
    }
  }

  const onEnter = async () => {
    if (timer > 0) {
      setPause(true)
      if (currAttempt.letterPos !== wordleRow) {
        setShake(true)
        return
      }

      let currWord = ""
      for (let i = 0; i < wordleRow; i++) {
        currWord += board[currAttempt.attempt][i]
      }
      setTimeout(() => setFlipAnimation(true), 100)
      datalayer.push({
        event: "general_event",
        event_name: "click_submit_wordle",
        feature: "simple engagement",
        engagement_type: "games",
        engagement_name: "wordle",
        campaign_name: wordle?.campaign_name.toLowerCase(), // e.g all
        event_label: `wordle - ${wordle.subtitle_introduction.toLowerCase()}`,
        content_id: wordle?.engagement_uuid,
      })
      const { data } = await ClientApiList.getWordleAnswer({
        engagement_uuid: wordle?.engagement_uuid,
        word: currWord,
        level: level,
      })

      if (data) {
        const correct = data?.data?.result.data?.correct
        const almost = data?.data?.result.data?.almost
        const wrong = data?.data?.result.data?.wrong
        const isSuccess = data?.data?.result.data?.is_success
        setCorrectLetter(correct)
        setAlmostLetter(almost)
        setWrongLetter(wrong)
        setCorrectKey((prev) => [...prev, ...correct])
        setAlmostKey((prev) => [...prev, ...almost])
        setWrongKey((prev) => [...prev, ...wrong])
        setCurrAttempt({ attempt: currAttempt.attempt + 1, letterPos: 0 })
        if (isSuccess) {
          setStatus("Congrats")
          setLoading(true)
          const { data: dataStatus } = await ClientApiList.getGameConfirmation({
            engagement_uuid: wordle?.engagement_uuid,
            type: "wordle",
            status: "completed",
            time: timer,
            level: level,
          })
          if (dataStatus) {
            if (level < wordle?.total_level) {
              setTitlePopup(dataStatus.data.data.subtitle_levelup)
              setSubPopup(dataStatus.data.data.desc_levelup)
              setImgPopup(
                isMobile
                  ? dataStatus.data.data.mobile_img_url_levelup
                  : dataStatus.data.data.desktop_img_url_levelup
              )
            } else {
              setTitlePopup(dataStatus.data.data.subtitle_confirmation)
              setSubPopup(dataStatus.data.data.desc_confirmation)
              setImgPopup(
                isMobile
                  ? dataStatus.data.data.mobile_img_url_confirm
                  : dataStatus.data.data.desktop_img_url_confirm
              )
            }

            setTimeout(() => {
              setSuccess(true)
              setPoint(data?.data?.result?.data?.point)
              if (data?.data?.result?.data?.point) {
                // const params = [...searchParams.entries(), ["point", true]]
                // const joinedParams = params
                //   .map((param) => param.join("="))
                //   .join("&")
                // replace(`${pathname}?${joinedParams}`, { scroll: false })
                handlePushQuery(false, [QUERYPARAM, true])
              }
              datalayer.push({
                event: "general_event",
                event_name: "page_reached_finish_on_wordle",
                feature: "simple engagement",
                engagement_type: "games",
                engagement_name: "wordle",
                campaign_name: wordle?.campaign_name.toLowerCase(), // e.g all
                event_label: `wordle - ${wordle?.subtitle_introduction.toLowerCase()}`,
                level: level,
                content_id: wordle?.engagement_uuid,
              })
              if (searchParams.get("page_uuid")) {
                getNextUrl(data?.data?.result?.data?.point)
              }
              setLoading(false)
              setPause(false)
            }, 1500)
            return
          }
        } else setPause(false)
      }

      if (currAttempt.attempt === 5) {
        setStatus("Kesempatan Habis")
        setLoading(true)
        const { data: dataStatus } = await ClientApiList.getGameConfirmation({
          engagement_uuid: wordle?.engagement_uuid,
          type: "wordle",
          status: "ranout",
        })
        if (dataStatus) {
          setTitlePopup(dataStatus.data.data.subtitle_ranout)
          setSubPopup(dataStatus.data.data.desc_ranout)
          setImgPopup(
            isMobile
              ? dataStatus.data.data.mobile_img_url_ranout
              : dataStatus.data.data.desktop_img_url_ranout
          )
          setTimeout(() => {
            setGameOver(true)
            setLoading(false)
          }, 1500)
          return
        }
      }
    }
  }

  const onDelete = () => {
    if (currAttempt.letterPos === 0) return
    const newBoard = [...board]
    newBoard[currAttempt.attempt][currAttempt.letterPos - 1] = ""
    setBoard(newBoard)
    setCurrAttempt({ ...currAttempt, letterPos: currAttempt.letterPos - 1 })
  }

  const CheckTimeOut = async () => {
    setLoading(true)
    const { data: dataStatus } = await ClientApiList.getGameConfirmation({
      engagement_uuid: wordle?.engagement_uuid,
      type: "wordle",
      status: "timeout",
    })
    if (dataStatus) {
      setTitlePopup(dataStatus.data.data.subtitle_timeout)
      setSubPopup(dataStatus.data.data.desc_timeout)
      setImgPopup(
        isMobile
          ? dataStatus.data.data.mobile_img_url_timeout
          : dataStatus.data.data.desktop_img_url_timeout
      )
      setTimeout(() => {
        setTimeoutGame(true)
        setLoading(false)
      }, 1500)
    }
  }

  const CloseGamePopup = async () => {
    setPause(true)
    setCloseGame(true)
    setTitlePopup("Yakin mau keluar? ")
    setSubPopup(
      `<p>Semua <em>progress</em> ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"} di level ini bakal hilang. ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "Kamu" : "Lo"} harus ulang main dari awal lagi buat ngumpulin poin.</p>`
    )
    setImgPopup(
      `/assets/${process.env.NEXT_PUBLIC_NAME}/mission/popup-confirm-exit.png`
    )
  }

  const onClickSuccess = async () => {
    if (level < wordle?.total_level && success) {
      setSuccess(false)
      setPoint(0)
      setLevel(level + 1)
      levelUpGame(level + 1)
    } else {
      setStage(1)
      setIsShowing(false)
      replace("/br")
    }
  }

  const onReplay = () => {
    if (timeoutGame) {
      replayGame()
      setTimeoutGame(false)
    } else if (gameOver) {
      replayGame()
      setGameOver(false)
    }
  }

  useEffect(() => {
    if (!pause && !success) {
      if (timer > 0 && status === "") {
        setTimeout(timeOutCallback, 1000)
      } else if (timer === 0) {
        setStatus("Waktu Habis")
        CheckTimeOut()
      }
    }
    // eslint-disable-next-line
  }, [pause, timer, timeOutCallback, success])

  useEffect(() => {
    window.scrollTo(0, 0)
    setTimeout(() => setOpenGame(true), 100)
    // eslint-disable-next-line
  }, [openGame])

  const headerColor =
    process.env.NEXT_PUBLIC_NAME === "magnum" ? "var(--cta_3)" : "var(--text_1)"

  const headerBGColor =
    process.env.NEXT_PUBLIC_NAME === "magnum"
      ? "var(--cta_3)"
      : "var(--background_5)"

  const levelBgColor =
    process.env.NEXT_PUBLIC_NAME === "magnum"
      ? "var(--cta_1)"
      : "var(--background_3)"

  const iconColor =
    process.env.NEXT_PUBLIC_NAME === "magnum" ? "fill-bg-1" : "fill-icon-1"

  const iconCtaColor =
    process.env.NEXT_PUBLIC_NAME === "magnum" ? "fill-icon-4" : "fill-icon-1"

  // const bgImgDummy = "https://dummyimage.com/1440x700/ff24ff/fff"
  const bgImg = isDesktop ? bgDesktop : bgMobile

  return (
    <>
      {loading && (
        <div className="fixed top-[0] backdrop-blur-[5px] z-[20] bg-black/[.75] bottom-0 right-0 left-0">
          <div className={`relative top-2/4 -translate-y-1/2`}>
            <Spinner />
          </div>
        </div>
      )}
      {!isMobile && (
        <ButtonBack
          onClick={() => {
            setStage(1)
            resetGame()
          }}
          className="absolute top-[-10px] left-[6px]"
        />
      )}
      <div className={clsx("absolute z-1 top-[-20px] h-[100dvh] w-full")}>
        {bgImg && (
          <Image
            data-testid="bg-img"
            alt="cover"
            objectFit="cover"
            fill
            src={bgImg}
          />
        )}
      </div>
      <article
        data-testid="game-wordle"
        className={`${Style.gameWrapper} ${openClass}
        !h-[700px]
        `}
      >
        <div className="max-w-[355px] w-full mx-auto px-[10px]">
          <div
            className="flex justify-center items-center gap-[10px] px-[15px] py-[12px] rounded-[15px] overflow-hidden"
            style={{ background: headerBGColor }}
          >
            <button
              onClick={() => {
                setPause(true)
                setOpenPopup(true)
              }}
            >
              <InfoIcon fillColor={iconCtaColor} />
            </button>
            <div className="flex flex-col items-center justify-center gap-[5px] w-full text-center">
              <div
                className="w-fit rounded-[100px] p-[5px] flex items-center justify-center gap-[15px]"
                style={{
                  background: levelBgColor,
                }}
              >
                <StarIcon fillColor={iconColor} />
                <span
                  className="!bg-clip-text block relative z-[1] text-sm font-bold font-font-family-3"
                  style={{
                    background: headerColor,
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  LEVEL {level}
                </span>
                <StarIcon fillColor={iconColor} />
              </div>
              <div className="flex justify-center items-center gap-[2px]">
                <ClockIcon />
                <span className="text-xs text-text-4">
                  {timer > 0 ? timer : 0} detik
                </span>
              </div>
            </div>
            <button
              onClick={() => {
                CloseGamePopup()
              }}
            >
              <CloseIcon fillColor={iconCtaColor} />
            </button>
          </div>
        </div>
        <div className={"mt-[30px]"}>
          <div className="mb-[25px]">
            <p className="text-base font-black font-font-family-8 text-center text-text-2">
              WORD HINT
            </p>
            <p className="text-sm font-medium font-font-family-6 text-center text-text-2">
              {theme1}
            </p>
          </div>
          <Board
            board={board}
            setBoard={setBoard}
            level={level}
            dummyWord={dummyWord}
            isActivity={isActivity}
            currAttempt={currAttempt}
            correctLetter={correctLetter}
            almostLetter={almostLetter}
            wrongLetter={wrongLetter}
            setCorrectKey={setCorrectKey}
            setAlmostKey={setAlmostKey}
            setWrongKey={setAlmostKey}
            wordleRow={wordleRow}
            failTime={failTime}
            stage={stage}
            shake={shake}
            setShake={setShake}
            flipAnimation={flipAnimation}
            setFlipAnimation={setFlipAnimation}
          />
        </div>
        <div
          className={
            isMobile
              ? "absolute bottom-0 left-2/4 -translate-x-1/2 w-full"
              : "hidden"
          }
        >
          <Keyboard
            onSelectLetter={onSelectLetter}
            onDelete={onDelete}
            onEnter={onEnter}
            currAttempt={currAttempt}
            correctKey={correctKey}
            almostKey={almostKey}
            wrongKey={wrongKey}
          />
        </div>
        {!isMobile && (
          <div className="flex w-full">
            <ButtonAction
              intent="primary"
              className="w-[355px] mx-auto"
              onClick={() => onEnter()}
            >
              Submit
            </ButtonAction>
          </div>
        )}
        {(success || timeoutGame || gameOver || closeGame) && (
          <PopupAction
            isShowing={isShowing}
            onClickBtnX={() => {
              if (closeGame) {
                setPause(false)
                setCloseGame(false)
              } else {
                setIsShowing(false)
                setStage(1)
                resetGame()
              }
            }}
            className={"!p-[10px]"}
            zIndex="z-[20]"
          >
            <div className="w-full h-full p-[15px] rounded-[10px] border-[2px] border-cta-3 border-dashed">
              <div className="h-full relative aspect-[304/177] rounded-[10px]">
                <Image alt="banner" src={imgPopup} />
              </div>
              <div className="my-[20px] max-w-[304px]">
                <p className="text-xl font-black font-font-family-8 mb-[5px] text-text-2 capitalize">
                  {titlePopup}
                </p>
                {point > 0 && (
                  <div className="flex items-center gap-[5px] justify-center mb-[5px]">
                    <div className="min-h-[20px] w-fit min-w-[20px] relative">
                      <Image
                        alt="cover"
                        fill
                        style={{
                          objectFit: "cover",
                          objectPosition: "center",
                          position: "absolute",
                          inset: 0,
                        }}
                        src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/reward-profile.svg`}
                      />
                    </div>
                    <div className="text-[20px] text-text-1 leading-[27px] font-black font-font-family-8">
                      {point} Poin!
                    </div>
                  </div>
                )}
                <Description
                  className={clsx(
                    " text-center font-medium font-font-family-6 text-text-2",
                    isMobile ? "text-sm" : "text-base"
                  )}
                  description={subPopup}
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                {nextUrl &&
                  level === wordle?.total_level &&
                  !(timeoutGame || gameOver) && !closeGame && (
                    <ButtonAction
                      className="py-[15px] px-[20px] leading-[20px] w-full"
                      intent="primary"
                      onClick={() => {
                        setIsShowing(false)
                        replace(nextUrl)
                      }}
                    >
                      Eksplor Tantangan Selanjutnya
                    </ButtonAction>
                  )}
                {closeGame && (
                  <div className="flex gap-[10px]">
                    <ButtonAction
                      className={clsx(
                        "py-[15px] px-[20px] leading-[20px] w-full"
                      )}
                      intent="secondary"
                      onClick={() => {
                        replace("/br")
                      }}
                    >
                      Keluar
                    </ButtonAction>
                    <ButtonAction
                      className={clsx(
                        "py-[15px] px-[20px] leading-[20px] w-full"
                      )}
                      intent="primary"
                      onClick={() => {
                        setPause(false)
                        setCloseGame(false)
                      }}
                    >
                      Balik Main
                    </ButtonAction>
                  </div>
                )}
                {success && (
                  <ButtonAction
                    className={clsx(
                      "py-[15px] px-[20px] leading-[20px] w-full"
                    )}
                    intent={
                      level < wordle.total_level && success
                        ? "primary"
                        : "secondary"
                    }
                    onClick={() => {
                      onClickSuccess()
                    }}
                  >
                    {level < wordle.total_level && success
                      ? `Lanjut Level ${level + 1}`
                      : "Cek keseruan lainnya"}
                  </ButtonAction>
                )}
                {(timeoutGame || gameOver) && (
                  <ButtonAction
                    className={clsx(
                      "py-[15px] px-[20px] leading-[20px] w-full"
                    )}
                    intent="primary"
                    onClick={() => onReplay()}
                  >
                    Coba Lagi
                  </ButtonAction>
                )}
              </div>
            </div>
          </PopupAction>
        )}
      </article>
      <InfoPopup
        isOpen={openPopup && !timeoutGame & !gameOver}
        onClose={() => {
          setOpenPopup(false)
          setPause(false)
        }}
      >
        <p
          className={clsx(
            "w-full font-font-family-3 font-bold text-2xl text-text-2 px-[20px] border-b border-cta-4",
            isMobile ? "py-[10px] mt-[10px]" : "py-[15px]"
          )}
        >
          INFORMASI
        </p>
        <div className="w-full flex gap-[20px] font-font-family-5 text-base text-text-3 px-[20px] border-b border-cta-4">
          <button
            className={clsx(
              "border-b-[3px] py-[15px]",
              infoHow ? "text-text-1 border-text-1" : "border-transparent"
            )}
            onClick={() => {
              setInfoHow(true)
            }}
          >
            How To Play
          </button>
          <button
            className={clsx(
              "border-b-[3px] py-[15px]",
              infoHow ? "border-transparent" : "text-text-1 border-text-1"
            )}
            onClick={() => {
              setInfoHow(false)
            }}
          >
            Game&apos;s Rules
          </button>
        </div>
        <div
          className={clsx(
            "w-full font-font-family-5 text-sm text-text-2 px-[20px]",
            isMobile ? "pb-[30px] pt-[20px]" : "py-[30px]"
          )}
        >
          {infoHow ? (
            <>
              <p
                className={clsx(
                  "text-text-2 font-font-family-7 font-bold",
                  isMobile ? "text-base" : "text-[20px]"
                )}
              >
                How To Play
              </p>
              <Description
                className={clsx(
                  "text-text-1 font-medium font-font-family-6",
                  isMobile ? "text-sm my-[10px]" : "text-base my-[15px]"
                )}
                description={wordle.subtitle_instruction}
              />
              <Description
                className={
                  ("text-text-2",
                  isMobile
                    ? "text-sm leading-[24px] pl-[10px]"
                    : "text-base leading-[25px] pl-[10px] ")
                }
                description={wordle.desc_instruction}
              />
            </>
          ) : (
            <>
              <p
                className={clsx(
                  "text-text-2 font-font-family-7 font-bold",
                  isMobile ? "text-base" : "text-[20px]"
                )}
              >
                Game&apos;s Rules
              </p>
              <div
                className={clsx(
                  "flex gap-[5px] items-center justify-center bg-bg-1 rounded-[10px]",
                  isMobile
                    ? "py-[30px] my-[10px]"
                    : "py-[46px] mt-[20px] mb-[10px] "
                )}
              >
                <WordleBox word="R" color="red" />
                <WordleBox word="I" color="black" />
                <WordleBox word="G" color="white" />
                <WordleBox word="H" color="red" />
                <WordleBox word="T" color="black" />
              </div>
              <div
                className={clsx(
                  "flex items-center",
                  isMobile ? "gap-[8px]" : "gap-[10px]"
                )}
              >
                <div
                  className={clsx(
                    "flex gap-[2px] items-center justify-center",
                    isMobile ? "min-w-[45px]" : "min-w-[60px]"
                  )}
                >
                  <WordleBox word="R" color="red" size="small" />
                  <WordleBox word="H" color="red" size="small" />
                </div>
                <p
                  className={clsx(
                    "text-text-2",
                    isMobile
                      ? "text-sm leading-[24px]"
                      : "text-base leading-[25px]"
                  )}
                >
                  {wordle.wordle_rules_1}
                </p>
              </div>
              <div
                className={clsx(
                  "flex items-center ",
                  isMobile ? "gap-[8px]" : "gap-[10px] my-[32px]"
                )}
              >
                <div
                  className={clsx(
                    "flex gap-[2px] items-center justify-center",
                    isMobile ? "min-w-[45px]" : "min-w-[60px]"
                  )}
                >
                  <WordleBox word="G" color="white" size="small" />
                </div>
                <p
                  className={clsx(
                    "text-text-2",
                    isMobile
                      ? "text-sm leading-[24px]"
                      : "text-base leading-[25px]"
                  )}
                >
                  {wordle.wordle_rules_2}
                </p>
              </div>
              <div
                className={clsx(
                  "flex items-center ",
                  isMobile ? "gap-[8px]" : "gap-[10px]"
                )}
              >
                <div
                  className={clsx(
                    "flex gap-[2px] items-center justify-center",
                    isMobile ? "min-w-[45px]" : "min-w-[60px]"
                  )}
                >
                  <WordleBox word="I" color="black" size="small" />
                  <WordleBox word="T" color="black" size="small" />
                </div>
                <p
                  className={clsx(
                    "text-text-2",
                    isMobile
                      ? "text-sm leading-[24px]"
                      : "text-base leading-[25px]"
                  )}
                >
                  {wordle.wordle_rules_3}
                </p>
              </div>
            </>
          )}
        </div>
      </InfoPopup>
    </>
  )
}

export default GameWordle

const InfoIcon = ({ fillColor }) => {
  if (process.env.NEXT_PUBLIC_NAME === "djisamsoe")
    return (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="13.0391"
          y="11.04"
          width="14.08"
          height="19.2003"
          fill="url(#paint0_linear_8043_44817)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20 35.8337C24.1993 35.8337 28.2266 34.1655 31.1959 31.1962C34.1652 28.2269 35.8334 24.1996 35.8334 20.0003C35.8334 15.8011 34.1652 11.7738 31.1959 8.80447C28.2266 5.83514 24.1993 4.16699 20 4.16699C15.8008 4.16699 11.7735 5.83514 8.80416 8.80447C5.83484 11.7738 4.16669 15.8011 4.16669 20.0003C4.16669 24.1996 5.83484 28.2269 8.80416 31.1962C11.7735 34.1655 15.8008 35.8337 20 35.8337ZM21.9792 14.0628C21.9792 14.5877 21.7707 15.0911 21.3995 15.4623C21.0283 15.8335 20.5249 16.042 20 16.042C19.4751 16.042 18.9717 15.8335 18.6005 15.4623C18.2294 15.0911 18.0209 14.5877 18.0209 14.0628C18.0209 13.5379 18.2294 13.0345 18.6005 12.6633C18.9717 12.2922 19.4751 12.0837 20 12.0837C20.5249 12.0837 21.0283 12.2922 21.3995 12.6633C21.7707 13.0345 21.9792 13.5379 21.9792 14.0628ZM18.0209 18.0212C17.6272 18.0212 17.2496 18.1775 16.9712 18.4559C16.6929 18.7343 16.5365 19.1119 16.5365 19.5055C16.5365 19.8992 16.6929 20.2768 16.9712 20.5551C17.2496 20.8335 17.6272 20.9899 18.0209 20.9899H18.5156V24.9482H16.5365C16.1428 24.9482 15.7652 25.1046 15.4869 25.383C15.2085 25.6614 15.0521 26.0389 15.0521 26.4326C15.0521 26.8263 15.2085 27.2039 15.4869 27.4822C15.7652 27.7606 16.1428 27.917 16.5365 27.917H23.4636C23.8572 27.917 24.2348 27.7606 24.5132 27.4822C24.7915 27.2039 24.9479 26.8263 24.9479 26.4326C24.9479 26.0389 24.7915 25.6614 24.5132 25.383C24.2348 25.1046 23.8572 24.9482 23.4636 24.9482H21.4844V18.0212H18.0209Z"
          fill="url(#paint1_linear_8043_44817)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_8043_44817"
            x1="27.1191"
            y1="30.2404"
            x2="10.5112"
            y2="27.7241"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#654D2C" />
            <stop offset="0.500935" stop-color="#DFD59A" />
            <stop offset="1" stop-color="#654D2C" />
            <stop offset="1" stop-color="#654D2C" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_8043_44817"
            x1="20"
            y1="4.16699"
            x2="20"
            y2="35.8337"
            gradientUnits="userSpaceOnUse"
          >
            <stop />
            <stop offset="1" stop-color="#221F20" />
          </linearGradient>
        </defs>
      </svg>
    )
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <rect
        x="13.0391"
        y="11.0403"
        width="14.08"
        height="19.2003"
        className={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 36C24.2435 36 28.3131 34.3143 31.3137 31.3137C34.3143 28.3131 36 24.2435 36 20C36 15.7565 34.3143 11.6869 31.3137 8.68629C28.3131 5.68571 24.2435 4 20 4C15.7565 4 11.6869 5.68571 8.68629 8.68629C5.68571 11.6869 4 15.7565 4 20C4 24.2435 5.68571 28.3131 8.68629 31.3137C11.6869 34.3143 15.7565 36 20 36ZM22 14C22 14.5304 21.7893 15.0391 21.4142 15.4142C21.0391 15.7893 20.5304 16 20 16C19.4696 16 18.9609 15.7893 18.5858 15.4142C18.2107 15.0391 18 14.5304 18 14C18 13.4696 18.2107 12.9609 18.5858 12.5858C18.9609 12.2107 19.4696 12 20 12C20.5304 12 21.0391 12.2107 21.4142 12.5858C21.7893 12.9609 22 13.4696 22 14ZM18 18C17.6022 18 17.2206 18.158 16.9393 18.4393C16.658 18.7206 16.5 19.1022 16.5 19.5C16.5 19.8978 16.658 20.2794 16.9393 20.5607C17.2206 20.842 17.6022 21 18 21H18.5V25H16.5C16.1022 25 15.7206 25.158 15.4393 25.4393C15.158 25.7206 15 26.1022 15 26.5C15 26.8978 15.158 27.2794 15.4393 27.5607C15.7206 27.842 16.1022 28 16.5 28H23.5C23.8978 28 24.2794 27.842 24.5607 27.5607C24.842 27.2794 25 26.8978 25 26.5C25 26.1022 24.842 25.7206 24.5607 25.4393C24.2794 25.158 23.8978 25 23.5 25H21.5V18H18Z"
        className="fill-cta-2"
      />
    </svg>
  )
}

const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M6 2.5C3.7945 2.5 2 4.2945 2 6.5C2 8.7055 3.7945 10.5 6 10.5C8.2055 10.5 10 8.7055 10 6.5C10 4.2945 8.2055 2.5 6 2.5ZM6.5 6.5H5.5V4H6.5V6.5ZM4.5 1H7.5V2H4.5V1ZM9.3535 2.1465L10.3535 3.1465L9.6465 3.8535L8.6465 2.8535L9.3535 2.1465Z"
        className="fill-icon-4"
      />
    </svg>
  )
}

const CloseIcon = ({ fillColor }) => {
  if (process.env.NEXT_PUBLIC_NAME === "djisamsoe")
    return (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 20C4 11.1634 11.1634 4 20 4C28.8366 4 36 11.1634 36 20C36 28.8366 28.8366 36 20 36C11.1634 36 4 28.8366 4 20Z"
          fill="url(#paint0_linear_8043_44821)"
        />
        <path
          d="M13.6563 13.6871C13.8642 13.4799 14.146 13.3635 14.4399 13.3635C14.7337 13.3635 15.0155 13.4799 15.2234 13.6871L19.9839 18.4375L24.7444 13.6871C24.8799 13.5414 25.0523 13.435 25.2434 13.3789C25.4345 13.3228 25.6372 13.3192 25.8301 13.3684C26.0231 13.4177 26.1992 13.5179 26.3399 13.6586C26.4805 13.7994 26.5805 13.9753 26.6294 14.168C26.6788 14.3604 26.6753 14.5625 26.6194 14.753C26.5635 14.9436 26.4571 15.1156 26.3115 15.2509L21.551 20.0014L26.3115 24.7518C26.4575 24.8871 26.5642 25.0591 26.6204 25.2498C26.6766 25.4404 26.6802 25.6427 26.6309 25.8353C26.5815 26.0278 26.481 26.2036 26.34 26.3439C26.199 26.4843 26.0226 26.5841 25.8296 26.6328C25.6368 26.6821 25.4343 26.6787 25.2433 26.6229C25.0524 26.5671 24.88 26.4609 24.7444 26.3156L19.9839 21.5652L15.2234 26.3156C15.0131 26.511 14.7351 26.6174 14.4478 26.6124C14.1605 26.6074 13.8864 26.4916 13.6829 26.2891C13.48 26.0861 13.3639 25.8125 13.3589 25.5258C13.354 25.2391 13.4606 24.9617 13.6563 24.7518L18.4168 20.0014L13.6563 15.2509C13.4487 15.0434 13.332 14.7622 13.332 14.469C13.332 14.1758 13.4487 13.8946 13.6563 13.6871Z"
          fill="url(#paint1_linear_8043_44821)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_8043_44821"
            x1="20"
            y1="4"
            x2="20"
            y2="36"
            gradientUnits="userSpaceOnUse"
          >
            <stop />
            <stop offset="1" stop-color="#221F20" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_8043_44821"
            x1="26.6654"
            y1="26.6674"
            x2="11.2359"
            y2="23.4795"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#654D2C" />
            <stop offset="0.500935" stop-color="#DFD59A" />
            <stop offset="1" stop-color="#654D2C" />
            <stop offset="1" stop-color="#654D2C" />
          </linearGradient>
        </defs>
      </svg>
    )
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M4 20C4 11.1634 11.1634 4 20 4C28.8366 4 36 11.1634 36 20C36 28.8366 28.8366 36 20 36C11.1634 36 4 28.8366 4 20Z"
        className="fill-cta-2"
      />
      <path
        d="M13.6563 13.6866C13.8642 13.4794 14.146 13.363 14.4399 13.363C14.7337 13.363 15.0155 13.4794 15.2234 13.6866L19.9839 18.4371L24.7444 13.6866C24.8799 13.541 25.0523 13.4345 25.2434 13.3784C25.4345 13.3223 25.6372 13.3187 25.8301 13.3679C26.0231 13.4172 26.1992 13.5174 26.3399 13.6582C26.4805 13.7989 26.5805 13.9749 26.6294 14.1675C26.6788 14.3599 26.6753 14.562 26.6194 14.7525C26.5635 14.9431 26.4571 15.1151 26.3115 15.2504L21.551 20.0009L26.3115 24.7513C26.4575 24.8866 26.5642 25.0586 26.6204 25.2493C26.6766 25.44 26.6802 25.6422 26.6309 25.8348C26.5815 26.0274 26.481 26.2031 26.34 26.3434C26.199 26.4838 26.0226 26.5836 25.8296 26.6323C25.6368 26.6816 25.4343 26.6782 25.2433 26.6224C25.0524 26.5666 24.88 26.4604 24.7444 26.3151L19.9839 21.5647L15.2234 26.3151C15.0131 26.5105 14.7351 26.6169 14.4478 26.6119C14.1605 26.607 13.8864 26.4911 13.6829 26.2886C13.48 26.0856 13.3639 25.812 13.3589 25.5253C13.354 25.2386 13.4606 24.9612 13.6563 24.7513L18.4168 20.0009L13.6563 15.2504C13.4487 15.043 13.332 14.7617 13.332 14.4685C13.332 14.1753 13.4487 13.8941 13.6563 13.6866Z"
        className={fillColor}
      />
    </svg>
  )
}

const StarIcon = ({ fillColor }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={fillColor}
        d="M6 0L6.33992 2.06367C6.64394 3.90941 8.09059 5.35606 9.93633 5.66008L12 6L9.93633 6.33992C8.09059 6.64394 6.64394 8.09059 6.33992 9.93633L6 12L5.66008 9.93633C5.35606 8.09059 3.90941 6.64394 2.06367 6.33992L0 6L2.06367 5.66008C3.90941 5.35606 5.35606 3.90941 5.66008 2.06367L6 0Z"
      />
    </svg>
  )
}

StarIcon.propTypes = {
  fillColor: PropTypes.string,
}

GameWordle.propTypes = {
  board: PropTypes.array,
  setBoard: PropTypes.func,
  timer: PropTypes.number,
  setTimer: PropTypes.func,
  currAttempt: PropTypes.object,
  setCurrAttempt: PropTypes.func,
  correctLetter: PropTypes.array,
  setCorrectLetter: PropTypes.func,
  almostLetter: PropTypes.array,
  setAlmostLetter: PropTypes.func,
  setWrongLetter: PropTypes.func,
  correctKey: PropTypes.array,
  setCorrectKey: PropTypes.func,
  almostKey: PropTypes.array,
  setAlmostKey: PropTypes.func,
  wrongKey: PropTypes.array,
  setWrongKey: PropTypes.func,
  stage: PropTypes.number,
  setStage: PropTypes.func,
  level: PropTypes.number,
  status: PropTypes.string,
  setStatus: PropTypes.func,
  isActivity: PropTypes.bool,
  dummyWord: PropTypes.string,
  wordleRow: PropTypes.number,
  openGame: PropTypes.bool,
  setOpenGame: PropTypes.func,
  resetGame: PropTypes.func,
  theme1: PropTypes.string,
  theme2: PropTypes.string,
  failTime: PropTypes.func,
  wordle: PropTypes.object,
  setPause: PropTypes.func,
}
