"use client"
import PropTypes from "prop-types"
import SubmissionFile from "../submission/submission-file"

export function SubmissionEngagement({ href, data }) {
  return (
    <div className="w-full h-full relative pt-[20px]">
      <SubmissionFile href={href} data={data} />
    </div>
  )
}

SubmissionEngagement.propTypes = {
  href: PropTypes.string,
  data: PropTypes.object,
}
