"use client"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import HistoryCardProgress from "../mgm/history-progress-card"
import { ClientApiList } from "@/lib/api/client-service"
import { Image } from "../shared/image/image"
import { useIsMobile } from "@/hooks/use-is-mobile"

export function MGMHistoryProgress({
  title,
  description,
  sub_title,
  items,
  bg_img_url,
}) {
  const [dataProgress, setDataProgress] = useState()
  const isMobile = useIsMobile()

  const handleGetProgress = async () => {
    try {
      const { data } = await ClientApiList.getMGMProgress()
      setDataProgress({ ...data.data.data.progress })
    } catch (err) {
      console.log("[Error]", err)
    }
  }

  useEffect(() => {
    handleGetProgress()
  }, [])

  return (
    <div className="rounded-[10px] lg:border-[1px] border-bg-6 bg-bg-3 p-[15px] lg:p-[14px] lg:p-[19px] mb-[15px] lg:m-0 lg:col-start-2 lg:row-span-2 lg:row-start-2 max-w-[609px] w-[calc(100%-20px)] lg:w-full mx-auto lg:max-w-none overflow-hidden text-text-4 relative">
      <Image
        className="absolute inset-0 z-[0]"
        src={`/assets/${process.env.NEXT_PUBLIC_NAME}/mgm/bg-riwayat-${isMobile ? "mobile" : "desktop"}.png`}
        alt="bg"
      />

      <div className="relative z-[1] text-[20px] leading-[27px] lg:text-[24px] lg:leading-[32px] font-bold font-font-family-7 mb-[10px]">
        {title}
      </div>
      <div className="relative z-[1] text-[14px] lg:text-[16px] leading-[24px] lg:leading-[25px] mb-[20px] lg:mb-[30px]">
        {description}
      </div>
      <div className="relative z-[1] text-[16px] leading-[22px] lg:text-[20px] lg:leading-[27px] font-bold font-font-family-7 mb-[10px] lg:mb-[15px]">
        {sub_title}
      </div>
      <div className="relative z-[1] flex justify-between items-center gap-[10px] lg:gap-[15px] lg:grid lg:grid-cols-[1fr]">
        <HistoryCardProgress
          title="Total Poin Terkumpul"
          icon
          label={`${dataProgress?.total_point || 0} Poin`}
          duration="1 Bulan Terakhir"
          bg="bg-dart.png"
        />
        <HistoryCardProgress
          title="Progress MGM"
          // current={5}
          total={dataProgress?.total_count || 0}
          label="Teman Ikutan"
          duration="1 Bulan Terakhir"
          bg="bg-chart.png"
        />
      </div>
    </div>
  )
}

MGMHistoryProgress.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  sub_title: PropTypes.string,
  items: PropTypes.array,
  bg_img_url: PropTypes.string,
}
