import React from 'react';
import Link from "@/components/shared/link/link";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

export const ShowQrButtonFallBack = () => {
    return (
        <button className="min-w-[120px] leading-[16px] min-w-[110px] py-[4px] lg:py-[5px] px-[10px] rounded-[5px] text-white bg-black text-[12px] font-medium font-font-family-6 lg:absolute lg:bottom-[8px] lg:left-[50%] lg:translate-x-[-50%]">
            Show This QR
        </button>
    );
};


const ShowQrButton = ({cta_label='Show This QR'}) => {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    const hrefParse = () => {
        const current = new URLSearchParams(Array.from(searchParams.entries())); // -> has to use this form
        // console.log(current);
        return {
            pathname,
            query: { ...Object.fromEntries(current.entries()), showQr: true },
        };
    };
    return (
        <Link href={hrefParse()} replace>
            <button
            style={{background:'var(--cta_1)'}}
            className="min-w-[120px] leading-[16px] lg:min-w-[108px] py-[4px] lg:py-[5px] px-[10px] rounded-[5px] text-[12px] font-medium font-font-family-6 lg:absolute lg:bottom-[8px] lg:left-[50%] lg:translate-x-[-50%]">
                <span className="!bg-clip-text" style={{ background:'var(--cta_2)', WebkitTextFillColor: 'transparent' ,}}>
                {cta_label}
                </span>
                {/* Show This QR */}
            </button>
        </Link>
    );
};

export default ShowQrButton;
