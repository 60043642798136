"use client"
import React, { useEffect } from "react"
// import BackBanner from "../shared/back-banner"
import ButtonAction from "../shared/button-action/button-action"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { Image } from "../shared/image/image"
import { usePathname, useRouter } from "next/navigation"
// import { useLogout } from "@/hooks/auth/use-logout"
import { useDatalayer } from "@/hooks/use-datalayer"
import { ClientStorage } from "ClientStorage";
import { ClientApiList } from "@/lib/api/client-service";

export function DeleteAccount() {
  const isMobile = useIsMobile()
  const pathname = usePathname()
  const { replace } = useRouter()
	const localStorage = new ClientStorage('localStorage');
  // const { execute: logout } = useLogout()
  const datalayer = useDatalayer()

  const checkIsLogin = async() => {
    await ClientApiList.getStatusLogin();
    // localStorage.empty()
  }

  useEffect(() => {
    localStorage.empty()
    return () => {
      // logout()
      checkIsLogin()
      datalayer.push({
        event: "general_event",
        event_name: "click_logout_button",
        feature: "logout",
        button_name: "Kembali Ke Homepage", // e.g., logout
        page_path: pathname,
        campaign_name: "all",
      })
    }
  }, [])

  const user = process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"
  return (
    <>
      {/* <BackBanner
        className="h-[100%] top-[58px] lg:top-[72px]"
        bgImg={`/assets/amild/profile-edit/${isMobile ? "bg-mobile.jpg" : "bg-desktop.jpg"}`}
      /> */}
      <Image
        className="absolute h-[100%] w-[100%] z-[3]"
        alt="delete"
        src={`/assets/${process.env.NEXT_PUBLIC_NAME}/profile-edit/${isMobile ? "bg-mobile.png" : "bg-desktop.png"}`}
      />
      <div className="relative text-text-2">
        {/* <BackBanner /> */}
        <div className="relative z-[3] px-[25px] pt-[20px] md:px-0 md:pt-[30px] pb-[80px] mx-[25px] max-w-[460px] mx-auto md:min-h-[342.5px] text-center">
          <div className="min-w-[325px] min-h-[242px] bg-transparent rounded-[10px] mb-[20px]">
            <Image
              className=""
              alt="delete"
              src={`/assets/${process.env.NEXT_PUBLIC_NAME}/profile-edit/del-${isMobile ? "mobile" : "desktop"}.png`}
            />
          </div>
          <div className="text-[20px] leading-[27px] font-bold font-font-family-7 mb-[10px]">
            Akun {user} Berhasil Di Hapus
          </div>
          <div className="max-w-[325px] lg:max-w-[460px] mx-auto text-[14px] md:text-[16px] leading-[24px] md:leading-[25px] mb-[20px]">
            Wah, akun {user} sudah resmi dihapus nih! Terima kasih atas
            perjalanan seru yang udah {user} lewati bersama kita. Semoga {user}{" "}
            terus menemukan hal-hal menarik dan inspiratif di perjalanan {user}{" "}
            berikutnya. Sampai jumpa kembali di lain waktu!
          </div>
          <ButtonAction
            onClick={() => {
              replace("/")
            }}
            intent="primary"
          >
            Kembali Ke Homepage
          </ButtonAction>
        </div>
      </div>
    </>
  )
}
