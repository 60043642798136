"use client"

import Link from "@/components/shared/link/link"
import styles from "./syaratKetentuan.module.scss"
import ButtonAction from "@/components/shared/button-action/button-action"
import clsx from "clsx"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { usePathname } from "next/navigation"

export default function CampaignSnk() {
  const isMobile = useIsMobile()
  const pathname = usePathname()
  const back = pathname.split("/")[1]

  return (
    <section
      className={clsx(
        styles.container,
        "w-full max-w-[460px] mx-auto relative pt-[30px] text-text-2 text-xs",
        isMobile && "px-3.5"
      )}
    >
      {/* <div className={styles.snk_bg}>
        <Image
          alt="bg"
          objectPosition="top left"
          src={isMobile ? snkBgMob : snkBgDesk}
          layout="fill"
          objectFit="contain"
        />
      </div> */}
      {/* <div className={styles.snk_back}>
        <Link href={prevPath || back} passHref>
          <ButtonBack />
        </Link>
      </div> */}
      <div className={styles.snk}>
        <h2
          className={clsx(
            styles.snk_title,
            "font-bold text-2xl text-text-2 capitalize font-font-family-3"
          )}
        >
          Program GAC 2024: Denmark & Australia
        </h2>
        <p
          className={clsx(
            styles.snk_title,
            "font-bold text-2xl text-text-2 capitalize font-font-family-3"
          )}
        >
          Syarat & Ketentuan
        </p>
        <p
          className={clsx(
            styles.snk_sub,
            "font-medium font-font-family-6 mt-[10px] text-text-2 text-sm"
          )}
        >
          <strong>PENTING:</strong> Harap baca syarat dan ketentuan ini (“Syarat
          & Ketentuan”) sebelum mengikuti program ini. Dengan mengikuti Program
          ini, anda mengkonfirmasi bahwa anda telah membaca, memahami dan setuju
          untuk mematuhi Syarat dan Ketentuan termasuk perubahannya yang dibuat
          oleh pihak Penyelenggara dari waktu ke waktu.
        </p>
        <p
          className={clsx(
            styles.snk_sub,
            "font-medium font-font-family-6 mt-[10px] text-text-2 text-sm"
          )}
        >
          GAC 2024: Denmark & Aussie adalah program konsumen yang ditujukan
          kepada perokok dewasa milik PT HM Sampoerna Tbk. (“Sampoerna”) yang
          merupakan produsen, penjual, pemasar serta afiliasi dari produsen yang
          bergerak di bidang bisnis tembakau dan produk yang mengandung nikotin
          lainnya, yang artinya juga meliputi riset dan pengembangan dan/atau
          distributor produk rokok, berbagai macam hasil tembakau lainnya yang
          dibakar maupun yang tidak dibakar, atau rokok elektronik/e-vapors
          (alat yang memproduksi aerosol dengan menguapkan larutan nikotin
          berperisa dan menggunakan energi baterai) atau produk mengandung
          nikotin lainnya yang dipasarkan dengan merek-merek dagang tertentu,
          termasuk merek A Mild (“Program”). Sampoerna menunjuk pihak ketiga
          dalam hal penyelenggaraan Program (“Penyelenggara”).
        </p>
        <p
          className={clsx(
            styles.snk_sub,
            "font-medium font-font-family-6 mt-[10px] text-text-2 text-sm"
          )}
        >
          Perokok dewasa yang berpartisipasi dalam Program ini (“Peserta”),
          wajib membaca, memahami, dan mematuhi syarat-syarat dan
          ketentuan-ketentuan yang diuraikan di bawah ini (“Syarat dan
          Ketentuan”).
        </p>
        <div className={styles.snk_desc}>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              A. Persyaratan Peserta mengikuti Program:
            </span>
            <p
              className={clsx(
                styles.snk_sub,
                "font-medium font-font-family-6 mt-[10px] text-text-2 text-sm"
              )}
            >
              Program ini berlaku di wilayah Republik Indonesia dan terbuka bagi
              setiap orang Peserta wajib memenuhi persyaratan Program sebagai
              berikut:
            </p>
            <ul className={styles.snk_tab}>
              <li>
                Peserta merupakan warga negara Indonesia dan bertempat tinggal
                di wilayah Indonesia.
              </li>
              <li>
                Peserta adalah perokok dewasa berusia minimal 21 tahun, dan
                memiliki identitas berupa KTP yang masih berlaku.
              </li>
              <li>
                Terdaftar dan memiliki akun pada <em>website</em> www.amild.id.
              </li>
              <li>Peserta sudah melengkapi profil akun di www.amild.id.</li>
              <li>Sehat jasmani dan rohani.</li>
              <li>
                Bersedia memenuhi seluruh ketentuan dalam Syarat & Ketentuan
                Program serta{" "}
                <a
                  href="https://www.pmiprivacy.com/id/consumer"
                  target="_blank"
                  className={styles.snk_red}
                >
                  Kebijakan Privasi
                </a>{" "}
                yang berlaku.
              </li>
              <li>
                Program ini berlaku di seluruh Indonesia sesuai dengan periode
                tertera.
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              B. Periode Program:
            </span>
            <ul className={styles.snk_tab}>
              <li>
                Program ini dimulai sejak 1 Februari dan berakhir pada 31
                Agustus 2024 (“Periode Program”).
              </li>
              <li>
                Setiap Entri yang tidak diterima selama Periode Program akan
                secara otomatis didiskualifikasi dari partisipasi. Penyelenggara
                tidak bertanggung jawab atas entri yang hilang, tertunda, atau
                salah arah.
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              C. Mekanisme Program:
            </span>
            <ul className={styles.snk_tab}>
              <li>
                Peserta wajib terdaftar dan memiliki akun pada <em>website</em>{" "}
                www.amild.id.
              </li>
              <li>
                Pendaftaran dapat dilakukan secara mandiri melalui{" "}
                <em>website</em> resmi amild.id atau melakukan di lokasi acara
                atau venue yang bekerjasama dengan A Mild.
              </li>
              <li>
                Peserta wajib mengikuti seluruh rangkaian aktivitas{" "}
                <strong>Program GAC 2024: Denmark & Australia</strong> yang
                diselenggarakan melalui <em>website</em> amild.id.
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <p
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              D. Hadiah Program:
            </p>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              Hadiah Utama:
            </span>
            <ul className={styles.snk_tab}>
              <li>
                6 (Enam) pemenang dan 6 (Enam) pendamping yang sudah terdaftar
                dan mengikuti program GAC Iconic Trip di amild.id masing-masing
                akan mendapatkan kesempatan untuk mengikuti rangkaian GAC Iconic
                Trip: Denmark atau Australia selama periode yang ditentukan oleh
                penyelenggara di bulan Juli atau Oktober 2024 sesuai dengan
                hasil dari <em>leaderboard</em> aktivitas amild.id yang diikuti
                selama periode program (pemenang wajib memenuhi syarat dan
                ketentuan yang berlaku).
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              Hadiah Hiburan:
            </span>
            <ul className={styles.snk_tab}>
              <li>
                100 (Seratus) pemenang yang sudah terdaftar dan mengikuti
                program GAC Iconic Trip: Denmark atau Australia di amild.id
                mendapatkan kesempatan untuk datang ke Festival Musik di Jakarta
                (sudah termasuk transportasi dan akomodasi).
              </li>
              <li>
                Dan seluruh peserta yang sudah terdaftar dan melakukan aktivitas
                di amild.id dapat melakukan penukaran poin untuk mendapatkan{" "}
                <em>exclusive merchandise</em> di <em>catalogue rewards</em>.
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              Ketentuan Pemenang:
            </span>
            <ul className={styles.snk_tab}>
              <li>
                Penentuan pemenang dilakukan melalui proses seleksi berdasarkan
                ketentuan yang telah tertera dalam Syarat & Ketentuan terlampir.
              </li>
              <li>
                Kandidat peserta terpilih akan diwajibkan mengikuti proses
                kurasi dan <em>interview</em> oleh Penyelenggara, sebagai proses
                penentuan peserta terpilih atau pemenang.
              </li>
              <li>Pengumuman hadiah utama dan hiburan:</li>
            </ul>
            <ul className={styles.snk_tab2}>
              <li>Hadiah Utama:</li>
            </ul>
            <ol type="1" className={styles.snk_tab3}>
              <li>
                3 (Tiga) pemenang akan diumumkan setelah tahap pertama program
                berakhir/selesai pada bulan Juni 2024.
              </li>
              <li>
                3 (Tiga) pemenang akan diumumkan setelah tahap kedua program
                berakhir/selesai pada bulan September 2024.
              </li>
            </ol>
            <ul className={styles.snk_tab2}>
              <li>Hadiah Hiburan:</li>
            </ul>
            <ol type="1" className={styles.snk_tab3}>
              <li>
                100 (Seratus) pemenang hadiah hiburan akan diumumkan setelah
                tahap kedua program berakhir/selesai pada bulan September 2024.
              </li>
            </ol>
            <ul className={styles.snk_tab}>
              <li>
                Pendamping dari pemenang adalah perokok dewasa berusia minimal
                21 tahun, dan memiliki identitas berupa KTP yang masih berlaku
              </li>
              <li>
                Pemenang akan diumumkan melalui <em>website</em> dan e-mail
                konfirmasi resmi amild.id dan akan dihubungi oleh Penyelenggara.
              </li>
              <li>
                Hadiah tidak dapat dipindahtangankan, tidak untuk
                diperjual-belikan, dan/atau tidak dapat ditukarkan dengan uang
                tunai atau dengan apapun ke Penyelenggara, baik secara
                keseluruhan atau sebagian.
              </li>
              <li>
                Peserta yang menjadi Pemenang dinyatakan didiskualifikasi
                apabila: (i) Peserta tidak dapat dihubungi oleh Penyelenggara
                untuk keperluan verifikasi dalam jangka waktu 2x24 jam sejak
                pengumuman pemenang, (ii) Peserta merupakan pemenang dari
                program yang sama pada periode pengumuman pertama, atau (iii)
                Pemenang tidak dapat menunjukkan kelengkapan dokumen pendukung
                pada saat proses verifikasi, seperti Kartu Tanda Penduduk (KTP),
                dan dokumen pendukung lainnya untuk memenuhi persyaratan
                administrasi yang berlaku sesuai jangka waktu yang diberikan
                oleh Penyelenggara. Sehubungan dengan hal ini, Peserta dengan
                ini setuju untuk melepaskan haknya sebagai Pemenang untuk
                menerima Hadiah dan akan membebaskan Penyelenggara dari klaim
                dan/atau tuntutan penyerahan Hadiah dalam bentuk apapun di masa
                mendatang.
              </li>
              <li>
                Keikutsertaan pemenang dalam keberangkatan ke luar negeri
                ditentukan oleh ketersediaan Paspor dan Visa sesuai kebijakan
                negara tujuan Program (Denmark & Australia). Segala penolakan
                Visa yang dikeluarkan oleh negara tujuan diluar kekuasaan
                Penyelenggara dan merupakan tanggung jawab pemenang.
                Penyelenggara akan menawarkan opsi lainnya sehubungan dengan
                hadiah jika terjadi penolakan terhadap Visa
              </li>
              <li>
                Penyelenggara berhak melakukan verifikasi dan/atau pengecekan
                validasi data Peserta serta: (i) mendiskualifikasi Peserta atau
                Pemenang; dan/atau (ii) menolak atau membatalkan pemberian
                Hadiah, apabila Peserta atau Pemenang diduga melakukan
                kecurangan atau pelanggaran Syarat dan Ketentuan ini dalam
                bentuk apapun.
              </li>
              <li>
                Keputusan Penyelenggara bersifat mutlak dan final sehingga tidak
                dapat diganggu gugat.
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              Ketentuan Umum:
            </span>
            <ul className={styles.snk_tab}>
              <li>
                Program ini tidak berlaku untuk semua karyawan, keluarga
                karyawan, dan/atau pihak ketiga yang terikat kerja sama dengan
                PT HM Sampoerna Tbk dan/atau afiliasinya.
              </li>
              <li>
                Penyelenggara berhak untuk mengubah tanggal, mekanisme, Hadiah
                dan/atau Syarat dan Ketentuan atas Program sepanjang konsep dan
                nilainya kurang lebih sama dengan yang diuraikan saat ini, tanpa
                pemberitahuan sebelumnya atau kompensasi dalam bentuk apapun
                kepada para Peserta dan/atau pihak manapun. Dalam hal terjadi
                peristiwa keadaan memaksa (<em>force majeure</em>) atau
                pertimbangan material lainnya, Penyelenggara dapat membatalkan
                penyelenggaraan Program tanpa kewajiban untuk memberikan
                pemberitahuan dan/atau kompensasi kepada pihak manapun.
              </li>
              <li>
                Penyelenggara tidak akan bertanggung jawab apabila terjadi
                penipuan dan/atau tindak pidana lainnya yang mengatasnamakan
                Program ini. Untuk itu, Peserta diminta untuk berhati-hati dan
                waspada terhadap modus penipuan dan/atau tindak pidana lainnya
                yang mungkin akan mengatasnamakan Program ini serta selalu
                memastikan berhubungan dengan pihak Penyelenggara yang sah
                terkait Program.
              </li>
              <li>
                Silakan hubungi atau periksa pihak Penyelenggara yang sah
                terkait Program pada <em>website</em> amild.id atau email ke
                info@amild.id.
              </li>
              <li>
                Setiap Peserta yang mengikuti Program ini menjamin bahwa setiap
                informasi yang diberikan adalah benar, tepat dan akurat selama
                periode Program.
              </li>
              <li>
                Dengan mengikuti Program ini, Peserta setuju atas dan memberikan
                izin kepada PT HM Sampoerna Tbk. dan/atau afiliasinya untuk:
              </li>
            </ul>
            <ul className={styles.snk_tab2}>
              <li>
                Memperoleh, mengumpulkan, mengolah, menganalisis, menyimpan,
                menampilkan, mengumumkan, mengirimkan, mempublikasikan dan/atau
                menyebarkan data pribadi Peserta dan setiap Pemenang (antara
                lain namun tidak terbatas kepada, informasi nama, foto, nomor
                identitas, kartu keluarga, nomor telepon) untuk keperluan
                verifikasi dan memproses kebutuhan Visa Pemenang;
              </li>
              <li>
                Menghubungi Peserta secara langsung ataupun melalui Pihak Ketiga
                yang telah ditunjuk;
              </li>
              <li>
                Mengungkapkan informasi data pribadi Peserta kepada PT HM
                Sampoerna Tbk. dan/atau afiliasinya untuk melakukan hal-hal di
                atas serta kepada pihak yang berwenang (jika disyaratkan oleh
                hukum yang berlaku di Negara Republik Indonesia); dan/atau
              </li>
              <li>
                Seluruh Hak Cipta dan Hak atas Kekayaan Intelektual (HAKI) yang
                timbul dari pelaksanaan program ini, termasuk namun tidak
                terbatas kepada ide, hak cipta, foto, video, karakter, musik,
                rekaman suara, rancangan, film, dan bahan lainnya (“Hak Kekayaan
                Intelektual”) baik yang diambil oleh pihak Penyelenggara ataupun
                Pemenang untuk keperluan publikasi di dalam website resmi
                amild.id dan/atau materi publikasi lainnya dari A Mild, maka
                sepenuhnya akan menjadi hak milik PT HM Sampoerna Tbk dan/atau
                afiliasinya di semua yurisdiksi dan tanpa batas waktu.
              </li>
              <li>
                Membuat dokumentasi dalam format apapun (foto atau video)
                rekaman video yang berisi antara lain, video, nama, dan komentar
                dari Peserta atau Pemenang untuk keperluan publikasi di dalam{" "}
                <em>website</em> amild.id atau materi publikasi lainnya dari A
                Mild dan PT HM Sampoerna Tbk.
              </li>
            </ul>
          </div>
        </div>
        <Link href={`/${back}/br`} passHref>
          <ButtonAction
            intent="primary"
            className={styles.snk_ctaBtn}
            fullWidth
          >
            Kembali
          </ButtonAction>
        </Link>
      </div>
    </section>
  )
}
