"use client"
import { useIsMobile } from "@/hooks/use-is-mobile"
import React, { useEffect, useRef, useState } from "react"
import { motion } from "framer-motion"
import { Image } from "@/components/shared/image/image"
import ButtonBack from "@/components/shared/button-back/button-back"
import CampaignCard from "@/components/campaign-intro/campaign-card/campaign-card"
import CampaignTitle from "@/components/campaign-intro/campaign-card/components/campaign-title"
import CampaignPoint from "@/components/campaign-intro/campaign-card/components/campaign-point"
import { SuccessIcon } from "@/components/sections/article-details"
import CampaignDescription from "@/components/campaign-intro/campaign-card/components/campaign-description"
import CheckBox from "@/components/edit-profile/checkbox"
import DOMPurify from "isomorphic-dompurify"
import Link from "@/components/shared/link/link"
import ButtonAction from "@/components/shared/button-action/button-action"
import { usePathname, useSearchParams } from "next/navigation"
import PopupGame from "../shared/popup-game/popup-game"
import { useForm } from "react-hook-form"
import { ClientApiList } from "@/lib/api/client-service"
import { useDatalayer } from "@/hooks/use-datalayer"
import { useInView } from "react-intersection-observer"

const UGCIntroComp = ({ data, tnc, finished, ...props }) => {
  const linkRef = useRef(null)
  const isMobile = useIsMobile()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const [hideRule, setHideRule] = useState(false)
  const [isShowPopup, setIsShowPopup] = useState(false)
  const datalayer = useDatalayer()
  const { register, watch, setValue } = useForm({
    isAgree: false,
  })
  const [landingRef, inView] = useInView({
    triggerOnce: true,
  })
  const init = useRef(false)

  useEffect(() => {
    if (
      linkRef.current &&
      tnc?.tnc &&
      tnc?.tnc.includes("target=_blank") &&
      linkRef.current.querySelector("a") &&
      !String(linkRef.current?.querySelector("a")).includes("target=_blank")
    ) {
      linkRef.current?.querySelector("a")?.setAttribute("target", "_blank")
      linkRef.current?.querySelector("a")?.setAttribute("rel", "noopener")
    }
  })

  useEffect(() => {
    if (tnc.is_tnc_checked && !init.current) {
      setValue("isAgree", tnc.is_tnc_checked)
      init.current = true
    }
  }, [props])

  const handleBtnStyleOnDisabled = () => {
    if (!tnc?.is_tnc) return "cursor-pointer"
    // return "cursor-pointer"
    else
      return tnc?.is_tnc && !watch("isAgree")
        ? "pointer-events-none cursor-not-allowed"
        : "cursor-pointer"
  }

  const hrefParse = () => {
    const current = new URLSearchParams(Array.from(searchParams.entries())) // -> has to use this form

    return {
      pathname,
      query: {
        ...Object.fromEntries(current.entries()),
        "hide-sub-rule": true,
      },
    }
  }

  const variants = {
    show: {
      opacity: 1,
      y: 0,
      display: "block",
      transition: {
        ease: "easeInOut",
        duration: 0.3,
      },
    },
    hide: {
      opacity: 0,
      y: "-100px",
      display: "none",
      transition: {
        ease: "easeInOut",
        duration: 0.3,
        display: {
          delay: 0.3,
          duration: 0.1,
        },
      },
    },
  }

  const handleBtnIntent = () => {
    if (!tnc?.is_tnc) return "primary"
    else return watch("isAgree") ? "primary" : "primary_disable"
  }

  const handleBtnAria = () => {
    return props.isTNC ? props.isTNC && !watch("isAgree") : false
  }

  const handleBtnIndex = () => {
    if (!props.isTNC) return undefined
    else return props.isTNC && !watch("isAgree") ? -1 : undefined
  }

  const handleDataLayer = () => {
    datalayer.push({
      event: "general_event",
      event_name: "click_mulai_sekarang_ugc",
      feature: "simple engagement",
      engagement_type: "games",
      engagement_name: "ugc",
      campaign_name: data?.campaign_name.toLowerCase(), // e.g all
      event_label: `ugc - ${data?.subtitle_introduction.toLowerCase()}`,
      level: 1,
      content_id: data?.engagement_uuid,
    })
  }

  const datalayerView = async () => {
    datalayer.push({
      event: "general_event",
      event_name: "page_reached_on_simple_engagement",
      feature: "simple engagement",
      page_type: "simple engagement",
      campaign_name: data?.campaign_name.toLowerCase(), // e.g all
      // userld: dataProfile?.data.data.dataProfile,
      event_label: `ugc - ${data?.subtitle_introduction.toLowerCase()}`,
      content_id: data?.engagement_uuid,
    })
  }

  useEffect(() => {
    if (inView) {
      datalayerView()
    }
    // eslint-disable-next-line
  }, [inView])

  const handleTNC = async () => {
    try {
      const { data } = await ClientApiList.setTncEngagement({
        engagement_uuid: props.engagement_uuid,
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setHideRule(searchParams.get("hide-sub-rule") === "true")
  }, [searchParams])

  return (
    <motion.div
      key="animation-on-state"
      variants={variants}
      animate={!hideRule ? "show" : "hide"}
      className="py-[20px] px-[10px] relative"
    >
      <ButtonBack />
      <div className="md:flex" ref={landingRef}>
        <div className="mb-[30px] text-[20px] leading-[27px] text-center md:hidden text-text-2">
          Submission Challenge
        </div>
        <div className="bg-bg-5 rounded-[10px] md:rounded-[0] md:rounded-l-[20px] overflow-hidden mb-[10px] md:mb-[0] w-[100%] max-w-[604px] left-0 right-0 min-h-[266.5px] relative">
          <Image
            alt="cover"
            className="object-center absolute"
            src={
              isMobile
                ? data?.mobile_image_introduction_url
                : data?.desktop_image_introduction_url
            }
          />
        </div>
        <CampaignCard className=" md:mb-0 md:min-h-[604px] md:flex flex-cols items-center lg:rounded-[0] md:!rounded-r-[20px] md:!px-[50px] w-full">
          <div className="flex gap-[20px] items-center">
            <div className="hidden w-fit lg:block text-[14px] font-medium font-font-family-6 tracking-[0.14px] leading-[10px] bg-cta-3 text-text-1 py-[10px] px-[15px] rounded-[10px] mb-[20px]">
              Submission Challenge
            </div>
            {!isMobile && data?.point > 0 && (
              <CampaignPoint
                className="hidden lg:flex"
                asset={
                  !finished &&
                  `/assets/${process.env.NEXT_PUBLIC_NAME}/icons/${process.env.NEXT_PUBLIC_NAME === "amild" ? "reward-profile-effect.png" : process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "reward.png" : "reward-profile.svg"}`
                }
                icon={finished && <SuccessIcon className="hidden lg:block" />}
              >
                {data.point}
              </CampaignPoint>
            )}
          </div>
          <CampaignTitle className="md:text-[40px] md:mb-[20px] leading-[24px] mb-[10px] lg:mb-[20px] lg:text-[40px] lg:leading-[44px] lg:font-font-family-3">
            {data?.subtitle_introduction}{" "}
          </CampaignTitle>
          {isMobile && data?.point > 0 && (
            <CampaignPoint
              className="lg:hidden flex"
              asset={
                !finished &&
                `/assets/${process.env.NEXT_PUBLIC_NAME}/icons/${process.env.NEXT_PUBLIC_NAME === "amild" ? "reward-profile-effect.png" : process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "reward.png" : "reward-profile.svg"}`
              }
              icon={finished && <SuccessIcon className="lg:hidden block" />}
            >
              {data?.point}
            </CampaignPoint>
          )}
          <CampaignDescription className="mb-0 md:'text-[20px] md:leading-[32px] md:mb-[20px] lg:text-[20px] leading-[20px] lg:leading-[28px] ">
            {data?.desc_introduction || "a"}
          </CampaignDescription>
          {tnc && tnc?.is_tnc ? (
            <form
              method="POST"
              className="flex gap-[5px] mt-[10px] md:mb-[30px]"
            >
              <CheckBox
                register={register}
                name={"isAgree"}
                disabled={tnc?.is_tnc_checked}
                isCenter={true}
                className="top-[calc(50%_-_10px)]"
              />
              <div
                ref={linkRef}
                className="text-[12px] lg:text-[14px] font-medium underline leading-[18px] font-medium text-text-1"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(tnc.tnc),
                }}
              ></div>
            </form>
          ) : null}

          <ButtonAction
            onClick={() => setIsShowPopup(true)}
            intent="secondary"
            className="lg:py-[9px] lg:px-[20px] lg:text-[14px] lg:leading-[20px] hidden md:flex items-center gap-[5px] w-fit mb-[30px]"
          >
            <div className="flex items-center gap-[5px]">
              <div className="w-[20px] h-[20px]">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="vuesax/bold/add-circle">
                    <g id="add-circle">
                      <path
                        style={{ fill: "var(--icon_1)" }}
                        className="fill-icon-1"
                        id="Vector"
                        d="M10.0001 1.66675C5.40841 1.66675 1.66675 5.40841 1.66675 10.0001C1.66675 14.5917 5.40841 18.3334 10.0001 18.3334C14.5917 18.3334 18.3334 14.5917 18.3334 10.0001C18.3334 5.40841 14.5917 1.66675 10.0001 1.66675ZM13.3334 10.6251H10.6251V13.3334C10.6251 13.6751 10.3417 13.9584 10.0001 13.9584C9.65842 13.9584 9.37508 13.6751 9.37508 13.3334V10.6251H6.66675C6.32508 10.6251 6.04175 10.3417 6.04175 10.0001C6.04175 9.65842 6.32508 9.37508 6.66675 9.37508H9.37508V6.66675C9.37508 6.32508 9.65842 6.04175 10.0001 6.04175C10.3417 6.04175 10.6251 6.32508 10.6251 6.66675V9.37508H13.3334C13.6751 9.37508 13.9584 9.65842 13.9584 10.0001C13.9584 10.3417 13.6751 10.6251 13.3334 10.6251Z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <span>Game Rules</span>
            </div>
          </ButtonAction>

          <div className="hidden md:block h-[1px] w-full bg-[#E6E6E6] mb-[30px]"></div>

          <Link
            aria-disabled={handleBtnAria()}
            tabIndex={handleBtnIndex()}
            className={`hidden md:block w-[100%] max-w-[343px] ${handleBtnStyleOnDisabled()}`}
            onClick={() => {
              handleTNC()
              handleDataLayer()
            }}
            href={hrefParse()}
          >
            <ButtonAction intent={handleBtnIntent()}>
              Mulai Sekarang
            </ButtonAction>
          </Link>
        </CampaignCard>
        <PopupGame open={isShowPopup} onClick={() => setIsShowPopup(false)}>
          <CampaignCard className="border-[#2C2A29] md:!p-0 md:!rounded-[0] md:!border-none bg-bg-3 max-w-[436px]">
            <CampaignTitle className="md:!text-[40px] leading-[24px] md:!leading-[54px] md:mb-[20px] lg:text-[40px] lg:font-font-family-3">
              {data.subtitle_instruction}
            </CampaignTitle>
            <CampaignDescription
              isList
              className="mb-0 md:!mb-0 md:!text-[16px] md:!leading-[25px] lg:text-[20px] leading-[20px] lg:leading-[28px]"
            >
              {data.desc_instruction}
            </CampaignDescription>
          </CampaignCard>
        </PopupGame>
        <CampaignCard className="border-cta-4 text-text-2 !bg-bg-3 md:hidden ">
          <CampaignTitle className="leading-[24px]">
            {data.subtitle_instruction}
          </CampaignTitle>
          <CampaignDescription isList className="mb-0">
            {data.desc_instruction}
          </CampaignDescription>
        </CampaignCard>
        <div
          className="fixed z-[19] bottom-[56px] left-2/4 -translate-x-1/2 w-full px-[10px] pt-[80px] pb-[20px] md:hidden "
          style={{
            background:
              "linear-gradient(180deg,rgba(255,255,255,0), var(--background_3) 40%)",
          }}
        >
          <Link
            aria-disabled={handleBtnAria()}
            tabIndex={handleBtnIndex()}
            className={`md:hidden ${handleBtnStyleOnDisabled()}`}
            href={hrefParse()}
            onClick={() => {
              handleTNC()
              handleDataLayer()
            }}
          >
            <ButtonAction intent={handleBtnIntent()}>
              Mulai Sekarang
            </ButtonAction>
          </Link>
        </div>
      </div>
    </motion.div>
  )
}

export default UGCIntroComp
