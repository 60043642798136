'use client';
import React, { useEffect, useRef } from 'react';
import { ClientApiList } from '@/lib/api/client-service';

export const IframeHallOfFame = () => {
    const iframeRef = useRef();

    useEffect(() => {
        async function getSubmission() {
            let _ = this;
            const defaultIFrameURL = "https://g.minigim.fun/magnum-studio-halloffame-2023/index.html";
            await ClientApiList.postIframeData("hof")
                .then((resp) => {
                    if (resp.data.data.length > 0) {
                        let halloffame = [];
                        resp.data.data.forEach((elm) => {
                            const meta = JSON.parse(elm.meta.studio);
                            delete meta.nama;
                            meta.items = meta.items[0];
                            halloffame.push({
                                halloffame: true,
                                id: elm.id + "",
                                nama: elm.name,
                                namastudio: elm.textSubmit,
                                ...meta,
                            });
                        });
                        const data = { halloffame };
                        iframeRef.current.src = defaultIFrameURL + "?data=" + btoa(JSON.stringify(data));
                    } else {
                        iframeRef.current.src = defaultIFrameURL;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    iframeRef.current.src = defaultIFrameURL;
                });
        }

        getSubmission()

        return () => {
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        // addGamePoint,
        // trackerUser,
    ]);


    return (
        // <template>
        <div className='w-[100%] h-[100%] min-h-[calc(100vh-170px)] min-w-[100vw]'>
            <iframe
                ref={iframeRef}
                className='block w-[100dvw] h-[100dvh]'
                seamless="seamless"
                title="game-view"
                // src="https://g.minigim.fun/magnum-studio-halloffame-2023/index.html"
                frameBorder="0"
            ></iframe>
        </div>
        // </template>
    );
};
