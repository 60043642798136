"use client"
import { usePathname, useSearchParams } from "next/navigation"
import React, { useState, useEffect, Suspense, useRef } from "react"
import PropTypes from "prop-types"
// custom comp. imports
import ButtonAction from "@/components/shared/button-action/button-action"
import PopupAction from "@/components/shared/popup-action/popup-action"
import html2canvas from "html2canvas"
import QRCode from "react-qr-code"
import Portal from "@/components/shared/portal"

const ShowQRFallback = () => {
  return <div>Loading...</div>
}

export function SuspendedShowQR({ qrImage, registered_account }) {
  const pathname = usePathname()
  const qrRef = useRef()
  const searchParams = useSearchParams()
  const [isShowing, setIsShowing] = useState(false)

  const hrefParse = () => {
    const current = new URLSearchParams(Array.from(searchParams.entries())) // -> has to use this form

    return {
      pathname,
      query: { ...Object.fromEntries(current.entries()), showQr: false },
    }
  }

  useEffect(() => {
    setIsShowing(searchParams.get("showQr") === "true")
  }, [searchParams])

  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a")
    fakeLink.style = "display:none;"
    fakeLink.download = fileName

    fakeLink.href = blob

    document.body.appendChild(fakeLink)
    fakeLink.click()
    document.body.removeChild(fakeLink)

    fakeLink.remove()
  }

  async function download(element, imageFileName) {
    const html = document.getElementsByTagName("html")[0]
    const body = document.getElementsByTagName("body")[0]
    let htmlWidth = html.clientWidth
    let bodyWidth = body.clientWidth
    const newWidth = element.scrollWidth - element.clientWidth
    if (newWidth > element.clientWidth) {
      htmlWidth += newWidth
      bodyWidth += newWidth
    }
    html.style.width = htmlWidth + "px"
    body.style.width = bodyWidth + "px"
    const canvas = await html2canvas(element, { useCORS: true })
    const image = canvas.toDataURL("image/png", 1.0)
    downloadImage(image, imageFileName)
    html.style.width = null
    body.style.width = null
  }

  if (!registered_account) {
    return null
  }

  return (
    <Portal>
      <PopupAction
        className="!text-text-2"
        isShowing={isShowing}
        href={hrefParse()}
        zIndex="z-[20]"
      >
        <div className="py-[10px] px-[78px] lg:px-0 bg-bg-1 rounded-[15px] overflow-hidden mb-[20px]">
          <div
            ref={qrRef}
            className="p-[16px] w-[152px] lg:mx-auto h-[152px] bg-white mb-[10px] relative overflow-hidden"
          >
            {/* <img className="h-[100%]" ref={qrRef} alt="cover" src={qrImage} data-testid="qr-image" /> */}
            <QRCode
              size={436}
              // ref={qrRef}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={registered_account}
              viewBox={`0 0 256 256`}
            />
          </div>
          <div
            data-testid="registered-account"
            className="py-[5px] px-[10px] w-fit mx-auto leading-[16px] lg:leading-[19px] rounded-[32px] bg-bg-2 text-white text-[12px] lg:text-[14px] font-medium font-font-family-6 tracking-[.5px]"
          >
            {registered_account}
          </div>
        </div>
        <div className="text-[20px] font-black font-font-family-8 mb-[5px] leading-[27px]">
          QR Code
        </div>
        <div className="text-[14px] lg:text-[16px] lg:px-[10px] leading-[20px] lg:leading-[25px] mb-[20px]">
          Tunjukkan QR Code{" "}
          {process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"} di
          lokasi event {String(process.env.NEXT_PUBLIC_NAME)}.id
        </div>
        {/* <a className="block" href={qrImage} download={'user-qr.png'} target="_blank"> */}
        <ButtonAction
          className="py-[15px] px-[20px] leading-[20px] lg:leading-[22px]"
          intent="primary"
          onClick={() => download(qrRef.current, "qr-image")}
        >
          {/* {ctaLabel} */}
          Download QR Code
        </ButtonAction>
        {/* </a> */}
      </PopupAction>
    </Portal>
  )
}

export function ShowQR(props) {
  return (
    <Suspense fallback={<ShowQRFallback />}>
      <SuspendedShowQR {...props} />
    </Suspense>
  )
}

SuspendedShowQR.propTypes = {
  qrImage: PropTypes.string,
  registered_account: PropTypes.string,
}
