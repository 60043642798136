"use client"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { useShowLoginPopup } from "@/hooks/auth/use-login-popup"
import { useRedirect } from "@/hooks/use-redirect"
import { usePathname, useSearchParams } from "next/navigation"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { Spinner } from "../shared/spinner"
import { useWindowSize } from "@/hooks/use-window-size"
import { Image } from "../shared/image/image"
import { ClientApiList } from "@/lib/api/client-service"
import clsx from "clsx"
import { useIsMobile } from "@/hooks/use-is-mobile"
import UseQueryParams from "@/hooks/use-query-params"

export function HomePageLayout({
  bg_img_url_mobile,
  bg_img_url_desktop,
  children,
  ...props
}) {
  const { handleFilterQuery } = UseQueryParams()
  const QUERYPARAM = "referral_code"
  const { isLoading, isLogin } = useIsLogin()
  const pathname = usePathname()
  const [isAspaceLoading, setIsAspaceLoading] = useState(false)
  const searchParams = useSearchParams()
  const [showPage, setShowPage] = useState(false)
  const [path, setPath] = useState("")

  const checkUbPath = !pathname.includes("/br") && isLogin && !isLoading
  const checkBrPath = pathname.includes("/br") && !isLogin && !isLoading

  const { width } = useWindowSize()
  const isDesktop = width >= 1024
  const bgImg = isDesktop ? bg_img_url_desktop : bg_img_url_mobile
  const isMobile = useIsMobile()

  useEffect(() => {
    if (pathname.includes("help")) {
      setPath("/help/br")
    } else if (pathname.includes("hubungi-kami")) {
      setPath("/hubungi-kami/br")
    } else if (pathname.includes("syarat-ketentuan")) {
      setPath("/syarat-ketentuan/br")
    } else {
      setPath("/br")
    }
  }, [path, pathname])

  useShowLoginPopup({
    showWhen: checkBrPath,
    closePath: "/",
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
  })

  const handleRedirectASpace = async () => {
    setIsAspaceLoading(true)
    try {
      const {
        data: { data: auth_data },
      } = await ClientApiList.getAuthData({
        platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
        redirect_url: `${process.env.NEXT_PUBLIC_META_URL}${pathname}`,
      })

      // eslint-disable-next-line no-control-regex
      const base = searchParams.get("aspace-url").replace(/[^\x00-\x7F]/g, "")
      const urlRedirect = `${base}?auth-data=${auth_data.data.auth_data}`.trim()
      window.open(urlRedirect, "_self").focus()
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (searchParams.has("aspace-url")) handleRedirectASpace()
  }, [searchParams])

  useRedirect({
    redirectWhen: checkUbPath,
    path: path,
    withHash: true,
    withQuery: true,
  })

  const handleMGM = async (referral_code) => {
    const result = await ClientApiList.getMGMLog({ referral_code })
    return result
  }

  useEffect(() => {
    if (searchParams.get(QUERYPARAM) && isLogin && !isLoading) {
      handleMGM(searchParams.get(QUERYPARAM))
      handleFilterQuery(QUERYPARAM)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [searchParams, isLogin, isLoading])

  useEffect(() => {
    if (!checkBrPath && !checkUbPath) setShowPage(true)
  }, [checkBrPath, checkUbPath])

  return (
    <div className="pt-[58px] lg:pt-[72px] min-h-[calc(100vh-170px)] bg-bg-1">
      <div
        className={clsx(
          "absolute left-0 right-0 w-full bg-bg-1 z-1 overflow-hidden",
          isMobile
            ? "h-[738px] rounded-b-[10px] top-[0px] "
            : "min-h-[520px] aspect-[1410/520] top-[16px] lg:top-[72px] rounded-[15px]",
          pathname.includes("ugc")
            ? "lg:w-[calc(100dvw_-_416px)] lg:top-[72px] lg:rounded-0 lg:h-[100dvh]"
            : ""
        )}
      >
        {bgImg && <Image alt="bg" src={bgImg} />}
      </div>
      {showPage && !isAspaceLoading ? (
        children
      ) : (
        <div className="h-[calc(100vh-170px)] flex items-center">
          <Spinner />
        </div>
      )}
    </div>
  )
}

HomePageLayout.propTypes = {
  children: PropTypes.array,
  bg_img_url_desktop: PropTypes.string,
  bg_img_url_mobile: PropTypes.string,
}
