"use client"
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useIsMobile } from "@/hooks/use-is-mobile"

import CampaignTitle from "../campaign-intro/campaign-card/components//campaign-title"
import CampaignDescription from "../campaign-intro/campaign-card/components/campaign-description"
import CampaignCard from "../campaign-intro/campaign-card/campaign-card"
import ButtonAction from "../shared/button-action/button-action"
import BackBanner from "../shared/back-banner"
import { Image } from "../shared/image/image"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { usePathname, useRouter } from "next/navigation"
import { useReqUrl } from "@/hooks/auth/use-req-url"
import { useDatalayer } from "@/hooks/use-datalayer"
import { cn } from "@/lib/utils"

export function CampaignIntroCard({
  title,
  description,
  img_url,
  img_mobile,
  img_desktop,
  hero_img_url,
  hero_bg_mobile,
  hero_bg_desktop,
  btn_label,
  hero_title_mobile,
  hero_title_desktop,
  bg_card_mobile,
  bg_card_desktop,
  title_type,
  hero_title,
  ...props
}) {
  const { isLogin, isLoading } = useIsLogin()
  const datalayer = useDatalayer()
  const pathname = usePathname()
  const { replace } = useRouter()

  const { execute: login } = useReqUrl({
    redirectUrl: `${pathname.slice(0, -3)}/br`,
  })
  const isMobile = useIsMobile()
  const campaign = pathname.split("/")[1].split("-").join(" ")
  const brands =
    process.env.NEXT_PUBLIC_NAME === "amild" ||
    process.env.NEXT_PUBLIC_NAME === "magnum"
  const labelSnk = "*Syarat dan Ketentuan Berlaku"
  const campaignBrand =
    campaign === "gac2024" || campaign === "hari besar musik keras"

  const onClickDatalayer = () => {
    datalayer.push({
      event: "general_event",
      event_name: `click_syarat_dan_ketentuan_berlaku`,
      feature: "hero engagement page",
      page_section: "campaign page",
      page_path: pathname,
      engagement_type: "interaction",
      campaign_name: campaign,
    })
    replace(`/${pathname.split("/")[1]}/br/syarat-ketentuan`)
  }

  const handleIsSpecialPage = () => {
    const specialPages = ["multivariant", "kepuasan-besar"]
    // console.log(specialPages.findIndex(path => pathname.includes(path)) !== -1, 'chefck')
    return specialPages.findIndex((path) => pathname.includes(path)) !== -1
  }

  useEffect(() => {
    if (!isLoading) {
      datalayer.push({
        event: "general_event",
        event_name: "page_reached_on_hero_engagement_page",
        feature: "hero engagement page",
        page_type: "hero engagement page",
        campaign_name: campaign,
      })
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLoading])

  if (!title && !description && !handleIsSpecialPage())
    return (
      <>
        <BackBanner
          className="min-h-[750px] lg:min-h-[520px] h-[calc(100dvh-114px)] lg:h-[520px] lg:top-[15px] lg:left-[15px] lg:right-[15px] bg-bg-5 object-top"
          imgClass="object-top"
          bgImg={isMobile ? hero_bg_mobile : hero_bg_desktop}
          // bgImg={'/assets/amild/test-prod-desktop.png'}
        ></BackBanner>
        <div className="min-h-[calc(100dvh-360px)] lg:min-h-[520px] flex items-end">
          {!isLogin ? (
            <ButtonAction
              onClick={() => {
                datalayer.push({
                  event: `general_event`,
                  event_name: "click_login_button",
                  feature: "login",
                  button_name: "masuk",
                  page_path: pathname,
                  campaign_name: "all",
                })
                login()
              }}
              intent="primary"
              className="!min-h-[50px] lg:max-w-[355px] mx-auto lg:mb-[30px]"
            >
              {btn_label}
            </ButtonAction>
          ) : null}
        </div>
      </>
    )

  return (
    <>
      <BackBanner
        className="lg:top-[15px] lg:left-[15px] lg:right-[15px] bg-bg-5 lg:h-[520px]"
        bgImg={isMobile ? hero_bg_mobile : hero_bg_desktop}
      ></BackBanner>
      <div className="w-fit absolute top-[106px] lg:top-[100px] lg:left-[30px] lg:min-h-[520px]">
        {title_type === "image" ? (
          <Image
            className="max-w-[220px] max-h-[100px] lg:max-w-[360px] lg:max-h-[200px] !w-screen !h-screen"
            src={isMobile ? hero_title_mobile : hero_title_desktop}
            alt="title"
          />
        ) : (
          <div className="font-font-family-3 max-w-[80%] lg:max-w-[386px] lg:max-h-[200px] text-[32px] lg:text-[40px] font-bold leading-[33px] lg:leading-[50px] text-text-2">
            {
              // hero_title.substring(0, 34)
              hero_title
            }
          </div>
        )}
      </div>
      {((pathname.includes("/br") && props.is_show_intro_card) ||
        (pathname.includes("/ub") && title && description && btn_label)) &&
      !handleIsSpecialPage() ? (
        <CampaignCard
          img={isMobile ? bg_card_mobile : bg_card_desktop}
          className="!border-bg-5 w-full relative  md:p-[20px] lg:p-[25px] col-start-2 row-start-1 self-start lg:mb-[0] lg:top-[19px] lg:mx-0 lg:right-[14px] lg:w-full lg:w-[100vw] lg:max-w-[614px] justify-self-end lg:my-[10px] xl:my-0 md:my-0 md:mb-[20px] md:max-h-[490px] lg:max-h-[500px] xl:max-h-[482px] !bg-bg-4 !text-text-4 h-full"
        >
          <CampaignTitle
            className={cn(
              `${isLogin ? "md:text-[32px] md:leading-[42px] md:mt-[9px] !text-text-4" : "!text-text-4 md:text-[24px] md:leading-[32px] md:mt-[14px]"}`,
              process.env.NEXT_PUBLIC_NAME === "amild"
                ? "font-font-family-3"
                : "font-font-family-7"
            )}
          >
            {process.env.NEXT_PUBLIC_NAME === "amild"
              ? String(title).toUpperCase()
              : title}
          </CampaignTitle>
          <CampaignDescription className="md:leading-[25px] text-text-4">
            {description}
          </CampaignDescription>
          {!isLoading && isLogin && brands && campaignBrand && (
            <div>
              <button
                className="text-sm underline"
                onClick={() => onClickDatalayer(labelSnk)}
                // href={`/${pathname.split("/")[1]}/br/syarat-ketentuan`}
              >
                {labelSnk}
              </button>
            </div>
          )}
          <div className="bg-bg-4 h-[138px] lg:min-h-[192px] w-[100%] relative md:max-w-[349px] md:max-h-[148.2px] lg:max-h-unset md:mb-[20px] mx-auto lg:max-w-none">
            {img_desktop && img_mobile ? (
              <Image
                alt="cover"
                fill
                objectFit="cover"
                src={isMobile ? img_mobile : img_desktop}
              />
            ) : null}
          </div>
          {!isLogin ? (
            // <>
            <ButtonAction
              onClick={() => {
                datalayer.push({
                  event: `general_event`,
                  event_name: "click_login_button",
                  feature: "login",
                  button_name: "masuk",
                  page_path: pathname,
                  campaign_name: "all",
                })
                login()
              }}
              intent="secondary"
              className="mt-[15px] lg:mt-[20px]"
            >
              {btn_label}
            </ButtonAction>
          ) : // </>
          null}
        </CampaignCard>
      ) : (
        <CampaignCard
          img={isMobile ? bg_card_mobile : bg_card_desktop}
          className="!bg-tranparent w-full relative  md:p-[20px] lg:p-[25px] col-start-2 row-start-1 self-start lg:mb-[0] lg:top-[19px] lg:mx-0 lg:right-[14px] lg:w-full lg:w-[100vw] lg:max-w-[614px] justify-self-end lg:my-[10px] xl:my-0 md:my-0 md:max-h-[490px] lg:max-h-[500px] xl:max-h-[482px] !bg-transparent !text-text-4 h-full !m-0 !p-0 !relative !z-[-1]"
        ></CampaignCard>
      )}
    </>
  )
}

CampaignIntroCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  img_url: PropTypes.string,
  hero_img_url: PropTypes.string,
  btn_label: PropTypes.string,
}
