"use client"
import React, { useRef, useState } from "react"
import { SwiperSlide } from "swiper/react"
import "swiper/css"
import VoucherCard from "./components/voucher-card.jsx/voucher-card"
import { useIsDesktop } from "@/hooks/use-is-mobile"
import { SwiperComp } from "@/components/swiper/swiper"
import ButtonAction from "@/components/shared/button-action/button-action"

export default function Vouchers({ vouchers = [], isLoading, handleClaim }) {
  const isDesktop = useIsDesktop()
  const swiperRef = useRef(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const showButton = swiperRef.current
    ? swiperRef.current?.virtualSize < vouchers?.length * 255 && activeIndex !== vouchers?.length -3
    : 0

  const handleNextSlide = () => {
    if (activeIndex < vouchers.length) {
      swiperRef?.current?.slideNext()
      setActiveIndex((prevState) => prevState + 1)
    }
  }
  const handlePrevSlide = () => {
    if (activeIndex !== 0) {
      swiperRef?.current?.slidePrev()
      setActiveIndex((prevState) => prevState - 1)
    }
  }

  const handleRenderList = () => {
    if (isLoading)
      return [1, 2, 3, 4, 5].map((item, index, arr) => (
        <SwiperSlide
          data-testid={`voucher-skeleton-${index}`}
          key={item}
          className={`!w-fit ${index === arr.length - 1 ? "!mr-[10px]" : ""}`}
        >
          <Skeleton />
        </SwiperSlide>
      ))
    else
      return vouchers.map((voucher, index) => {
        return (
          <SwiperSlide
            key={voucher.title}
            className={`!w-fit !h-[auto] ${index === vouchers.length - 1 ? "!mr-[10px]" : ""}`}
          >
            <VoucherCard
              handleClaim={handleClaim}
              {...{
                title: voucher.masking_title,
                validDate: voucher.end_date,
                id: voucher.id,
                exp: voucher.point,
                expIcon: voucher.exp_icon,
                bgImg: voucher.imageurl,
              }}
            />
          </SwiperSlide>
        )
      })
  }

  if (!isLoading && vouchers.length === 0) return

  return (
    <>
      <div className="text-text-2 pl-[5px] lg:pl-0 text-[16px] font-medium font-font-family-6 mb-[10px] leading-[22px] flex items-center justify-between">
        Exclusive Voucher
        <div className="hidden relative lg:flex items-center gap-[5px]">
          <ButtonAction
            disabled={activeIndex === 0}
            onClick={handlePrevSlide}
            className="min-h-[30px] min-w-[30px] w-[100%] h-[100%] !max-w-[30px] !max-h-[30px] flex items-center justify-center !p-0"
            intent={`${activeIndex === 0 ? "primary_disable" : "primary"}`}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 19L8 12L15 5"
                className={`${activeIndex === 0 ? "stroke-icon-3" : "stroke-icon-4"}`}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {/* <Image className={`${Style.img} ${activeIndex === 0 ? Style.disable : Style.active} max-w-[20px] max-h-[20px] fill-text-3`} src="/assets/amild/icons/chevron-left.svg" alt="prev" /> */}
          </ButtonAction>
          <ButtonAction
            disabled={!showButton}
            onClick={handleNextSlide}
            className="min-h-[30px] min-w-[30px] w-[100%] h-[100%] !max-w-[30px] !max-h-[30px] flex items-center justify-center !p-0"
            intent={`${!showButton ? "primary_disable" : "primary"}`}
          >
            {/* <Image className={`${Style.img} ${!showButton ? Style.disable : Style.active} max-w-[20px] max-h-[20px] fill-text-3`} src="/assets/amild/icons/chevron-right.svg" alt="prev" /> */}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={`${!showButton ? "stroke-icon-3" : "stroke-icon-4"}`}
                d="M9 5L16 12L9 19"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ButtonAction>
          {/* <PrevButton onClick={handlePrevSlide} className="relative translate-y-0 bg-[crimson] !inset-0 lg:w-[30px] lg:h-[30px] lg:rounded-[10px] flex items-center justify-center" swiperRef={swiperRef} /> */}
          {/* <NextButton onClick={handleNextSlide} className="relative translate-y-0 bg-[crimson] !inset-0 lg:w-[30px] lg:h-[30px] lg:rounded-[10px] flex items-center justify-center" swiperRef={swiperRef} /> */}
        </div>
      </div>
      <div className="relative mb-[15px] min-h-[266px] h-[auto] flex gap-[5px]">
        <div className="absolute top-0 bottom-0 left-[-10px] lg:left-0 right-[-15px] lg:right-0">
          <SwiperComp
            ref={swiperRef}
            className="!pl-[10px] lg:!pl-0"
            slidesPerView={"auto"}
            spaceBetween={isDesktop ? 10 : 5}
          >
            {handleRenderList()}
          </SwiperComp>
        </div>
      </div>
    </>
  )
}

function Skeleton() {
  return (
    <div className=" animate-pulse border-[1px] flex flex-col border-cta-4 p-[5px] rounded-[10px] bg-bg-3 w-[100vw] h-[100vh] max-w-[228px] max-h-[247px]">
      <div className="w-[100vw] bg-[#D9D9D9] h-[100vh] relative rounded-[10px] max-w-[218px] max-h-[109px] overflow-hidden mb-[5px]"></div>
      <div className="p-[5px] pb-[10px] grow-[1] flex flex-col">
        <div className="h-[21px] w-[100%] bg-[#D9D9D9] mb-[2px]"></div>
        <div className="h-[21px] w-[20%] bg-[#D9D9D9] mb-[5px]"></div>
        <div className="h-[19px] w-[80%] bg-[#D9D9D9] mb-[20px] text-text-3"></div>
        <div className="h-[19px] w-[40%] bg-[#D9D9D9] mt-auto font-bold font-font-family-7 text-cta-1 text-[14px] leading-[19px] flex items-center gap-[5px] "></div>
      </div>
    </div>
  )
}
