"use client"
import React from "react"

export default function YourChallengeInfo({ icon, children, challengeTitle }) {
  return (
    <div className="overflow-hidden p-[5px] border-b-[1px] border-cta-1 lg:p-[10px] rounded-[5px] lg:rounded-[10px] text-text-1 relative">
      <div style={{background:'var(--text_1)', opacity:'0.25'}} className="absolute z-[0] inset-0"></div>
      <div className="text-[10px] lg:text-[12px] leading-[14px] lg:leading-[16px] tracking-[0.1] lg:tracking-none mb-[2px] lg:mb-[5px]">
        {challengeTitle}
      </div>
      <div className="flex items-center gap-[5px]">
        <div className="w-[12px] h-[12px] lg:w-[20px] lg:h-[20px]">{icon}</div>
        <div className="text-[12px] lg:text-[16px] leading-[16px] lg:leading-[22px] font-medium font-font-family-6">
          {children}
        </div>
      </div>
    </div>
  )
}
