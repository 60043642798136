import React from "react"
import { Image } from "../shared/image/image"
import { motion } from "framer-motion"
import { useIsDesktop } from "@/hooks/use-is-mobile"
import Link from "@/components/shared/link/link"

const variants = {
  show: {
    opacity: 1,
    y: 0,
    display: "block",
  },
  hide: {
    opacity: 0,
    y: -100,
    display: "none",
  },
  transition: {
    ease: "easeInOut",
    duration: 0.3,
  },
}

const UGCNotif = ({ isShow }) => {
  const isDesktop = useIsDesktop()
  return (
    <motion.div
      key="animation-on-state"
      variants={variants}
      animate={isShow && !isDesktop ? "show" : "hide"}
      className="bg-bg-2 text-text-4 relative z-[3] px-[10px] py-[8px] !lg:hidden"
    >
      <div className="flex items-center gap-[5px]">
        <div className="h-[24px] w-[24px]">
          <Image
            src={`/assets/${process.env.NEXT_PUBLIC_NAME}/ugc/ribbon.svg`}
            alt="ribbon"
          />
        </div>
        <Link
          href={"/submission-gallery/br?tab=galeri%20poster&showToast=true"}
          className="inline-block flex items-center"
        >
          <div>
            Lihat <b>winners gallery</b> di sini
          </div>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.42847 4L7.42847 8L3.42847 12"
              stroke="white"
              stroke-width="1.2"
              stroke-linecap="square"
            />
            <path
              d="M8.19043 4L12.1904 8L8.19043 12"
              stroke="white"
              stroke-width="1.2"
              stroke-linecap="square"
            />
          </svg>
        </Link>
        <div
          role="button"
          tabIndex={0}
          className="cursor-pointer h-[32px] w-[32px] ml-auto mr-[2px] flex text-cta-1 flex items-center justify-center"
        >
          x
        </div>
      </div>
      <div></div>
    </motion.div>
  )
}

export default UGCNotif
