"use client"

import Link from "@/components/shared/link/link"
import styles from "./syaratKetentuan.module.scss"
import ButtonAction from "@/components/shared/button-action/button-action"
import clsx from "clsx"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { usePathname } from "next/navigation"
import PropTypes from "prop-types"

export default function CampaignSnkMagnum({ isArtwork }) {
  const isMobile = useIsMobile()
  const pathname = usePathname()
  const back = pathname.split("/")[1]

  return (
    <section
      className={clsx(
        styles.container,
        "w-full max-w-[460px] mx-auto relative py-[30px] text-text-2 text-xs",
        isMobile && "px-3.5"
      )}
    >
      {/* <div className={styles.snk_bg}>
        <Image
          alt="bg"
          objectPosition="top left"
          src={isMobile ? snkBgMob : snkBgDesk}
          layout="fill"
          objectFit="contain"
        />
      </div> */}
      {/* <div className={styles.snk_back}>
        <Link href={prevPath || back} passHref>
          <ButtonBack />
        </Link>
      </div> */}
      <div className={styles.snk}>
        <h2
          className={clsx(
            styles.snk_title,
            "font-bold text-2xl text-text-2 capitalize font-font-family-3"
          )}
        >
          PROGRAM MAGNUMOTION HARI BESAR MUSIK KERAS 2024
        </h2>
        <p
          className={clsx(
            styles.snk_title,
            "font-bold text-2xl text-text-2 capitalize font-font-family-3"
          )}
        >
          Syarat & Ketentuan
        </p>
        <p
          className={clsx(
            styles.snk_sub,
            "font-medium font-font-family-6 mt-[10px] text-text-2 text-sm"
          )}
        >
          <strong>PENTING:</strong> Harap baca syarat dan ketentuan ini (“Syarat
          & Ketentuan”) sebelum mengikuti program ini. Dengan mengikuti Program
          ini, anda mengkonfirmasi bahwa anda telah membaca, memahami dan setuju
          untuk mematuhi Syarat dan Ketentuan termasuk perubahannya yang dibuat
          oleh pihak Penyelenggara dari waktu ke waktu.
        </p>
        <p
          className={clsx(
            styles.snk_sub,
            "font-medium font-font-family-6 mt-[10px] text-text-2 text-sm"
          )}
        >
          Magnumotion Hari Besar Musik Keras 2024 adalah program konsumen yang
          ditujukan kepada perokok dewasa milik PT HM Sampoerna Tbk.
          (“Sampoerna”) yang merupakan produsen, penjual, pemasar serta afiliasi
          dari produsen yang bergerak di bidang bisnis tembakau dan produk yang
          mengandung nikotin lainnya, yang artinya juga meliputi riset dan
          pengembangan dan/atau distributor produk rokok, berbagai macam hasil
          tembakau lainnya yang dibakar maupun yang tidak dibakar, atau rokok
          elektronik/e-vapors (alat yang memproduksi aerosol dengan menguapkan
          larutan nikotin berperisa dan menggunakan energi baterai) atau produk
          mengandung nikotin lainnya yang dipasarkan dengan merek-merek dagang
          tertentu, termasuk merek Magnum Filter (“Program”). Sampoerna menunjuk
          pihak ketiga dalam hal penyelenggaraan Program (“Penyelenggara”).
        </p>
        <p
          className={clsx(
            styles.snk_sub,
            "font-medium font-font-family-6 mt-[10px] text-text-2 text-sm"
          )}
        >
          Perokok dewasa yang berpartisipasi dalam Program ini (“Peserta”),
          wajib membaca, memahami, dan mematuhi syarat-syarat dan
          ketentuan-ketentuan yang diuraikan di bawah ini (“Syarat dan
          Ketentuan”).
        </p>
        <div className={styles.snk_desc}>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              A. Persyaratan Peserta mengikuti Program:
            </span>
            <p
              className={clsx(
                styles.snk_sub,
                "font-medium font-font-family-6 mt-[10px] text-text-2 text-sm"
              )}
            >
              Program ini berlaku di wilayah Republik Indonesia dan terbuka bagi
              setiap orang Peserta wajib memenuhi persyaratan Program sebagai
              berikut:
            </p>
            <ul className={styles.snk_tab}>
              <li>
                Peserta merupakan warga negara Indonesia dan bertempat tinggal
                di wilayah Indonesia.
              </li>
              <li>
                Peserta adalah perokok dewasa berusia minimal 21 tahun, dan
                memiliki identitas berupa KTP yang masih berlaku.
              </li>
              <li>
                Terdaftar dan memiliki akun pada <em>website</em> www.magnum.id.
              </li>
              <li>Peserta sudah melengkapi profil akun di www.magnum.id.</li>
              <li>Sehat jasmani dan rohani.</li>
              <li>
                Bersedia memenuhi seluruh ketentuan dalam Syarat & Ketentuan
                Program serta{" "}
                <a
                  href="https://www.pmiprivacy.com/id/consumer"
                  target="_blank"
                  className="text-text-1"
                >
                  Kebijakan Privasi
                </a>{" "}
                yang berlaku.
              </li>
              <li>
                Program ini berlaku di seluruh Indonesia sesuai dengan periode
                tertera.
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              B. Periode Program:
            </span>
            <ul className={styles.snk_tab}>
              <li>
                Program ini dimulai sejak 27 Maret dan berakhir pada 27 Mei 2024
                (“Periode Program”).
              </li>
              <li>
                Setiap Entri yang tidak diterima selama Periode Program akan
                secara otomatis didiskualifikasi dari partisipasi. Penyelenggara
                tidak bertanggung jawab atas entri yang hilang, tertunda, atau
                salah arah.
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              C. Mekanisme Program:
            </span>
            <ul className={styles.snk_tab}>
              <li>
                Peserta wajib terdaftar dan memiliki akun pada <em>website</em>{" "}
                www.magnum.id.
              </li>
              <li>
                Pendaftaran dapat dilakukan secara mandiri melalui{" "}
                <em>website</em> resmi magnum.id atau melakukan di lokasi acara
                atau <em>venue</em> yang bekerjasama dengan magnum.id.
              </li>
              <li>
                Peserta wajib mengikuti seluruh rangkaian aktivitas{" "}
                <strong>Hari Besar Musik Keras 2024 </strong> yang
                diselenggarakan melalui <em>website</em> magnum.id.
              </li>
              {isArtwork ? <li>Submisi Karya Besar</li> : null}
            </ul>
            {isArtwork ? (
              <ul className={styles.snk_tab2}>
                <li>
                  Ukuran Visual artwork yang dapat disubmit : 500x373 pixel
                  (Max. 1MB), format JPEG, JPG, PNG.
                </li>
                <li>
                  Tema karya visual :{" "}
                  <strong>&apos;LOUDER DISTORTION/DISTORSI KERAS&apos;</strong>.
                </li>
                <li>
                  Karya harus melampirkan elemen yang melingkupi
                  warna(Hitam,Putih, Silver).
                </li>
                <li>
                  Keaslian dan orisinalitas hasil karya (foto, vektor,
                  ilustrasi, desain, dan objek visual lainnya) dapat
                  dipertanggungjawabkan, bukan menjiplak hasil karya orang lain
                  yang dimodifikasi dan tidak memakai elemen yang melanggar hak
                  cipta serta etika pembuatan karya cipta. Jika terbukti terjadi
                  pelanggaran ketentuan, hasil karya akan dianulir atau/dan
                  dibatalkan.
                </li>
                <li>
                  Karya tidak memuat visual yang dapat menarik perhatian anak di
                  bawah umur (tokoh kartun, mainan, pendidikan, dsb), tidak
                  berhubungan dengan kesehatan, olahraga, agama, politik,
                  budaya/adat daerah, tidak menampilkan bentuk rokok
                  (pack/batang/asap) dan brand/logo/simbol
                  produk/organisasi/gerakan tertentu.
                </li>
              </ul>
            ) : null}
          </div>
          <div className={styles.snk_part}>
            <p
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              D. Hadiah Program:
            </p>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              Hadiah Utama:
            </span>
            <ul className={styles.snk_tab}>
              <li>
                6 (Enam) pemenang dari hasil <em>leaderboard vote artwork</em>{" "}
                tertinggi yang sudah terdaftar dan mengikuti program{" "}
                <em>Hari Besar Musik Keras</em> di Magnum.id masing-masing akan
                mendapatkan Galaxy Tab S9 setelah periode program dan hasil
                karya visual <em>artwork</em> yang telah disubmit ke magnum.id
                akan menjadi karya kolaborasi dengan{" "}
                <em>Hari Besar Musik Keras</em> dengan output: Merchandise,
                melingkupi T-shirt, Tote Bag, dan lain lain.
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              Hadiah Hiburan:
            </span>
            <ul className={styles.snk_tab}>
              <li>
                1500 (seribu lima ratus) pemenang yang sudah menyelesaikan
                aktivitas di magnum.id selama periode{" "}
                <em>
                  <strong>HARI BESAR MUSIK KERAS</strong>
                </em>{" "}
                berlangsung dapat melakukan penukaran poin untuk mendapatkan{" "}
                <em>exclusive merchandise</em> berupa;{" "}
                <em>Gitar Listrik/Amplifier/Headphones/Voucher/Tiket Konser</em>
                .
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              Ketentuan Pemenang:
            </span>
            <ul className={styles.snk_tab}>
              <li>
                Penentuan pemenang dilakukan melalui proses seleksi berdasarkan
                ketentuan yang telah tertera dalam Syarat & Ketentuan terlampir.
              </li>
              <li>
                Kandidat peserta terpilih akan diwajibkan mengikuti proses
                kurasi dan <em>interview</em> oleh Penyelenggara, sebagai proses
                penentuan peserta terpilih atau pemenang.
              </li>
              <li>Pengumuman hadiah utama dan hiburan:</li>
            </ul>
            <ul className={styles.snk_tab2}>
              <li>Hadiah Utama:</li>
            </ul>
            <ol type="1" className={styles.snk_tab3}>
              <li>
                6 (Enam) pemenang akan diumumkan setelah program
                berakhir/selesai pada bulan Juli 2024.
              </li>
            </ol>
            <ul className={styles.snk_tab2}>
              <li>Hadiah Hiburan:</li>
            </ul>
            <ol type="1" className={styles.snk_tab3}>
              <li>
                1500 (Seribu lima ratus) pemenang <em>exclusive merchandise</em>{" "}
                dari magnum.id akan diumumkan bertahap setiap bulan pada minggu
                pertama bulan berikutnya sampai dengan program berakhir di Juli
                2024.
              </li>
            </ol>
            <ul className={styles.snk_tab}>
              <li>
                Pendamping dari pemenang adalah perokok dewasa berusia minimal
                21 tahun, dan memiliki identitas berupa KTP yang masih berlaku
              </li>
              <li>
                Pemenang akan diumumkan melalui <em>website</em> dan e-mail
                konfirmasi resmi magnum.id dan akan dihubungi oleh
                Penyelenggara.
              </li>
              <li>
                Hadiah tidak dapat dipindahtangankan, tidak untuk
                diperjual-belikan, dan/atau tidak dapat ditukarkan dengan uang
                tunai atau dengan apapun ke Penyelenggara, baik secara
                keseluruhan atau sebagian.
              </li>
              <li>
                Peserta yang menjadi Pemenang dinyatakan didiskualifikasi
                apabila: (i) Peserta tidak dapat dihubungi oleh Penyelenggara
                untuk keperluan verifikasi dalam jangka waktu 2x24 jam sejak
                pengumuman pemenang, (ii) Peserta merupakan pemenang dari
                program yang sama pada periode pengumuman pertama, atau (iii)
                Pemenang tidak dapat menunjukkan kelengkapan dokumen pendukung
                pada saat proses verifikasi, seperti Kartu Tanda Penduduk (KTP),
                dan dokumen pendukung lainnya untuk memenuhi persyaratan
                administrasi yang berlaku sesuai jangka waktu yang diberikan
                oleh Penyelenggara. Sehubungan dengan hal ini, Peserta dengan
                ini setuju untuk melepaskan haknya sebagai Pemenang untuk
                menerima Hadiah dan akan membebaskan Penyelenggara dari klaim
                dan/atau tuntutan penyerahan Hadiah dalam bentuk apapun di masa
                mendatang.
              </li>
              <li>
                Penyelenggara berhak melakukan verifikasi dan/atau pengecekan
                validasi data Peserta serta: (i) mendiskualifikasi Peserta atau
                Pemenang; dan/atau (ii) menolak atau membatalkan pemberian
                Hadiah, apabila Peserta atau Pemenang diduga melakukan
                kecurangan atau pelanggaran Syarat dan Ketentuan ini dalam
                bentuk apapun.
              </li>
              <li>
                Keputusan Penyelenggara bersifat mutlak dan final sehingga tidak
                dapat diganggu gugat.
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              Ketentuan Umum:
            </span>
            <ul className={styles.snk_tab}>
              <li>
                Program ini tidak berlaku untuk semua karyawan, keluarga
                karyawan, dan/atau pihak ketiga yang terikat kerja sama dengan
                PT HM Sampoerna Tbk dan/atau afiliasinya.
              </li>
              <li>
                Penyelenggara berhak untuk mengubah tanggal, mekanisme, Hadiah
                dan/atau Syarat dan Ketentuan atas Program sepanjang konsep dan
                nilainya kurang lebih sama dengan yang diuraikan saat ini, tanpa
                pemberitahuan sebelumnya atau kompensasi dalam bentuk apapun
                kepada para Peserta dan/atau pihak manapun. Dalam hal terjadi
                peristiwa keadaan memaksa (<em>force majeure</em>) atau
                pertimbangan material lainnya, Penyelenggara dapat membatalkan
                penyelenggaraan Program tanpa kewajiban untuk memberikan
                pemberitahuan dan/atau kompensasi kepada pihak manapun.
              </li>
              <li>
                Penyelenggara tidak akan bertanggung jawab apabila terjadi
                penipuan dan/atau tindak pidana lainnya yang mengatasnamakan
                Program ini. Untuk itu, Peserta diminta untuk berhati-hati dan
                waspada terhadap modus penipuan dan/atau tindak pidana lainnya
                yang mungkin akan mengatasnamakan Program ini serta selalu
                memastikan berhubungan dengan pihak Penyelenggara yang sah
                terkait Program.
              </li>
              <li>
                Silakan hubungi atau periksa pihak Penyelenggara yang sah
                terkait Program pada <em>website</em> magnum.id atau email ke{" "}
                <span className="text-text-1">info@magnum.id</span>.
              </li>
              <li>
                Setiap Peserta yang mengikuti Program ini menjamin bahwa setiap
                informasi yang diberikan adalah benar, tepat dan akurat selama
                periode Program.
              </li>
              <li>
                Dengan mengikuti Program ini, Peserta setuju atas dan memberikan
                izin kepada PT HM Sampoerna Tbk. dan/atau afiliasinya untuk:
              </li>
            </ul>
            <ul className={styles.snk_tab2}>
              <li>
                Memperoleh, mengumpulkan, mengolah, menganalisis, menyimpan,
                menampilkan, mengumumkan, mengirimkan, mempublikasikan dan/atau
                menyebarkan data pribadi Peserta dan setiap Pemenang (antara
                lain namun tidak terbatas kepada, informasi nama, foto, nomor
                identitas, kartu keluarga, nomor telepon) untuk keperluan
                verifikasi
              </li>
              <li>
                Menghubungi Peserta secara langsung ataupun melalui Pihak Ketiga
                yang telah ditunjuk;
              </li>
              <li>
                Mengungkapkan informasi data pribadi Peserta kepada PT HM
                Sampoerna Tbk. dan/atau afiliasinya untuk melakukan hal-hal di
                atas serta kepada pihak yang berwenang (jika disyaratkan oleh
                hukum yang berlaku di Negara Republik Indonesia); dan/atau
              </li>
              <li>
                Seluruh Hak Cipta dan Hak atas Kekayaan Intelektual (HAKI) yang
                timbul dari pelaksanaan program ini, termasuk namun tidak
                terbatas kepada ide, hak cipta, foto, video, karakter, musik,
                rekaman suara, rancangan, film, dan bahan lainnya (“Hak Kekayaan
                Intelektual”) baik yang diambil oleh pihak Penyelenggara ataupun
                Pemenang untuk keperluan publikasi di dalam website resmi
                magnum.id dan/atau materi publikasi lainnya dari magnum.id, maka
                sepenuhnya akan menjadi hak milik PT HM Sampoerna Tbk dan/atau
                afiliasinya di semua yurisdiksi dan tanpa batas waktu.
              </li>
              <li>
                Hasil karya pemenang menjadi hak milik Magnum dan PT HM
                Sampoerna Tbk. Penyelenggara berhak mempublikasikan dan
                menggunakan hasil karya tersebut untuk diproduksi dan
                dipergunakan untuk tujuan komersil.
              </li>
              <li>
                Seluruh Hak Cipta dan Hak atas Kekayaan Intelektual (HAKI) yang
                timbul dari pelaksanaan program ini, termasuk namun tidak
                terbatas kepada ide, hak cipta, foto, video, karakter, musik,
                rekaman suara, rancangan, film, dan bahan lainnya (“Hak Kekayaan
                Intelektual”) baik yang diambil oleh pihak Penyelenggara ataupun
                Pemenang untuk keperluan publikasi di dalam website resmi
                magnum.id dan/atau materi publikasi lainnya dari magnum.id, maka
                sepenuhnya akan menjadi hak milik PT HM Sampoerna Tbk dan/atau
                afiliasinya di semua yurisdiksi dan tanpa batas waktu.
              </li>
              <li>
                Membuat dokumentasi dalam format apapun (foto atau video)
                rekaman video yang berisi antara lain, video, nama, dan komentar
                dari Peserta atau Pemenang untuk keperluan publikasi di dalam{" "}
                <em>website</em> magnum.id atau materi publikasi lainnya dari
                Magnum dan PT HM Sampoerna Tbk.
              </li>
            </ul>
          </div>
        </div>
        {!isArtwork && (
          <Link href={`/${back}/br`} passHref>
            <ButtonAction
              intent="primary"
              className={styles.snk_ctaBtn}
              fullwidth
            >
              Kembali
            </ButtonAction>
          </Link>
        )}
      </div>
    </section>
  )
}

CampaignSnkMagnum.propTypes = {
  isArtwork: PropTypes.bool,
}
