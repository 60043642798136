"use client"
import React, { useEffect, useState } from "react"
import { ClientApiList } from "@/lib/api/client-service"
import crypto from "crypto"
import { ToastComponent } from "../toast"
import { usePathname, useSearchParams } from "next/navigation"
import { useDatalayer } from "@/hooks/use-datalayer"
import UseQueryParams from "@/hooks/use-query-params"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { Spinner } from "../shared/spinner"
import { useLogout } from "@/hooks/auth/use-logout"
import { useReqUrl } from "@/hooks/auth/use-req-url"
import { ClientStorage } from "ClientStorage"

export const encryptGame = (data) => {
  let dechiper = crypto.createCipheriv(
    "aes-256-ctr",
    process.env.NEXT_PUBLIC_GAME_KEY,
    process.env.NEXT_PUBLIC_GAME_IV
  )
  let dec = dechiper.update(data, "utf8", "hex")
  dec += dechiper.final("hex")
  return dec
}

export const decryptData = (data) => {
  let dechiper = crypto.createDecipheriv(
    "aes-256-ctr",
    process.env.NEXT_PUBLIC_GAME_KEY,
    process.env.NEXT_PUBLIC_GAME_IV
  )
  let dec = dechiper.update(data, "hex", "utf8")
  dec += dechiper.final("utf8")
  return JSON.parse(dec)
}

export const IframeOfflinePhoto = () => {
  const localStorage = new ClientStorage("localStorage")
  const QUERYPARAM = "point"
  const { handlePushQuery } = UseQueryParams()
  const datalayer = useDatalayer()
  const [point, setPoint] = useState()
  const [isSuccess, setIsSuccess] = useState(false)
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const [url, setUrl] = useState(true)
  const { isLogin, isLoading } = useIsLogin()
  const [isLoadingAuth, setIsLoadingAuth] = useState(true)
  const [engagementType, setEngagemenType] = useState()
  const [isShowSpinner, setIsShowSpinner] = useState(false)

  const { execute: login } = useReqUrl({
    redirectUrl: `${pathname}?engagement=${engagementType}`,
  })

  const { execute: logout } = useLogout(pathname)

  function handlePoint(point) {
    if (point !== 0) {
      setPoint(point)
      handlePushQuery(false, [QUERYPARAM, true])
      setIsSuccess(true)
    }
  }

  async function handleSubmit(type) {
    let detailData
    if (type === "photo") detailData = "Photobooth"
    else detailData = "Timeless Mix"
    const { data: dataReturn } = await ClientApiList.postTrack({
      source: "AG",
      type: "Amild Mini Games",
      detail: detailData,
    })
    handlePoint(dataReturn.data.data.point)
    setIsShowSpinner(false)
  }

  function handleDataLayer(data) {
    datalayer.push(data)
  }

  async function handleEvent(url) {
    if (!searchParams.has("engagement")) {
      setUrl(url)
    } else {
      const {
        data: { data: auth_data },
      } = await ClientApiList.getAuthData({
        // ga: getGALinker(),
        // gl: getGL(),
        platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
        redirect_url: `${process.env.NEXT_PUBLIC_META_URL}/br`,
      })
      let newuUrl = url + `?auth-data=${auth_data.data.auth_data}`
      setUrl(newuUrl)
    }
    setIsLoadingAuth(false)
  }

  async function eventHandler(event) {
    // console.log(event)
    const data = JSON.parse(atob(event.data))
    // console.log(data, "data event", data.action, data.action === "logout")
    if (data.action == "datalayer") handleDataLayer(data.value[0])
    else if (data.action === "setupTotem") {
      localStorage.set("isSetTotem", data.totem, 432000)
    } else if (data.action === "loginregister") {
      setIsShowSpinner(true)
      setEngagemenType(data.engagement_type)
    } else if (data.action === "engagementdone") {
      setIsShowSpinner(true)
      handleSubmit(data.engagement_type)
    } else if (data.action === "logout") {
      setIsShowSpinner(true)
      logout()
    }
    // else console.log(data)
  }

  const handlePath = () => {
    if (searchParams.get("engagement") === "photo") return "photo"
    else if (searchParams.get("engagement") === "timeless") return "timeless"
    else return Boolean(localStorage.get("isSetTotem")) == true ? "home" : ""
  }

  const events = (e) => {
    if (
      process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
        ? e.origin !== "https://timeless-photo.antigravity.dev"
        : e.origin !== "https://timeless-photo.stg.antigravity.dev"
    )
      return
    eventHandler(e)
  }

  useEffect(() => {
    if (engagementType && !isLogin && !isLoading) {
      login()
    } else if (engagementType && isLogin && !isLoading) {
      window.location.replace(`${pathname}?engagement=${engagementType}`)
    }
    // return () => setIsShowSpinner(false)
  }, [engagementType])

  useEffect(() => {
    let url =
      process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
        ? `https://timeless-photo.antigravity.dev/${handlePath()}`
        : `https://timeless-photo.stg.antigravity.dev/${handlePath()}`

    handleEvent(url)

    window.addEventListener("message", events, false)

    return () => {
      window.removeEventListener("message", events, false)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLoading, isLogin])

  const titleBrand =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? `Kamu berhasil raih ${point} poin`
      : `Lo berhasil dapetin ${point} poin!`

  if (isLoadingAuth)
    return (
      <div className="h-[calc(100vh-56px)] flex items-center">
        <Spinner />
      </div>
    )

  return (
    <div className="w-[100%] h-[100%] h-[calc(100dvh-56px)] min-w-[100vw]">
      <ToastComponent
        onClick={() => {
          setIsSuccess(false)
        }}
        title={titleBrand}
        desc="Lanjut eksplor dan kumpulkan lebih banyak poin!"
        isOpen={isSuccess}
      />
      {isShowSpinner ? (
        <div
          className={`fixed top-[0] backdrop-blur-[5px] bg-black/[.75] bottom-0 right-0 left-0 z-[19] flex items-center justify-center`}
          // onClick={closeHandle}
        >
          {" "}
          <Spinner />
        </div>
      ) : null}
      <iframe
        className="block w-[100dvw] h-[calc(100dvh-56px)]"
        seamless="seamless"
        title="game-view"
        allow="camera; microphone"
        src={url}
        frameBorder="0"
      ></iframe>
    </div>
  )
}
