
// import { useUserId } from '@/hooks/user/use-user-id';
// import { ClientApi } from '@/lib/amild-api';
import { pushDataLayer } from '@/helper/datalayer/data-layer';
import { ClientApiList } from '@/lib/api/client-service';
import { useCallback, useState } from 'react';
import { useIsLogin } from './auth/use-is-login';

const defaultOptions = {
	noInteractionUser: false,
	noDatalayerCampaign: false,
	interactionUserCallback: () => { },
};

export const useDatalayer = (options = defaultOptions) => {
	const { noInteractionUser, noDatalayerCampaign, interactionUserCallback } =
		options;

	const { isLogin, isLoading } = useIsLogin();
	const [profile, setProfile] = useState({});

	const push = useCallback(
		async (datalayer) => {
			if (isLogin && !isLoading) {
				const { data: dataProfile } = await ClientApiList.getProfileData();
				setProfile(dataProfile);
			}

			const dl = JSON.parse(JSON.stringify(datalayer));
			if (noDatalayerCampaign) {
				delete dl.campaign_name;
				delete dl.event_label;
				delete dl.content_id;
			} else {
				delete dl.event_label;
				delete dl.content_id;
			}

			pushDataLayer(dl);

			if (!isLoading && !noInteractionUser) {
				const { campaign_name, event_name, feature, content_progress, content_type, button_name, page_type, page_path, event_label, content_id } = datalayer;
				const href = typeof window !== 'undefined' ? window.location.href : "";
				const { data, error, status } = await ClientApiList.getInteractionUser({
					user_id: !isLoading && isLogin && profile?.data.data.gigya_id,
					campaign: campaign_name,
					event_category: event_name,
					event_action: feature || content_progress,
					event_label: event_label || content_type || button_name || page_type,
					creative: page_path || href,
					content_id: content_id
				});
				interactionUserCallback?.({ data, error, status });
			}
		},
		// [interactionUserCallback, noDatalayerCampaign, noInteractionUser]
		[interactionUserCallback, noDatalayerCampaign, noInteractionUser]
	);

	return {
		push,
	};
};

export const getPublishDate = (item) => {
	let date = new Date(item);
	const day = date.getDate();
	const month = date.getMonth() + 1;
	return `${day < 10 ? 0 : ""}${day}-${month < 10 ? 0 : ""}${month}-${date.getFullYear()}`;
};