"use client"
import React, { Suspense, useEffect, useRef, useState } from "react"
import { motion } from "framer-motion"
import { useForm } from "react-hook-form"

import BackBanner from "../shared/back-banner"
import CampaignCard from "../campaign-intro/campaign-card/campaign-card"
import TextArea from "../edit-profile/text-area"
import ButtonAction from "../shared/button-action/button-action"
import { useIsDesktop } from "@/hooks/use-is-mobile"
import { PopupSubmission } from "../submission/popup-submission/popup-submission"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { ClientApiList } from "@/lib/api/client-service"
import ReCAPTCHA from "react-google-recaptcha"
import { useDatalayer } from "@/hooks/use-datalayer"
import ButtonBack from "../shared/button-back/button-back"
import { getArticlePathname } from "@/hooks/use-pathname"
import UseQueryParams from "@/hooks/use-query-params";
import { urlToCampaign } from "@/helper/utils";

const variants = {
  show: {
    opacity: 1,
    display: "block",
  },
  hide: {
    opacity: 0,
    display: "none",
  },
  transition: {
    ease: "easeInOut",
    duration: 0.3,
  },
}

function SubmissionFallback() {
  return <div>SubmissionFallback</div>
}

function SuspendedSubmission({ data, engagement_uuid }) {
  const QUERYPARAM = {point:"point", submissionPopup:'show-submission-popup'}
  const { handlePushQuery } = UseQueryParams()
  const captchaRef = useRef()
  const [isError, setIsError] = useState(false)
  const datalayer = useDatalayer()
  const {  replace } = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const isDesktop = useIsDesktop()
  const [currentLength, setCurrentLength] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [answers, setAnswers] = useState([])
  const { register, handleSubmit,  reset, resetField } = useForm()
  const maxChar = 500
  const [isOpen, setIsOpen] = useState(false)
  const [popupData, setPopupData] = useState(false)
  const [hideRule, setHideRule] = useState(false)
  const [point, setPoint] = useState(0)
  const [nextUrl, setNextUrl] = useState()
  const [isSuccess, setIsSuccess] = useState(false)
  const [campaign, setCampaign] = useState("")
  const [dataText, setDataText] = useState({
    ...data,
    questions: ["pertanyaan 1"],
  })
  const isMultipleChoices = dataText?.submission?.amount_of_question > 1
  const length = dataText?.submission?.amount_of_question

  const getDetail = async () => {
    const { data } = await ClientApiList.getEngagementDetail({
      type: "text-submission",
      engagement_uuid: engagement_uuid,
    })
    setDataText({
      ...data?.data.data,
      questions: data?.data.data.submission.engagement_submission_questions,
    })
    // set answers length to same as questions
    setAnswers(data?.data.data.submission.engagement_submission_questions.map(question => ('')))
  }
  useEffect(() => {
    getDetail()
    // eslint-disable-next-line
  }, [])

  const handleGetConfirmation = async () => {
    try {
      const { data } = await ClientApiList.getEngagementSubmissionConfirmation({
        engagement_uuid,
        type: "text-submission",
        status: "completed",
      })
      setPopupData({ ...data.data.data })
    } catch (err) {
      console.log("[Error]", err)
    }
  }

  const onSubmit = async () => {
    captchaRef.current.reset()
    const token = await captchaRef.current.executeAsync()

    if (!token) {
      setIsError(true)
      replace(pathname)
      return
    } else {
      setIsError(false)
      setNextUrl()
      await handleGetConfirmation()
      datalayer.push({
        event: "general_event",
        event_name: "click_kirim_jawaban_text_submission",
        feature: "simple_engagement",
        engagement_type: "submission",
        engagement_name: "text",
        event_label: `text submission - ${dataText.engagement_title.toLowerCase()}`,
        level: 1,
        campaign_name: campaign.toLowerCase(), // e.g all: ;
        content_id: engagement_uuid,
      })

      try {
        const { data } = await ClientApiList.getEngagementSubmissionAnswer({
          engagement_uuid,
          type: "text",
          submission: answers,
          "g-recaptcha-response": token,
        })

        if (searchParams.get("page_uuid")) {
          const { data:nextUrlData } = await ClientApiList.getNextChallenge({
            page_uuid: searchParams.get("page_uuid"),
            mission_order: parseInt(searchParams.get("index")),
          })

          if (
            Object.keys(nextUrlData.data.data).length === 0 &&
            nextUrlData.data.data.constructor === Object
          ) {
            setNextUrl(searchParams.get("campaign"))
          } else {
            const nextMissionUrl = getArticlePathname(nextUrlData.data.data)
            setNextUrl(
              `${nextMissionUrl}?page_uuid=${searchParams.get("page_uuid")}&index=${parseInt(searchParams.get("index")) + 1}&campaign=${searchParams.get("campaign")}${data?.data?.result?.data?.point ? `&${QUERYPARAM.point}=true` : ""}`
            )
          }
        }
        setPoint(data.data.result.data.point)
        setTimeout(
          () => (data.data.result.data.point &&data.data.result.data.point !== 0 ? setIsSuccess(true) : null),
          500
        )

        setTimeout(() => {
          setCurrentLength(0)
          resetFormValue()
        }, 1000)

        handlePushQuery(false, [QUERYPARAM.point, true], [QUERYPARAM.submissionPopup, true])
      } catch (err) {
        console.log("[Erro]", err)
        return
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      datalayer.push({
        event: "general_event",
        event_name: "page_reached_success_submit_text",
        feature: "simple engagement",
        engagement_type: "submission",
        engagement_name: "text",
        event_label: `text submission - ${dataText?.engagement_title.toLowerCase()}`,
        level: 1,
        campaign_name: campaign.toLowerCase(), // e.g all: ;
        character_result: "jawaban lo berhasil dikirim",
        content_id: engagement_uuid,
      })
    }
  }, [isOpen])

  useEffect(() => {
    setHideRule(searchParams.get("hide-sub-rule") === "true")
    setIsOpen(searchParams.get("show-submission-popup") === "true")
    setCampaign(searchParams.get("campaign") || urlToCampaign(pathname))
  }, [searchParams])

  const onKeyUp = (e) => {
    setCurrentLength(e.target.value.length)
  }

  const onChange = (e) => {
    const mappedAnswer = [...answers]
    mappedAnswer[currentIndex] = e.target.value
    setAnswers(mappedAnswer)
  }

  const resetFormValue = async () => {
    reset()
  }

  const handleReset = () => {
    resetField("submission")
    if (answers[currentIndex]) setCurrentLength(answers[currentIndex].length)
  }

  useEffect(() => {
    handleReset()
  }, [currentIndex])

  const handlePrevQuestion = () => {
    if (currentIndex - 1 >= 0) {
      setCurrentLength(answers[currentIndex - 1])
      setCurrentIndex((prev) => prev - 1)
    }
  }

  const handleNextQuestion = () => {
    if (currentIndex + 1 < dataText?.questions.length) {
      setCurrentLength(answers[currentIndex + 1] || 0)
      setCurrentIndex((prev) => prev + 1)
    }
  }

  return (
    <>
      <PopupSubmission
        nextUrl={nextUrl}
        popupData={popupData}
        point={point}
        isOpen={isOpen}
      />
      <motion.div
        key="animation-on-state"
        variants={variants}
        animate={hideRule ? "show" : "hide"}
        className="relative px-[10px]"
      >
        <ButtonBack onClick={() => replace(pathname)} />
        <BackBanner
          className="lg:top-[15px] lg:left-[15px] lg:right-[15px] oveflow-hidden bg-bg-5"
          bgImg={
            isDesktop
              ? dataText?.submission?.desktop_img_url_file
              : dataText?.submission?.mobile_img_url_file
          }
        />
        <div className="pt-[216px] md:pt-[280px] min-h-[738px]">
          <form method="POST" onSubmit={handleSubmit(onSubmit)}>
            {isError ? (
              <div className="bg-cta-3 p-[10px] mb-[10px] rounded-[10px] my-[5px] flex gap-[5px] items-center text-[12px] text-text-1 font-medium font-font-family-6 tracking-[0.25px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11ZM5.25 8.25C5.25 7.83579 5.58579 7.5 6 7.5C6.41421 7.5 6.75 7.83579 6.75 8.25C6.75 8.66421 6.41421 9 6 9C5.58579 9 5.25 8.66421 5.25 8.25ZM5.50806 3.41012C5.55039 3.17688 5.75454 3 6 3C6.24546 3 6.44961 3.17688 6.49194 3.41012L6.5 3.5V6L6.49194 6.08988C6.44961 6.32312 6.24546 6.5 6 6.5C5.75454 6.5 5.55039 6.32312 5.50806 6.08988L5.5 6V3.5L5.50806 3.41012Z"
                    fill="#FD2A29"
                  />
                </svg>
                <p>{`Captcha harus diisi`}</p>
              </div>
            ) : null}
            <CampaignCard className="relative bg-bg-3 md:max-w-[461px] mx-auto md:rounded-[10px] md:p-[15px] !p-[15px]">
              {isMultipleChoices ? (
                <div className="p-[10px] bg-[#ED1C24]/[0.1] bg-cta-3 rounded-[5px] w-fit mb-[10px] text-[14px] leading-[20px] md:leading-[10px] tracking-[0.25px] font-medium font-font-family-6">
                  <div className="text-text-2 font-bold font-font-family-7 inline-block font-bold">
                    <span className="text-text-1">{answers.filter((answer) => answer !== "").length}</span>/
                    {length}
                  </div>
                  <span> Pertanyaan Terjawab</span>
                </div>
              ) : null}
              <div className="text-[20px] leading-[27px] font-bold font-font-family-7  mb-[10px]">
                {dataText?.questions[currentIndex].question}
              </div>
              <div className="text-[16px] leading-[20px] font-medium font-font-family-6 mb-[20px]">
                {dataText?.questions[currentIndex].short_desc}
              </div>
              <div className="relative mb-[20px] rounded-[5px]">
                <TextArea
                  maxLength={maxChar}
                  validation={{
                    maxLength: maxChar,
                    required: true,
                    onChange: (e) => onChange(e),
                  }}
                  onKeyUp={onKeyUp}
                  register={register}
                  name="submission"
                  placeholder="Tulis jawabanmu disini..."
                  className=" border-[1px] px-[9px] py-[9px] bg-bg-3 w-full h-full min-h-[224px]"
                  value={answers[currentIndex]}
                />
                <div className="absolute pr-[9px] right-[10px] min-h-[20px] bottom-[15px] origin-bottom text-[12px] leading-[20px] text-right text-[#CCC]">
                  ({currentLength}/{maxChar} karakter)
                </div>
              </div>
              <div className="flex items-center gap-[5px]">
                <ButtonAction
                  disabled={
                    answers.filter((answer) => answer === "").length > 0 &&
                    answers.length ===
                      dataText?.submission?.amount_of_question
                  }
                  type="submit"
                  value="Submit"
                  intent={`${answers.filter((answer) => answer === "").length > 0 ? "primary_disable" : "primary"}`}
                >
                  Kirim
                </ButtonAction>
                {isMultipleChoices ? (
                  <>
                    <ButtonAction
                      onClick={handlePrevQuestion}
                      intent={
                        currentIndex === 0 ? "primary_disable" : "secondary"
                      }
                      disabled={currentIndex === 0}
                      className="w-fit min-w-[54px] min-h-[50px]"
                    >
                      <div className="flex items-center justify-center w-fit min-w-[30px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          className="rotate-180"
                        >
                          <path
                            d="M9 5.54785L16 12.5479L9 19.5479"
                            // stroke="#ED1C24"
                            className={`${currentIndex === 0 ? "stroke-icon-3" : "stroke-icon-1"}`}
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </ButtonAction>
                    <ButtonAction
                      onClick={handleNextQuestion}
                      intent={
                        currentIndex === dataText?.questions.length - 1
                          ? "primary_disable"
                          : "secondary"
                      }
                      disabled={currentIndex === dataText?.questions.length - 1}
                      className="w-fit min-w-[54px] min-h-[50px]"
                    >
                      <div className="flex items-center justify-center w-fit min-w-[30px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M9 5.54785L16 12.5479L9 19.5479"
                            className={`${currentIndex === dataText?.questions.length - 1 ? "stroke-icon-3" : "stroke-icon-1"}`}
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </ButtonAction>
                  </>
                ) : null}
              </div>
            </CampaignCard>
            <ReCAPTCHA
              ref={captchaRef}
              size="invisible"
              sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA}
            />
          </form>
        </div>
      </motion.div>
    </>
  )
}

export function Submission(props) {
  return (
    <Suspense fallback={<SubmissionFallback />}>
      <SuspendedSubmission {...props} />
    </Suspense>
  )
}
