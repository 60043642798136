"use client"
import React, { Suspense } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import CategoryCard from "../../shared/category-card/category-card"
import PropTypes from "prop-types"

function EventCategoriesFallback() {
  return <div>Loading...</div>
}

function SuspendedEventCategories({ selectedCat, categories, setIsLoading }) {
  return (
    <div className="relative w-[100%] h-[42px] mb-[15px] flex gap-[5px] md:mb-[30px] max-w-[650px] mx-auto">
      <div className="absolute left-[-15px] md:left-0 right-[-10px]">
        <Swiper slidesPerView={"auto"} spaceBetween={5}>
          {categories.map((category, index) => {
            return (
              <SwiperSlide
                key={category.id}
                className={`!w-fit ${index === 0 ? "!ml-[15px] md:!ml-0" : ""}`}
              >
                <CategoryCard
                  size="medium"
                  categoryName="event"
                  intent={
                    selectedCat === category.label
                      ? "active"
                      : process.env.NEXT_PUBLIC_NAME === "sampoernakretek"
                        ? "categoriesTransparent"
                        : "categories"
                  }
                  value={category.label}
                  className={
                    selectedCat === category.label
                      ? "pointer-events-none cursor-not-allowed"
                      : ""
                  }
                  aria-disabled={selectedCat === category.label}
                  tabIndex={selectedCat === category.label ? -1 : undefined}
                  setIsLoading={setIsLoading}
                >
                  {category.label}
                </CategoryCard>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default function EventCategories(props) {
  return (
    <Suspense fallback={EventCategoriesFallback}>
      <SuspendedEventCategories {...props} />
    </Suspense>
  )
}

EventCategories.propTypes = {
  categories: PropTypes.array,
  setIsLoading: PropTypes.bool,
}
