/* eslint-disable react-hooks/exhaustive-deps */
"use client"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import PopupAction from "../shared/popup-action/popup-action"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { Image } from "../shared/image/image"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { Description } from "../shared/description/description"
import Link from "next/link"
import ButtonAction from "../shared/button-action/button-action"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { ClientStorage } from "ClientStorage"
import { ClientApiList } from "@/lib/api/client-service"
import clsx from "clsx"
import { Spinner } from "../shared/spinner"

export default function GeneralPopup({ list = [] }) {
  const clientStorage = new ClientStorage("localStorage")
  const onBoardCookie = clientStorage.get("onBoarding")
  const brandPrefCookie = clientStorage.get("brand-preference")
  const { isLogin, isLoading } = useIsLogin()
  const isMobile = useIsMobile()
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const [isOnBoardingShowed, setIsOnBoardingShowed] = useState(false)
  const [isBrandPrefShowed, setIsBrandPrefShowed] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [listPopup, setListPopup] = useState([])
  const [currentShow, setCurrentShow] = useState(0)
  const [isLoadingPopup, setIsLoadingPopup] = useState(true)

  useEffect(() => {
    if (!isLoading && isLogin && !pathname.includes("/allaccess-loading")) {
      checkOnBoardingAndBrandPref()
    }
  }, [isLoading, isLogin, pathname, searchParams])

  // popup general will show after popup brand-preference and on boarding
  const checkOnBoardingAndBrandPref = async () => {
    const { data } = await ClientApiList.getOnBoarding()

    const onBoarding =
      onBoardCookie ||
      searchParams?.get("onBoarding") === "false" ||
      data?.data?.data?.displayed

    const brandPref =
      brandPrefCookie ||
      searchParams?.get("brandPreferences") === "false" ||
      data?.data?.data?.displayed_brandPreferences

    setIsOnBoardingShowed(onBoarding)
    setIsBrandPrefShowed(brandPref)
  }

  useEffect(() => {
    if (!list.length) return

    if (
      !isLoading &&
      isLogin &&
      !pathname.includes("/allaccess-loading") &&
      isOnBoardingShowed &&
      isBrandPrefShowed
    ) {
      setIsLoadingPopup(true)
      handleListPopup()
    }
  }, [isLoading, isLogin, pathname, isOnBoardingShowed, isBrandPrefShowed])

  // get popup list on current page
  const handleListPopup = async () => {
    let promises = []
    let listIndex = []
    const generalParse = clientStorage.get("popupGeneral") || []

    list?.forEach((popup, index) => {
      // popup that had showed, will not show again until logout
      const popupExist = generalParse?.findIndex(
        (val) => Number(val) === Number(popup?.id)
      )
      const correctPage = popup?.url_page === pathname

      if (popupExist < 0 && correctPage) {
        promises.push(ClientApiList.getPopupGeneral({ id: popup?.id }))
        listIndex.push(index)
      }
    })

    Promise.all(promises)
      .then((values) => {
        let datas = []
        values.forEach(({ data }, index) => {
          const displayed = data?.data?.data?.displayed
          if (!displayed) {
            datas.push(list[listIndex[index]])
          }
        })
        setListPopup(datas)
      })
      .then(() => showPopup())
  }

  const handlePopupStatus = async () => {
    const popupData = listPopup[currentShow]

    if (popupData?.frequency === 3) {
      const payload = {
        id: popupData?.id,
        clicked_cta: true,
      }
      await ClientApiList.getPopupGeneral(payload)
    }

    setLocalStorage()
    setIsShowing(false)
    router.push(popupData?.href)

    // if page changes, start again from 0
    // but if href not move to other page, show next popup if any
    const nextIndex =
      currentShow !== listPopup.length - 1 ? currentShow : currentShow + 1
    setCurrentShow(pathname !== popupData?.href ? 0 : nextIndex)
  }

  const handleClosePopup = () => {
    setLocalStorage()
    setIsShowing(false)
    if (currentShow !== listPopup.length - 1) {
      setCurrentShow(currentShow + 1)
      setIsLoadingPopup(true)
      showPopup()
    }
  }

  const setLocalStorage = () => {
    const popupData = listPopup[currentShow]
    const generalParse = clientStorage.get("popupGeneral") || []
    const newData = [...generalParse, popupData?.id]
    localStorage.setItem("popupGeneral", JSON.stringify(newData))
  }

  const showPopup = () => {
    setTimeout(() => {
      setIsLoadingPopup(false)
      setIsShowing(true)
    }, 1000)
  }

  return (
    isLogin &&
    listPopup.length && (
      <PopupAction
        zIndex="z-20"
        isShowing={isShowing || isLoadingPopup}
        onClickBtnX={handleClosePopup}
        showCloseBtn={!isLoadingPopup}
        isFullImage={isLoadingPopup}
      >
        {!isLoadingPopup ? (
          <>
            <div className="h-full relative aspect-[304/177]">
              <Image
                alt="banner"
                src={
                  isMobile
                    ? listPopup[currentShow]?.img_mobile
                    : listPopup[currentShow]?.img_desktop
                }
              />
            </div>
            <div className="mx-auto my-[20px] w-[304px]">
              <div className="text-xl font-black font-font-family-8 mb-[5px] text-text-2">
                {listPopup[currentShow]?.title}
              </div>
              <Description
                className="text-sm font-medium font-font-family-6 text-text-2"
                description={listPopup[currentShow]?.description}
              />
            </div>
            <div
              className={clsx(
                "flex items-center justify-between gap-[10px]",
                listPopup[currentShow]?.cta_type === "vertical" && "flex-col"
              )}
            >
              {listPopup[currentShow]?.cta_label_close !== "null" && (
                <Link
                  className={clsx(
                    "block w-[100%]",
                    listPopup[currentShow]?.cta_type === "vertical" && "order-2"
                  )}
                  href={"#"}
                  onClick={handleClosePopup}
                >
                  <ButtonAction
                    className="py-[15px] px-[20px] leading-[20px]"
                    intent="secondary"
                  >
                    {listPopup[currentShow]?.cta_label_close}
                  </ButtonAction>
                </Link>
              )}
              <div className="block w-[100%]">
                <ButtonAction
                  className="py-[15px] px-[20px] leading-[20px]"
                  intent="primary"
                  onClick={() => {
                    handlePopupStatus()
                  }}
                >
                  {listPopup[currentShow]?.cta_label}
                </ButtonAction>
              </div>
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </PopupAction>
    )
  )
}

GeneralPopup.propTypes = {
  list: PropTypes.array,
}
