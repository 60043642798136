"use client";
import React, { forwardRef } from "react";
import { Swiper } from "swiper/react";
import { cn } from "@/lib/utils";
import { twMerge } from "tailwind-merge";
import { Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";

export const NextButton = ({ swiperRef, handleNext, className }) => {
  return (
    <button
      onClick={() => swiperRef.current?.slideNext()}
      className={twMerge(
        "absolute right-2 lg:right-[8px] top-1/2 -translate-y-1/2 z-10",
        className
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect width={24} height={24} rx={10} fill="white" fillOpacity="0.2" />
        <path
          d="M10.276 19.6093L17.8853 12L10.276 4.39062L8.39062 6.27596L14.1146 12L8.39062 17.724L10.276 19.6093Z"
          fill="#FCFBFE"
        />
      </svg>
    </button>
  );
};

export const PrevButton = ({ swiperRef, handlePrev, className }) => {
  return (
    <button
      onClick={() => swiperRef.current?.slidePrev()}
      className={twMerge(
        "absolute left-2 lg:left-[8px] top-1/2 -translate-y-1/2 z-10",
        className
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect
          width={24}
          height={24}
          rx={10}
          transform="matrix(-1 0 0 1 24 0)"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          d="M13.724 19.6093L6.11471 12L13.724 4.39062L15.6094 6.27596L9.88537 12L15.6094 17.724L13.724 19.6093Z"
          fill="#FCFBFE"
        />
      </svg>
    </button>
  );
};

export const Dots = ({ items, activeIndex }) => {
  return (
    <div className="bottom-4 left-1/2 -translate-x-1/2 absolute z-10 flex gap-2">
      {items.map((_, index) => (
        <div
          key={index}
          className={cn(
            "w-[5px] h-[5px] bg-bg-3 rounded-full",
            index === activeIndex && "w-[20px]"
          )}
        />
      ))}
    </div>
  );
};

export const SwiperComp = forwardRef(function SwiperPreset(props, ref) {
  const {
    setActiveIndex,
    children,
    slidesPerView = 1,
    loop = false,
    roundLengths = false,
    loopAdditionalSlides = 0,
    spaceBetween = 0,
    centeredSlides = false,
    onSlideChange,
    speed = 300,
    className,
    delay = 1000,
    autoplay = "Disable",
  } = props;
  return (
    <Swiper
      modules={[Autoplay]}
      autoplay={
        autoplay !== "Disable"
          ? {
              delay,
              disableOnInteraction: true,
            }
          : false
      }
      speed={autoplay === "Slow" ? 10000 : autoplay === "Medium" ? 5000 : speed}
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      loop={loop}
      loopAdditionalSlides={loopAdditionalSlides}
      centeredSlides={centeredSlides}
      roundLengths={roundLengths}
      onSwiper={(swiper) => {
        ref.current = swiper;
        if (typeof setActiveIndex === "function")
          setActiveIndex(swiper.activeIndex);
      }}
      className={className}
      onSlideChange={(swiper) => {
        if (typeof setActiveIndex === "function")
          setActiveIndex(swiper.activeIndex);
      }}
      ref={ref}
    >
      {children}
    </Swiper>
  );
});
