"use client";
import React from "react";
import { twMerge } from "tailwind-merge";
import { Image } from "@/components/shared/image/image";
import PropTypes from 'prop-types';

export default function BackBanner({ bgImg, className, children, imgClass, object }) {
  return (
    <div
      data-testid="back-banner"
      className={twMerge(
        "absolute bg-no-repeat rounded-b-[10px] lg:rounded-[15px] overflow-hidden top-[0px] left-0 right-0 h-[738px] md:h-[100%] lg:max-h-[520px] bg-transparent z-1",
        className
      )}
    >
      <Image
        data-testid="bg-img"
        alt="cover"
        className={twMerge("object-bottom", imgClass)}
        src={bgImg}
        objectFit={object}
      />
      {children}
    </div>
  );
}

BackBanner.propTypes = {
  bgImg: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.object,
  imgClass: PropTypes.string
};
