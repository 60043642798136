/* eslint-disable react-hooks/exhaustive-deps */
"use client"
import ButtonAction from "@/components/shared/button-action/button-action"
import PopupAction from "@/components/shared/popup-action/popup-action"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { useState, useEffect, useRef, useReducer } from "react"
import { Description } from "../shared/description/description"
import PropTypes from "prop-types"
import { ClientApiList } from "@/lib/api/client-service"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { useIsDesktop, useIsMobile } from "@/hooks/use-is-mobile"
import { AnimatePresence, motion } from "framer-motion"
import clsx from "clsx"
import { Chevron } from "./bantuan"
import { ToastComponent } from "../toast"
import { useDatalayer } from "@/hooks/use-datalayer"
import { Spinner } from "../shared/spinner"
import { cn } from "@/lib/utils"
import Style from "./brand-popup.module.css"
import { ClientStorage } from "ClientStorage"
import CheckRadio from "../brand-preference/check-radio"
import UserOrigin from "../brand-preference/user-origin"

export function BrandPreference({ list = {} }) {
  const isDesktop = useIsDesktop()
  const localStorage = new ClientStorage("localStorage")
  const [isShowing, setIsShowing] = useState(false)
  const [point, setPoint] = useState(0)
  const [send, setSend] = useState(false)
  const [choices, setChoices] = useState([])
  const [other, setOther] = useState("")
  const [control, setControl] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {}
  )

  const [choicesLabel, setChoiceLabel] = useState([])

  const handleKey = () => {
    setChoiceLabel([...list[0].list_type])
    const map = list[0].list_type.map((item) => ({
      isOpen: false,
      choice: "",
      label: item,
    }))
    setControl(map.reduce((a, v) => ({ ...a, [v.label]: { ...v } }), {}))
  }

  useEffect(() => handleKey(), [])

  const pathname = usePathname()
  const isMobile = useIsMobile()
  const datalayer = useDatalayer()
  const searchParams = useSearchParams()
  const { replace } = useRouter()
  const { isLogin, isLoading } = useIsLogin()
  const [isShowError, setIsShowError] = useState(false)
  const containerRef = useRef(null)
  const [isQuitSmoking, setIsQuitSmoking] = useState(false)
  const [brandPrefStat, setBrandPrefStat] = useState(true)
  const [origins, setOrigins] = useState()

  const onChange = (label) => {
    const foundIndex = choices.findIndex((choice) => choice === label)
    if (foundIndex === -1) {
      if (label.includes("berhenti")) {
        setIsQuitSmoking(true)
        setChoices([label])
      } else {
        setChoices(
          [...choices, label].sort(
            (a, b) => choicesLabel.indexOf(a) - choicesLabel.indexOf(b)
          )
        )
      }
      setIsShowError(false)
    } else {
      if (label.includes("berhenti")) {
        setIsQuitSmoking(false)
      }
      handleChoiceValue(label)("")
      const filteredChoice = choices.filter((choice) => choice !== label)
      setChoices([...filteredChoice])
      if (filteredChoice.length === 0) setIsShowError(true)
    }
  }

  const hrefParse = () => {
    let links = { pathname, query: null }
    const current = new URLSearchParams(Array.from(searchParams.entries()))
    const newQuery = {
      ...Object.fromEntries(current.entries()),
      brandPreferences: searchParams?.get("brandPreferences") === "false",
    }
    links.query = newQuery
    return links
  }

  const handleValueToPass = () => {
    let answers = []
    choices.map((choice) => answers.push(handleValueChoice(choice)))
    return answers
  }

  const getBrandPreference = async (update) => {
    const payload = {
      displayed: update,
      type: choices.join(","),
      detail: handleValueToPass().join(","),
      province: origins?.province,
      city: origins?.city,
    }
    try {
      const { data } = await ClientApiList.getOnBoarding(payload)

      if (isLogin && data) {
        setBrandPrefStat(data?.data.data.displayed_brandPreferences)
      }

      if (update && data) {
        setIsShowing(false)
        setBrandPrefStat(data?.data.data.displayed_brandPreferences)
        setPoint(data?.data.data.point_get)
        hrefParse()

        if (data?.data.data.point_get) {
          const params = [...searchParams.entries(), ["point", true]]
          const joinedParams = params.map((param) => param.join("=")).join("&")
          replace(`${pathname}?${joinedParams}`, { scroll: false })
        }
      }
    } catch (err) {
      if (err.status === 401) window.location.reload()
    }
  }

  const checkBrandPreferenceStatus = async () => {
    try{

      const { data } = await ClientApiList.getOnBoarding()
      if (data) {
        setBrandPrefStat(data?.data.data.displayed_brandPreferences)
      data?.data.data.displayed_brandPreferences
        ? localStorage.set(
            "brand-preference",
            !data?.data.data.displayed_brandPreferences
          )
        : null
      }
    }catch(err){
      if (err.status === 401) window.location.reload()
    }
  }

  useEffect(() => {
    if (
      !isLoading &&
      isLogin &&
      !pathname.includes("/allaccess-loading") &&
      !pathname.includes("/amild-offline")
    )
      checkBrandPreferenceStatus()
  }, [isLoading, isLogin, pathname])

  useEffect(() => {
    if (
      searchParams.get("brandPreferences") === "false" ||
      searchParams.has("aspace-url") ||
      brandPrefStat ||
      // (searchParams.get("brandPreferences") === "true" && brandPrefStat) ||
      localStorage.get("brand-preference")
    ) {
      setIsShowing(false)
    } else {
      setIsShowing(true)
    }
  }, [searchParams, brandPrefStat])

  const iconVariants = {
    open: { rotate: 0 },
    closed: { rotate: -180 },
  }

  const handleOptionList = (choice) => {
    const index = choicesLabel.findIndex((item) => item === choice)
    return list[0].list_detail[index]
  }

  const handleOpener = (choice) => {
    const keys = choicesLabel.filter((label) => label !== choice)
    const mapped = keys
      .map((key) => ({ ...control[key] }))
      .reduce((a, v) => ({ ...a, [v.label]: { ...v, isOpen: false } }), {})
    return (e) =>
      setControl({ ...mapped, [choice]: { ...control[choice], isOpen: e } })
  }

  const handleChoiceValue = (choice) => {
    return (e) =>
      setControl({ [choice]: { ...control[choice], choice: e, isOpen: false } })
  }

  const handleValueOpener = (choice) => {
    return control[choice].isOpen
  }

  const handleValueChoice = (choice) => {
    return control[choice].choice
  }

  const handleValidate = () => {
    return (
      handleValueToPass().findIndex((choice) => choice === "") === -1 &&
      handleValueToPass().length > 0 &&
      Object.values(origins).findIndex((origin) => origin === "") === -1
    )
  }

  return (
    <>
      {isLogin && (
        <PopupAction
          zIndex="z-20"
          maxWidth={isMobile ? "max-w-[354px]" : "max-w-[620px]"}
          padding="p-0"
          className={"h-[80%] mb-0 -translate-y-[47.5%]"}
          isShowing={isShowing}
          href={hrefParse()}
          onClickBtnX={() => {
            localStorage.set("brand-preference", true)
            setIsShowing(false)
          }}
        >
          <div
            className={cn(`relative z-[1] py-[10px] w-full overflow-hidden h-full rounded-[15px]`)}
            ref={containerRef}
          >
            <div
              className={cn(
                `relative z-[1] w-full py-[15px] px-[25px] lg:py-[20px] overflow-y-auto h-full`,
                Style.container
              )}
              ref={containerRef}
            >
              {send && (
                <div className="absolute top-[0] bottom-0 left-0 right-0 bg-white/[.75] z-10 rounded-[15px] flex justify-center items-center">
                  <Spinner />
                </div>
              )}
              <div className="mx-auto w-[100%] max-w-[308px] lg:max-w-[570px]">
                <div className="text-[20px] lg:text-[24px] leading-[28px] lg:leading-[36px] font-bold font-font-family-3 mb-[10px] text-text-2">
                  {list[0]?.title}
                </div>
                <Description
                  className="text-[14px] lg:text-[16px] leading-[20px] lg:leading-[24px] lg:text-base font-medium font-font-family-6 text-text-2 mb-[20px]"
                  description={list[0]?.description}
                />
              </div>
              <div className={"overflow-hidden lg:overflow-visible"}>
                <div
                  className={
                    "overflow-scroll lg:overflow-visible p-[10px] lg:p-[20px]"
                  }
                >
                  <div>
                    <div className="relative max-w-[325px] lg:max-w-none mx-auto">
                      <div>
                        <p className="text-[14px] leading-[20px] text-left lg:text-center font-bold text-text-2">
                          {list[0]?.instruction}
                        </p>
                        <ul className="flex flex-col lg:flex-row flex-wrap lg:items-start lg:justify-center gap-x-[15px] gap-y-[5px] mt-[5px] text-text-2">
                          {choicesLabel.map((choice) => {
                            return (
                              <li
                                key={choice}
                                className="py-[5px] w-[100%] lg:max-w-[120px]"
                              >
                                <CheckRadio
                                  disabled={isQuitSmoking}
                                  choices={choices}
                                  onChange={onChange}
                                  asset={"/assets/amild/pref-brand/cig.png"}
                                  label={choice}
                                />
                              </li>
                            )
                          })}
                        </ul>
                        <div className="flex flex-col lg:flex-row lg:justify-center gap-[5px]">
                          <div className="text-left mt-[5px] text-[12px] leading-[18px] text-text-5 italic lg:text-center">
                            *Lo bisa pilih lebih dari 1 Kategori
                          </div>
                          {isShowError ? (
                            <div className="text-text-1 text-left mt-[5px] text-[12px] llg:max-h-noneading-[18px] italic font-medium">
                              *Pilih Jenis Rokok terlebih dahulu
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="w-[100%] h-[5px] bg-[#F5F5F5] my-[15px] lg:my-[20px]"></div>
                    </div>
                  </div>

                  {/* FIELD CHOICES */}
                  <div className="lg:flex flex-wrap gap-[5px]">
                    {choicesLabel.map((choice, index, arr) => {
                      return (
                        <div
                          key={choice}
                          className={cn(
                            index !== arr.length - 1 ? "mb-[10px] lg:mb-0" : "",
                            "lg:max-w-[170px] lg:grow-[1] lg:flex flex-col justify-between"
                          )}
                        >
                          <p className="text-sm text-left font-black font-font-family-8 text-text-2 mb-[10px] capitalize max-w-[325px] lg:max-w-none mx-auto">
                            {list[0].brand_instruction[index]}
                            {/* Pilih Brand */}
                          </p>
                          <div className="relative w-full max-w-[325px] lg:max-w-none mx-auto">
                            <AnimatePresence>
                              <button
                                onClick={() =>
                                  handleOpener(choice)(!control[choice].isOpen)
                                }
                                disabled={
                                  choices.findIndex(
                                    (findChoice) => findChoice === choice
                                  ) === -1
                                }
                                className={clsx(
                                  "relative w-full lg:min-w-[120px] text-left text-sm font-medium font-font-family-6 flex justify-between items-center border rounded-[10px] capitalize",
                                  isMobile ? "p-[10px]" : "p-[15px]",
                                  handleValueOpener(choice)
                                    ? "border-cta-1"
                                    : "border-cta-4",
                                  // done2 ? "text-text-2" : "text-text-3 italic",
                                  choices.findIndex(
                                    (findChoice) => findChoice === choice
                                  ) === -1
                                    ? "bg-cta-4"
                                    : ""
                                )}
                              >
                                <p
                                  className={cn(
                                    "h-[20px] capitalize",
                                    handleValueChoice(choice) === ""
                                      ? "italic text-text-3"
                                      : "text-text-2 not-italic text-ellipsis overflow-hidden"
                                  )}
                                >
                                  {handleValueChoice(choice) || "Pilih Brand"}
                                </p>
                                <motion.span
                                  key="icon"
                                  initial={false}
                                  animate={
                                    handleValueOpener(choice)
                                      ? "open"
                                      : "closed"
                                  }
                                  variants={iconVariants}
                                  transition={{ duration: 0.2 }}
                                >
                                  <Chevron className={"fill-icon-2"} />
                                </motion.span>
                              </button>
                              {handleValueOpener(choice) && (
                                <motion.div
                                  initial={{
                                    opacity: 0,
                                    y: isDesktop ? -10 : -10,
                                  }}
                                  animate={{ opacity: 1, y: 0 }}
                                  exit={{
                                    opacity: 0,
                                    y: isDesktop ? -10 : -10,
                                  }}
                                  transition={{ duration: 0.2 }}
                                  className={cn(
                                    "w-[100%] max-h-[130px] absolute z-[5] top-[47px] lg:top-[57px] border border-cta-1 rounded-[10px] bg-bg-3 overflow-hidden"
                                  )}
                                >
                                  <div
                                    className={cn(
                                      "max-h-[130px] overflow-y-scroll flex flex-col gap-[10px] p-[10px]",
                                      Style.container
                                    )}
                                  >
                                    {handleOptionList(choice)?.map((item) => (
                                      <button
                                        key={item}
                                        onClick={() => {
                                          handleChoiceValue(choice)(item)
                                          // handleOpener(choice)(false)
                                          setOther()
                                        }}
                                      >
                                        <Description
                                          className="text-ellipsis overflow-hidden lg:max-w-[170px] text-text-2 text-left text-sm capitalize"
                                          description={item}
                                        />
                                      </button>
                                    ))}
                                    {(choice
                                      .toLowerCase()
                                      .includes("cigarette") ||
                                      choice.toLowerCase().includes("rokok")) &&
                                    !choice
                                      .toLowerCase()
                                      .includes("e-cigarette") ? (
                                      <div className="p-[5px] rounded-[10px] bg-bg-6">
                                        <div className="text-left text-[14px] leading-[20px] mb-[10px] text-text-2">
                                          Lainnya:
                                        </div>
                                        <div className="flex lg:flex-col gap-[5px] lg:gap-[10px] min-h-[30px] items-center justify-between">
                                          <input
                                            value={other}
                                            onChange={(e) =>
                                              setOther(e.target.value)
                                            }
                                            className={cn(
                                              "capitalize text-[14px] text-text-2 placeholder:italic w-[100%] bg-bg-6 outline-0 leading-[20px] border-0 border-b-[1px]",
                                              other ? "border-cta-1" : ""
                                            )}
                                            placeholder="Tulis Di Sini"
                                          />
                                          {other ? (
                                            <ButtonAction
                                              onClick={() => {
                                                handleChoiceValue(choice)(other)
                                                // handleOpener(choice)(false)
                                              }}
                                              className="min-h-[30px] !text-[12px] !p-[5px] flex items-center justify-center !leading-[18px]"
                                              intent={"secondary"}
                                            >
                                              Konfirmasi
                                            </ButtonAction>
                                          ) : null}
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </motion.div>
                              )}
                            </AnimatePresence>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  <div className="w-full max-w-[325px] lg:max-w-none mx-auto h-[5px] bg-[#F5F5F5] my-[15px] lg:my-[20px]" />

                  {/* FIELD ORIGIN */}
                  <UserOrigin
                    list={list[0]?.list_province}
                    setOrigins={setOrigins}
                  />
                </div>
                <div className="flex items-center justify-between gap-[10px] max-w-[355px] h-[50px] mx-auto mt-[20px]">
                  <ButtonAction
                    className="py-[15px] px-[20px] leading-[20px] font-font-family-7"
                    intent={handleValidate() ? "primary" : "primary_disable"}
                    onClick={() => {
                      setSend(true)
                      getBrandPreference("BrandPreferences")
                    }}
                    disabled={!handleValidate() || send}
                  >
                    {list[0].cta_label}
                  </ButtonAction>
                </div>
              </div>
            </div>
          </div>
        </PopupAction>
      )}
      <ToastComponent
        onClick={() => {
          setPoint(false)
        }}
        title={`Berhasil dapat ${point} Poin`}
        desc="Eksplor lebih banyak tantangan buat dapet lebih banyak poin"
        onShow={() => {
          datalayer.push({
            event: "general_event",
            event_name: `page_reached_finish_brand_preferences`,
            feature: "brand preferences",
            engagement_type: "brand preferences",
            engagement_name: "brand preferences",
            campaign_name: "all", // e.g all
            event_label: `brand preferences - Berhasil dapat ${point} Poin`,
          })
        }}
        isOpen={point}
      />
    </>
  )
}

BrandPreference.propTypes = {
  list: PropTypes.array,
}
