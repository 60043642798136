import { Image } from "@/components/shared/image/image"
import React from "react"
import { twMerge } from "tailwind-merge"
import PropTypes from "prop-types"
export default function CampaignPoint({ children, asset, className, icon }) {
  return (
    <div
      className={twMerge(
        "w-fit break-keep whitespace-nowrap text-[14px] lg:text-[16px] mb-[6px] leading-[20px] lg:leading-[24px] font-bold font-font-family-7 tracking-[0.25px] text-text-1 flex items-center gap-[5px] lg:mb-[20px]",
        className
      )}
    >
      {icon || (
        <Image
          className={"max-w-[24px] lg:max-w-[32px] max-h-[24px] lg:max-h-[32px]"}
          alt="poitn get"
          src={asset}
        />
      )}
      <div>{children} Poin</div>
    </div>
  )
}

CampaignPoint.propTypes = {
  children: PropTypes.array,
  asset: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.object,
}
