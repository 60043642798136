"use client"
import React from "react"
import { twMerge } from "tailwind-merge"

import Style from "./campaign-description.module.css"
import DOMPurify from "isomorphic-dompurify"
import PropTypes from "prop-types"

// } text-[14px]  text-text-2 font-normal  md:mb-[20px]`,lg:text-[20px] mb-[10px] leading-[20px] lg:leading-[28px]
// text-[14px] lg:text-[16px] font-normal mb-[15px] leading-[24px] md:mb-[20px] md:leading-[25px] text-text-4
export default function CampaignDescription({ children, className, isList }) {
  console.log(children)
  return (
    <div
      data-testid="campaign-description"
      className={twMerge(
        `${
          isList ? Style.list : Style.description} text-[14px] lg:text-[16px] text-text-2 font-normal mb-[15px] leading-[24px] md:mb-[20px]`,
          className
        )}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(children.replace(/&nbsp;/g,'')) }}
        />
      )
    }

    CampaignDescription.propTypes = {
      children: PropTypes.any,
      className: PropTypes.string,
      isList: PropTypes.bool,
    }
