"use client"
import ButtonBack from "../shared/button-back/button-back"
import PropTypes from "prop-types"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { useShowLoginPopup } from "@/hooks/auth/use-login-popup"

export function EventDetailPageLayout({ children }) {
  const { isLoading, isLogin } = useIsLogin()

  useShowLoginPopup({
    showWhen: !isLogin && !isLoading,
    closePath: "/",
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
  })

  return (
    <div className="lg:p-[15px] relative mt-[58px] lg:mt-[72px]">
      <ButtonBack />
      {children}
    </div>
  )
}

EventDetailPageLayout.propTypes = {
  children: PropTypes.object,
}
