"use client"
import * as ScrollAreaPrimitives from '@radix-ui/react-scroll-area';
import clsx from 'clsx';
import styles from './scroll-area.module.scss';

export const ScrollArea = ({ children, stickyBanner }) => (
	<ScrollAreaPrimitives.Root className={clsx(styles.scroll_area, stickyBanner && styles.scroll_area_sticky)}>
		<ScrollAreaPrimitives.Viewport className={styles.viewport}>
			{children}
		</ScrollAreaPrimitives.Viewport>
		{/* <ScrollAreaPrimitives.Scrollbar
      className={styles.scroll_bar}
      orientation="horizontal"
    >
      <ScrollAreaPrimitives.Thumb className={styles.thumb} />
    </ScrollAreaPrimitives.Scrollbar> */}
		<ScrollAreaPrimitives.Scrollbar
			className={styles.scroll_bar}
			orientation="vertical"
		>
			<ScrollAreaPrimitives.Thumb className={styles.thumb} />
		</ScrollAreaPrimitives.Scrollbar>
		<ScrollAreaPrimitives.Corner className={styles.corner} />
	</ScrollAreaPrimitives.Root>
);
