"use client"
import React, { useEffect, useState } from "react"
import { ClientApiList } from "@/lib/api/client-service"
import { Spinner } from "../shared/spinner"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { useRouter } from "next/navigation"
import { ToastComponent } from "../toast"
import UseQueryParams from "@/hooks/use-query-params"

export const IframeLeaderBoard = () => {
  const QUERYPARAM = "point"
  const { handlePushQuery } = UseQueryParams()
  const [isLoadingAuth, setIsLoadingAuth] = useState(true)
  const [url, setUrl] = useState(true)
  const { isLogin, isLoading } = useIsLogin()
  const { replace } = useRouter()
  const [point, setPoint] = useState()
  const [isSuccess, setIsSuccess] = useState(false)
  const _URL =
    process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
      ? "https://campaign.djisamsoe.id"
      : "https://campaign.dev.djisamsoe.id"

  function handlePoint(point) {
    if (point !== 0) {
      setPoint(point)
      handlePushQuery(false, [QUERYPARAM, true])
      setIsSuccess(true)
    }
  }

  async function handleSubmit(type) {
    const { data: dataReturn } = await ClientApiList.postTrack({
      source: "AG",
      type: "DSS Game Aksi Mahakarya",
      detail: "aksi-mahakarya-dss",
    })
    handlePoint(dataReturn.data.data.point)
  }

  async function handleEvent(url) {
    const {
      data: { data: auth_data },
    } = await ClientApiList.getAuthData({
      detail_page: "eyJyZWRpcmVjdFRvIjoiL21pbmkta29udGVzL2JyIn0=",
      first_page: "login",
      device_type: "Mozilla/5.0",
      device_id: "_u-oQHmKypzuLi2v0QS-0",
      platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
      redirect_url: `${process.env.NEXT_PUBLIC_META_URL}/br`,
    })
    // console.log(auth_data.data.auth_data, 'auth-data');
    // let newuUrl = url + `?auth-data=${auth_data.data.url.split('?')[1].split('=')[1]}`;
    let newuUrl =
      url + `?redirectTo=/mini-kontes/br&auth-data=${auth_data.data.auth_data}`
    setUrl(newuUrl)
    setIsLoadingAuth(false)
  }

  function handleUrlRedirect(to) {
    switch (to) {
      case "edit-profile":
        replace("/profil/ubah-profil/br")
        return
      default:
        replace("/br")
        return
    }
  }

  function handleOrigin(event) {
    if (event.origin !== _URL) return
    // console.log(event.data, 'event handle');
    if (String(event.data) === "directLink"){
      // replace('/thank-you-joget-asyik/br');
      replace("/br")
      return
    }
    if (String(event.data.type) === "navigate"){
      handleUrlRedirect(event.data.payload.to)
    }
    if (
      String(event.data.action) === "aksi-mahakarya-dss" &&
      !Object.hasOwn(event.data, "level")
    )
      handleSubmit()
      return
  }

  useEffect(() => {
    let url = `${_URL}/auth/allaccess`

    isLogin && !isLoading && handleEvent(url)

    window.addEventListener("message", handleOrigin, false)

    return () => {
      window.removeEventListener("message", handleOrigin, false)
    }
  }, [isLoading, isLogin])

  const titleBrand =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? `Kamu berhasil raih ${point} poin`
      : `Lo berhasil dapetin ${point} poin!`

  if (isLoadingAuth)
    return (
      <div className="h-[calc(100vh-170px)] flex items-center">
        <Spinner />
      </div>
    )

  return (
    // <template>
    <div className="h-[calc(100dvh-56px)] w-[100%]">
      <ToastComponent
        onClick={() => {
          setIsSuccess(false)
        }}
        title={titleBrand}
        desc="Lanjut eksplor dan kumpulkan lebih banyak poin!"
        isOpen={isSuccess}
      />
      <iframe
        className="h-[100%] w-[100%]"
        src={url}
        title="mini-kontes"
        allow="camera; microphone"
        frameBorder="0"
        // src="https://campaign.sampoernakretek.id/auth/allaccess?auth-data=CODE"
      />
    </div>
    // </template>
  )
}
