"use client"
import clsx from "clsx"
import { useIsMobile } from "@/hooks/use-is-mobile"
import CampaignSnk from "../campaign-intro/snk/syaratKetentuan"
import CampaignSnkMagnum from "../campaign-intro/snk/syaratKetentuanMagnum"
import PropTypes from "prop-types"

export function SyaratKetentuanCampaign({ isArtwork }) {
  const isMobile = useIsMobile()
  return (
    <div
      className={clsx(
        "w-full max-w-[460px] mx-auto relative pt-[30px] ",
        isMobile && "px-3.5"
      )}
    >
      {process.env.NEXT_PUBLIC_NAME === "magnum" ? (
        <CampaignSnkMagnum isArtwork={isArtwork} />
      ) : (
        <CampaignSnk />
      )}
    </div>
  )
}

SyaratKetentuanCampaign.propTypes = {
  isArtwork: PropTypes.bool,
}
