"use client"
import { Image } from "@/components/shared/image/image"
import { Description } from "../shared/description/description"
import clsx from "clsx"
import { useIsMobile } from "@/hooks/use-is-mobile"
import PropTypes from "prop-types"

export function HubungiKami({ title, description, info = {} }) {
  const isMobile = useIsMobile()
  const redText = info?.title?.split(" ").slice(0, 2).join(" ")
  const blackText = info?.title?.split(" ").splice(2).join(" ")

  return (
    <div
      className={clsx(
        "w-full max-w-[460px] mx-auto relative ",
        isMobile ? "pt-[23px] px-3.5" : " pt-[30px] px-0"
      )}
    >
      <p className="font-bold text-2xl text-text-2 font-font-family-3">
        {title}
      </p>
      <Description
        className={clsx("mb-[23px] mt-[10px] text-text-2 text-base")}
        description={description}
      />
      <div className="mt-[20px] p-[20px] bg-bg-6 rounded-[15px]">
        <p
          className={clsx(
            "font-bold  font-font-family-7 text-base text-text-2"
          )}
        >
          <span className="text-text-1">{redText}</span>{" "}
          <span>{blackText}</span>
        </p>
        <a
          style={{ background: "var(--cta_1)" }}
          className="flex w-fit mx-auto p-[30px] my-[15px] bg-cta-1 text-cta-2 rounded-[10px] flex justify-center items-center gap-[5px]"
          href={`mailto:${info?.email_brand}`}
        >
          {/* <div
            className={clsx(
              "relative aspect-square",
              isMobile ? "w-[16px]" : "w-[24px]"
            )}
          >
            <Image alt="icon" src={info?.icon_url} />
          </div> */}
          <MailIcon fillIcon="var(--cta_2)" />
          <span className="text-[1.1rem] font-bold text-cta-2 font-font-family-7">
            {info?.email_brand}
          </span>
        </a>
        <Description
          className="text-xs text-text-5 tracking-[0.2px]"
          description={info?.description}
        />
      </div>
    </div>
  )
}

HubungiKami.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  info: PropTypes.object,
}

const MailIcon = ({ className, fillIcon }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: fillIcon }}
    >
      <path
        className={className}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 20.5C4.20435 20.5 3.44129 20.1839 2.87868 19.6213C2.31607 19.0587 2 18.2956 2 17.5V7.5C2 6.70435 2.31607 5.94129 2.87868 5.37868C3.44129 4.81607 4.20435 4.5 5 4.5H19C19.7956 4.5 20.5587 4.81607 21.1213 5.37868C21.6839 5.94129 22 6.70435 22 7.5V17.5C22 18.2956 21.6839 19.0587 21.1213 19.6213C20.5587 20.1839 19.7956 20.5 19 20.5H5ZM7.625 8.72C7.52313 8.63302 7.40483 8.56739 7.27711 8.527C7.1494 8.48662 7.01487 8.47231 6.88151 8.48491C6.74816 8.49752 6.61869 8.53679 6.50081 8.6004C6.38292 8.664 6.27901 8.75064 6.19525 8.85517C6.11149 8.9597 6.04959 9.08 6.01321 9.20891C5.97684 9.33783 5.96673 9.47274 5.9835 9.60564C6.00026 9.73853 6.04356 9.86671 6.11081 9.98255C6.17806 10.0984 6.2679 10.1995 6.375 10.28L10.125 13.281C10.6571 13.707 11.3184 13.9391 12 13.9391C12.6816 13.9391 13.3429 13.707 13.875 13.281L17.625 10.281C17.7276 10.1989 17.813 10.0975 17.8763 9.98237C17.9396 9.8673 17.9797 9.74087 17.9942 9.61031C18.0087 9.47975 17.9973 9.34762 17.9607 9.22145C17.9241 9.09529 17.8631 8.97756 17.781 8.875C17.6989 8.77244 17.5974 8.68705 17.4824 8.6237C17.3673 8.56035 17.2409 8.52029 17.1103 8.50581C16.9798 8.49132 16.8476 8.50269 16.7215 8.53927C16.5953 8.57585 16.4776 8.63692 16.375 8.719L12.625 11.719C12.4476 11.861 12.2272 11.9384 12 11.9384C11.7728 11.9384 11.5524 11.861 11.375 11.719L7.625 8.719V8.72Z"
      />
    </svg>
  )
}

MailIcon.propTypes = {
  className: PropTypes.string,
  fillIcon: PropTypes.string,
}
