"use client"
import clsx from "clsx"
import { Description } from "../shared/description/description"
import { useIsMobile } from "@/hooks/use-is-mobile"
import PropTypes from "prop-types"
import Link from "@/components/shared/link/link"

export function Sitemap(props) {
  const { title, list } = props
  const isMobile = useIsMobile()
  return (
    <div
      className={clsx(
        "w-full max-w-[460px] mx-auto relative pt-[30px] text-text-2",
        isMobile && "px-3.5"
      )}
    >
      <p className="font-bold font-font-family-7 text-2xl capitalize text-center">
        {title}
      </p>
      <div className="mt-[20px] mx-[20px] flex flex-col gap-[15px]">
        {list?.map((item, idx) => (
          <div key={idx}>
            <p className="font-bold font-font-family-7 text-xl">{item.title}</p>
            <div className="mt-[5px]">
              {item?.links?.map((link, index) => (
                <Link key={index} href={link.href} passHref>
                  <div className="flex gap-[16px] text-base font-medium font-font-family-6 items-center">
                    <LinkIcon />
                    <span>{link.label}</span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

Sitemap.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
}

const LinkIcon = () => {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Group 11</title>
      <g
        id="Desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Sitemap-Page" transform="translate(-409.000000, -199.000000)">
          <g id="Group-11" transform="translate(409.000000, 199.000000)">
            <rect id="Rectangle" x="0" y="0" width="32" height="32"></rect>
            <g
              id="link-2-copy-7"
              transform="translate(5.000000, 11.000000)"
              stroke="#E8242A"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            >
              <path
                d="M14,0 L17,0 C18.3260824,0 19.597852,0.526784202 20.5355339,1.46446609 C21.4732158,2.40214799 22,3.67391755 22,5 C22,7.76142375 19.7614237,10 17,10 L14,10 M8,10 L5,10 C3.67391755,10 2.40214799,9.4732158 1.46446609,8.53553391 C0.526784202,7.59785201 0,6.32608245 0,5 C0,2.23857625 2.23857625,1.77635684e-15 5,1.77635684e-15 L8,1.77635684e-15"
                id="Shape"
              ></path>
              <line x1="7" y1="5.5" x2="15" y2="5.5" id="Path"></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
