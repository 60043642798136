"use client"
import ButtonAction from "@/components/shared/button-action/button-action"
import PopupAction from "@/components/shared/popup-action/popup-action"
import { Image } from "@/components/shared/image/image"
import Link from "@/components/shared/link/link"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { useState, useEffect, Suspense } from "react"
import { Description } from "../shared/description/description"
import PropTypes from "prop-types"
import { ClientApiList } from "@/lib/api/client-service"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { ClientStorage } from "ClientStorage"

function SuspendedOnBoarding({ list = {} }) {
  const localStorage = new ClientStorage("localStorage")
  const onBoardCookie = localStorage.get("onBoarding")
  const brandPrefCookie = localStorage.get("brand-preference")
  const [isShowing, setIsShowing] = useState(false)
  // const [isOnBoarding, setIsOnBoarding] = useState(true)
  const [isBrandPrefShowed, setIsBrandPrefShowed] = useState(false)

  const { replace, ...router } = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const isMobile = useIsMobile()

  const { isLogin, isLoading } = useIsLogin()

  const getOnBoarding = async (update) => {
    try {
      const { data } = await ClientApiList.getOnBoarding({
        displayed: update,
      })

      if (isLogin && data) {
        setIsShowing(!data?.data.data.displayed)
      }
      if (update) {
        setIsShowing(false)
        router.push(list[0]?.href)
      }
    } catch (err) {
      if (err.status === 401) window.location.reload()
    }
  }

  const checkBrandPreferenceStatus = async () => {
    try {
      const { data } = await ClientApiList.getOnBoarding()
      if (data) setIsBrandPrefShowed(data?.data.data.displayed_brandPreferences)
    } catch (err) {
      if (err.status === 401) window.location.reload()
    }
  }

  const hrefParse = () => {
    let links = { pathname, query: null }
    const current = new URLSearchParams(Array.from(searchParams.entries()))
    const newQuery = {
      ...Object.fromEntries(current.entries()),
      onBoarding: searchParams?.get("onBoarding") === "false",
    }
    links.query = newQuery
    return links
  }

  useEffect(() => {
    if (!isLoading && isLogin && !pathname.includes("/allaccess-loading"))
      checkBrandPreferenceStatus()

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLoading, isLogin, pathname])

  /* check brand preferences */
  useEffect(() => {
    if (
      !isLoading &&
      isLogin &&
      !pathname.includes("/allaccess-loading") &&
      !pathname.includes("/amild-offline") &&
      !searchParams.has("aspace-url")&&
      !onBoardCookie &&
      (brandPrefCookie ||
        searchParams.get("brandPreferences") === "false" ||
        isBrandPrefShowed ||
        (!brandPrefCookie && !isBrandPrefShowed))
    )
      getOnBoarding()

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    isLoading,
    isLogin,
    pathname,
    onBoardCookie,
    brandPrefCookie,
    searchParams,
    setIsBrandPrefShowed,
  ])

  const handleCloseOnBoarding = () => {
    localStorage.set("onBoarding", true)
    setIsShowing(false)
  }

  return (
    <>
      {isLogin && (
        <Suspense fallback={<onBoardingFallback />}>
          <PopupAction
            zIndex="z-20"
            isShowing={isShowing}
            href={hrefParse()}
            onClickBtnX={() => handleCloseOnBoarding()}
          >
            <div className="h-full relative aspect-[304/177]">
              <Image
                alt="banner"
                src={isMobile ? list[0]?.img_mobile : list[0]?.img_desktop}
              />
            </div>
            <div className="mx-auto my-[20px] w-[304px]">
              <div className="text-xl font-black font-font-family-8 mb-[5px] text-text-2">
                {list[0]?.title}
              </div>
              <Description
                className="text-sm font-medium font-font-family-6 text-text-2"
                description={list[0]?.description}
              />
            </div>
            <div className="flex items-center justify-between gap-[10px]">
              <Link
                className="block w-[100%]"
                href={hrefParse()}
                onClick={() => handleCloseOnBoarding()}
              >
                <ButtonAction
                  className="py-[15px] px-[20px] leading-[20px]"
                  intent="secondary"
                >
                  {list[0]?.cta_label_close}
                </ButtonAction>
              </Link>
              <ButtonAction
                className="py-[15px] px-[20px] leading-[20px]"
                intent="primary"
                onClick={() => {
                  getOnBoarding("true")
                }}
              >
                {list[0]?.cta_label}
              </ButtonAction>
            </div>
          </PopupAction>
        </Suspense>
      )}
    </>
  )
}

SuspendedOnBoarding.propTypes = {
  list: PropTypes.array,
}

function OnBoardingFallback() {
  return <>onBoarding</>
}

export function OnBoarding(props) {
  return (
    <Suspense fallback={<OnBoardingFallback />}>
      <SuspendedOnBoarding {...props} />
    </Suspense>
  )
}
