"use client"
import React from "react"
import Styles from "./reward-card.module.scss"
import { Image } from "@/components/shared/image/image"
import { useIsDesktop } from "@/hooks/use-is-mobile"
import clsx from "clsx"

const RewardCard = ({ reward }) => {
  const isDesktop = useIsDesktop()
  return (
    <div className={Styles.prize__card}>
      <div></div>
      {/* <div>{reward.position}</div> */}
      <div
        data-testid="reward-title"
        className={clsx(
          "font-bold font-font-family-7"
          // reward.id === 2 && isDesktop && "!text-xl"
        )}
      >
        {isDesktop ? reward.titleDesk : reward.title}
      </div>
      <div
        className={
          reward.id === 2 ? "w-[100px] aspect-square" : "w-[70px] aspect-square"
        }
      >
        <Image
          data-testid="reward-image"
          src={reward.img}
          layout="fill"
          alt={reward.title}
        />
      </div>
    </div>
  )
}

export default RewardCard
