import { ClientApiList } from "@/lib/api/client-service"
import { usePathname, useRouter } from "next/navigation"
import { useState } from "react"
import { useForm } from "react-hook-form"

const useEditPhone = ({
  setIsError,
  captchaRef,
  setIsOpen,
  step,
  setStep,
  setIsSms,
  pathName,
  setIsSuccess,
  setToastMessage,
}) => {
  const { replace } = useRouter()
  const pathname = usePathname()

  const handleDefaultFormValue = async () => {
    const { data } = await ClientApiList.getProfileData()
    const dob = new Date(data.data.data.dob)
    const date = dob.getDate()
    const month = dob.getMonth() + 1
    const year = dob.getFullYear()
    const fav_brand = data.data.data.favorite_brand.brand_id
    if (pathName.includes("verifikasi")) {
      return {
        ...data.data.data,
        date,
        month,
        year,
        fav_brand,
        password: "",
        phone_number: data.data.data.phone,
        use_otp: "",
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
        "g-recaptcha-response": false,
      }
    } else {
      return {
        ...data.data.data,
        date,
        month,
        year,
        fav_brand,
        password: "",
        phone_number: "",
        use_otp: "",
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
        "g-recaptcha-response": false,
      }
    }
  }

  const {
    watch,
    register,
    getValues,
    handleSubmit,
    reset: resetForm,
    setError,
    setValue,
    setFocus,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: async () => await handleDefaultFormValue(),
  })

  const resetFormValue = async () => {
    const result = await handleDefaultFormValue()
    resetForm(result)
    // captchaRef.current?.execute();
  }

  const [formError, setFormError] = useState(null)

  const reset = () => {
    setFormError(null)
  }

  const onSubmit = async (data) => {
    captchaRef.current.reset()
    const token = await captchaRef.current.executeAsync()
    setFormError(null)

    if (!token) {
      setIsError(true)
      replace(pathname)
      return
    } else {
      setIsError(false)

      if (step === 1)
        try {
          const result = await ClientApiList.checkPassword({
            password: data.password,
            login_name: data.email || data.phone,
            "g-recaptcha-response": token,
          })
          if (result.status === 200) {
            const response = await ClientApiList.requestOTPChannel({
              phone: data.phone_number,
            })

            if (response.status !== 200) {
              setError("phone_number", { message: response.error.message })
              setFocus("phone_number")
              return
            } else {
              setIsSms(response.data.data.data.data.channel === "SMS")
              setIsOpen(true)
            }
          } else {
            setError("phone_number", { message: result.error.message })
            setError("password", { message: result.error.message })
            setFocus("phone_number")
            return
          }
        } catch (err) {
          setError("phone_number", {
            message: err?.message || err?.errors[0]?.message,
          })
          setError("password", {
            message: err?.message || err?.errors[0]?.message,
          })
          setFocus("phone_number")
          return
        }
      else {
        try {
          const body = {
            phone_number: data.phone_number,
            password: data.password,
            otp: [data.otp1, data.otp2, data.otp3, data.otp4].join(""),
          }

          const result = await ClientApiList.updatePhone({ ...body })
          const resultCheckPoint = await ClientApiList.checkUserPoint()

          if (
            result.status === 200 &&
            resultCheckPoint.status === 200 &&
            resultCheckPoint.data.data.activity_point.verify_phone_number
              .status &&
            !resultCheckPoint.data.data.activity_point.verify_phone_number
              .popup_displayed
          ) {
            try {
              const resultUpdatePopup = await ClientApiList.updatePopup({
                activity: "verify_phone_number",
              })
              if (resultUpdatePopup.status === 200) {
                resetFormValue()
                setStep(1)
                setToastMessage(
                  `Berhasil Verifikasi, ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "Kamu" : "Lo"} Berhasil Dapetin ${resultCheckPoint.data.data.activity_point.verify_phone_number.point_get} Points`
                )
                setTimeout(() => {
                  setIsSuccess(true)
                }, 100)
                setTimeout(() => {
                  replace("/profil/ubah-profil/br")
                }, [1800])
              }
            } catch (err) {
              return
            }
          } else if (
            result.status === 200 &&
            resultCheckPoint.status === 200 &&
            resultCheckPoint.data.data.activity_point.verify_phone_number
              .status &&
            resultCheckPoint.data.data.activity_point.verify_phone_number
              .popup_displayed
          ) {
            resetFormValue()
            setStep(1)
            setIsSuccess(true)
            setTimeout(() => {
              replace("/profil/ubah-profil/br")
            }, [1500])
          } else {
            if (result.status === 401) window.location.reload()
            setError("otp4", { message: result.error.message })
            setFocus("otp4")
          }
        } catch (err) {
          if (err.status === 401) window.location.reload()
          setError("otp4", { message: err?.message })
          setFocus("otp4")
        }
      }
    }
  }

  return {
    register,
    getValues,
    errors,
    formError,
    reset,
    watch,
    isValid,
    setValue,
    onSubmit: handleSubmit(onSubmit),
  }
}

export default useEditPhone
