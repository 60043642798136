"use client"
import { useLogout } from "@/hooks/auth/use-logout"
import { useDatalayer } from "@/hooks/use-datalayer"
import { usePathname } from "next/navigation"
import React from "react"

export default function ButtonLogout() {
  const pathname = usePathname()
  const { execute: logout } = useLogout()
  const datalayer = useDatalayer()

  return (
    <button
      onClick={() => {
        logout()
        datalayer.push({
          event: "general_event",
          event_name: "click_logout_button",
          feature: "logout",
          button_name: "keluar", // e.g., logout
          page_path: pathname,
          campaign_name: "all",
        })
      }}
      className="absolute min-h-[50px] items-center text-icon-1 z-[2] top-[10px] right-[10px] lg:right-[35px] lg:top-[35px] bg-bg-1 py-[11px] px-[15px] rounded-[10px] border-[1px] border-cta-4 lg:flex items-center gap-[5px]"
    >
      <div className="hidden text-icon-1 lg:block order-1 text-[14px] font-bold font-font-family-7">
        Keluar
      </div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="vuesax/linear/logout">
          <g id="logout">
            <path
              id="Vector"
              d="M12.5859 6.29995C12.3276 3.29995 10.7859 2.07495 7.41094 2.07495H7.3026C3.5776 2.07495 2.08594 3.56662 2.08594 7.29162V12.725C2.08594 16.45 3.5776 17.9416 7.3026 17.9416H7.41094C10.7609 17.9416 12.3026 16.7333 12.5776 13.7833"
              className="stroke-icon-1"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M7.50104 10H16.9844"
              className="stroke-icon-1"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_3"
              d="M15.1263 7.20825L17.918 9.99992L15.1263 12.7916"
              className="stroke-icon-1"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    </button>
  )
}
