"use client"
import React from "react"
import YourChallengeCard from "../user-profile-leaderboard/components/your-challenge-card/your-challenge-card"
import BackBanner from "../shared/back-banner"
import ButtonBack from "../shared/button-back/button-back"
import { useIsMobile } from "@/hooks/use-is-mobile"

export function YourChallenge({
  bg_img_url_mobile,
  bg_img_url_desktop,
  title,
  leaderboard_img_url,
  cta_label,
  href,
  subtitle,
  description,
  start_date,
  end_date,
  date_icon,
  user_participate_count,
  user_participate_count_icon,
  section_title,
  redirect_url,
  periode
}) {
  const isMobile = useIsMobile()

  return (
    <div className="max-w-[636px] mx-auto lg:!max-w-[50%] lg:sticky lg:w-full overflow-hidden px-[10px] lg:p-[15px] lg:pr-[6px] pb-[10px] lg:m-0 relative z-[5] lg:max-h-[624px] lg:sticky lg:top-[64px]">
      <BackBanner
        className="hidden lg:block oveflow-hidden lg:top-[15px] lg:left-[15px] lg:z-[-1] lg:max-h-[609px] h-full lg:p-0 "
        bgImg={isMobile ? bg_img_url_mobile : bg_img_url_desktop}
        imgClass={'!object-top'}
      >
        <ButtonBack className="lg:left-[15px] lg:top-[15px]" />
        {/* <Image
                alt='cover'
                fill
                style={{ objectFit: "cover" }}
                src='https://images.unsplash.com/photo-1696263075473-49877750c1a2?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=300&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY5Nzc3MzAyMA&ixlib=rb-4.0.3&q=80&w=300'
            /> */}
      </BackBanner>
      <div className="hidden w-fit mx-auto lg:block text-[20px] lg:text-[24px] font-medium font-font-family-6 text-center text-black leading-[27px] lg:leading-[32px] pt-[23px] mb-[186px] text-text-2">
        Leaderboard
      </div>
      <div className="text-[20px] lg:text-[24px] leading-[27px] lg:leading-[32px] font-medium font-font-family-6 mb-[10px] text-text-2 lg:hidden">
        {section_title}
      </div>
      <YourChallengeCard
        {...{
          title: title,
          challengeImg: leaderboard_img_url,
          linkLabel: cta_label,
          href,
          subtitle: subtitle,
          description: description,
          endDate: end_date,
          periode:periode,
          startDate: start_date,
          dateIcon: date_icon,
          redirect_url,
          userParticipateCount: user_participate_count,
          userParticipateCountIcon: user_participate_count_icon,
        }}
      />
    </div>
  )
}
