import React from "react"
// import Button from "../../../components/Button/Button";
import Style from "./Key.module.scss"

export default function Key({
  children,
  value,
  big,
  onSelectLetter,
  onDelete,
  onEnter,
  correctKey,
  almostKey,
  wrongKey,
}) {
  const correctClassSk =
    process.env.NEXT_PUBLIC_NAME === "sampoernakretek"
      ? Style.correctSk
      : Style.correct
  const correctClass =
    process.env.NEXT_PUBLIC_NAME === "magnum"
      ? Style.correctMagnum
      : correctClassSk
  const almostClass =
    process.env.NEXT_PUBLIC_NAME === "magnum"
      ? Style.almostMagnum
      : Style.almost
  const wrongClass =
    process.env.NEXT_PUBLIC_NAME === "magnum" ? Style.wrongMagnum : Style.wrong

  const base =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe" ||
    process.env.NEXT_PUBLIC_NAME === "sampoernakretek"
      ? Style.baseDark
      : Style.base
  const almostColor = almostKey ? almostClass : base
  const wrongColor = wrongKey ? wrongClass : almostColor
  const colorClass = correctKey ? correctClass : wrongColor
  const selectLetter = () => {
    if (value === "ENTER") {
      onEnter()
    } else if (!value) {
      onDelete()
    } else {
      onSelectLetter(value)
    }
  }

  return (
    <button
      onClick={selectLetter}
      className={`${Style.btnKeyboard} ${big ? Style.enter : ""} ${colorClass}`}
    >
      {value || children}
    </button>
  )
}
