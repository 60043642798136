"use client"
import React, { useEffect, useRef, useState } from "react"
import CampaignCard from "../campaign-intro/campaign-card/campaign-card"
import { Image } from "../shared/image/image"
import { useIsTablet } from "@/hooks/use-is-mobile"
import WinnerCard from "../winner/winner-card"
import { ClientApiList } from "@/lib/api/client-service"

const CampaignWinner = ({
  title,
  description,
  page_uuid,
  mobile_bg,
  desktop_bg,
  ...props
}) => {
  const isMobile = useIsTablet()
  const [winnerList, setWinnerList] = useState([])
  const container = useRef()

  const handleGetData = async () => {
    try {
      const { data } = await ClientApiList.getCampaignWinner({
        page_uuid,
      })
      setWinnerList(data.data.data.doc)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    handleGetData()
  }, [])

  return (
    <CampaignCard
      className="col-span-2 relative p-0 lg:p-0 border-0 lg:max-h-[470px] lg:mb-0 bg-[crimson]"
      wrapperClass="min-h-[700px] lg:min-h-[470px] px-[10px] py-[73px] pt-[83px] lg:py-[50px] 2xl:py-[50px] lg:px-[65px] flex"
    >
      <Image
        className="absolute inset-0 z-[0] lg:max-h-[470px] lg:object-left"
        src={
          isMobile
            ? mobile_bg
            : desktop_bg
        }
        // src="/assets/amild/winner/winner-desktop.png"
        alt="winner"
      />
      <div className="relative z-[1] grow-[1] max-w-[700px] mx-auto lg:max-w-none flex flex-col lg:flex-row lg:gap-[60px] 2xl:gap-[90px]">
        <div className="w-full grow-[1] lg:min-w-[400px] 2xl:min-w-[30%] lg:max-w-[400px] 2xl:max-w-[30%] py-[15px] lg:py-[25px] 2xl:py-[25px]">
          <div className="font-bold text-[32px] lg:text-[36px] leading-[36px] lg:leading-[40px] text-center lg:text-left font-font-family-3 mb-[10px] text-text-2">
            {title}
          </div>
          <div className="text-[14px] lg:text-[16px] leading-[24px] text-center lg:text-left mb-[20px] max-w-[323px] md:max-w-none mx-auto text-text-2">
            {description}
          </div>
        </div>
        <div
          ref={container}
          style={{
            background: isMobile ? "" : "rgba(89, 131, 83, 0.09)",
            backdropFilter: isMobile ? "" : "blur( 5px )",
          }}
          className="px-[17px] min-h-[100px] grow-[2] lg:rounded-[10px] lg:p-[0] h-[100%] lg:min-h-[370px] md:max-h-[370px] overflow-hidden lg:grow-[1]"
        >
          <div
            style={{ maxHeight: container?.current?.offsetHeight }}
            className="max-h-[100%] lg:min-h-[100%] md:max-h-[370px] lg:max-h-none p-[10px] overflow-y-auto"
          >
            <ol className="flex flex-col md:flex lg:grid grid-cols-2 gap-[5px] lg:gap-[10px]">
              {winnerList.map((item, idx) => (
                <li key={item.masking_name + idx}>
                  <WinnerCard pos={idx + 1} name={item.masking_name} />
                  {/* <WinnerCardSkeleton pos={idx+1}/> */}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </CampaignCard>
  )
}

export default CampaignWinner
