"use client"

import { capitalizeFirstLetter } from "@/helper/utils"

export const editProfile = {
  title: "Ubah Profil",
  description: `Update data diri ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"} biar lebih mudah dihubungi saat terpilih jadi pemenang merchandise eksklusif ${capitalizeFirstLetter(String(process.env.NEXT_PUBLIC_NAME))}.ID.`,
  personal_info: {
    section_title: "Data Diri",
    form_items: [
      {
        label: "Nama Lengkap Sesuai KTP",
        type: "text",
        placeholder: "Josh Thompson",
        disabled: true,
        key: "fullname",
        name: "fullname",
      },
      {
        label: "Email",
        type: "text",
        link_label: "Update Email",
        link: "/change-email",
        link_label_verified: "Verifikasi Email",
        link_verified: "#",
        placeholder: "joshthompson@mail.com",
        // disabled: true,
        key: "email",
        name: "email",
        // config: { required: "Silahkan isi email" }
      },
      {
        label: "Nomor Handphone",
        type: "phone",
        link_label: "Update Nomor",
        link: "/ubah-nomor",
        link_label_verified: "Verifikasi Nomor",
        link_verified: "/verifikasi-handphone",
        placeholder: "81234567890",
        key: "phone",
        name: "phone",
        config: { required: "Silahkan lakukan update nomor" },
      },
      // {
      //   label: "Nomor KTP",
      //   type: "text",
      //   info_label: "Kenapa [Brand] perlu KTP Lo?",
      //   info_content: "test",
      //   placeholder: "451345234523",
      //   info_label: 'Kenapa [Brand] perlu KTP Lo?',
      //   info_content: ''
      // },
      {
        label: "Tanggal Lahir",
        type: "date",
        key: "dob",
        disabled: true,
        name: "dob",
        // config: { required: "Silahkan pilih tanggal lahir" }
      },
      {
        label: "Jenis Kelamin",
        type: "select",
        key: "gender",
        name: "gender",
        placeholder: "Pilih Jenis Kelamin",
        options: [
          { value: "m", label: "Laki-laki" },
          { value: "f", label: "Perempuan" },
        ],
        config: { required: "Silahkan pilih gender" },
      },
    ],
  },
  address_info: {
    section_title: "Data Tempat Tinggal",
    form_items: [
      {
        label: "Kota",
        type: "select",
        placeholder: "Pilih Kota",
        key: "city_id",
        name: "city_id",
        config: { required: "Silahkan pilih kota" },
      },
      {
        label: "Alamat Lengkap",
        type: "textarea",
        placeholder:
          "Contoh: Budi Perumahan Indah Asri no.15. Jalan Panjang Raya, Kecamatan Kembangan, Kebun Jeruk, Jakarta Barat, 11530",
        key: "address",
        name: "address",
        config: { required: "Silahkan isi alamat" },
      },
    ],
  },
  brand_info: {
    section_title: "Preferensi Brand",
    form_items: [
      {
        // label: "Brand Rokok Favorit no.1",
        type: "select",
        placeholder: "Pilih Rokok",
        key: ["favorite_brand"]["brand_id"],
        name: "fav_brand",
        config: { required: "Silahkan pilih brand" },
      },
      // {
      //   label: "Brand Rokok Favorit no.2",
      //   type: "select",
      //   placeholder: 'Select One'
      // },
      {
        label: "Email promotional (Opsional)",
        type: "checkbox",
        placeholder: "Berhenti berlangganan email promosional",
        key: "subscribe_email_promo",
        name: "stop_subscribe_email_promo",
      },
    ],
  },
  // security_info: {
  //   section_title: "Pertanyaan Pengaman",
  //   form_items: [
  //     {
  //       label: "Pertanyaan Pengaman",
  //       type: "select",
  //       placeholder: "Select One"
  //     },
  //     {
  //       label: "Jawaban Pertanyaan",
  //       type: "text",
  //       placeholder: 'Tulis Jawaban Anda Di Sini'
  //     },
  //   ]
  // },
  submit_label: "Simpan Perubahan",
  cance_label: "Batal",
}

export const changePassword = {
  title: "Ubah Password",
  description: `Konfirmasi password lama ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"} untuk mengubah password yang baru.`,
  changePassword: {
    section_title: "Ubah Password",
    form_items: [
      {
        label: "Password Lama",
        type: "password",
        placeholder: "Masukkan Password Lama",
        name: "old_password",
      },
      {
        label: "Password Baru",
        type: "password",
        placeholder: "Masukkan Password Baru",
        name: "new_password",
      },
      {
        label: "Konfirmasi Password",
        type: "password",
        placeholder: "Konfirmasi Password",
        name: "confirm_password",
      },
    ],
  },
  submit_label: "Simpan Perubahan",
  cance_label: "Batal",
}

export const accountSetting = {
  title: "Pengaturan Akun",
  description: `Masukkan password ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"} untuk mengubah pengaturan akun.`,
  accountSetting: {
    section_title: "Pengaturan Akun",
    form_items: [
      {
        label: "Email/Nomor Telpon",
        type: "text",
        placeholder: "joshthompson@mail.com",
        name: "login_name",
        disabled: true,
      },
      {
        label: "Password",
        type: "password",
        placeholder: "Masukkan Password",
        name: "password",
      },
      {
        label: "Opsi Pengaturan Akun",
        isMulti: true,
        list: [
          {
            type: "checkbox",
            placeholder: "Email yang gue terima terlalu banyak",
            name: "spam",
          },
          {
            type: "checkbox",
            placeholder: "Gue tidak tertarik dengan informasinya",
            name: "uninterest",
          },
          {
            type: "checkbox",
            placeholder: "Gue berhenti merokok",
            name: "stop",
          },
          {
            type: "checkbox",
            placeholder: "Hapus akun gue secara permanen",
            name: "permanent_delete",
          },
        ],
      },
    ],
  },
  submit_label: "Simpan Perubahan",
  cance_label: "Batal",
}
