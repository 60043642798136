"use client"
import { Image } from "@/components/shared/image/image"
import { useIsMobile } from "@/hooks/use-is-mobile"
import React from "react"
import Link from "@/components/shared/link/link"
import { ClientApiList } from "@/lib/api/client-service"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import PropTypes from "prop-types"
import { useQuery } from "@tanstack/react-query"

export default function FAB(props) {
  const isMobile = useIsMobile()
  const { isLogin, isLoading } = useIsLogin()

  const { data: dataUser } = useQuery({
    queryKey: ["profileData"],
    queryFn: async () => {
      const { data } = await ClientApiList.getProfileData()
      return data?.data.data
    },
    enabled: Boolean(isLogin && !isLoading),
  })

  return (
    <>
      {!!dataUser?.tier_id && (
        <div className={`right-0 z-[15] top-[50%] fixed`}>
          <div className="w-[110px] h-[110px]">
            <Link href={props?.fab_redirect_url || "#"}>
              <Image
                data-testid="img"
                alt="floating button"
                src={isMobile ? props?.fab_mobile : props.fab_dekstop}
              />
            </Link>
          </div>
        </div>
      )}
    </>
  )
}

FAB.propTypes = {
  props: PropTypes.object,
  fab_redirect_url: PropTypes.string,
  fab_mobile: PropTypes.string,
  fab_dekstop: PropTypes.string,
}
