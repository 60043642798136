"use client"
import React, { useRef, useState } from "react"
import Link from "@/components/shared/link/link"
import "swiper/css"
import BackBanner from "../shared/back-banner"
import { Dots, NextButton, PrevButton, SwiperComp } from "../swiper/swiper"
import { SwiperSlide } from "swiper/react"
import { Image } from "@/components/shared/image/image"
import { useIsMobile } from "@/hooks/use-is-mobile"
import ButtonBack from "../shared/button-back/button-back"
import PropTypes from "prop-types"
import { useDatalayer } from "@/hooks/use-datalayer"

export function EventBannerCalendar({
  autoplay,
  bg_img_url,
  data,
  show_navigation,
  speed,
}) {
  const swiperRef = useRef()
  const [activeIndex, setActiveIndex] = useState(0)
  const isMobile = useIsMobile()
  const slidesPerView = 1
  const spaceBetween = 0
  const items = data.items
  const datalayer = useDatalayer()

  const onClickDatalayer = (name, index) => {
    datalayer.push({
      event: "general_event",
      event_name: "header_event_banner",
      feature: "banner",
      banner_name: name, // e.g discover new experiences now!
      banner_position: index, // e.g 1
      // page_type: '{{page category}}', // e.g special engagement
      // campaign_name: '{{campaign}}’, // e.g all
    })
  }
  // const handleNext = () => {
  //     if (activeIndex + 1 < items.length)

  //         setActiveIndex(prevState => prevState + 1);
  // };

  // const handlePrev = () => {
  //     if (activeIndex - 1 >= 0)
  //         setActiveIndex(prevState => prevState - 1);
  // };
  //

  return (
    <div className="lg:sticky top-[79px] w-[100%] lg:max-w-[50%] lg:max-h-[609px] w-full">
      <ButtonBack className="z-[15] lg:top-[15px] lg:left-[15px]" />
      <BackBanner
        // bgImg='/assets/dummy-bg-dekstop.png'
        bgImg={
          isMobile
            ? data.bg_img_url.mobile_image_url
            : data.bg_img_url.desktop_image_url
        }
        className="lg:max-h-[609px] bg-bg-5"
      />

      {/* </BackBanner> */}
      <div className="relative z-[10] p-[10px] lg:p-0 w-[100%] mb-[20px] lg:mb-[60px] lg:top-[0]">
        <div className="text-center text-[20px] leading-[27px] lg:text-[24px] lg:leading-[18px] font-medium font-font-family-6 mt-[10px] lg:mt-[30px] mb-[76px] lg:mb-[44px] text-text-4">
          Events
        </div>
        <div className="relative lg:mx-[13px] min-h-[199px] md:min-h-[376px] ">
          {items.length > 0 ? (
            <>
              <PrevButton className="" swiperRef={swiperRef} />
              <SwiperComp
                autoplay={autoplay}
                setActiveIndex={setActiveIndex}
                ref={swiperRef}
                slidesPerView={slidesPerView}
                spaceBetween={spaceBetween}
                className="left-0"
              >
                {items.map((item, index) => {
                  return (
                    <SwiperSlide key={item.id}>
                      <Link
                        href={item.href}
                        onClick={() => onClickDatalayer("test banner", index)}
                      >
                        <div
                          className={` min-h-[199px] md:min-h-[376px] h-full max-h-[271px] max-w-[671px] mx-auto relative rounded-[10px] overflow-hidden`}
                        >
                          <Image
                            // objectFit="contain"
                            alt="containe"
                            className="z-[1] object-center absolute"
                            // src='/assets/dummy-bg.png'
                            src={
                              isMobile
                                ? item.mobile_image_url
                                : item.desktop_image_url
                            }
                          />
                        </div>
                      </Link>
                    </SwiperSlide>
                  )
                })}
              </SwiperComp>
              <Dots
                activeIndex={activeIndex}
                items={items.map((item, index) => ({ id: index }))}
              />
              <NextButton swiperRef={swiperRef} />
            </>
          ) : null}
        </div>
        {/* <BannerItem type='tertiary' items={items} speed={speed} autoplay={autoplay} show_navigation={show_navigation} /> */}
      </div>
    </div>
  )
}

EventBannerCalendar.propTypes = {
  autoplay: PropTypes.number,
  bg_img_url: PropTypes.string,
  data: PropTypes.object,
  show_navigation: PropTypes.bool,
  speed: PropTypes.number,
}
