"use client"

import { getQueryParams } from "@/helper/queryparam/get-query-params"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { ClientApiList } from "@/lib/api/client-service"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { useEffect, useState } from "react"
import { ToastComponent } from "../toast"
import { Image } from "../shared/image/image"
import { Spinner } from "../shared/spinner"
import DOMPurify from "isomorphic-dompurify"
import UseQueryParams from "@/hooks/use-query-params";

const VerifyEmail = (props) => {
  const QUERYPARAM = "point-verify"
  const { handlePushQuery } = UseQueryParams()
  const searchParams = useSearchParams()
  const { replace } = useRouter()
  const pathname = usePathname()
  const [isSuccess, setIsSuccess] = useState(false)
  const [point, setPoint] = useState(0)
  const error = getQueryParams("errorCode")
  const { isLoading } = useIsLogin()

  const handleGetProfile = async () => {
    try {
      await ClientApiList.getProfileData()
    } catch (err) {
      // console.log("[Err]", err)
    }
  }

  const handleGetPoint = async () => {
    try {
      const resultCheckPoint = await ClientApiList.checkUserPoint()
      // console.log(resultCheckPoint, "checkpoint")
      if (
        resultCheckPoint.status === 200 &&
        resultCheckPoint.data.data.activity_point.verify_email.status &&
        !resultCheckPoint.data.data.activity_point.verify_email.popup_displayed
      ) {
        try {
          const resultUpdatePopup = await ClientApiList.updatePopup({
            activity: "verify_email",
          })
          // console.log(resultUpdatePopup)
          if (resultUpdatePopup.status === 200) {
            setPoint(
              resultCheckPoint.data.data.activity_point.verify_email.point_get
            )
            setTimeout(() => setIsSuccess(true), 100)
            setTimeout(() => {
              handlePushQuery(false, [QUERYPARAM, true])
            }, 300)
          }
        } catch (err) {
          // console.log(err, "err")
        }
      } else {
        console.log(resultCheckPoint)
      }
    } catch (err) {
      console.log("[Err]", err)
    }
  }

  const getAllData = async () => {
    await handleGetProfile()
    await handleGetPoint()
  }

  useEffect(() => {
    if (searchParams.get("errorCode") == 0) {
      getAllData()
    }
  }, [])

  const data = error == 0 ? props.complete : props.timeout

  const titleBrand =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? `Berhasil Verifikasi, Kamu Berhasil Raih ${point} Poin`
      : `Berhasil Verifikasi, Lo Berhasil Dapetin ${point} Poin`

  const brandName = process.env.NEXT_PUBLIC_NAME.toUpperCase()

  return (
    <>
      <ToastComponent
        onClick={() => {
          // setToastMessage('Update nomor berhasil')
          setIsSuccess(false)
        }}
        title={titleBrand}
        desc={`Lanjut eksplor ${brandName}.ID sekarang!`}
        isOpen={isSuccess}
      />
      <div className="relative p-[50px] lg:p-0 lg:pt-[135px] max-w-[470px] mx-auto">
        {!isLoading ? (
          <>
            <div className="w-[100%] max-w-[304px] mx-auto min-h-[177px] relative mb-[20px]">
              <Image
                src={data.icon}
                alt="verify-email"
                objectFit="contain"
                className="absolute inset-0"
              />
            </div>
            <div className="text-[20px] lg:text-[24px] leading-[28px] lg:leading-[32px] text-center font-bold font-font-family-7 text-text-2 mb-[10px]">
              {data.title}
            </div>
            <div
              className="text-[16px] leading-[24px] text-center text-text-2 pb-[20px]"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.desc),
              }}
            ></div>
          </>
        ) : (
          <div className="h-[calc(100vh-170px)] flex items-center">
            <Spinner />
          </div>
        )}
      </div>
    </>
  )
}

export default VerifyEmail
